import React, { useEffect, useState } from "react";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import moment from "moment";
import style from "./Project.module.css";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { HtmlIntoString } from "../../Components/Common/HtmlIntoText";
const ViewProjectInformation = ({ projectData }) => {
  let [projectInfo, setProjectInfo] = useState({});
  const skeletonData = [
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 2, height: "1.2rem" },
    { qty: 1, height: "6rem" },
    { qty: 1, height: "6rem" },


  ]


  useEffect(() => {
    setProjectInfo(projectData);
  }, [projectData]);
  return (
    <>
      {
        projectData ?
          (


            <>

              <h5 className="my-3"> Project Details</h5>
              <div className="row">
                <div className="col-md-6 col-12">



                  <ViewPageTable
                    tableData={[
                      { "Project Name": projectInfo?.project_name || "--" },
                      { "Project Number": projectInfo?.projectNumber || "--" },
                      { "Project Type": projectInfo?.project_department || "--" },
                      { "Estimated Project Value": projectInfo?.estimated_project_value?.price !== undefined? `${projectInfo?.estimated_project_value?.price} ${projectInfo?.estimated_project_value?.unit}` : "--", },
                      { "Project Area": projectInfo?.project_square_feet?.measurement !== undefined? `${projectInfo?.project_square_feet?.measurement} ${projectInfo?.project_square_feet?.unit}` : "--", },
                      { "Number of Zone": projectInfo?.no_of_sections || "--", },
                      { "Expected Start Date": moment(projectInfo?.start_date).format("DD/MM/YYYY") || "--", },
                      { "Street Address": projectInfo?.address || "--" },
                      { "Project City": projectInfo?.city || "--" },
                      { Country: projectInfo?.country || "--" },
                      { County: projectInfo?.postcode_country || "--" },
                      { 'Reviewer Comment': projectInfo?.reviewer_comment || "--" },


                    ]}
                  />



                </div>
                <div className="col-md-6 col-12 ">
                  <ViewPageTable
                    tableData={[
                      { "Project Status": projectInfo?.project_status || "--" },
                      { "Project Department": projectInfo?.project_department || "--" },
                      { "Project Scope": projectInfo?.project_type || "--" },
                      { "Project Area": projectInfo?.project_square_feet?.measurement !== undefined? `${projectInfo?.project_square_feet?.measurement} ${projectInfo?.project_square_feet?.unit}` : "--", },
                      {
                        "Number of floors": projectInfo?.no_of_floors || "--",
                      },
                      {
                        "Expected Completion Date":
                          moment(projectInfo?.completion_date).format("DD/MM/YYYY") ||
                          "--",
                      },
                      {
                        "Time Zone": projectInfo?.time_zone || "--",
                      },
                      { State: projectInfo?.state || "--" },
                      { locality: projectInfo?.locality || "--" },
                      { "Post Code": projectInfo?.zip_code || "--" },
                      { Phone: projectInfo?.phone || "--" },
                      { 'Approver Comment': projectInfo?.approver_comment || "--" },
                    ]}
                  />
                </div>

              </div>

              <div className="py-3">


                <hr className="" />
              </div>
              <div className="row">


                <div className="col-12">

                  <div className="gray-lt mb-2">Project Description: </div>

                  <div className="gray-d"> {HtmlIntoString({ html: projectInfo?.project_description })}</div>


                </div>

                <div className="col-12">
                  <div className="view_page_para_head mt-4 mb-2">Project Photos:</div>
                  <div className="d-flex flex-wrap gap-3">
                    <ViewPageDocumentName docUrl={projectInfo?.project_logo?.length !== 0 && projectInfo?.project_logo !== undefined?projectInfo?.project_logo[0]?.fileUrl:''} name={projectInfo?.project_logo?.length !== 0 && projectInfo?.project_logo !== undefined?projectInfo?.project_logo[0]?.fileUrl:''} />

                  </div>

                </div>


                <div className="col-12 mt-4">


                  <div className="view_page_para_head mt-4 mb-2">Project Photos:</div>
                  <div className="d-flex flex-wrap gap-3">
                    {projectInfo?.project_photos?.map((res, key) => (
                      <ViewPageDocumentName docUrl={res?.url} key={key} name={res?.fileName} />
                    )
                    )}
                  </div>

                </div>
              </div>


            </>) :
          <FormSkeleton data={skeletonData} mt={"mt-1"} gap={"gap-2"} />
      }
    </>
  );
};

export default ViewProjectInformation;
