import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";

const ProjectProgress = ({ overViewGetData }) => {
  const [donutData, setDonutDatas] = useState([
    {
      name: "Complete",
      data: 0,
      additionalLabel: "Additional Info 1",
    },
    {
      name: "Ready for Review",
      data: 0,
      additionalLabel: "Additional Info 1",
    },
    {
      name: "Initiated",
      data: 0,
      additionalLabel: "Additional Info 4",
    },
    {
      name: "Not Started",
      data: 0,
      additionalLabel: "Additional Info 3",
    },
  ])




  const chartDataget = async () => {
    let obj = [
      {
        name: "Complete",
        data: 0,
        additionalLabel: "Additional Info 1",
      },
      {
        name: "Ready for Review",
        data: 0,
        additionalLabel: "Additional Info 1",
      },
      {
        name: "Initiated",
        data: 0,
        additionalLabel: "Additional Info 4",
      },
      {
        name: "Not Started",
        data: 0,
        additionalLabel: "Additional Info 3",
      },
    ];

    overViewGetData?.forEach(e => {
      if (e.status === "approved") {
        obj[0].data = obj[0].data + 1
      } else if (e.status === "pendingapproval") {
        obj[1].data = obj[1].data + 1
      } else if (e.status === "inreview") {
        obj[2].data = obj[2].data + 1
      } else if (e.status === "draft") {
        obj[3].data = obj[3].data + 1
      }



    });

    setDonutDatas(obj)
  }

  useEffect(() => {
    chartDataget()
  }, [overViewGetData])


  const total = donutData.reduce((sum, item) => sum + item.data, 0);

  const options = {
    chart: {
      type: "donut",
    },
    labels: donutData.map(item => {
      const percentage = (item.data / total * 100).toFixed(1);
      return `${item.name} (${isNaN(percentage) ? 0 : (percentage || 0)})%`;
    }),
    dataLabels: {
      enabled: false,
    },
    colors: ["#a0d468", "#4fc1e9", "#ffce54", "#ed5565"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              showAlways: true,
              label: 'Total Responses',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
              }
            }
          }
        }
      }
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center', // Aligns legends in the center horizontally
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: 400,
      width: '100%', // Customize width as needed
      formatter: function (val, opts) {
        return val;
      }
    }
  };

  return (
    <div>
      <div>
        <ReactApexChart
          options={options}
          series={donutData.map((item) => item.data)}
          type="donut"
        />
      </div>
    </div>
  );
}

export default ProjectProgress;
