import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { BorderedTable } from "../Tables/BorderedTable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { getTaskVersionHistory, getVersionHistoryById, getTransmittalVersionHistory, getObservationVersionHistory, getpunchVersionHistory, getSubmittalVersionHistory, getincidentVersionHistory, getSpecificationVersionHistory, getIssueVersionHistory, getdailyLogVersionHistory, getRFIVersionHistory, getFileVersionHistory, getInspectionVersionHistory, getMeetingVersionHistory } from "../../Api/Admin/AllAPIs";
import { TableSkelton } from "./TableSkelton";
import { formatDateInIST, formatKeysValues, getActionsPermission, getProjectId, toast } from "../../utils";
import { HtmlIntoText } from "./HtmlIntoText";
import { useSelector } from "react-redux";

export default function ViewVersion({ isView }) {
  const navigate = useNavigate();
  const [skeletonLoader, setSkeletonLoader] = useState(true)
  const { heading, name, id } = useParams();

  const projectId = localStorage.getItem('projectId')
  const [version, setVersion] = useState([])

  let permissionData = useSelector(e => e.rolesAndPermissionData)


  useEffect(() => {
    if (permissionData.data?.length > 0 && !isView) {
    
      let isAccess = true;
      switch (heading.toLowerCase()) {
        case "task":
          isAccess = getActionsPermission("Task version history");
          break;
        case "rfi":
          isAccess = getActionsPermission("Rfi version history");
          break;
        case "submittal":
  
          isAccess = getActionsPermission("Submittals version history");
          break;
        case "transmittal":
          isAccess = getActionsPermission("Transmittal version history");
          break;
        case "specification":
          isAccess = getActionsPermission("Specifications version history");
          break;
        case "observation":
          isAccess = getActionsPermission("Observation Version history");
          break;
        case "issue":
          isAccess = getActionsPermission("Issue version history");
          break;
        case "punch":
          isAccess = getActionsPermission("Punch version History");
          break;
        case "incident":
          isAccess = getActionsPermission("Incident version history");
          break;
        case "inspection":
          isAccess = getActionsPermission("Inspection version history");
          break;
        case "dailylog":
          isAccess = getActionsPermission("Daily Log version history");
          break;
        case "drawing":
          isAccess = getActionsPermission("Drawing version history");
          break;
        case "drawing":
          isAccess = getActionsPermission("Drawing version history");
          break;
        case "photo":
          isAccess = getActionsPermission("Photos version history");
          break;
        case "model":
          isAccess = getActionsPermission("Model version history");
          break;
        case "meeting":
          isAccess = getActionsPermission("Meeting version History");
          break;

      }

      if (!isAccess) {
        navigate("/innernoAccess")
        toast.error("You don't have permission to access version history")
      }
    }
  }, [permissionData])

  const fetchVersion = async () => {


    try {

      let body = {
        project_id: getProjectId(),
        item_id: id
      }

      let body1 = {
        project_id: getProjectId(),
        item_id: id,
        role: heading
      }

      let res;
      switch (heading.toLowerCase()) {
        case "task":
          res = await getTaskVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "rfi":
          res = await getRFIVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "submittal":
          res = await getSubmittalVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "transmittal":
          res = await getTransmittalVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "specification":
          res = await getSpecificationVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "observation":
          res = await getObservationVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "issue":
          res = await getIssueVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "punch":
          res = await getpunchVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "incident":
          res = await getincidentVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "dailylog":
          res = await getdailyLogVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;
        case "inspection":
          res = await getInspectionVersionHistory(body)
          setVersion(res?.data?.data || [])
          break;

        case "document":
          res = await getFileVersionHistory(body1)
          setVersion(res?.data?.data || [])
          break;
        case "photo":
          res = await getFileVersionHistory(body1)
         
          setVersion(res?.data?.data || [])
          break;

        case "model":
          res = await getFileVersionHistory(body1)
          setVersion(res?.data?.data || [])
          break;

        case "drawing":
          res = await getFileVersionHistory(body1)
          setVersion(res?.data?.data || [])
          break;
        case "meeting":
          res = await getMeetingVersionHistory(body1)
          setVersion(res?.data?.data || [])
          break;

        default:
          const payload = {
            tool_name: heading.toLocaleLowerCase(),
            item_id: id
          }
          setSkeletonLoader(true);
          let res2 = await getVersionHistoryById(projectId, payload)
          setVersion(res2.data.data.reverse());
          break
      }

    }
    catch (err) {
      console.log(err)
    }
    finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    fetchVersion()
  }, [])

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // getTime() will return NaN if the date is invalid
  }

  function parseData(val) {

    if (isValidDate(val)) {
      return formatDateInIST(val)
    }

    return <HtmlIntoText html={val} />



  }


  function formatDate(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric', // Add seconds if you need them
      hour12: true // Use 12-hour time format, change to false for 24-hour format
    };
    const date = new Date(dateString);
    return date.toLocaleString(undefined, options);
  }
  const keyNameTemplate = (rowData) => {
    return (
      <div>
        {rowData.changes.map((change, index) => (
          <div className="mb-2 text-capitalize" style={{ minHeight: "20px" }} key={index}>
            {formatKeysValues(change.keyName)}
          </div>
        ))}
      </div>
    );
  };

  const preValTemplate = (rowData) => {

    return (
      <div>
        {rowData.changes.map((change, index) => (
          <div className="mb-2" style={{ minHeight: "20px" }} key={index}>
            {change?.preVal === "" ? "---" : (parseData(change?.preVal) || "--")}
          </div>
        ))}
      </div>
    );
  };

  // Function to render the newVal
  const newValTemplate = (rowData) => {
    return (
      <div>
        {rowData.changes.map((change, index) => (
          <div className="mb-2" style={{ minHeight: "20px" }} key={index}>
            {parseData(change?.newVal)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ maxWidth: "100vw", overflow: "auto" }}>
      {!isView && <button onClick={() => navigate(-1)} className="btn border mb-2">Back</button>}
      <div className={` d-flex flex-column gap-4 card`}>

        {/* {!isView &&
          <>
            <div className="d-flex gap-1 align-items-center">
              <div className="gray-ult" style={{ cursor: 'pointer' }}>{heading}/{name}/</div>
              <div className="gray-d">Version History</div>
            </div>
          </>
        } */}
        <div className="d-flex between align-items-center">

          <h1>Version History {heading}</h1>
          {/* <div className="d-flex gap-1 align-items-center">
            <AiOutlineExport className="pe-1" />
            Export
            <MdOutlineKeyboardArrowDown />
          </div> */}
        </div>
        {/* <div className=" w-100 d-flex between align-items-center">
          <div className="  w-25  SearchInputC">
            <SearchInput
              value={""}
              onChange={(e) => {
                // setSearch(e.target.value);
              }}
            />
          </div>
          <div className=" d-flex justify-content-end">
            <button
              className="filter_btn me-3"
            //   onClick={() => setVisible(true)}
            >
              Filter RFI{" "}
            </button>
            <button
              className={`clear_btn `}
            
              onClick={""}
            >
              Clear All
            </button>
          </div>
        </div> */}

        <div className="drawingTable d-flex flex-column gap-3 mt-1">
          {!isView && <h2>Changes History</h2>}
          {
            skeletonLoader ?
              <TableSkelton header={["Version", "Date", "Change", "From", "To"]} />
              :
              <BorderedTable>
                <DataTable
                  value={version}
                //   footer={customFooter}
                >
                  <Column
                    field="#"
                    header="Version"
                    headerStyle={{ width: "4rem", fontWeight: "bold" }}
                    body={(rowData, rowIndex) => (
                      <div className="d-flex justify-content-center">
                        {rowIndex.rowIndex + 1}
                      </div>
                    )}
                  ></Column>


                  <Column headerStyle={{ fontWeight: "bold" }}
                    field="date"
                    header="Date"
                    body={(rowData) => formatDate(rowData.date)}
                  ></Column>

                  <Column headerStyle={{ fontWeight: "bold" }}
                    field="user_name" header="Done By"></Column>

                  <Column headerStyle={{ fontWeight: "bold" }}
                    field="keyName"
                    header="Change"
                    body={keyNameTemplate}

                  />
                  <Column headerStyle={{ fontWeight: "bold" }}
                    field="preVal"
                    header="From"
                    body={preValTemplate}
                  // style={{margin:"10px 0"}}
                  />
                  <Column headerStyle={{ fontWeight: "bold" }}
                    field="newVal"
                    header="To"
                    body={newValTemplate}
                  />




                </DataTable>
              </BorderedTable>}
        </div>
      </div>
    </div>
  );
}
