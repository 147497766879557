import React, { useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog';
import { getProjectHistory, getVersionHistoryById, sendEmailWithAttachment, getTransmittalVersionHistory, getObservationVersionHistory, getTaskVersionHistory, getFileVersionHistory, getMeetingVersionHistory, getInspectionVersionHistory } from '../../../Api/Admin/AllAPIs';
import { BorderedTable } from '../../Tables/BorderedTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import EmailTemplateWithPdf from '../EmailTemplateWithPdf';
import jsPDF from 'jspdf';
import { downloadPdf } from './downloadPdf';
import { Checkbox } from 'primereact/checkbox';
import { getProjectId, toast } from '../../../utils';
import Loader from '../Loader';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { PdfHeaderAndFooter } from '../PagesComponents/PdfHeaderAndFooter';


export const DownloadPdfAndSendEmail = ({ children, heading, id, fileName, files, forProject, popupShow, hideButton }) => {
    let [dialog, setDialog] = useState(false)

    let pdfRef = useRef(null);
    let buttonRef = useRef(null);
    let [loader, setLoader] = useState(false);
    const projectId = localStorage.getItem('projectId')
    const [dataFetched, setDataFetched] = useState(false);
    const [includeVersionHistory, setIncludeVersionHistory] = useState(false);
    const [versionHistory, setVersionHistory] = useState([])
 
    useEffect(() => {
        if (popupShow) {
            buttonRef.current.click()
        }

    }, [popupShow])

    const fetchVersion = async () => {

        const payload = {
            tool_name: heading.toLocaleLowerCase(),
            item_id: id
        }
        try {
            let body = {
                project_id: getProjectId(),
                item_id: id
            }

            let body1 = {
                project_id: getProjectId(),
                item_id: id,
                role: heading
            }
            let res = []
            if (forProject) {
                res = await getProjectHistory(projectId)
            } else {

                switch (heading.toLowerCase()) {
                    case "transmittal":
                        res = await getTransmittalVersionHistory(body)
                        setVersionHistory(res?.data?.data || [])
                        break;
                    case "task":
                    
                        res = await getTaskVersionHistory(body)
                        setVersionHistory(res?.data?.data || [])
                        break;

                    case "observation":
                        res = await getObservationVersionHistory(body)
                        setVersionHistory(res?.data?.data || [])
                        break;

                    case "document":
                        res = await getFileVersionHistory(body1)
                        setVersionHistory(res?.data?.data || [])
                        break;
                    case "photo":
                        res = await getFileVersionHistory(body1)
                        setVersionHistory(res?.data?.data || [])
                        break;

                    case "model":
                        res = await getFileVersionHistory(body1)
                        setVersionHistory(res?.data?.data || [])
                        break;

                    case "drawing":
                        res = await getFileVersionHistory(body1)
                        setVersionHistory(res?.data?.data || [])
                        break;
                    case "meeting":
                        res = await getMeetingVersionHistory(body1)
                        setVersionHistory(res?.data?.data || [])
                        break;
                    case "inspection":
                        res = await getInspectionVersionHistory(body1)
                      
                        setVersionHistory(res?.data?.data || [])
                        break;




                    default:
                        const payload = {
                            tool_name: heading.toLocaleLowerCase(),
                            item_id: id
                        }
                        let res2 = await getVersionHistoryById(projectId, payload).then(ress => { return ress.data.data })
                        setVersionHistory(res2 || [])
                }

            }
            setDataFetched(true)

        }
        catch (err) {
            console.log(err)
        }

    }
    const keyNameTemplate = (rowData) => {
        return (
            <div>
                {rowData.changes.map((change, index) => (
                    <div className="mb-2" key={index}>{change.keyName}</div>
                ))}
            </div>
        );
    };
    const preValTemplate = (rowData) => {
        return (
            <div>
                {rowData.changes.map((change, index) => (
                    <div className="mb-2" key={index}>{change.preVal}</div>
                ))}
            </div>
        );
    };

    // Function to render the newVal
    const newValTemplate = (rowData) => {
        return (
            <div>
                {rowData.changes.map((change, index) => (
                    <div className="mb-2" key={index}>{change.newVal}</div>
                ))}
            </div>
        );
    };
    function formatDate(dateString) {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric', // Add seconds if you need them
            hour12: true // Use 12-hour time format, change to false for 24-hour format
        };
        const date = new Date(dateString);
        return date.toLocaleString(undefined, options);
    }

    useEffect(() => {
        if (versionHistory && !dataFetched) {

            fetchVersion()
        }
    }, [versionHistory])



    async function sendEmail(payload) {


        const opt = {
            margin: [0.2, 0.2], // Margins: [top, left, bottom, right]
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };


        const res = await html2pdf().from(pdfRef.current).set(opt).output('blob');

        setLoader(true)
        // const doc = new jsPDF();
        try {
          

            const reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onloadend = async function () {
                const base64data = reader.result.split(',')[1];
                const formattedAttachment = {
                    content: base64data,
                    filename: fileName ? `${fileName}.pdf` : 'toolData.pdf',
                    encoding: "base64",
                    contentType: 'application/pdf',
                };
              

                const body = {
                    subject: `${fileName || "Tool"}  Details`,
                    to: payload.to,
                    text: "This is text of email",
                    cc: payload.cc,
                    bcc: payload.bcc,
                    attachments: formattedAttachment,
                    htmlData: payload.text
                }
                await sendEmailWithAttachment(body)
                // doc.save("rfi.pdf");
                setLoader(false)
                setDialog(false)
                toast.success("Email Sent Successfully")
            }
        }
        catch (err) {
            // toast.success("Email Sent Successfully !")
            toast.error("Some Error Occured")
            setLoader(false)
            console.log(err)
        }
    }
    return (
        <>
            <div ref={buttonRef} style={{ opacity: hideButton ? '0' : '1' }} onClick={(event) => {
                event.stopPropagation();

                setDialog(true)
            }}>
                Send Email
            </div>

            <div style={{ height: "0px", width: "0px", overflow: "hidden" }}>





                <div ref={pdfRef} id="testtest" style={{ background: 'white' }}>
                    <PdfHeaderAndFooter>
                        <div>
                            {children}
                        </div>
                        {includeVersionHistory &&
                            <div className='px-3'>
                                <br />
                                <div className='heading'>Version History</div>
                                <br />
                                <BorderedTable>
                                    {forProject ? <DataTable
                                        value={versionHistory}
                                    //   footer={customFooter}
                                    >
                                        <Column
                                            field="#"
                                            header="Version"
                                            headerStyle={{ width: "4rem", fontWeight: "bold" }}
                                            body={(rowData, rowIndex) => (
                                                <div className="d-flex justify-content-center">
                                                    {rowIndex?.rowIndex + 1}
                                                </div>
                                            )}
                                        ></Column>


                                        <Column headerStyle={{ fontWeight: "bold" }}
                                            field="date"
                                            header="Date & time"
                                            body={(rowData) => moment(rowData?.date)?.format('DD-MM-YYYY h:mm a')}
                                        ></Column>


                                        <Column headerStyle={{ fontWeight: "bold" }}
                                            field="stageName"
                                            header="Change In"
                                        // body={keyNameTemplate}

                                        />
                                        <Column headerStyle={{ fontWeight: "bold" }}
                                            field="user_name"
                                            header="Changed By"
                                        // body={preValTemplate}
                                        // style={{margin:"10px 0"}}
                                        />

                                    </DataTable> :
                                        <DataTable
                                            value={versionHistory}
                                        //   footer={customFooter}
                                        >
                                            <Column
                                                field="#"
                                                header="Version"
                                                headerStyle={{ width: "4rem", fontWeight: "bold" }}
                                                body={(rowData, rowIndex) => (
                                                    <div className="d-flex justify-content-center">
                                                        {rowIndex.rowIndex + 1}
                                                    </div>
                                                )}
                                            ></Column>


                                            <Column headerStyle={{ fontWeight: "bold" }}
                                                field="date"
                                                header="Date"
                                                body={(rowData) => formatDate(rowData.date)}
                                            ></Column>
                                            {/* <Column headerStyle={{ fontWeight: "bold" }}
                                            field="user_name" header="Done By"></Column> */}

                                            <Column headerStyle={{ fontWeight: "bold" }}
                                                field="keyName"
                                                header="Change"
                                                body={keyNameTemplate}

                                            />
                                            <Column headerStyle={{ fontWeight: "bold" }}
                                                field="preVal"
                                                header="From"
                                                body={preValTemplate}
                                            // style={{margin:"10px 0"}}
                                            />
                                            <Column headerStyle={{ fontWeight: "bold" }}
                                                field="newVal"
                                                header="To"
                                                body={newValTemplate}
                                            />




                                        </DataTable>}
                                </BorderedTable>
                            </div>
                        }
                    </PdfHeaderAndFooter>
                </div>




            </div>

            <Dialog
                header={"Send Email"}
                onClick={(e) => e.stopPropagation()}
                visible={dialog}
                onHide={() => setDialog(false)}
                draggable={false}
                style={{ width: "50vw" }}
            >
                <div className='d-flex gap-2 algin-items-center mb-2'>
                    <Checkbox
                        checked={includeVersionHistory}
                        onChange={(e) => setIncludeVersionHistory(e.target.checked)} />
                    <label htmlFor="" className="labelCss">
                        Include Version History
                    </label>
                </div>
                <EmailTemplateWithPdf loader={loader} isDialog={false} sendEmail={sendEmail} />
            </Dialog>

        </>
    );
};
