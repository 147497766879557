import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDailyLogDataById } from '../../Api/Admin/AllAPIs';
import FormSkeleton from '../../Components/Common/FormSkeleton';
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable';
import moment from 'moment';
import { ViewPageDocumentName } from '../../Components/Common/ViewPageComponents/ViewPageDocumentName';
import ViewVersion from '../../Components/Common/ViewVersion';
import { AiOutlineExport } from 'react-icons/ai';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf';

export const ViewDailyLog = ({ includeVersionHistory, usedAsComponent }) => {
    const { id } = useParams();
    const [dailyLogData, setDailyLogData] = useState({});
    const [skeltonLoader, setSkeletonLoader] = useState(false);
    const pdfRef = useRef(null)
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getDailyLog(id);
        }
    }, [id]);

    async function getDailyLog(id) {
        try {
            setSkeletonLoader(true);
            let res = await getDailyLogDataById(id);
         
            let response = res?.data?.data?.[0] || {};
            setDailyLogData(response);
        } catch (err) {
            console.log(err);
        } finally {
            setSkeletonLoader(false);
        }
    }

    const skeletonData = [
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
    ];

    function showDateAndTime(date) {
        return `${moment(date).format('DD-MM-YYYY')} / ${moment.utc(date).format("HH:mm")}`
    }

    function downloadPdfhandler() {
        downloadPdf(pdfRef.current, [
            {
                heading: "Attached Documents", files: dailyLogData?.Attachments.map(e => e.fileUrl)
            }
        ], "dailyLog")
    }

    return (
        <div>

            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <span
                        className="open_Rfi_text"
                        onClick={() => navigate("/projectManagement/dailyLog")}
                    >
                        Daily Log
                    </span>
                    <span className="px-1 open_Rfi_text">/</span>
                    <span className="createNew_text"> View Daily Log</span>
                </div>

                {!usedAsComponent && <div className="end">
                    <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={downloadPdfhandler}
                    >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                    </div>
                </div>}

            </div>


            <div ref={pdfRef} className='p-3 bg-white'>

                <div className="newRfi_text">
                    DAILY LOG
                </div>
                <br />
                <div>

                    {skeltonLoader ?
                        <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
                        <div>
                            <div className="row">
                                <div className="col-6">
                                    <ViewPageTable tableData={[
                                        { "Visit Id": dailyLogData?.dailyLogId },
                                        { "Visitor Name": dailyLogData?.visitorName },
                                        { "Visitor Company Name": dailyLogData.visitorCompanyName },
                                        { "Visitor Visit Purpose": dailyLogData.visitPurpose },
                                        { "Actual Start Date/Time": showDateAndTime(dailyLogData?.actualStartDate) },
                                        { "Actual End Date/Time": showDateAndTime(dailyLogData?.actualEndDate) },
                                        { "Planned Start Date": showDateAndTime(dailyLogData?.plannedStartDate) },
                                        { "Planned End Date": showDateAndTime(dailyLogData?.plannedEndDate) },
                                    ]} />
                                </div>
                                <div className="col-6">
                                    <ViewPageTable tableData={[
                                        { "Company Address": dailyLogData?.companyAddress },
                                        { "Hours Spent": `${dailyLogData?.hoursSpent?.split("-")[0]} Hr ${dailyLogData?.hoursSpent?.split("-")[1]} Min` },
                                        { "Visit Status": dailyLogData?.visitStatus },
                                        { "Company Type": dailyLogData?.companyType },
                                        { "Reviewer": dailyLogData?.approver?.[0]?.first_name },
                                        { "Approver": dailyLogData?.reviewer?.[0]?.first_name },
                                        { "Approver Comment": dailyLogData?.approver_comment || "--" },
                                        { "Reviewer Comment": dailyLogData?.reviewer_comment || "--" }

                                    ]} />
                                </div>
                            </div>

                            <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
                            <div className="d-flex flex-wrap gap-3">
                                {dailyLogData?.Attachments?.map((res, key) => (
                                    <ViewPageDocumentName docUrl={res?.fileUrl} key={key} name={res?.fileName} />
                                )
                                )}
                            </div>

                        </div>

                    }

                    {(!usedAsComponent || includeVersionHistory) &&
                        <>
                            <hr />
                            <ViewVersion isView={true} />
                        </>
                    }
                </div>

                {/* vvvv  */}
            </div>


            {!usedAsComponent &&
                <div className="d-flex justify-content-end mt-2">
                    <button className='btn border' onClick={() => navigate("/projectManagement/dailyLog")}>Back</button>
                </div>
            }


            <br />
            
        </div>
    );
};
