import React, { useEffect, useState } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { InputNumber } from "primereact/inputnumber";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { addUser, getCompany, getallrole, uploadDocs } from "../../Api/Admin/AllAPIs";
import { toast } from "../../utils";
import Loader from "../../Components/Common/Loader";
import { RadioButton } from "primereact/radiobutton";
import { getTimeZoneOptions } from "../../Components/Common/timeZone";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
function AddUserInfo({ paddingneeded, condition }) {

  let [userType, setUserType] = useState("individual")

  const timeZoneOptions = getTimeZoneOptions();

  const [checked, setChecked] = useState(false);
  let [timeZone, setTimeZone] = useState(null)
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [company, setCompany] = useState({});
  const countries = Country.getAllCountries();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [county, setCounty] = useState("");
  const [locality, setLocality] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [fax, setFax] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [tag, setTag] = useState("");
  const [interviewedOn, setInterviewedOn] = useState("");
  const [userMsg, setUserMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [response, setResponse] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUpload2, setFileUpload2] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [fileName2, setFileName2] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [removePhoto2, setRemovePhoto2] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [previousFile2, setPreviousFile2] = useState([]);
  const [joiningDate, setJoiningDate] = useState("")
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyRole, setCompanyRole] = useState("");
  const [idStore, setIdStore] = useState();
  const [eye, setEye] = useState(true);
  const [allUserRoles, setAllUserRoles] = useState([])
  const [contractEndDate, setContractEndDate] = useState("")
  const [Validation, setValidation] = useState({
    firstName: false,
    lastName: false,
    initials: false,
    email: false,
    address: false,
    selectedCountry: false,
    selectedState: false,
    selectedCity: false,
    zip: false,
    businessPhone: false,
    fax: false,
    cellPhone: false,
    joiningDate: false,
    interviewedOn: false,
    // jobTitle: false,
    tags: false,
    company: false,
    companyRole: false,
    userMsg: false,
    selectedUserRole: false,
    // password: false,
  });

  const userRole = [
    { name: "Owner", code: "owner" },
    { name: "Project Manager", code: "project_manager" },
    { name: "Site Manager", code: "site_manager" },
    { name: "Principle Architect", code: "principal_architecher" },
    { name: "Priciple Designer", code: "principal_designer" },
    { name: "Architect	Designer", code: "architect_designer" },
    { name: "Site Worker", code: "site_worker" },
    { name: "Financial Analyst", code: "finacial_analyst" },
    { name: "Accountant", code: "account" },
    { name: "Resource Manager", code: "resource_manager" },
    { name: "Equipments head", code: "equipmenthead" },
    { name: "HR Manager", code: "hr_manager" },
  ];



  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;



  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const validate = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/.test(
      password
    );
  };
  const [password, setPassword] = useState('')
  const data = [{ name: "By Email" }, { name: "By Phone" }, { name: "By msg" }];
  const roleData = [
    { name: "Primary Contract" },
    { name: "Invoice Contract" },
    { name: "Employee" },
  ];

  const checkValidation = () => {



    let num = 0;
    if (!firstName) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        firstName: true,
      }));
    }
    if (!lastName) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        lastName: true,
      }));
    }
    if (!initials) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        initials: true,
      }));
    }
    if (!email || !emailRegex.test(email)) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        email: true,
      }));
    }
    if (!selectedCountry) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        selectedCountry: true,
      }));
    }
    if (!selectedState) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        selectedState: true,
      }));
    }
    // if (!selectedCity) {
    //   num++
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     selectedCity: true,
    //   }));
    // }
    if (!address) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        address: true,
      }));
    }

    if (!interviewedOn) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        interviewedOn: true,
      }));
    }
    if (!joiningDate) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        joiningDate: true,
      }));
    }


    if (!zip) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        zip: true,
      }));
    }
    if (!businessPhone) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        businessPhone: true,
      }));
    }

    if (!fax) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        fax: true,
      }));
    }
    if (!cellPhone) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        cellPhone: true,
      }));
    }
    // if (!jobTitle) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     jobTitle: true,
    //   }));
    // }

    if (!tag) {
      num++
      setValidation((prevState) => ({
        ...prevState,
        tag: true,
      }));
    }
    if (userType === "user") {

      if (!companyName) {
        num++
        setValidation((prevState) => ({
          ...prevState,
          companyName: true,
        }));
      }
      if (!companyRole) {
        num++
        setValidation((prevState) => ({
          ...prevState,
          companyRole: true,
        }));
      }

    }
    else {
      if (!contractEndDate) {
        num++
        setValidation((prevState) => ({
          ...prevState,
          contractEndDate: true,
        }));
      }

    }


    // if (!userMsg) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     userMsg: true,
    //   }));
    // }
    if (!selectedUserRole) {
      setValidation((prevState) => ({
        ...prevState,
        selectedUserRole: true,
      }))
    }
    // if (!password) {
    //   num++
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     password: true
    //   }))
    // }


    // if (
    //   !firstName ||
    //   !lastName ||
    //   !initials ||
    //   !selectedCountry ||
    //   !selectedState ||
    //   !selectedCity ||
    //   !email ||
    //   !address ||
    //   !zip ||
    //   !businessPhone ||
    //   !fax ||
    //   !cellPhone ||
    //   !tag ||
    //   !userMsg ||
    //   // !selectedUserRole ||
    //   !password
    // ) {

    //   console.log({firstName,lastName,initials,selectedCountry,selectedState,selectedCity,email,address,zip,businessPhone,fax,tag,userMsg,password,cellPhone});


    //   toast.info("Please fill all the Fields Marked with * are Mandatory");
    //   return true;
    // }
    if (num === 0) {
      return false;
    }
    else {
      toast.info("Please fill all the Fields Marked with * are Mandatory");
      return true
    }
  };

  const getStates = (countryName, countryIsoCode, defult, citys) => {
 
    setSelectedState("");
    setSelectedCity("");
    const states = State.getStatesOfCountry(countryIsoCode);
 
    setStates(states);

    if (defult) {
      
      let dataState = states.filter((e) => e.name === defult)[0];
      setSelectedState(dataState);
      getCities(dataState, citys);
    }
  };

  const getCities = (state, citys) => {
    let isoCode = state?.isoCode;
    let cCode = state?.countryCode;

    setSelectedCity("");

    const cities = City.getCitiesOfState(cCode, isoCode);
   
    setCities(cities);
    if (citys) {
      setSelectedCity(cities.filter((e) => e.name === citys)[0]);
    }
  };
  useEffect(() => {
    const companyIdFromLocalStorage = localStorage.getItem("companyId");
    if (companyIdFromLocalStorage) {
      setIdStore(companyIdFromLocalStorage);
     
    }
  }, []);
  const handleGetCompany = async () => {
    try {
      const res = await getCompany();
    
      const companyData = res?.data?.data;
     
      // setResponse(companyData)
      if (companyData && idStore) {
        const filteredData = companyData.filter((company) => {
          return company._id === idStore;
        });
        if (filteredData.length > 0) {
          setCompanyName(filteredData[0].company_name);
        }
      } else {
        setResponse(companyData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCompanyChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = response.find(
      (item) => item.company_name === selectedValue
    );
    setSelectedCompany(selectedOption); // Set the selected company object
  };
  useEffect(() => {
    handleGetCompany();
  }, []);

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };
  const handleFileSubmit2 = (event) => {
    setFileUpload2(event);
  };

  const handleAddUser = async () => {
    if (checkValidation()) {
      return;
    }

    // if (!validate(password)) {
    //   setValidation({...Validation,password:true})
    //   toast.error("Password must have at least 10 characters a mixture of both uppercase and lowercase letters inclusion of at least one special character, e.g., ! @ # ?")

    //   return
    // }
    let uploadResponse = {};
    if (fileUpload.length !== 0) {
      const formData = new FormData();
      for (const file of fileUpload) {
        
        formData.append("upload", file);
      }
      uploadResponse = await uploadDocs(formData);
    }
    let uploadResponse2 = {};
    if (fileUpload2.length !== 0) {
      const formData = new FormData();
      for (const file of fileUpload2) {
     
        formData.append("upload", file);
      }
      uploadResponse2 = await uploadDocs(formData);
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      initals: initials,
      email_address: email,
      address: address,
      city: selectedCity?.name || selectedCity,
      country: selectedCountry?.name,
      state: selectedState?.name,
      zip: zip,
      timeZone: timeZone,
      county: county,
      locality: locality,
      business_phone: businessPhone,
      // "ext": "string",
      business_fax: fax,
      cell_phone: cellPhone,
      role: selectedUserRole,
      tags_keywords: tag,
      // active: checked,
      // send_user_messages: userMsg,
      // is_employee_procore_certification: checkedTwo,
      // is_insurance_manager_procore_certification: checkedThree,
      attachments: uploadResponse.data,
      user_profile: uploadResponse2.data,
      // password: password,
      user_type: userType,

      interviewed_on: interviewedOn || "",
      joining_date: joiningDate || "",

    };

    if (userType === "user") {
      payload.company_role = companyRole
      payload.company = companyName
    }
    else {
      payload.contract_end_date = contractEndDate || ""
    }
   

    try {


      setLoader(true);

      await addUser(payload);
      setLoader(false)
      setBtnDisable(false);
      toast.success("User Added Successfully!");
      navigate("/userManagement");
      setFirstName("");
      setLastName("");
      setInitials("");
      setEmail("");
      setAddress("");
      setSelectedCountry("");
      setSelectedState("");
      setSelectedCity("");
      setZip("");
      setBusinessPhone("");
      setFax("");
      setCellPhone("");
      setJobTitle("");
      setTag("");
      setChecked(false);
      setCheckedTwo(false);
      setCheckedThree(false);
      setUserMsg("");
      setCompanyName("");
      setFileUpload("");
      setFileUpload2("");

    } catch (err) {
      console.log(err);
      setLoader(false);

      toast.error(err?.response?.data?.message);

    }
    setLoader(false)
  };

  async function getAllRoles() {
    try {
      let res = await getallrole()
     
      setAllUserRoles(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllRoles()
  }, [])


  return (
    <>
      {loader ? (<Loader />) : (
        <>
          <div
            className="main-container"
          >
            <div>
              <div className="row">
                {condition && (
                  <div className="col-md-12 py-3 ">
                    <span
                      className="open_Rfi_text"
                      onClick={() => {
                        navigate("/projectDirectories");
                      }}
                    >
                      Directory
                    </span>
                    <span className="px-1 open_Rfi_text">/</span>
                    <span className="createNew_text">New User</span>
                  </div>
                )}
              </div>
              <span className="page_heading">User Information</span>
              <div className="twoColumnFormGridContainer mt-4">

                <div>
                  <label htmlFor="" className="labelCss">
                    First Name: <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    className={Validation.firstName ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, firstName: false })
                    }
                  ></TextInput>
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Last Name: <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <TextInput
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      className={Validation.lastName ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, lastName: false })
                      }
                    ></TextInput>
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    User Role: <span className="text-danger">*</span>
                  </label>

                  <SingleSelect
                    placeholder="Select user role"
                    options={allUserRoles}
                    optionLabel="role"
                    name=""
                    value={allUserRoles.find(e => e._id == selectedUserRole)}
                    onChange={(e) => {
                    
                      setSelectedUserRole(e.target.value._id);

                    }}
                    onClick={() =>
                      setValidation({ ...Validation, selectedUserRole: false })
                    }
                    className={Validation.selectedUserRole ? "Validation p-0" : "p-0"}
                    filter={true}
                    filterInputAutoFocus={true}

                  />
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Email Address: <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <TextInput
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className={Validation.email ? "Validation" : ""}
                      onClick={() => setValidation({ ...Validation, email: false })}
                    ></TextInput>
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Cell Phone: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="number"
                      inputId="integeronly"
                      placeholder="Cell Phone"
                      value={cellPhone}
                      // max={10}
                      style={{ height: "50px" }}
                      // useGrouping={false}

                      onChange={(e) => {
                        const newValue = e.target.value;
                        let numRegex = /^[0-9]+$/;
                        if ((newValue === '' || numRegex.test(newValue))) {
                          setCellPhone(e.target.value)
                        }

                      }}
                      className={`${Validation.cellPhone ? "Validation" : ""} form-control`}
                      onClick={() =>
                        setValidation({ ...Validation, cellPhone: false })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Business Phone: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="number"
                      inputId="integeronly"
                      placeholder="Business Phone"
                      value={businessPhone}
                      // max={10}
                      style={{ height: "50px" }}
                      useGrouping={false}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        let numRegex = /^[0-9]+$/;
                        if ((newValue === '' || numRegex.test(newValue))) {
                          setBusinessPhone(newValue)
                        }

                      }}
                      className={`${Validation.businessPhone ? "Validation" : ""} form-control`}
                      onClick={() =>
                        setValidation({ ...Validation, businessPhone: false })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="labelCss">
                    Interviewed On: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="date"
                      placeholder="Interviewed On"
                      value={interviewedOn}
                      // max={10}
                      style={{ height: "50px" }}
                      useGrouping={false}
                      onChange={(e) => {
                        setInterviewedOn(e.target.value)
                      }}
                      className={`${Validation.interviewedOn ? "Validation" : ""} form-control`}
                      onClick={() =>
                        setValidation({ ...Validation, interviewedOn: false })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="labelCss">
                    Joining Date: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="date"
                      placeholder="Joining Date"
                      value={joiningDate}
                      // max={10}
                      style={{ height: "50px" }}
                      useGrouping={false}
                      onChange={(e) => {
                        setJoiningDate(e.target.value)

                      }}
                      className={`${Validation.joiningDate ? "Validation" : ""} form-control`}
                      onClick={() =>
                        setValidation({ ...Validation, joiningDate: false })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Address: <span className="text-danger">*</span>
                  </label>

                  <TextInput
                    placeholder="Address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    className={Validation.address ? "Validation" : ""}
                    onClick={() => setValidation({ ...Validation, address: false })}
                  ></TextInput>
                </div>
                <div>
                  <label htmlFor="" className="labelCss">
                    Locality:
                  </label>
                  <div className="">
                    <TextInput placeholder={"Locality"} value={locality} onChange={(e) => setLocality(e.target.value)} />
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="labelCss">
                    Country: <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <SingleSelect
                      placeholder="Country"
                      options={countries.map((country) => ({
                        label: country.name,
                        value: country,
                      }))}
                      value={selectedCountry}
                      onChange={(e) => {
                        const selectedCountry = e.value;
                        setSelectedCountry(e.target.value);
                        getStates(selectedCountry.name, selectedCountry.isoCode);
                      }}
                      className={
                        Validation.selectedCountry ? "Validation p-0" : "p-0"
                      }
                      onClick={() =>
                        setValidation({ ...Validation, selectedCountry: false })
                      }
                      filter={true}
                      filterInputAutoFocus={true}
                    />
                  </div>
                </div>


                <div>
                  <label htmlFor="" className="labelCss">
                    State: <span className="text-danger">*</span>
                  </label>

                  <SingleSelect
                    placeholder="State"
                    options={Array.from(states)?.map((state) => ({
                      label: state.name,
                      value: state,
                    }))}
                    name="state"
                    value={selectedState}
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      getCities(e.target.value);
                    }}
                    className={Validation.selectedState ? "Validation p-0" : "p-0"}
                    onClick={() =>
                      setValidation({ ...Validation, selectedState: false })
                    }
                    filter={true}
                    filterInputAutoFocus={true}
                  />
                </div>
                <div>
                  <label htmlFor="" className="labelCss">
                    City:
                  </label>
                  <div className="">
                    <SingleSelect
                      placeholder="City"
                      options={cities.map((city) => ({
                        label: city.name,
                        value: city.name,
                      }))}
                      value={selectedCity}
                      onChange={(e) => {
                        setSelectedCity(e.target.value)
                     
                      }}
                      className={Validation.selectedCity ? "Validation p-0" : "p-0"}
                      onClick={() =>
                        setValidation({ ...Validation, selectedCity: false })
                      }
                      filter={true}
                      filterInputAutoFocus={true}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="labelCss">
                    County:
                  </label>
                  <div className="">
                    <TextInput placeholder={"County"} value={county} onChange={(e) => setCounty(e.target.value)} />
                  </div>
                </div>


                <div>
                  <label htmlFor="" className="labelCss">
                    Zip code: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      inputId="integeronly"
                      placeholder="Zip"
                      value={zip}
                      max={5}
                      style={{ height: "50px" }}
                      useGrouping={false}
                      onChange={(e) => {
                        const newValue = e.target.value;

                        setZip(e.target.value)


                      }}
                      className={`${Validation.zip ? "Validation" : ""} form-control`}
                      onClick={() => setValidation({ ...Validation, zip: false })}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Time Zone : <span className="text-danger">*</span>
                  </label>
                  <AutoCompleteSelect
                    // className={validation.time_zone ? "Validation" : ""}
                    options={timeZoneOptions}
                    value={timeZone}
                    optionLabel="label"
                    onChange={(e) => {

                      setTimeZone(e)
                    }}
                    placeholder="Select Time Zone"

                  />
                </div>

                <div>
                  <label htmlFor="" className="labelCss">
                    Business Fax: <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      inputId="integeronly"
                      placeholder="Fax"
                      value={fax}
                      // max={5}
                      // pattern="\d{4}-\d{6}"
                      // useGrouping={false}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        let numRegex = /^[0-9]+$/;
                        if ((newValue === '' || numRegex.test(newValue))) {
                          setFax(newValue)
                        }


                      }}
                      style={{ width: "100%", height: "50px" }}
                      className={`${Validation.fax ? "Validation" : ""} form-control`}
                      onClick={() => setValidation({ ...Validation, fax: false })}
                    />
                  </div>
                </div>



                <div>
                  <label htmlFor="" className="labelCss">
                    Initials: <span className="text-danger">*</span>
                  </label>

                  <TextInput
                    placeholder="Initials"
                    value={initials}
                    onChange={(e) => {
                      setInitials(e.target.value);
                    }}
                    className={Validation.initials ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, initials: false })
                    }
                  ></TextInput>
                </div>


                <div>
                  <label htmlFor="" className="labelCss">
                    Tags/Keywords: <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <TextInput
                      placeholder="Tags/Keywords"
                      value={tag}
                      onChange={(e) => {
                        setTag(e.target.value);
                      }}
                      className={Validation.tag ? "Validation" : ""}
                      onClick={() => setValidation({ ...Validation, tag: false })}
                    ></TextInput>
                  </div>
                </div>
              </div>

              <div className="css_com my-3">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={setFileUpload}
                  onChange={handleFileSubmit}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFile}
                />
              </div>
              <div className="css_com my-3">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto2}
                  value={fileName2}
                  setFiles={setFileUpload2}
                  onChange={handleFileSubmit2}
                  setValue={setFileName2}
                  label={"User Profile"}
                  setPreviousFiles={setPreviousFile2}
                />
              </div>
              <div>
                <label htmlFor="" className="labelCss">
                  User Type:
                </label>
                <div className="flex flex-wrap gap-3">
                  <div className="flex align-items-center">
                    <RadioButton inputId="userType1" name="userType" value="user" onChange={(e) => setUserType(e.value)} checked={userType === 'user'} />
                    &nbsp;
                    <label htmlFor="userType1" className="ml-2">Company</label>
                  </div>
                  <div className="flex align-items-center">
                    <RadioButton inputId="userType2" name="userType" value="individual" onChange={(e) => {
                      setUserType(e.value)
                      setCompanyName("")
                      setCompanyRole("")
                      }} checked={userType === 'individual'} />
                    &nbsp;
                    <label htmlFor="userType2" className="ml-2">Individual</label>
                  </div>
                </div>
              </div>

              {userType === "user" &&
                <div className="twoColumnFormGridContainer mt-3">
                  <div>
                    <label htmlFor="" className="labelCss">
                      Company Name: <span className="text-danger">*</span>
                    </label>
                    <div className="">

                      <SingleSelect
                        placeholder="Company"
                        options={response.map((item) => ({
                          label: item.company_name,
                          value: item._id,
                        }))}
                        readOnly={true}
                        value={companyName}
                        // onChange={(e) => {
                       
                        //     setCompanyName(e.target.value);
                        // }}
                        onChange={(selectedOption) => {
                          setCompanyName(selectedOption.value);
                        }}
                        className={Validation.companyName ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...Validation, companyName: false })
                        }
                        filter={true}
                        filterInputAutoFocus={true}
                      />

                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      Company Role: <span className="text-danger">*</span>
                    </label>

                    <SingleSelect
                      placeholder="Company Role"
                      options={roleData.map((item) => item.name)}
                      name=""
                      value={companyRole}
                      onChange={(e) => {
                        setCompanyRole(e.target.value);
                      }}
                      className={Validation.companyRole ? "Validation p-0" : "p-0"}
                      onClick={() =>
                        setValidation({ ...Validation, companyRole: false })
                      }
                      filter={true}
                      filterInputAutoFocus={true}
                    />
                  </div>
                </div>
              }

              {userType !== "user" &&
                <div className="col-6 mt-3">
                  <label htmlFor="" className="labelCss">
                    Contract End Date: <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    placeholder="Tags/Keywords"
                    value={contractEndDate}
                    style={{ height: "50px" }}
                    onChange={(e) => {
                      setContractEndDate(e.target.value);
                    }}
                    className={`${Validation.contractEndDate ? "Validation" : ""} form-control`}
                    onClick={() => setValidation({ ...Validation, contractEndDate: false })}
                  ></input>
                </div>
              }

              <div className="btn_main mb-2">
                <span
                  className="cancelBtn px-2 py-2"
                  onClick={() => {
                    navigate("/userManagement");
                  }}
                >
                  {" "}
                  Cancel
                </span>
                <div onClick={handleAddUser}>
                  <span className="CreateRfi px-2 py-2">Create</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
}

export default AddUserInfo;
