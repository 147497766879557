import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import TextInput from "../../../../../Components/Inputs/TextInput";
import SingleSelect from "../../../../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { PrimaryButton } from "../../../../../Components/Buttons/PrimaryButton";
import { HiDownload } from "react-icons/hi";
import { DragAndDrop } from "../../../../../Components/Inputs/DragAndDrop";

function AddSovItem({ onSubmit }) {
  const [visible, setVisible] = useState(false);
  const [importsov, setImportsov] = useState(false);

  const centeredHeaderTemplate = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">Add SOV Item</span>

      <span className=" " onClick={() => setVisible(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  const centeredHeaderTop = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">
        Import SOV from CSV
      </span>

      <span className=" " onClick={() => setImportsov(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  return (
    <div>
      <span className="mt-5 " onClick={() => setVisible(true)}>
        <span className="add_icon_text">
          {" "}
          <IoMdAdd size={15} /> Add SOV Item{" "}
        </span>
      </span>
      <Dialog
        style={{ width: "40rem" }}
        visible={visible}
        closable={false}
        draggable={false}
        onHide={() => setVisible(false)}
        header={centeredHeaderTemplate}
      >
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Budget Code: <span className="text-danger">*</span>
            </label>
            <SingleSelect
              // inputId="integeronly"
              placeholder="Enter Title"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></SingleSelect>
          </div>
          <div className="col-md-6 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Tax Code: <span className="text-danger">*</span>
            </label>
            <SingleSelect
              // inputId="integeronly"
              placeholder="Enter Tax Code"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></SingleSelect>
          </div>
          <div className="col-md-6 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Quantity: <span className="text-danger">*</span>
            </label>
            <TextInput
              // inputId="integeronly"
              placeholder="Enter Quantity"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></TextInput>
          </div>

          <div className="col-md-6 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Unity Of Measure(UOM):
            </label>
            <TextInput
              // inputId="integeronly"
              placeholder="Enter Quantity"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></TextInput>
          </div>

          <div className="col-md-6 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Unit Cost:
            </label>
            <TextInput
              // inputId="integeronly"
              placeholder="Enter Quantity"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></TextInput>
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Amount: <span className="text-danger">*</span>
            </label>
            <TextInput
              // inputId="integeronly"
              placeholder="Enter Title"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></TextInput>
          </div>

          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Description:
            </label>
            <div className="card">
              <Editor
                //   value={data?.description}
                // className={Validation.description ? "Validation" : ""}
                // onClick={() => setValidation({ ...Validation, description: false })}
                name="description"
                //   onTextChange={handleTextChange}
                style={{ height: "100px" }}
              />
            </div>
          </div>

          <span className="text-center mt-2 add_icon_text"> Or </span>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <span className="flex justify-center w-full font-bold add_icon_text text-decoration-none">
              Import Schedule of Value from CSV?
            </span>

            <span className=" ">
              <span
                className="add_icon_text text-decoration-none d-flex align-items-center"
                onClick={() => {setImportsov(true); setVisible(false) }}
              >
                {" "}
                <IoMdAdd size={15} />
                Import{" "}
              </span>
            </span>
          </div>

          <div className="end mt-5 gap-2">
            <button
              className="btn border"
              onClick={() => setVisible(false)}
              type="button"
            >
              Cancel
            </button>
            <PrimaryButton addIcon type="submit" text={"Add SOV"} />
          </div>
        </div>
      </Dialog>

      <Dialog
        style={{ width: "40%" }}
        visible={importsov}
        closable={false}
        draggable={false}
        onHide={() => setImportsov(false)}
        header={centeredHeaderTop}
      >
        <div className="add_icon_text text-decoration-none">
          To import Schedule of Value line items from a CSV file, kindly
          download the CSV template given below and add your data to it in the
          given format. After saving the downloaded file along with changes
          made, you can upload the file below.
        </div>
        <div className="add_icon_text text-decoration-none">
          Kindly note that any line item whose amount value is left blank will
          not be imported.
        </div>
        <div className="mt-3">
          <button className="filter_btn ">
            {" "}
            <HiDownload style={{ marginRight: "5px" }} size={17} />
            Download CSV Template
          </button>
        </div>
        <div className="row  mt-2  css_com">
          <div className="col-md-12">
            <DragAndDrop
              label={"CSV File"}
              // setRemovePhoto={setRemovePhoto}
              // value={fileName}
              // setFiles={setFileUpload}
              // onChange={handleFileSubmit}
              // setValue={setFileName}
              // setPreviousFiles={setPreviousFile}
            />
          </div>
        </div>
        <div className="end mt-5 gap-2">
          <button
            className="btn border"
            onClick={() => setImportsov(false)}
            type="button"
          >
            Cancel
          </button>
          <PrimaryButton type="submit" text={"Add SOV"} />
        </div>
      </Dialog>
    </div>
  );
}

export default AddSovItem;
