import React from 'react'
import { LuSearch } from "react-icons/lu";
import style from "./input.module.css"
import { SearchIcon } from '../Icons/SearchIcon';
import { CrossIcon } from '../Icons/CrossIcon';

export const SearchInput = ({placeholder,onCancel,...props}) => {
  return (
    <div className={style.searchInput_main_con}>
        <input type='text' {...props} placeholder={placeholder || "Search"}/>
        <div className={style.search_icon}>
        <SearchIcon/>
        </div>
        <div className={style.cross_icon}>
        <CrossIcon onClick={onCancel}/>
        </div>
    </div>
  )
}
