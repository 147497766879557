import React, { useEffect, useRef, useState } from 'react'
import { ViewPageDocumentName } from '../../Components/Common/ViewPageComponents/ViewPageDocumentName'
import { useNavigate, useParams } from 'react-router-dom'
import { AiOutlineExport } from 'react-icons/ai'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable'
import moment from 'moment'
import { getSubmittalById, sendEmailWithAttachment } from '../../Api/Admin/AllAPIs'
import { Statuscomp } from '../../Components/Common/StatusComp'
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText'
import { ViewPagesPdfTemplate } from '../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate'
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf'
import FormSkeleton from '../../Components/Common/FormSkeleton'
import ViewVersion from '../../Components/Common/ViewVersion'
import EmailTemplateWithPdf from '../../Components/Common/EmailTemplateWithPdf'
import jsPDF from 'jspdf'
import { toast } from '../../utils'

export const ViewSubmittal = ({ usedAsComponent, includeVersionHistory }) => {

    let [submittal, setSubmittal] = useState({})

    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const skeletonData = [
        { qty: 1, height: "2.5rem" },
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 1, height: "2.4rem" },

        { qty: 1, height: "5rem" },

    ]

    let { id } = useParams()

    let navigate = useNavigate()
    let pdfRef = useRef()
    async function getsubmittal() {
        try {
            setSkeletonLoader(true);
            let res = await getSubmittalById(id)
            setSubmittal(res?.data?.data?.[0] || {})
         
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setSkeletonLoader(false);
        }
    }

    useEffect(() => {
        getsubmittal()
    }, [])
    
    function pdfDownload() {
        downloadPdf(pdfRef.current, [
            { heading: "Attached Documents", files: submittal?.submittal_file.map(e => e.url) }
        ],"Submittal")
    }

    async function okok(dd) {
        let res = await downloadPdf(pdfRef.current, [
            { heading: "Attached Documents", files: submittal?.submittal_file.map(e => e.url) }
        ], "Submittals", true)

        try {

            const reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onloadend = async function () {
                const base64data = reader.result.split(',')[1];

                const formattedAttachment = {
                    content: base64data,
                    filename: 'submittal.pdf',
                    type: 'application/pdf',
                    disposition: 'attachment',
                };

                const body = {
                    subject: "Email header header",
                    to: dd.to,
                    text: "This is text of email",
                    cc: dd.cc,
                    bcc: dd.bcc,
                    attachments: formattedAttachment,
                    htmlData: dd.text
                }
                let ee = await sendEmailWithAttachment(body)
                toast.success("Email Send successfully")
            }
        }
        catch (err) {
            console.log(err)
        }
    }



    const formatHourSpent = (hours) => {
        if (!hours || hours === "--") return "N/A"; 
    
       
        const parts = hours.split('-').map(Number);
    
   
        if (parts.some(isNaN)) return "Invalid";
    
        const [min, max] = parts;
    
  
        if (parts.length === 1) { 
            if (min < 1) {
                return `<1 hour`; 
            } else {
                return `${min} hours`; 
            }
        } else if (max) { 
            if (max >= 10) {
                return `${min} hours ${max} min`; 
            } else {
                return `${min} hours ${max} min`; 
            }
        } else {
            return `${min} hours`; 
        }
    };
    
      
      
      const hourSpentDisplay = formatHourSpent(submittal.hourSpent);
    return (
        <div className="rfiView">
            {
                skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :

                    <div className="rfiparent">
                        <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
                            <div className="w-100">
                                <span
                                    className="open_Rfi_text"
                                    onClick={() => navigate("/projectManagement/submittals")}
                                >
                                    Submittals
                                </span>
                                <span className="px-1 open_Rfi_text">/</span>
                                <span className="createNew_text">View Submittal</span>
                            </div>
                            {/* <div style={{ width: "120px" }}>
                                <EmailTemplateWithPdf sendEmail={okok} isDialog={true} />
                            </div> */}
                        </div>
                        <div className="rfibody d-flex flex-column gap-4">
                            <div className="d-flex justify-content-between">
                                <h1 className="headdingRFI">{submittal?.submittal_title}</h1>
                                <div className="d-flex align-items-center gap-2" onClick={pdfDownload}>
                                {!usedAsComponent &&      <div
                                        className="d-flex gap-2 align-items-center"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <AiOutlineExport />
                                        Export
                                        <MdOutlineKeyboardArrowDown />
                                    </div>}

                                    {/* <div className="d-flex" style={{ cursor: "pointer" }}>
                                <BsThreeDotsVertical />
                            </div> */}
                                </div>
                            </div>

                            <div className="d-flex flex-column gap-3">
                                <div className="row">
                                    <div className="col-6">
                                        <ViewPageTable tableData={[
                                            { "Responsible Contractor": submittal?.submittal_responsibleContractor?.map(contractor => contractor.company_name).join(', ') || "--" },
                                            { "Received From":submittal?.submittal_receivedFrom},
                                            { "Submittal Manager": submittal?.submittal_submittalManager?.map(manager => manager.first_name).join(', ') || "--" },
                                          
                                            { "Location": submittal?.submittal_location || "--" },
                                            { "Title": submittal?.submittal_title || "--" },
                                            { "Document Version": submittal?.submittal_revision || "--" },
                                            { "Cost Code": submittal?.submittal_costCode || "--" },
                                            { "Responsible team": submittal?.submittal_distributionList || "--" },
                                            { "Submittal Type": submittal?.submittal_submittalType || "--" },
                                             { "Reviewer": submittal?.reviewer?.map(reviewers => reviewers?.first_name)|| "--" },
                                           
                                             { "Reviewer Comment":submittal?.approver_comment || "--" },
                                            // { "Approval Status": submittal?.approval_status || "--"  },

                                            { "WorkFlow's Status": submittal?.workflow_status|| "--" },
                                            
                                        ]} />
                                    </div>
                                    <div className="col-6">
                                        
                                        <ViewPageTable tableData={[
                                             
                                            { "Submittal Create Date": moment(submittal?.submittal_submitBy).format("DD/MM/YYYY") || "--" },
                                            { "Document Issue date": moment(submittal?.submittal_issueDate).format("DD/MM/YYYY") || "--" },
                                            { "Document Recieve date": moment(submittal?.submittal_receivedDate).format("DD/MM/YYYY") || "--" },
                                            { "Final Due Date": moment(submittal?.submittal_finalDueDate).format("DD/MM/YYYY") || "--" },
                                            { "Document Number": submittal?.submittal_number || "--" },
                                            { "Submittal Category": submittal?.submittal_specSection || "--" },
                                            { "Submittals package": submittal?.submittal_submittalPackage || "--" },
                                            { "Linked Drawings": submittal?.submittal_linkedDrawings || "--" },
                                            { "Approver": submittal?.approver?.map(reviewers => reviewers?.first_name)|| "--" },
                                            { "Approver Comment":submittal?.approver_comment || "--" },
                                            {"Hours Spent": submittal?.hourSpent
                                            ? `${submittal?.hourSpent?.split("-")[0]} hour ${
                                                submittal?.hourSpent?.split("-")[1]
                                              } Min`
                                            : "--",
                                        },
                                            
                                            
                                        ]} />
                                  
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="view_page_para_head mt-4 mb-2">Linked Drawing(s):</div>
                <ViewPageDocumentName name="referaldrawing.png" /> */}


         

                        <div className="view_page_para_head mt-4 mb-2">Description</div>
                        <div className="view_page_bold_text" >
                            <HtmlIntoText html={submittal?.submittal_description} />
                        </div>

                        <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
                        <div className="d-flex flex-wrap gap-3">
                            {submittal?.submittal_file?.map((res, key) => (
                                <ViewPageDocumentName docUrl={res?.url} key={key} name={res?.fileName} />
                            )
                            )}
                        </div>


                    </div>
            }
            <div style={{ height: "0px", overflow: "hidden" }}>
                <div ref={pdfRef}>
            <div className='card p-5'>
            <ViewPagesPdfTemplate
                        header={`#SUBMITTAL ${submittal?.submittal_title}`}
                        tableData1={[
                            { "Responsible Contractor": submittal?.submittal_responsibleContractor?.map(contractor => contractor.company_name).join(', ') || "--" },
                                            { "Received From": moment(submittal?.submittal_receivedDate).format("DD/MM/YYYY") || "--" },
                                            { "Submittal Manager": submittal?.submittal_submittalManager?.map(manager => manager.first_name).join(', ') || "--" },
                                          
                                            { "Location": submittal?.submittal_location || "--" },
                                            { "Title": submittal?.submittal_title || "--" },
                                            { "Document Version": submittal?.submittal_revision || "--" },
                                            { "Cost Code": submittal?.submittal_costCode || "--" },
                                            { "Responsible team": submittal?.submittal_distributionList || "--" },
                                            { "Submittal Type": submittal?.submittal_submittalType || "--" },
                                             { "Reviewer": submittal?.reviewer?.map(reviewers => reviewers?.first_name)|| "--" },
                                             { "Approver Comment":submittal?.approver_comment || "--" },
                                        
                                            // { "Approval Status": submittal?.approval_status || "--"  },

                                            { "WorkFlow's Status": submittal?.workflow_status|| "--" },
                        ]}
                        description={{
                            key: "Specification Description",
                            value: <HtmlIntoText html={submittal?.submittal_description} />
                        }}
                        tableData2={[
                            { "Status": submittal?.submittal_approval_status || "--" },
                                            { "Submittal Create Date": moment(submittal?.submittal_submitBy).format("DD/MM/YYYY") || "--" },
                                            { "Document Issue date": moment(submittal?.submittal_issueDate).format("DD/MM/YYYY") || "--" },
                                            { "Document Recieve date": moment(submittal?.submittal_receivedDate).format("DD/MM/YYYY") || "--" },
                                            { "Final Due Date": moment(submittal?.submittal_finalDueDate).format("DD/MM/YYYY") || "--" },
                                            { "Document Number": submittal?.submittal_number || "--" },
                                            { "Submittal Category": submittal?.submittal_specSection || "--" },
                                            { "Submittals package": submittal?.submittal_submittalPackage || "--" },
                                            { "Linked Drawings": submittal?.submittal_linkedDrawings || "--" },
                                            { "Approver": submittal?.approver?.map(reviewers => reviewers?.first_name)|| "--" },
                                            { "Approver Comment":submittal?.approver_comment || "--" },
                                            {"Hours Spent": submittal?.hourSpent
                                                ? `${submittal?.hourSpent?.split("-")[0]} hour ${
                                                    submittal?.hourSpent?.split("-")[1]
                                                  } Min`
                                                : "--",
                                            },
                        ]}


          
                        
                    />
                    {(!usedAsComponent || includeVersionHistory) &&
            <>
              <hr />
              <ViewVersion isView={true} />
              <br/>
              <br/>
            </>
          }
            </div>
                    
                </div>
            </div>
      
      
            {(!usedAsComponent || includeVersionHistory) &&
            <>
              <hr />
              <ViewVersion isView={true} />
              <br/>
              <br/>
            </>
          }
            {!usedAsComponent &&
            <div className="d-flex justify-content-end mt-2">
                <button className='btn border' onClick={() => navigate("/projectManagement/submittals")}>Back</button>
            </div>}
            <br />
        </div>
    )
}
