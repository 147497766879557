import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { FiLock } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import { RiScreenshot2Line } from "react-icons/ri";
import "primereact/resources/themes/saga-blue/theme.css"; // theme
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // icons
import Popover from "../../../../Components/Common/Popover";
import { useNavigate } from "react-router-dom";

import CustomDialog from "../../../../Components/Common/CustomDialog";
import { ModifyBudget } from "./ModifyBudget";
 import { AddSnapshots} from "./AddSnapshots"
import Pagination from "../../../../Components/Common/Pagination/Pagination";

const sampleCustomers = [
  {
    id: 1,
    Description: "General Requirements",
    Category: { name: "", code: "us" },
    Original_Budget_Amount: "$ 667,000.00",
    Budget_Modifications: "Budget Modifications",
    Approved_COs: "",
    Revised_Budget: "$ 667,000.00",
    Pending_Budget_Changes: "",
    Projected_Budget: "$ 667,000.00",
    Committed_Costs: "",
    Direct_Costs: "",
    Pending_Cost_Changes: "",
    Projected_Costs: "",
    Forecast_to_Complete: "$ 667,000.00",
    Estimated_Cost_at_Completion: "$ 667,000.00",
    Projected_Over_Under: "$ 0.00",
    person: [
      {
        id: 1,
        Description: "General Purposes",
        Category: "Labor",
        Original_Budget_Amount: "$ 65,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 65,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 65,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 65,000.00",
        Estimated_Cost_at_Completion: "$ 65,000.00",
        Projected_Over_Under: "$ 0.00",
      },
    ],
  },
  {
    id: 2,
    Description: "Site Preparation",
    Category: { name: "Construction", code: "us" },
    Original_Budget_Amount: "$ 120,000.00",
    Budget_Modifications: "$ 5,000.00",
    Approved_COs: "$ 2,000.00",
    Revised_Budget: "$ 127,000.00",
    Pending_Budget_Changes: "$ 1,000.00",
    Projected_Budget: "$ 128,000.00",
    Committed_Costs: "$ 80,000.00",
    Direct_Costs: "$ 75,000.00",
    Pending_Cost_Changes: "$ 3,000.00",
    Projected_Costs: "$ 128,000.00",
    Forecast_to_Complete: "$ 48,000.00",
    Estimated_Cost_at_Completion: "$ 128,000.00",
    Projected_Over_Under: "$ 0.00",
    person: [
      {
        id: 2,
        Description: "Excavation",
        Category: "Labor",
        Original_Budget_Amount: "$ 40,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 40,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 40,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 40,000.00",
        Estimated_Cost_at_Completion: "$ 40,000.00",
        Projected_Over_Under: "$ 0.00",
      },
      {
        id: 3,
        Description: "Grading",
        Category: "Equipment",
        Original_Budget_Amount: "$ 20,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 20,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 20,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 20,000.00",
        Estimated_Cost_at_Completion: "$ 20,000.00",
        Projected_Over_Under: "$ 0.00",
      },
    ],
  },
  {
    id: 3,
    Description: "Foundation",
    Category: { name: "Construction", code: "us" },
    Original_Budget_Amount: "$ 300,000.00",
    Budget_Modifications: "$ 10,000.00",
    Approved_COs: "$ 5,000.00",
    Revised_Budget: "$ 315,000.00",
    Pending_Budget_Changes: "$ 3,000.00",
    Projected_Budget: "$ 318,000.00",
    Committed_Costs: "$ 200,000.00",
    Direct_Costs: "$ 190,000.00",
    Pending_Cost_Changes: "$ 8,000.00",
    Projected_Costs: "$ 318,000.00",
    Forecast_to_Complete: "$ 118,000.00",
    Estimated_Cost_at_Completion: "$ 318,000.00",
    Projected_Over_Under: "$ 0.00",
    person: [
      {
        id: 4,
        Description: "Concrete",
        Category: "Materials",
        Original_Budget_Amount: "$ 100,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 100,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 100,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 100,000.00",
        Estimated_Cost_at_Completion: "$ 100,000.00",
        Projected_Over_Under: "$ 0.00",
      },
      {
        id: 5,
        Description: "Reinforcement",
        Category: "Labor",
        Original_Budget_Amount: "$ 50,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 50,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 50,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 50,000.00",
        Estimated_Cost_at_Completion: "$ 50,000.00",
        Projected_Over_Under: "$ 0.00",
      },
    ],
  },
  {
    id: 4,
    Description: "Framing",
    Category: { name: "Construction", code: "us" },
    Original_Budget_Amount: "$ 500,000.00",
    Budget_Modifications: "$ 20,000.00",
    Approved_COs: "$ 10,000.00",
    Revised_Budget: "$ 530,000.00",
    Pending_Budget_Changes: "$ 5,000.00",
    Projected_Budget: "$ 535,000.00",
    Committed_Costs: "$ 400,000.00",
    Direct_Costs: "$ 380,000.00",
    Pending_Cost_Changes: "$ 15,000.00",
    Projected_Costs: "$ 535,000.00",
    Forecast_to_Complete: "$ 135,000.00",
    Estimated_Cost_at_Completion: "$ 535,000.00",
    Projected_Over_Under: "$ 0.00",
    person: [
      {
        id: 6,
        Description: "Wood Framing",
        Category: "Materials",
        Original_Budget_Amount: "$ 200,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 200,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 200,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 200,000.00",
        Estimated_Cost_at_Completion: "$ 200,000.00",
        Projected_Over_Under: "$ 0.00",
      },
      {
        id: 7,
        Description: "Metal Framing",
        Category: "Materials",
        Original_Budget_Amount: "$ 100,000.00",
        Budget_Modifications: "$ 0.00",
        Approved_COs: "$ 0.00",
        Revised_Budget: "$ 100,000.00",
        Pending_Budget_Changes: "$ 0.00",
        Projected_Budget: "$ 100,000.00",
        Committed_Costs: "$ 0.00",
        Direct_Costs: "$ 0.00",
        Pending_Cost_Changes: "$ 0.00",
        Projected_Costs: "$ 0.00",
        Forecast_to_Complete: "$ 100,000.00",
        Estimated_Cost_at_Completion: "$ 100,000.00",
        Projected_Over_Under: "$ 0.00",
      },
    ],
  },
];

export const Budget = () => {
  const [customers, setCustomers] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [ modifyBudget,setModifyBudget]=useState(false)
  const [ addSnapshots,setAddSnapshots]=useState(false)
  
  const [lock,setLock]=useState( true)
  const navigate = useNavigate();

  const [dialogVisible, setDialogVisible] = useState(false);
  const [activeButton, setActiveButton] = useState(null);

  const [page, setPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)
  const perPageData = 10

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleConfirm = () => {

    setDialogVisible(false);
    setLock(!lock)
  };

  const handleLockSubmiut=()=>{

    setDialogVisible(false);

  }

  useEffect(() => {
    setCustomers(sampleCustomers);
  }, []);

  const headerTemplate = (data) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={data.representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${data.representative.image}`}
          width="32"
        />
        <span className="font-bold">{data?.representative.name}</span>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={rowData?.Category?.name}
          src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
          className={`flag flag-${rowData?.Category?.code}`}
          style={{ width: "24px" }}
        />
        <span>{rowData?.Category?.name}</span>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.person?.length > 0;
  };

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="border">
        <DataTable
          value={rowData.person}
          className="childrenTable"
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          selectionMode={"checkbox"}
          tableStyle={{ minWidth: "60rem" }}
        >
          {/* <Column style={{ width: "4rem" }}></Column> */}
          <Column
            field="#"
            header="Sno."
            body={(rowData, rowIndex) => <div>{rowIndex.rowIndex + 1.1}</div>}
            style={{ width: "4.7rem" }}
          />
          <Column
            header=""
            body={(rowData) => (
              <div className="flex align-items-center gap-2">
                <span>{rowData?.Description}</span>
              </div>
            )}
            style={{
              width: "12.1rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Category"
            header=""
            style={{
              width: "8.5rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Original_Budget_Amount"
            header=""
            style={{ width: "5rem", whiteSpace: "nowrap" }}
          />
          <Column
            field="Budget_Modifications"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Approved_COs"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Revised_Budget"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Pending_Budget_Changes"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Projected_Budget"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Committed_Costs"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Direct_Costs"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Pending_Cost_Changes"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Projected_Costs"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Forecast_to_Complete"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Estimated_Cost_at_Completion"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
          <Column
            field="Projected_Over_Under"
            header=""
            style={{
              width: "13.3rem",
              whiteSpace: "nowrap",
            }}
          />
         <Column
      header="Actions"
      body={(rowData, meta) => (
        <div className="flex justify-content-center">
          <Popover
            options={["Change Budget","Delete","View"]}
            onSelect={(index, event) => {
              if (index === 2) navigate(`view`);
            }}
          />
        </div>
      )}
    />
        </DataTable>
      </div>
    );
  };
  
  return (
    <>
      <div className="mt-2">
      <div className="d-flex flex-wrap justify-content-between mb-4">
  <span className="d-flex  gap-2 mb-sm-1 mb-2 mb-md-0">
    <div
      className="filter_btn d-flex align-items-center"
      onClick={() => setDialogVisible(true)}
    >
      <FiLock size={15} />
      <span className="align-middle ms-1">{lock ? "Lock Budget" : "Unlock Budget"}</span>
    </div>
    <button className="filter_btn d-flex align-items-center" onClick={()=> navigate("modifybudget")} disabled={lock}  >
      <BiEdit size={18} />
      <span className="align-middle ms-1">Modify Budget</span>
    </button>
    <div className="filter_btn d-flex align-items-center" onClick={()=>setAddSnapshots(true)}>
      <RiScreenshot2Line size={18} />
      <span className="align-middle ms-1">Take Snapshot</span>
    </div>
  </span>
  <span className=" custom_btnClass d-flex gap-2 ">
    <span className="filter_btn">Filter Budget</span>
    <span className="filter_btn  ">Clear All</span>
  </span>
</div>


<div className="card">
  <DataTable
    value={customers}
    expandedRows={expandedRows}
    onRowToggle={(e) => setExpandedRows(e.data)}
    rowExpansionTemplate={rowExpansionTemplate}
    dataKey="id"
    tableStyle={{ minWidth: "50rem" }}
  >
    <Column expander={allowExpansion} style={{ width: "3rem" }} />
    <Column
      field="Description"
      header="Description"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Category"
      header="Category"
      body={countryBodyTemplate}
      style={{
        width: "10rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Original_Budget_Amount"
      header="Original Budget Amount"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Budget_Modifications"
      header="Budget Modifications"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Approved_COs"
      header="Approved COs"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Revised_Budget"
      header="Revised Budget"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Pending_Budget_Changes"
      header="Pending Budget Changes"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Projected_Budget"
      header="Projected Budget"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Committed_Costs"
      header="Committed Costs"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Direct_Costs"
      header="Direct Costs"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Pending_Cost_Changes"
      header="Pending Cost Changes"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Projected_Costs"
      header="Projected Costs"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Forecast_to_Complete"
      header="Forecast to Complete"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Estimated_Cost_at_Completion"
      header="Estimated Cost at Completion"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      field="Projected_Over_Under"
      header="Projected Over/Under"
      style={{
        width: "15rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
    <Column
      header="Actions"
      body={(rowData, meta) => (
        <div className="flex justify-content-center">
        <Popover
  options={["Change Budget", "Delete", "View"]}
  onSelect={(index, event) => {
    if (index === 0) {
      navigate('2');
    } else if (index === 2) {
      navigate('view');
    }
  }}
/>

        </div>
      )}
    />
  </DataTable>
</div>

    


      </div>




      <CustomDialog
        visible={dialogVisible}
        title={lock ?   " Lock Budget" : "Unlock Budget" }
        content={
          <>
            { lock ? (<>    <div className="mb-2 font-weight-normal">
              Are you sure you want to lock the Budget Table? If any changes
              need to be made after this, only the “Project Manager” or the
              “Financial Manager” has the access to unlock the Budget for the
              same.
            </div>
            <div className="font-weight-normal">
              While the Budget table is locked, only Budget Modifications can be
              done which would not change the Original Budget Amount.
            </div>  </>) :  (<>    <div className="mb-2 font-weight-normal">
              Are you sure you want to unlock the Budget Table.
            </div>
            <div className="font-weight-normal">
            Before unlocking, select what you want to do with the modifications done in the Budget table. It is recommended to “Preserve & Unlock Budget” as once these modification are deleted, they can never be recovered.
            </div></>)  }
          </>
        }
        onConfirm={handleConfirm}
        label={lock ?  " Lock Budget" :  "Unlock Budget" }
        setVisible={setDialogVisible}
        onSubmit={handleConfirm}
        lock={lock}
      />

      <AddSnapshots

      visible={addSnapshots}
      setVisible={setAddSnapshots}
       


      />
      <div className='mt-2'>
      <Pagination
                  currentPage={page}
                  totalCount={totalRecords}
                  pageSize={perPageData}
                  onPageChange={page => {
                   
                      setPage(page)
                  }}
              />
      </div>
    </>
  );
};
