import React, { useEffect, useState } from "react";
import "./CreatePunch.css";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DeleteButton } from "../../Components/Buttons/DeleteButton";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { useNavigate } from "react-router";
import style from "./PaintTouchup.css";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { Dropdown } from "bootstrap";
import { MultipleDragAndDrop } from "../../Components/Inputs/MultipleDragAndDrop";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { MdAdd, MdOutlineAttachment } from "react-icons/md";
import { addCategory, addPunch, addSubmittalCategory, addTrade, getAllCategory, getCompanyByProjectId, getCountForPunchByProjectId, getDrawing, getDrawingByProjectId, getTradeByProjectId, getUserByProjectId, getUsersByCompanyId, uploadDocs } from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectCode, getProjectName, getUserId, getUserName, parseHashedData, toast } from "../../utils";

import Loader from "../../Components/Common/Loader";
import { Editor } from "primereact/editor";
import { LoopFileUpload } from "../../Components/Inputs/LoopFileUpload";
import { MultiSelect } from "primereact/multiselect";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import AddSets from "../../Components/Common/AddSets";
import TimeInput from "../../Components/Inputs/TimeSelector";
import moment from "moment";
import { TOOL_STATUS } from "../../constants";
import { FormHeader } from "../../Components/Common/FormHeader";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { useFetchAllParentsTaskByTool } from "../../hooks/useFetchAllParentsTaskByTool";
export const CreatePunch = () => {
  const isApproverAccess = getActionsPermission("Punch Approver")
  const isReviewerAccess = getActionsPermission("Punch Reviewer")

  let ss = useSelector(e => e.rolesAndPermissionData)
  const [approver, setApprover] = useState()
  const [reviewer, setReviewer] = useState()
  const [allUsersOfProject, setAllUsersOfProject] = useState([])
  const [visible, setVisible] = useState(false)
  const [punchListID, setPunchListID] = useState('')
  const [loading, setLoading] = useState(false)
  const [hourSpent, setHourSpent] = useState(
    {
      hours: 0,
      minutes: 0
    }
  )
  const dataTest = [
    "Elicia Katherine ",
    "Dennis Paul",
    "Sheryll Lacie ",
    "A&C Contruction",
    "Jonshon Builders",
    "Roy Constructors",
    "Smith Engineering",
  ];



  const domain = window.location.origin;
  const [tableData, setTableData] = useState([
    {
      AssigneeName: "",
      Comments: "",
      Attachment: [],
      Status: "",
    },
  ]);

  const [memberResponsible, setMemberResponsible] = useState(dataTest);
  // const [types, setTypes] = useState(type);
  const [assigneeValues, setAssigneeValues] = useState([]);
  const [punchItemName, setPunchItemName] = useState("");
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [personResponsible, setPersonResponsible] = useState([])
  const [punchItemManager, setPunchItemManager] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [types, setTypes] = useState("");
  const [trade, setTrade] = useState("");
  const [description, setDescription] = useState("");
  const [drawing, setDrawing] = useState("");
  const [eventDate, setEventDate] = useState();
  const [rowDataName, setRowDataName] = useState("Garry Scott");
  const [responsibleContractor, setResponsibleContractor] = useState([])
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null);
  const [addCategoryy, setAddCategoryy] = useState()
  const [companies, setCompanies] = useState([])
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNameAssignee, setFileNameAssignee] = useState([]);
  const [previousFilesAssignee, setPreviousFilesAssignee] = useState([]);
  const [selectedFilesAssignee, setSelectedFilesAssignee] = useState([]);
  const [loader, setLoader] = useState(false);
  const [drawingListing, setDrawingListing] = useState([]);
  const [assigneeAttachment, setAssigneeAttachment] = useState({});
  const [visible2, setVisible2] = useState(false)
  // const [addTrade, setAddTrade] = useState('')


  const [linkedDrawing, setLinkedDrawing] = useState('')
  const [users, setUsers] = useState([])
  const [trades, setTrades] = useState([])
  const [addTradess, setAddTradess] = useState()
  const projectId = localStorage.getItem("projectId");
  const projectData = JSON.parse(localStorage.getItem("projectData"));

  const { data: parentsToolData, isLoading, getData: getParentOfPunchData } = useFetchAllParentsTaskByTool("Punch")
  const [parentId, setParentId] = useState([]);

  const [Validation, setValidation] = useState({
    punchItemName: false,
    person: false,
    punchItemManager: false,
    eventDate: false,
    location: false,
    punchNo: false,
    trade: false,
    category: false,
    description: false,
    // drawing: false,
    hourSpent: false
  });

  const status_options = [
    { id: 1, status: "Draft", name: "On Hold" },
    { id: 2, status: "work required", name: "Open" },
    { id: 3, status: "Ready to close", name: "Close" },
  ];

  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" }
  ]

  const handleFileInput = (event) => {

    setSelectedFiles(event);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };


  // let emailData = [];
  // let manager = allUsersOfProject?.filter(res => punchItemManager.includes(res?._id))

  // manager?.forEach(res => {
  //   email_data.push({
  //     email: res?.email_address,
  //     type: "Punch List Manager",
  //     tool_name: punchItemName,
  //     project: getProjectName(),
  //     toolType: "Punch List",
  //     name: res?.first_name,
  //     projectName: getProjectName(),
  //     project_id: projectData?.project_code,
  //     tool_id: punchListID,
  //     createdAt: moment().format('DD MMM YYYY  hh:mm A'),
  //     createdBy: userData?.name

  //   });
  // })



  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const generateNextPunch = async () => {
    try {
      let res = await getCountForPunchByProjectId(projectId)

      setPunchListID(res.data.data);
    } catch (Err) {
      console.log(Err)
    }
  };


  useEffect(() => {
    generateNextPunch();
  }, []);

  useEffect(() => {
    getParentOfPunchData()
    handleGetDrawing();
  }, []);

  const handleGetDrawing = async () => {
    try {
      const res = await getDrawingByProjectId(projectId);
      const getListing = res?.data?.data;
      setDrawingListing(getListing);
    } catch (error) {
      console.log(error);
    }
  };

  const checkValidation = () => {
    let isValid = true;
    const updatedValidation = {};

    // Check each required field and update validation state
    if (!punchItemName.trim()) {
      updatedValidation.punchItemName = true;
      isValid = false;
    }
    if (!punchListID) {
      setValidation((prevState) => ({
        ...prevState,
        punchNo: true,
      }));
      isValid = false;
    }
    // if (responsibleContractor.length == 0) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     contractor: true,
    //   }));
    // }
    // if (membersResponsible.length == 0) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     membersResponsible: true,
    //   }));
    // }
    if (personResponsible.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        person: true,
      }));
    }
    if (punchItemManager.length == 0) {
      setValidation((prevState) => ({
        ...prevState,
        punchItemManager: true,
      }));
    }

    if (!location.trim()) {
      updatedValidation.location = true;
      isValid = false;
    }
    if (!trade.trim()) {
      updatedValidation.trade = true;
      isValid = false;
    }
    if (!category) {
      updatedValidation.category = true;
      isValid = false;
    }
    // if (!status.trim()) {
    //   updatedValidation.status = true;
    //   isValid = false;
    // }

    // if (!drawing.trim()) {
    //   updatedValidation.drawing = true;
    //   isValid = false;
    // }
    if (!hourSpent) {
      updatedValidation.hourSpent = true;
      isValid = false;
    }


    if (!eventDate) {
      updatedValidation.eventDate = true;
      isValid = false;
    }

    // Update validation state
    setValidation((prevState) => ({
      ...prevState,
      ...updatedValidation,
    }));

    if (!isValid) {
      toast.info("Please fill all the mandatory fields marked with *.");
    }

    return isValid;
  };


  let navigate = useNavigate();



  const deleteAssignee = (ob, index) => {


    let arr = tableData.splice(index, 1);

    // setTableData((prev) => {
    //   prev.splice(index, 1);
    //   return [...prev];
    // });
  };



  const handleCreate = async () => {
    if (selectedFiles.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (!checkValidation()) {

      return;
    }
    setLoader(true);
    let attachfile = [];

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      for (const file of selectedFiles) {

        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);
      attachfile = uploadResponse?.data;
    }


    for (const key in assigneeAttachment) {

      const formData = new FormData();
      let attachfile1 = [];
      for (const data in assigneeAttachment[key]) {
        formData.append("upload", assigneeAttachment[key][data]);
      }
      if (assigneeAttachment[key].length > 0) {
        const uploadResponse = await uploadDocs(formData);
        attachfile1 = uploadResponse?.data;
      }

      if (tableData[key]) {
        tableData[key].Attachment = attachfile1;
        setTableData(tableData);
      }
    }

    // let emailData = []



    // if (isReviewerAccess) {
    //   emailData.push({
    //     email: reviewer.email_address,
    //     type: "Reviewer",
    //     rfi_name: punchItemName,
    //     project: getProjectName(),
    //     toolName: "PUNCH LIST",
    //     name: reviewer?.first_name
    //   })
    // }

    //   if (body.reviewer_id && body.approver_id) {
    //     body.status = TOOL_STATUS.inreview
    // }

    // send email to reviewer if selected 

    let email_data = [];
    if (reviewer) {
      let reviewerData = allUsersOfProject.find(e => e._id == reviewer);
      email_data.push({
        name: reviewerData?.first_name,
        to: reviewerData?.email_address,
        tool_number: punchListID,
        tool: "Punch List",
        tool_name: punchItemName,
        project_name: getProjectName(),
        subject: `Action Required: Appointed as the Reviewer to  Punch #${punchListID} ${punchItemName}`,
        project_id: "8767",
        template_type: "createdToolEmailToApprover",
        url: `${domain}/coretools/tasktable`,
        created_by: getUserName()
      })
    }
    // send email to reviewer if selected 
    if (approver) {
      let approverData = allUsersOfProject.find(e => e._id == approver)
      email_data.push({
        name: approverData?.first_name,
        to: approverData?.email_address,
        tool_number: punchListID,
        tool: "Punch List",
        tool_name: punchItemName,
        subject: `FYI: Appointed as the Approver to Punch #${punchListID} ${punchItemName}`,
        project_name: getProjectName(),
        project_id: getProjectCode(),
        template_type: "createdToolEmailToReviewer",
        url: `${domain}/coretools/tasktable`,
        created_by: getUserName()
      })
    }

    punchItemManager?.forEach((userId) => {
      let currentUser = allUsersOfProject.find((e) => e._id == userId)
      if (currentUser) {
        email_data.push({
          name: currentUser?.first_name,
          to: currentUser?.email_address,
          tool_number: punchListID,
          tool: "Punch List",
          tool_name: punchItemName,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          subject: `FYI:New Punch created in ${getProjectName()}`,
          template_type: "createdToolEmailToAssociatedUsers",
          userType: "Responsible Manager", // new key
          url: `${domain}/coretools/tasktable`,
          created_by: getUserName()
        })
      }
    })

    personResponsible?.forEach((userId) => {
      let currentUser = allUsersOfProject.find((e) => e._id == userId)
      if (currentUser) {
        email_data.push({
          name: currentUser?.first_name,
          to: currentUser?.email_address,
          tool_number: punchListID,
          tool: "Punch List",
          tool_name: punchItemName,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          subject: `FYI:New Punch created in ${getProjectName()}`,
          template_type: "createdToolEmailToAssociatedUsers",
          userType: "Responsible Person",
          url: `${domain}/coretools/tasktable`,
          created_by: getUserName()
        })
      }
    })




    let payload = {
      punchID: punchListID,
      parent_id:parentId,
      punchItemName: punchItemName,
      date: eventDate,
      membersResponsible: membersResponsible,
      person: personResponsible,
      status: TOOL_STATUS.draft,
      punchItemManager: punchItemManager,
      location: location,
      assignees: tableData,
      category: category,
      trade: trade,
      responsibleContractor: responsibleContractor,
      category: category,
      discription: description,
      linkDrawing: drawing,
      attachfile: attachfile ? attachfile : [],
      project_id: projectId,
      reviewer_id: reviewer ? reviewer : null,
      approver_id: approver ? approver : null,
      email_data: email_data,
      hourSpent: hourSpent,
      workflow_status: status,
      creator_email: getUserId()
    };

    if (payload.reviewer_id && payload.approver_id) {
      payload.status = TOOL_STATUS.inreview
    }

    try {
      let result = await addPunch(payload);

      toast.success("Punch Created successfully!");
      navigate("/projectManagement/punch");
      setCategory("");
      setMemberResponsible("");
      setPersonResponsible("");
      setTrade("");

    } catch (error) {
      if (error?.response?.data?.message === "Punch ID Already Exist") {
        toast.error(error?.response?.data?.message);
      }
      else {
        console.error("Error creating Punch:", error);
        toast.error("Something Wents Wrong, Please Try Again!");
      }

    }
    setLoader(false);
  };



  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllUsersOfProject()
  }, [])

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);
      if (res && res?.data) {

        const companyNames = res?.data?.data[0]?.companynames;


        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id

        }));

        setCompanies(formattedCompanies);
      } else {
        // console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getcompany()
  }, [])

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value; // e.value should be an array of selected company IDs
    setResponsibleContractor(selectedCompanyIds);


    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, { company_id: [companyId] });
          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map(user => ({
              label: user.name,
              value: user._id
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log(error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(new Set(usersFromAllCompanies.map(user => user.value)))
        .map(id => {
          return usersFromAllCompanies.find(user => user.value === id);
        });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };

  const getPunchCategory = async (projectId) => {
    try {
      const res = await getAllCategory(projectId)

      const categoryy = res?.data?.data

      const capitalizedCategory = categoryy.map(category => ({
        ...category,
        name: capitalizeFirstLetter(category.name)
      }));
      setCategories(capitalizedCategory)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPunchCategory(projectId)
  }, [])

  // const submitPunchCategory = async () => {

  //   try {
  //     const payload = {
  //       name: addCategoryy,
  //       project_id: projectId
  //     }
  //     await addCategory(payload)
  //     setAddCategoryy('')
  //     getPunchCategory(projectId)
  //     setVisible(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getPunchTrade = async (projectId) => {
    try {
      const res = await getTradeByProjectId(projectId)

      const tradee = res?.data?.data

      const capitalizedTrades = tradee.map(trade => ({
        ...trade,
        name: capitalizeFirstLetter(trade.name)
      }));

      setTrades(capitalizedTrades);
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPunchTrade(projectId)
  }, [])
  // const submitTrade = async () => {

  //   try {
  //     const payload = {
  //       name: addTradess,
  //       project_id: projectId
  //     }
  //     await addTrade(payload)
  //     setAddTradess('')
  //     getPunchTrade(projectId)
  //     setVisible2(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }


  const handleCreateAddIcon = async (type) => {

    try {
      if (type === "category" && !addCategoryy) {
        toast.info(
          "Please fill  the  Submittal Category field marked with * are mandatory"
        );
        return;
      }

      if (type === "trade" && !addTradess) {
        toast.info(
          "Please fill  the  Submittal Package field marked with * are mandatory"
        );
        return;
      }



      if (type === "category") {
        setLoading(true)
        await addSubmittalCategory({
          name: addCategoryy,
          project_id: projectId
        });
        setAddCategoryy('')
        getPunchCategory(projectId)
        setVisible(false);
        setLoading(false)

      } else if (type === "trade") {
        setLoading(true)
        await addTrade({
          name: addTradess,
          project_id: projectId
        });
        setAddTradess('')
        setVisible2(false)
        getPunchTrade(projectId)
        setLoading(false)

      }


    } catch (error) {
      console.log(error);
    }
  };

  return (

    <div className={` ${style.createPunch_con}`}>
      <div>
        {loader ? (
          <Loader />
        ) : (
          <div>
            <FormHeader
              to={"/projectManagement/punch"}
              formHeader={"Punch List"}
              breadcrumItem={["Punch List", `Create Punch List`]} />

            <div className="row">
              <div className="col-md-12 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Punch List Name: <span className="text-danger">*</span>
                </label>
                {/* {console.log(
                  "Punch Item Name Validation",
                  validation.punchItemName
                )} */}
                <TextInput
                  placeholder="Punch List Name"
                  value={punchItemName}
                  onChange={(e) => setPunchItemName(e.target.value)}
                  className={Validation.punchItemName ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...Validation, punchItemName: false })
                  }
                >
                  {" "}
                </TextInput>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Punch List ID: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Punch List ID"
                  value={punchListID}
                  onChange={(e) => setPunchListID(e.target.value)}
                  className={`${Validation.punchNo ? "Validation" : ""
                    } form-control common_input`}
                  onClick={() =>
                    setValidation({ ...Validation, punchNo: false })
                  }
                >
                  {" "}
                </TextInput>
              </div>

              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Due Date: <span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  style={{ height: "50px" }}
                  placeholder="Due Date"
                  min={new Date().toISOString().split("T")[0]}
                  // value={formatDate(eventDate)}
                  value={eventDate}
                  onChange={(e) => setEventDate(e.target.value)}
                  className={`${Validation.eventDate ? "Validation" : ""
                    } form-control`}
                  onClick={() =>
                    setValidation({ ...Validation, eventDate: false })
                  }
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Contractor Company(s):{" "}
                </label>

                <MultiSelect
                  value={responsibleContractor}
                  //  onChange={(e) => setResponsibleContractor(e.value)}  
                  onChange={handleCompanyChange}
                  options={companies}
                  optionLabel="label"
                  filter placeholder="Company"
                  // maxSelectedLabels={3} 
                  style={{ width: "100%" }}
                  className={`p-0 ${Validation.contractor ? "Validation" : ""} `}
                  onClick={() =>
                    setValidation({ ...Validation, contractor: false })
                  }

                />

              </div>

              <div className="col-md-6">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Member(s):
                </label>
                <MultiSelect
                  value={membersResponsible}
                  onChange={(e) => setMembersResponsible(e.value)}
                  options={users}
                  optionLabel="label"
                  filter placeholder="Select Member"
                  // maxSelectedLabels={3} 
                  style={{ width: "100%" }}
                  className={`w-full md:w-20rem ${Validation.membersResponsible ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, membersResponsible: false })
                  }
                />
              </div>

              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Responsible Person (s): <span className="text-danger">*</span>
                </label>

                <MultiSelect
                  value={personResponsible}
                  onChange={(e) => {
                    setPersonResponsible(e.value)
                    setValidation({ ...Validation, person: false })
                  }}
                  options={allUsersOfProject.map((item) => ({ label: item?.first_name, value: item?._id }))}
                  optionLabel="label"
                  filter placeholder="Select Person"
                  // maxSelectedLabels={3} 
                  style={{ width: "100%" }}
                  className={`w-full md:w-20rem ${Validation.person ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, person: false })
                  }
                />

              </div>


              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Punch List Manager(s): <span className="text-danger">*</span>
                </label>
                <MultiSelect
                  value={punchItemManager}
                  onChange={(e) => {
                    setPunchItemManager(e.value)
                    setValidation({ ...Validation, punchItemManager: false })
                  }}
                  options={allUsersOfProject.map((item) => ({
                    label: item?.first_name,
                    value: item?._id,
                  }))}
                  optionLabel="label"
                  filter
                  placeholder="Select Manager"
                  // maxSelectedLabels={3}
                  style={{ width: "100%" }}
                  className={`w-full md:w-20rem ${Validation.punchItemManager ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, punchItemManager: false })
                  }
                />

              </div>
              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Location: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className={Validation.location ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...Validation, location: false })
                  }
                >
                  {" "}
                </TextInput>
              </div>


              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label htmlFor="" className="py-2 labelCss">
                    Category: <span className="text-danger">*</span>
                  </label>
                  <div className="card flex justify-content-center  ">


                    <AutoCompleteSelect
                      options={categories.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={category}
                      optionLabel="category_name"
                      onChange={(value) => {
                        setCategory(value)
                        setValidation({ ...Validation, category: false })
                      }}
                      className={`p-0 ${Validation.category ? "Validation" : ""} text-capitalize`}
                      onClick={() =>
                        setValidation({ ...Validation, category: false })
                      }
                      placeholder="Select Category"
                    />

                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Hours Spent:<span className="text-danger">*</span>
                </label>
                <TimeInput
                  value={hourSpent}
                  onChange={(e) => {

                    setHourSpent(e)
                  }}
                  className={Validation.hourSpent ? "Validation p-0" : "p-0"}

                  onClick={() =>
                    setValidation({ ...Validation, hourSpent: false })
                  }
                />

              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <div className="card flex justify-content-center  ">
                    <label htmlFor="" className="py-2 labelCss">
                      Trade: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={trades.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={trade}
                      optionLabel="trade_name"
                      onChange={(value) => {
                        setTrade(value)
                        setValidation({ ...Validation, trade: false })
                      }}
                      className={`p-0 ${Validation.trade ? "Validation" : ""}`}
                      onClick={() => setValidation({ ...Validation, trade: false })}
                      placeholder="Select Trade"
                      style={{ textTransform: 'capitalize' }}
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible2(true)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Workflow Status: <span className="text-danger">*</span>
                </label>
                <SingleSelect
                  value={status}
                  options={statusOptions}
                  placeholder="Workflow Status"
                  onChange={(e) => setStatus(e.target.value)}
                  className={Validation.status ? "Validation p-0" : "p-0"}
                  onClick={() =>
                    setValidation({ ...Validation, status: false })
                  }
                // disabled={
                //   alldata.rfi_approval_status == "approved" ||
                //   alldata.rfi_review_status == "approved"
                // }
                />
              </div>

              <div className="col-md-6 d-flex">
                <div className="maindiv_plusIcon">
                  <label htmlFor="" className="py-2 labelCss">
                    Linked Drawings:
                  </label>
                  <SingleSelect
                    options={drawingListing?.map((item) => ({
                      label: item.drawing_name,
                      value: item.drawing_name,
                    }))}
                    value={drawing}
                    placeholder={"Link Drawing (s)"}
                    onChange={(e) => setDrawing(e.target.value)}
                  // className={Validation.drawing ? "Validation" : ""}
                  // onClick={() =>
                  //   setValidation({ ...Validation, drawing: false })
                  // }
                  />
                </div>
              </div>




              {isReviewerAccess &&
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject?.map((item) => {
                      return {
                        label: item?.first_name,
                        value: item?._id,
                      }

                    })}
                    value={reviewer}
                    optionLabel="label"
                    onChange={(value) => {
                      setReviewer(value);
                    }}
                    className={`p-0`}

                    // disabled={reviewer === getUserId()}
                    placeholder="Select Reviewer"

                  />
                </div>}

              {isApproverAccess &&
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject?.map((item) => {
                      return {
                        label: item?.first_name,
                        value: item?._id,
                      }
                    })}
                    value={approver}
                    optionLabel="label"
                    onChange={(value) => {
                      setApprover(value);
                    }}
                    className={`p-0`}

                    // disabled={approver === getUserId()}
                    placeholder="Select Approver"

                  />
                </div>
              }


              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                Parent Task:
                </label>


                <SingleSelect
                  showClear
                  placeholder="Select Parent"
                  options={parentsToolData}
                  optionLabel="PunchItemName"
                  value={parentsToolData?.find((e) => e._id == parentId?.[0]) || null}
                  onChange={(e) => {
                    setParentId(e?.value?._id ? [e?.value?._id] : []);
                  }}
                  filter={true}
                  filterInputAutoFocus={true}
                />

              </div>


              <div className="col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Description: <span className="text-danger">*</span>
                </label>
                {/* <Editor
                  style={{ height: "200px" }}
                  placeholder={"Description"}
                  value={description}
                  onTextChange={(e) => setDescription(e.htmlValue)}
                  className={Validation.description ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...Validation, description: false })
                  }
                /> */}


                <ReactQuill theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </div>

              <div className="col-12 mt-2">
                <label
                  htmlFor=""
                  className="py-2 labelCss text-decoration-underline"
                ></label>

                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={selectedFiles}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                />
              </div>
            </div>

            {/* <div>
              <hr className="my-4" />
            </div>
            <div className="fw-bold fs-6">Assignees</div>
            <br />
            <BorderedTable>
              <DataTable value={tableData}>
                <Column
                  field="id"
                  header=""
                  style={{ minWidth: "4rem" }}
                  body={(rowData, meta) => (
                    <div className="d-flex justify-content-center">
                      {meta.rowIndex + 1}
                    </div>
                  )}
                ></Column>
                <Column
                  header="Assignee Name"
                  style={{ width: "20%" }}
                  body={(rowData, rowIndex) => (
                    <SingleSelect
                      className="flex align_center"
                      style={{ height: "40px" }}
                      
                      options={assigneeUsers}

                      value={rowData.AssigneeName}
                      onChange={(e) => {
                        setTableData((pre) => {
                         
                          pre[rowIndex.rowIndex].AssigneeName = e.target.value;
                          return [...pre];
                        });
                      }}
                    />
                  )}
                ></Column>
                <Column
                  style={{ width: "20%" }}
                  field="comment"
                  header="Comments"
                  body={(rowData, rowIndex) => (
                    <div>
                      <TextInput
                        onChange={(e) => {
                          setTableData((pre) => {
                          
                            pre[rowIndex.rowIndex].Comments = e.target.value;
                            return [...pre];
                          });
                        }}
                        value={rowData.Comments}
                      />
                    </div>
                  )}
                ></Column>
                <Column
                  style={{ width: "40%" }}
                  body={(rowData, meta) => (
                    <div>
                   
                      <LoopFileUpload
                        index={meta?.rowIndex}
                        data={assigneeAttachment}
                        setData={setAssigneeAttachment}
                      />
                 
                    </div>
                  )}
                  header="Attachment"
                ></Column>
                <Column
                  style={{ width: "20%" }}
                  body={(rowData, rowIndex) => (
                    <div>
                      <SingleSelect
                        className="flex align_center"
                        style={{ height: "40px" }}
                        options={["Unresolved", "Ready for Review"]}
                        placeholder="Select"
                        value={rowData.Status}
                        onChange={(e) => {
                          setTableData((pre) => {
                           
                            pre[rowIndex.rowIndex].Status = e.target.value;
                            return [...pre];
                          });
                        }}
                      />
                    </div>
                  )}
                  header="Status"
                ></Column>
                <Column
                  style={{ minWidth: "4rem" }}
                  body={(rowData, rowIndex) => (
                    <div>
                      <span
                        onClick={() =>
                          deleteAssignee(rowData, rowIndex.rowIndex)
                        }
                      >
                        <DeleteButton />
                      </span>
                    </div>
                  )}
                ></Column>
              </DataTable>
            </BorderedTable>

            <div className="mt-2">
              +
              <span
                className="addAssignee_btn ms-1 fw-bold fs-6 pt-2"
                onClick={handleAddRow}
              >
                Add Assignee
              </span>
            </div> */}

            <div className="d-flex justify-content-end gap-2 mt-3">
              <button
                className="btn border"
                onClick={() => navigate("/projectManagement/punch")}
              >
                Cancel
              </button>
              <PrimaryButton text={"Create"} onClick={handleCreate} />
            </div>
          </div>
        )}
      </div>
      <br />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Punch Category"}
        label={"Category"}
        reqFor={"create"}
        value={addCategoryy}
        setValue={setAddCategoryy}
        onSubmit={() => handleCreateAddIcon("category")}
      // onSubmit={submitPunchCategory}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible2}
        setVisible={setVisible2}
        CategoryName={"Punch Trade"}
        label={"Trade"}
        reqFor={"create"}
        value={addTradess}
        setValue={setAddTradess}
        onSubmit={() => handleCreateAddIcon("trade")}
      // onSubmit={submitTrade}
      />
    </div>
  );
};
