import React, { useState } from 'react'
import AddGeneralInfo from './AddGeneralInfo';
function AddCompany() {
    let formType = new URLSearchParams(window.location.search).get("formType");
    const [activeTabIndex, setActiveTabIndex] = useState(formType === "individual" ? 1 : 0);
    return (
        <>
            <div className=" main-container">
                <div className="form_header">Create Company</div>
                <AddGeneralInfo formType={formType} setActiveTabIndex={setActiveTabIndex} />
            </div>
        </>
    )
}

export default AddCompany;