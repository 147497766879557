import React, { useEffect, useState } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import {
  addUser,
  getCompany,
  getUserById,
  getallrole,
  updateCompany,
  updateUser,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { toast } from "../../utils";
import { RadioButton } from "primereact/radiobutton";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import moment from "moment";
import { getTimeZoneOptions } from "../../Components/Common/timeZone";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";

function EditUserInfo() {
  const location = useLocation();
  let { backUrl } = location?.state || { backUrl: "/userManagement" }
  const timeZoneOptions = getTimeZoneOptions();
  const { id } = useParams();
  const [checked, setChecked] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  let [userType, setUserType] = useState("");

  const [selectedUserRole, setSelectedUserRole] = useState("");
  let [timeZone, setTimeZone] = useState("")
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [company, setCompany] = useState({});
  const countries = Country.getAllCountries();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [county, setCounty] = useState("");
  const [locality, setLocality] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [fax, setFax] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [tag, setTag] = useState("");
  const [userMsg, setUserMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [loader, setLoader] = useState(true);
  const [companyRole, setCompanyRole] = useState("");
  const [response, setResponse] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUpload2, setFileUpload2] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [fileName2, setFileName2] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [previousFile2, setPreviousFile2] = useState([]);
  const [allUserRoles, setAllUserRoles] = useState([])

  const [joiningDate, setJoiningDate] = useState("");
  const [interviewedOn, setInterviewedOn] = useState("")
  const [contractEndDate, setContractEndDate] = useState("")

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [Validation, setValidation] = useState({
    firstName: false,
    lastName: false,
    initials: false,
    email: false,
    address: false,
    selectedCountry: false,
    selectedState: false,
    selectedCity: false,
    zip: false,
    businessPhone: false,
    fax: false,
    cellPhone: false,
    jobTitle: false,
    tags: false,
    company: false,
    companyRole: false,
    userMsg: false,
  });

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [

    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },

    { qty: 1, height: "7rem" },
    { qty: 1 },
    { qty: 2 },

  ]

  const roleData = [
    { name: "Primary Contract" },
    { name: "Invoice Contract" },
    { name: "Employee" },
  ];
  const checkValidation = () => {
    let num = 0;
    if (!firstName) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        firstName: true,
      }));
    }
    if (!lastName) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        lastName: true,
      }));
    }
    if (!initials) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        initials: true,
      }));
    }
    if (!email || !emailRegex.test(email)) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        email: true,
      }));
    }



    if (!selectedCountry) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        selectedCountry: true,
      }));
    }
    if (!selectedState) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        selectedState: true,
      }));
    }
    // if (!selectedCity) {
    //     num++
    //     setValidation((prevState) => ({
    //         ...prevState,
    //         selectedCity: true,
    //     }));
    // }
    if (!address) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        address: true,
      }));
    }
    if (!zip) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        zip: true,
      }));
    }
    if (!businessPhone) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        businessPhone: true,
      }));
    }
    if (!fax) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        fax: true,
      }));
    }
    if (!cellPhone) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        cellPhone: true,
      }));
    }
    if (!selectedUserRole) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        selectedUserRole: true,
      }));
    }
    // if (!jobTitle) {

    //     setValidation((prevState) => ({
    //         ...prevState,
    //         jobTitle: true,
    //     }));
    // }

    if (!tag) {
      num++;
      setValidation((prevState) => ({
        ...prevState,
        tag: true,
      }));
    }

    if (userType === "user") {
      if (!companyName) {
        num++;
        setValidation((prevState) => ({
          ...prevState,
          companyName: true,
        }));
      }
      if (!companyRole) {
        num++;
        setValidation((prevState) => ({
          ...prevState,
          companyRole: true,
        }));
      }
    }

    // if (!userMsg) {
    //     setValidation((prevState) => ({
    //         ...prevState,
    //         userMsg: true,
    //     }));
    // }

    // if (
    //     !firstName ||
    //     !lastName ||
    //     !initials ||

    //     !selectedCountry ||
    //     !selectedState ||
    //     !selectedCity ||
    //     !email ||
    //     !address ||
    //     !zip ||
    //     !businessPhone ||
    //     !fax ||
    //     !cellPhone ||
    //     !jobTitle ||
    //     !tag ||
    //     !companyName ||
    //     !companyRole ||
    //     !userMsg

    // ) {
    //     return true;
    // }
    if (num == 0) {
      return false;
    } else {
      toast.info("Please fill all the Fields Marked with * are Mandatory");

      return true;
    }
  };

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };
  const handleFileSubmit2 = (event) => {
    setFileUpload2(event);
  };

  const handleGetCompany = async () => {
    try {
      const res = await getCompany();

      const companyData = res?.data?.data;
      // return companyData
    
      setResponse(companyData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetUser = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getUserById(id);
    
      const userData = res?.data?.data[0];
    
      setUserType(userData?.user_type);
      setSelectedUserRole(userData?.role);
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setInitials(userData.initals);
      setEmail(userData.email_address);
      setAddress(userData.address);
      setJoiningDate(userData.joining_date)
      setInterviewedOn(userData.interviewed_on)
      setContractEndDate(userData.contract_end_date)
      // setSelectedCountry(userData.country);
      // setSelectedState(userData.state);
      // setSelectedCity(userData.city);
      setCounty(userData?.county || "")
      setLocality(userData?.locality || "")
      setZip(userData.zip);
      setTimeZone(userData?.timeZone || "")
      setBusinessPhone(userData.business_phone);
      setFax(userData.business_fax);
      setCellPhone(userData.cell_phone);
      setJobTitle(userData.job_title);
      setTag(userData.tags_keywords);
      setChecked(userData.active);
      setCheckedTwo(userData.is_employee_procore_certification);
      setCheckedThree(userData.is_insurance_manager_procore_certification);
      setUserMsg(userData.send_user_messages);
      setCompanyName(userData.company);
      setCompanyRole(userData.company_role);

      setFileName(
        userData.attachments.map((item) => ({
          name: item?.fileName,
          id: item?._id,
        }))
      );
      setFileName2(
        userData.user_profile.map((item) => ({
          name: item?.fileName,
          id: item?._id,
        }))
      );




      setPreviousFile([...userData?.attachments]);
      setPreviousFile2([...userData?.user_profile]);

      const country = countries.find((c) => c.name === userData.country);
      if (country) {
        setSelectedCountry(country);
        const states = State.getStatesOfCountry(country.isoCode);
        setStates(states);

        const state = states.find((s) => s.name === userData.state);
        if (state) {
          setSelectedState(state);
          const cities = City.getCitiesOfState(country.isoCode, state.isoCode);
          setCities(cities);

          const city = cities.find((c) => c.name === userData.city);

          if (city) {
            setSelectedCity(city);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  const getStates = (countryIsoCode, defaultState, defaultCity) => {
    setSelectedState("");
    setSelectedCity("");
   
    const states = State.getStatesOfCountry(countryIsoCode);
   
    setStates(states);

    if (defaultState) {
      const state = states.find((s) => s.name === defaultState);
      setSelectedState(state);
      getCities(state, defaultCity);
    }
  };

  const getCities = (state, defaultCity) => {
    const { isoCode, countryCode } = state || {};
    setSelectedCity("");

    const cities = City.getCitiesOfState(countryCode, isoCode);
    setCities(cities);
   
    if (defaultCity) {
      const city = cities.find((c) => c.name === defaultCity);
      setSelectedCity(city);
    }
  };

  const handleUpdateUser = async () => {
    if (checkValidation()) {
      return;
    }

    let uploadedFiles = [];

    if (fileUpload && fileUpload.length > 0) {
      const formData = new FormData();
      for (const file of fileUpload) {
        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);

      uploadedFiles = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        fileUrl: item.fileUrl,
      }));
    }
    let uploadedFiles2 = [];

    if (fileUpload2 && fileUpload2.length > 0) {
      const formData2 = new FormData();
      for (const file of fileUpload2) {
        formData2.append("upload", file);
      }

      const uploadResponse2 = await uploadDocs(formData2);

      uploadedFiles2 = uploadResponse2.data.map((item) => ({
        fileName: item.fileName,
        fileUrl: item.fileUrl,
      }));
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      initals: initials,
      email_address: email,
      address: address,
      city: selectedCity?.name,
      country: selectedCountry?.name,
      state: selectedState?.name,
      zip: zip,
      timeZone: timeZone,
      county: county,
      locality: locality,
      company: companyName || null,
      business_phone: businessPhone,
      business_fax: fax,
      cell_phone: cellPhone,
      job_title: jobTitle,
      tags_keywords: tag,
      active: checked,
      send_user_messages: userMsg,
      is_employee_procore_certification: checkedTwo,
      is_insurance_manager_procore_certification: checkedThree,
      attachments: [...previousFile, ...uploadedFiles],
      user_profile: [...previousFile2, ...uploadedFiles2],
      deleted_file: removePhoto,
      company_role: companyRole || "",
      user_type: userType,
      role: selectedUserRole,
      interviewed_on: interviewedOn || "",
      joining_date: joiningDate || "",
      contract_end_date: contractEndDate || ""

    };
    try {
    

      await updateUser(id, payload);
      setLoader(true);
      setBtnDisable(false);
      toast.success("User Updated Successfully!");
      navigate("/userManagement");
      setFirstName("");
      setLastName("");
      setInitials("");
      setEmail("");
      setAddress("");
      setSelectedCountry("");
      setSelectedState("");
      setSelectedCity("");
      setZip("");
      setBusinessPhone("");
      setFax("");
      setCellPhone("");
      setJobTitle("");
      setTag("");
      setChecked(false);
      setCheckedTwo(false);
      setCheckedThree(false);
      setUserMsg("");
      setCompanyName("");
    } catch (err) {
      console.log(err);
      toast.error("Something Wents Wrong, Please Try Again!");
    }
    setLoader(false);
  };
  useEffect(() => {
    handleGetCompany();
    handleGetUser();
  }, []);
  const userRole = [
    { name: "Owner", code: "owner" },
    { name: "Project Manager", code: "project_manager" },
    { name: "Site Manager", code: "site_manager" },
    { name: "Principle Architect", code: "principal_architecher" },
    { name: "Priciple Designer", code: "principal_designer" },
    { name: "Architect	Designer", code: "architect_designer" },
    { name: "Site Worker", code: "site_worker" },
    { name: "Financial Analyst", code: "finacial_analyst" },
    { name: "Accountant", code: "account" },
    { name: "Resource Manager", code: "resource_manager" },
    { name: "Equipments head", code: "equipmenthead" },
    { name: "HR Manager", code: "hr_manager" },
  ];


  async function getAllRoles() {
    try {
      let res = await getallrole()
     
      setAllUserRoles(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllRoles()
  }, [])
  return (
    <>
      <div className=" main-container">
        <div className="">
          <div>
            <span className="page_heading">Edit User Management</span>
          </div>
          {
            skeletonLoader ?
              <FormSkeleton data={skeletonData} mt={"mt-4"} />
              :
              <>
                <div className="twoColumnFormGridContainer mt-3">
                  <div>
                    <label htmlFor="" className="labelCss">
                      First Name: <span className="text-danger">*</span>
                    </label>

                    <TextInput
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      className={Validation.firstName ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, firstName: false })
                      }
                    ></TextInput>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      Last Name: <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <TextInput
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        className={Validation.lastName ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...Validation, lastName: false })
                        }
                      ></TextInput>
                    </div>
                  </div>

                  {/* <div>
    <label htmlFor="" className=" labelCss">
        Job Title: <span className="text-danger">*</span>
    </label>

    <TextInput placeholder="Job Title"
        value={jobTitle}
        onChange={(e) => { setJobTitle(e.target.value) }}
        className={Validation.jobTitle ? "Validation" : ""}
        onClick={() =>
            setValidation({ ...Validation, jobTitle: false })
        }
    >

    </TextInput>
</div> */}

                  <div>
                    <label htmlFor="" className="labelCss">
                      User Role: <span className="text-danger">*</span>
                    </label>

                    <SingleSelect
                      placeholder="Select user role"
                      options={allUserRoles}
                      name=""
                      optionLabel="role"
                      value={allUserRoles.find(e => e._id == selectedUserRole)}
                      onChange={(e) => {
                        setSelectedUserRole(e.target.value._id);
                      }}
                      onClick={() =>
                        setValidation({ ...Validation, selectedUserRole: false })
                      }
                      className={
                        Validation.selectedUserRole ? "Validation p-0" : "p-0"
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="" className="labelCss">
                      Email Address: <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <TextInput
                      disabled
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className={Validation.email ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...Validation, email: false })
                        }
                      ></TextInput>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="" className=" labelCss">
                      Cell Phone: <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        placeholder="Cell Phone"
                        value={cellPhone}
                        // max={10}
                        useGrouping={false}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          let numRegex = /^[0-9]+$/;
                          if (newValue === "" || numRegex.test(newValue)) {
                            setCellPhone(e.target.value);
                          }
                        }}
                        style={{ width: "100%" }}
                        className={`form-control ${Validation.cellPhone ? "Validation" : ""
                          }`}
                        onClick={() =>
                          setValidation({ ...Validation, cellPhone: false })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className=" labelCss">
                      Business Phone: <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        inputId="integeronly"
                        placeholder="Business Phone"
                        value={businessPhone}
                        // max={10}
                        useGrouping={false}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          let numRegex = /^[0-9]+$/;
                          if (newValue === "" || numRegex.test(newValue)) {
                            setBusinessPhone(e.target.value);
                          }
                        }}
                        className={`form-control ${Validation.businessPhone ? "Validation" : ""
                          }`}
                        onClick={() =>
                          setValidation({ ...Validation, businessPhone: false })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      Interviewed On: <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="date"
                        placeholder="Interviewed On"
                        value={moment(interviewedOn).format('YYYY-MM-DD')}
                        // max={10}
                        style={{ height: "50px" }}
                        useGrouping={false}
                        onChange={(e) => {
                         
                          setInterviewedOn(e.target.value)
                        }}
                        className={`${Validation.interviewedOn ? "Validation" : ""} form-control`}
                        onClick={() =>
                          setValidation({ ...Validation, interviewedOn: false })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      Joining Date: <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="date"
                        placeholder="Joining Date"
                        value={moment(joiningDate).format('YYYY-MM-DD')}
                        // max={10}
                        style={{ height: "50px" }}
                        useGrouping={false}
                        onChange={(e) => {
                          setJoiningDate(e.target.value)

                        }}
                        className={`${Validation.joiningDate ? "Validation" : ""} form-control`}
                        onClick={() =>
                          setValidation({ ...Validation, joiningDate: false })
                        }
                      />
                    </div>
                  </div>









                  <div>
                    <label htmlFor="" className="labelCss">
                      Address: <span className="text-danger">*</span>
                    </label>

                    <TextInput
                      placeholder="Address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      className={Validation.address ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, address: false })
                      }
                    ></TextInput>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      Locality:
                    </label>
                    <div className="">
                      <TextInput placeholder={"Locality"} value={locality} onChange={(e) => setLocality(e.target.value)} />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      Country: <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <SingleSelect
                        placeholder="Country"
                        options={countries.map((country) => ({
                          label: country.name,
                          value: country,
                        }))}
                        value={selectedCountry}
                        onChange={(e) => {
                          const selectedCountry = e.value;
                         
                          setSelectedCountry(e.target.value);
                          getStates(selectedCountry.isoCode);
                        }}
                        className={
                          Validation.selectedCountry ? "Validation p-0" : "p-0"
                        }
                        onClick={() =>
                          setValidation({ ...Validation, selectedCountry: false })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      State: <span className="text-danger">*</span>
                    </label>

                    <SingleSelect
                      placeholder="State"
                      options={Array.from(states)?.map((state) => ({
                        label: state.name,
                        value: state,
                      }))}
                      name="state"
                      value={selectedState}
                      onChange={(e) => {
                        setSelectedState(e.target.value);
                        getCities(e.target.value);
                      }}
                      className={
                        Validation.selectedState ? "Validation p-0" : "p-0"
                      }
                      onClick={() =>
                        setValidation({ ...Validation, selectedState: false })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="" className=" labelCss">
                      City:
                    </label>
                    <div className="">
                      <SingleSelect
                        placeholder="City"
                        options={Array.from(cities)?.map((city) => ({
                          label: city.name,
                          value: city,
                        }))}
                        name="city"
                        value={selectedCity}
                        onChange={(e) => {
                          setSelectedCity(e.target.value);
                        }}
                        className={
                          Validation.selectedCity ? "Validation p-0" : "p-0"
                        }
                        onClick={() =>
                          setValidation({ ...Validation, selectedCity: false })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="" className="labelCss">
                      County:
                    </label>
                    <div className="">
                      <TextInput value={county} onChange={(e) => setCounty(e.target.value)} />
                    </div>
                  </div>


                  <div>
                    <label htmlFor="" className=" labelCss">
                      ZIP: <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="text"
                        inputId="integeronly"
                        placeholder="Zip"
                        value={zip}
                        // max={5}
                        style={{ height: "50px" }}
                        useGrouping={false}
                        onChange={(e) => {
                          const newValue = e.target.value;

                          setZip(e.target.value);

                        }}
                        className={`form-control ${Validation.zip ? "Validation" : ""
                          }`}
                        onClick={() => setValidation({ ...Validation, zip: false })}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="" className="py-2 labelCss">
                      Time Zone : <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      // className={validation.time_zone ? "Validation" : ""}
                      options={timeZoneOptions}
                      value={timeZone}
                      optionLabel="label"
                      onChange={(e) => {

                        setTimeZone(e)
                      }}
                      placeholder="Select Time Zone"

                    />
                  </div>

                  <div>
                    <label htmlFor="" className=" labelCss">
                      Business Fax: <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        inputId="integeronly"
                        placeholder="Fax"
                        value={fax}
                        // max={5}
                        style={{ height: "50px" }}
                        useGrouping={false}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          let numRegex = /^[0-9]+$/;
                          if (newValue === "" || numRegex.test(newValue)) {
                            setFax(e.target.value);
                          }
                        }}
                        className={`form-control ${Validation.fax ? "Validation" : ""
                          }`}
                        onClick={() => setValidation({ ...Validation, fax: false })}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="" className=" labelCss">
                      Initials: <span className="text-danger">*</span>
                    </label>

                    <TextInput
                      placeholder="Initials"
                      value={initials}
                      onChange={(e) => {
                        setInitials(e.target.value);
                      }}
                      className={Validation.initials ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, initials: false })
                      }
                    ></TextInput>
                  </div>
                  <div>
                    <label htmlFor="" className=" labelCss">
                      Tags/Keywords: <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <TextInput
                        placeholder="Tags/Keywords"
                        value={tag}
                        onChange={(e) => {
                          setTag(e.target.value);
                        }}
                        className={Validation.tag ? "Validation" : ""}
                        onClick={() => setValidation({ ...Validation, tag: false })}
                      ></TextInput>
                    </div>
                  </div>
                </div>

                {/* <div className="row   ">
<div className="col-md-6 d-flex align-items-center justify-content-between">
    <label htmlFor="" className=" labelCss">
        Active:
    </label>
    <Checkbox
        onChange={(e) => setChecked(e.checked)}
        checked={checked}
    ></Checkbox>
</div>
</div> */}

                {/* <div className="row mt-3  ">
<div className="col-md-6 d-flex align-items-center justify-content-between">
    <label htmlFor="" className=" labelCss">
        Send This User Message:
    </label>
    <SingleSelect options={data.map((item) =>
        item.name
    )}
        value={userMsg}
        onChange={(e) => { setUserMsg(e.target.value) }}
        placeholder="send by"
        className={Validation.userMsg ? "Validation p-0" : "p-0"}
        onClick={() =>
            setValidation({ ...Validation, userMsg: false })
        }
    />
</div>
</div> */}
                {/* <div className="row mt-3  ">
<div className="col-md-6 d-flex align-items-center justify-content-between">
    <label htmlFor="" className=" labelCss">
        Is Employee of Procore Certification:
    </label>
    <Checkbox
        onChange={(e) => setChecked(e.checked)}
        checked={checked}
    ></Checkbox>
</div>
</div>
<div className="row mt-3 ">
<div className="col-md-6 d-flex align-items-center justify-content-between">
    <label htmlFor="" className=" labelCss">
        Is an Insurance Manager of Procore Certification?:
    </label>
    <Checkbox
        onChange={(e) => setChecked(e.checked)}
        checked={checked}
    ></Checkbox>
</div>
</div> */}
                <div className="row mt-3   css_com">
                  <div className="col-md-12">
                    <DragAndDrop
                      setRemovePhoto={setRemovePhoto}
                      value={fileName}
                      setFiles={setFileUpload}
                      onChange={handleFileSubmit}
                      setValue={setFileName}
                      setPreviousFiles={setPreviousFile}
                    />
                  </div>
                  <div className="col-md-12">
                    <DragAndDrop
                      setRemovePhoto={setRemovePhoto}
                      value={fileName2}
                      setFiles={setFileUpload2}
                      onChange={handleFileSubmit2}
                      setValue={setFileName2}
                      setPreviousFiles={setPreviousFile2}
                      label={"User Profile"}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <label htmlFor="" className="labelCss">
                    User Type:
                  </label>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled
                        inputId="userType1"
                        name="userType"
                        value="user"
                        onChange={(e) => {
                          setUserType(e.value);
                        }}
                        checked={userType === "user"}
                      />
                      &nbsp;
                      <label htmlFor="userType1" className="ml-2">
                        User
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        disabled
                        inputId="userType2"
                        name="userType"
                        value="individual"
                        onChange={(e) => {
                          setUserType(e.value);
                          setCompanyName("");
                          setCompanyRole("");
                        }}
                        checked={userType === "individual"}
                      />
                      &nbsp;
                      <label htmlFor="userType2" className="ml-2">
                        Individual
                      </label>
                    </div>
                  </div>
                </div>

                {userType === "user" ? (
                  <div className="twoColumnFormGridContainer mt-2">
                    <div>
                      <label htmlFor="" className=" labelCss">
                        Company Name: <span className="text-danger">*</span>
                      </label>
                      <div className="">
                        {response.length > 0 && (
                          <SingleSelect
                            disabled
                            placeholder="Company"
                            options={response.map((item) => ({
                              label: item.company_name,
                              value: item._id,
                            }))}
                            value={companyName}
                            onChange={(e) => {
                              
                              setCompanyName(e.target.value);
                            }}
                            className={Validation.companyName ? "Validation" : ""}
                            onClick={() =>
                              setValidation({ ...Validation, companyName: false })
                            }
                          />
                        )}
                       
                      </div>
                    </div>
                    <div>
                      <label htmlFor="" className=" labelCss">
                        Company Role: <span className="text-danger">*</span>
                      </label>

                      <SingleSelect
                        disabled
                        placeholder="State"
                        options={roleData.map((item) => item.name)}
                        name=""
                        value={companyRole}
                        onChange={(e) => {
                          setCompanyRole(e.target.value);
                        }}
                        className={
                          Validation.companyRole ? "Validation p-0" : "p-0"
                        }
                        onClick={() =>
                          setValidation({ ...Validation, companyRole: false })
                        }
                      />
                    </div>
                  </div>
                ) :
                  <div className="col-6 mt-3">
                    <label htmlFor="" className="labelCss">
                      Contract End Date: <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Tags/Keywords"
                      value={moment(contractEndDate).format('YYYY-MM-DD')}
                      style={{ height: "50px" }}
                      onChange={(e) => {
                        setContractEndDate(e.target.value);
                      }}
                      className={`${Validation.contractEndDate ? "Validation" : ""} form-control`}
                      onClick={() => setValidation({ ...Validation, contractEndDate: false })}
                    ></input>
                  </div>

                }


              </>}

          <div className="btn_main   ">
            <span
              className="cancelBtn p-2"
              onClick={() => {
                navigate(backUrl || "/userManagement");
              }}
            >
              Cancel
            </span>
            <div>
              {/* <PrimaryButton text={"Edit"} onClick={handleUpdateUser} /> */}
              <span className="CreateRfi px-2 py-2" onClick={handleUpdateUser}>Edit</span>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUserInfo;
