import { React, useEffect, useState } from "react";
import "./Email.css";
import { useNavigate } from "react-router-dom";
import SingleSelect from "../../Components/Common/SingleSelect";
import TextInput from "../../Components/Inputs/TextInput";
import { Editor } from "primereact/editor";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { TagsInput } from "react-tag-input-component"
import { Controller, useForm } from "react-hook-form";
import { getProjectId, getUserId } from "../../utils";
import { MultiSelect } from "primereact/multiselect";
import { addEmail, getFromEmailByUser, getGroupById, getTag, uploadMultipleDocs } from "../../Api/Admin/AllAPIs";
import CustomDragAndDrop from "../../Components/Inputs/CustomDragAndDrop";
import ReactQuill from "react-quill";
export default function Compose() {

  let [allTags, setAllTags] = useState([]);

  let [files, setFiles] = useState([])
  let [fromEmails, setFromEmails] = useState([]);

  let [btnLoader, setBtnLoader] = useState(false)
  const { control, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues:
    {
      from: "",
      from_email: "",
      to: "",
      cc: "",
      subject: "",
      message: "",
      project_id: getProjectId(),
      send_by: getUserId(),
      tag: [],
      attachments: []
    }
  })


  async function onSubmit(data) {
    try {
  
      setBtnLoader(true)
      let newFiles = files.map(e => e.file)
      if (newFiles.length > 0) {
        let re = await uploadMultipleDocs(newFiles)
    
        data.attachments = re?.data?.data
      }


      let res = await addEmail(data)
      navigate("/projectManagement/email")

    } catch (error) {
      console.log(error)
    }
    finally {
      setBtnLoader(false)
    }
  }
  let navigate = useNavigate();

  useEffect(() => {
    getAllTags()
  }, [])
  async function getAllTags() {
    try {
      let res = await getTag(getProjectId())
      setAllTags(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllFromEmail()
  }, [])

  async function getAllFromEmail() {
    try {
      let res = await getFromEmailByUser(getUserId())
   
      setFromEmails(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  let from = watch("from")

  useEffect(() => {
    if (from) {
      getFromDataById()
    }
  }, [from])

  async function getFromDataById() {
    try {
      setValue("to", "")
      let res = await getGroupById(from)
    
      let data = res.data.data[0]
      setValue("to", data.usersData.map(e => e.email_address).join(","))

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="composeEmailPage">
      <div className="parent">
        <div className="body d-flex flex-column gap-4">
          <div className="navLink d-flex gap-1">
            <h6
              className="dimNav"
              onClick={() => {
                navigate("/projectManagement/email");
              }}
            >
              Email /{" "}
            </h6>
            <h6>New Email</h6>
          </div>
          <h1>New Email</h1>
          <hr className="m-0" />

          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="formArea w-100 row">

              <div className="col-12 mt-3">
                <label>From</label>
                <Controller
                  name="from"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SingleSelect
                        placeholder="From"
                        options={fromEmails.map(e => ({ label: e.email, value: e._id }))}
                        className={errors.from ? "Validation" : ""}
                        value={value}
                        onChange={(e) => {
                          onChange(e.value)
                          setValue("from_email", fromEmails.find(d => d._id == e.value)?.email)
                        }}

                      />
                    );
                  }}
                />
              </div>
              <div className="col-12 mt-3">
                <label>To</label>
                <Controller
                  name="to"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className={errors.to ? "Validation" : ""}>
                        <TagsInput
                          value={value.split(",").filter(e => e)}
                          onChange={(e) => {
                            onChange(e.join(","))
                          }}
                          placeHolder="TO"
                        />
                      </div>
                    );
                  }}
                />
              </div>

              <div className="col-12 mt-3">
                <label>CC</label>
                <Controller
                  name="cc"

                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className={errors.cc ? "Validation" : ""}>
                        <TagsInput
                          className={errors.cc ? "Validation" : ""}
                          value={value.split(",").filter(e => e)}
                          onChange={(e) => {
                          
                            onChange(e.join(","))
                          }}
                          placeHolder="CC"
                        />
                      </div>
                    );
                  }}
                />
              </div>

              <div className="col-12 mt-3">
                <label>Tags</label>
                <Controller
                  name="tag"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MultiSelect
                        value={value}
                        className={`${errors.tag ? "Validation" : ""} w-100`}

                        onChange={onChange}
                        options={allTags.map(e => ({ label: e.tag_name, value: e._id }))}
                        filter placeholder="Tag"
                      />
                    );
                  }}
                />
              </div>

              <div className="col-12 mt-3">
                <label>Subject</label>
                <Controller
                  name="subject"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextInput
                        value={value}
                        className={errors.subject ? "Validation" : ""}
                        onChange={onChange}

                      />
                    );
                  }}
                />
              </div>
              <div className="col-12 mt-3">
                <label>Message</label>
                <Controller
                  name="message"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      // <Editor
                      //   className={errors.message ? "Validation" : ""}
                      //   style={{ height: "120px" }}
                      //   value={value}
                      //   onTextChange={(e) => onChange(e.htmlValue)}
                      // />
                      <ReactQuill theme="snow"
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>



              <div className="col-12 mt-3">
                <CustomDragAndDrop
                  value={files}
                  onChange={(e) => {
                    setFiles(e)
                  }}
                  label={"Documents"}
                />
              </div>





              <div className="mt-5 w-100 justify-content-end d-flex gap-3 align-items-center mb-2">
                <button className="btn-Secondary" type="button" onClick={() => navigate("/projectManagement/email")}>
                  Cancel
                </button>
                <div className="d-flex align-items-center ">
                  <PrimaryButton loader={btnLoader} type="submit" text={"Send"} />
                </div>
              </div>

            </div>
          </form>


        </div>
      </div>
    </div>
  );
}
