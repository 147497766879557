import { useCallback, useEffect, useState } from "react"
import { getAllDocumentTypes } from "../Api/Admin/AllAPIs"


export const useFetchAllDocumentTypes = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            let res = await getAllDocumentTypes()

            let data = res?.data?.data || []
            setData(data.map((e) => ({ value: e._id, label: e.name })))
        } catch (error) {
            setError(error)
        }
        finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => {
        getData()
    }, [])


    return ({ data, error, loading, getData })

}