import React, { useRef, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import style from "./button.module.css";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

export const DeleteButton = ({ onConfirm, onCancel, header }) => {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);

  const accept = () => {
    onConfirm();
    // toast.current.show({
    //   severity: "info",
    //   summary: "Confirmed",
    //   detail: "You have accepted",
    //   life: 3000,
    // });
    setVisible(false);
  };

  const reject = () => {
    onCancel();
    // toast.current.show({
    //   severity: "warn",
    //   summary: "Rejected",
    //   detail: "You have rejected",
    //   life: 3000,
    // });
    setVisible(false);
  };

  const confirmDelete = () => {
    setVisible(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className={style.deleteButton} onClick={confirmDelete}>
        <RiDeleteBin5Line />
      </div>
      <ConfirmDialog
        style={{
          width: "30%",
          minWidth: "25rem",
          padding: "0.8rem",
          background: "#fff",
        }}
        draggable={false}
        visible={visible}
        onHide={() => setVisible(false)}
        header={<div style={{ fontSize: "22px" }}> Delete {header}</div>}
        message={
          <div className="mt-2" style={{ fontSize: "18px" }}>
            Are you sure you want to delete this particular {header}?
          </div>
        }
        acceptClassName="p-button-danger"
        acceptLabel="Yes"
        rejectLabel="No"
        footer={
          <div className="d-flex gap-3 justify-content-end mt-4">
            <button className="filter_btn  " onClick={()=>setVisible(false)}>
              {" "}
              Cancel
            </button>
            <button onClick={accept} className="filter_btn_apply ">
              {" "}
              Delete
            </button>
          </div>
        }
      />
    </>
  );
};

export default DeleteButton;
