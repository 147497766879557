import React, { useState } from 'react'
import style from "./button.module.css"
import { IoMdAdd } from "react-icons/io";
import { MdOutlineAdd } from "react-icons/md";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';

export const PrimaryButton = ({ className, text, loader, data, addIcon, confirmation, dialogHeader, btn1Text, btn2Text, dialogBody, onClick, ...props }) => {
    let [visible, setVisible] = useState(false)
    return (
        <>
            <button
                disabled={loader}
                onClick={() => confirmation ? setVisible(true) : onClick && onClick(data)}
                {...props} className={`${className} ${style.primary_button_con} d-flex align-items-center gap-1 text-nowrap`} >
                {addIcon ? <span className='mb-1'>
                    <MdOutlineAdd className='fs-4' />
                </span> : null}

                {loader ?

                    <div className='px-4 py-1 d-flex align-items-center'>
                        <ProgressSpinner style={{ width: '23px', height: '23px' }} strokeWidth="3" animationDuration=".5s" />
                    </div>
                    : text}

            </button>
            <ConfirmDialog
                style={{
                    width: "30%",
                    minWidth: "25rem",
                    background: "#fff",
                }}
                draggable={false}
                visible={visible}
                onHide={() => setVisible(false)}
                header={<div style={{ fontSize: "22px" }}> {dialogHeader}</div>}
                message={
                    <div className="mt-2" style={{ fontSize: "18px" }}>
                        {dialogBody}
                    </div>
                }
                acceptClassName="p-button-danger"
                acceptLabel="Yes"
                rejectLabel="No"
                footer={
                    <div className="d-flex gap-3 justify-content-end mt-4">
                        <button className="filter_btn  " onClick={() => setVisible(false)}>

                            {btn2Text || "Cancel"}
                        </button>
                        <button onClick={() => {
                            onClick && onClick(data)
                            setVisible(false)
                        }} className="filter_btn_apply ">

                            {btn1Text || "Delete"}
                        </button>
                    </div>
                }
            />
        </>
    )
}
