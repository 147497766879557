import { createSlice } from '@reduxjs/toolkit'



export const counterSlice = createSlice({
  name: 'counter',
  initialState:0,
  reducers: {
   inc:(state)=>{
    return state+1
   }
    
  },
})

export const sliderToggle = createSlice({
  name:'ToggleBtn',
  initialState:true,
  reducers: {
    toggleByValue:(state,action)=>{
      return action.payload
    },
    toggleByClick:(state)=>{
      return !state
    }

  }
})



export const {inc} = counterSlice.actions
export const {toggleByClick,toggleByValue} = sliderToggle.actions




