import React, { useRef, useState, useEffect } from "react";
import { AiOutlineExport } from "react-icons/ai";
import "primeicons/primeicons.css";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import style from "./schedule.module.css";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import ScheduleCalender from "./ScheduleCalender";
import ScheduleGainttChart from "./ScheduleGainttChart";
import ScheduleLookHead from "./ScheduleLookHead";
import { CSVLink } from "react-csv";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { Tag } from "primereact/tag";
import ScheduleTaskModal from "./ScheduleTaskModal";
import { getScheduleDataWithPagination } from "../../Api/Admin/AllAPIs";
import { durationBetwenTwoDays, getProjectId, getToolsCommonData, loader } from "../../utils";
import moment from "moment";
import Pagination from "../../Components/Common/Pagination/Pagination";


export default function Schedule() {
  const [products, setProducts] = useState([
    {
      id: 1,
      web: 1,
      tname: "Release given for priority submitals",
      tools: "Task",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "30/3/2024",
      duration: "10 days",
    },
    {
      id: 2,
      web: 1,
      tname: "Release given for priority submitals",
      tools: "Task",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "30/3/2024",
      duration: "10 days",
    },
    {
      id: 3,
      web: 1,
      tname: "Release given for priority submitals",
      tools: "Task",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "30/3/2024",
      duration: "10 days",
    },
    {
      id: 4,
      web: 1,
      tname: "Release given for priority submitals",
      tools: "Task",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "30/3/2024",
      duration: "10 days",
    },
    {
      id: 5,
      web: 1.3,
      tname: "Release given for priority submitals",
      tools: "RFI",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "1/4/2024",
      duration: "10 days",
    },
    {
      id: 6,
      web: 2.5,
      tname: "Release for priority submitals",
      tools: "Submittals",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "5/4/2024",
      duration: "10 days",
    },
    {
      id: 7,
      web: 3.1,
      tname: "Release given for priority submitals",
      tools: "Punch Lists",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "10/4/2024",
      duration: "10 days",
    },
    {
      id: 8,
      web: 1,
      tname: "Release given for priority submitals",
      tools: "Observations",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "15/4/2024",
      duration: "10 days",
    },
    {
      id: 9,
      web: 3.1,
      tname: "Release given for priority submitals",
      tools: "Specifications",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "10/4/2024",
      duration: "10 days",
    },
    {
      id: 10,
      web: 1,
      tname: "Release given for priority submitals",
      tools: "Log",
      sDate: "14/3/2024",
      fDate: "22/3/2024",
      dueDate: "15/4/2024",
      duration: "10 days",
    },
  ]);
  const [allData, setAllData] = useState([])

  const [OpenModal, setOpenModal] = useState(false);

  const [page, setPage] = useState(1)
  const [totalCount, settotalCount] = useState(0)
  const perPageData = 10
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [selectedTool, setSelectedTool] = useState({ code: "All", name: "Tools" });  // Default to All

  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const toolsOptions = [
    { name: "All", code: "All" },
    { name: "Task", code: "tasks" },
    { name: "Inspection", code: "inspectiondatas" },
    { name: "Incident", code: "incidents" },
    { name: "Issue", code: "issues" },
    { name: "Daily log", code: "dailylogdatas" },
    { name: "Specification", code: "specifications" },
    { name: "Observation", code: "observations" },
    { name: "Punch", code: "punchdatas" },
    { name: "Submittal", code: "submittals" },
    { name: "Rfi", code: "rfis" },
  ];


  const csvData = products.map((item) => ({
    WBS: item.web,
    "task name": item.tname,
    Resources: item.tools,
    "Start Date": item.sDate,
    "End Date": item.fDate,
  }));

  const csvHeaders = [
    { label: "WBS", key: "WBS" },
    { label: "Task Name", key: "Task Name" },
    { label: "Tools", key: "Tools" },
    { label: "Start Date", key: "Start Date" },
    { label: "End Date", key: "End Date" },
  ];

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      didParseCell: function (data) {
        data.cell.styles.fontSize = 2;
      },
    });
    doc.save("schedule.pdf");
  };

  const toolsTemplate = (rowData) => {
    const colors = {
      task: "#FFA726",
      rfi: "#42A5F5",
      submittal: "#66BB6A",
      dailyLog: "#26C6DA",
      observation: "#AB47BC",
      punch: '#FF7043',
      issue: "#EC407A",
      incident: "#005FAE",
      inspection: "#8F9BE3",
      observation: "#AB47BC"
    };
    const color = colors[rowData.toolName] || "#343A40";
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "30px",
            height: "20px",
            borderRadius: "8px",
            backgroundColor: color,
            marginRight: "8px",
          }}
        ></div>
        <span>{rowData.toolName}</span>
      </div>
    );
  };

  useEffect(() => {
    getData();
  }, [page,selectedTool.code])

 

  async function getData() {
    try {
      let body = {
        page: page,
        perPageData,
        toolName: selectedTool.code === "All" ? null : selectedTool.code
      }
      loader.start()
      let res = await getScheduleDataWithPagination(getProjectId(), body)
     
      settotalCount(res?.data?.totalCount || 0)
      setAllData(res.data.data)
    } catch (error) {
      console.log(error)
    }
    finally {
      loader.stop()
    }
  }



  return (
    <>
      <ScheduleTaskModal open={OpenModal} />
      <div>
        <div>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h1 className="heading d-flex align-items-center ">SCHEDULE</h1>
            </div>
            {/* <div className="d-flex">
              <div className="d-flex align-items-center">
                <ExportPdfButton
                  exportToPDF={exportToPDF}
                  csvBtn={
                    <CSVLink
                      className="pe-1"
                      data={csvData}
                      headers={csvHeaders}
                      filename={"Schedule.csv"}
                    >
                      CSV
                    </CSVLink>
                  }
                />
              </div>

            </div> */}
          </div>

          <div style={{ height: "0", overflow: "hidden" }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>WBS</th>
                  <th>Task Name</th>
                  <th>Tools</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {products?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.web}</td>
                      <td>{item.tname}</td>
                      <td>{item.tools}</td>
                      <td>{item.sDate}</td>
                      <td>{item.fDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <TabView>
            <TabPanel header="List">
              <div className="row mt-3">
                <div className="col-md-9 d-flex justify-content-start align-items-center">
                  <div className="d-flex align-items-center me-3">
                    <Dropdown
                      value={selectedTool}
                      options={toolsOptions}
                      onChange={(e) => setSelectedTool(e.value)}
                      optionLabel="name"
                      placeholder="Tools"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                {skeletonLoader ? (
                  <TableSkelton
                    header={[
                      "ID",
                      "WBS",
                      "Task Name",
                      "Tools",
                      "Start Date",
                      "End Date",
                      "Progress",
                    ]}
                  />
                ) : (
                  <BorderedTable>
                    <DataTable

                      value={allData}
                    >
                      <Column header="ID" body={(rowData) => getToolsCommonData(rowData.toolName, rowData).id} />
                      <Column header="Name" body={(rowData) => getToolsCommonData(rowData.toolName, rowData)?.name || "---"} />
                      <Column header="Tool Name" body={toolsTemplate} />
                      <Column header="Start Date" body={(rowData) => moment(rowData.created_at).format("DD-MM-YYYY")} />
                      <Column header="Due Date" body={(rowData) => getToolsCommonData(rowData.toolName, rowData)?.dueDate ? moment(getToolsCommonData(rowData.toolName, rowData).dueDate).format("DD-MM-YYYY") : "---"} />
                      <Column header="Duration" body={(rowData) => {
                        let days = durationBetwenTwoDays(rowData.created_at, getToolsCommonData(rowData.toolName, rowData).dueDate)
                        return days ? `${days} Day${days !== 1 ? "s" : ""}` : "---"
                      }} />
                      {/* <Column body={toolsTemplate} header="Tools" /> */}
                    </DataTable>
                  </BorderedTable>
                )}
              </div>
              <br />
              <Pagination
                currentPage={page}
                totalCount={totalCount}
                pageSize={perPageData}
                onPageChange={setPage} />
            </TabPanel>

            <TabPanel header="Calender">
              <ScheduleCalender />
            </TabPanel>
            <TabPanel header="Gantt">
              <ScheduleGainttChart />
            </TabPanel>

          </TabView>
        </div>
      </div>
      <br />
    </>
  );
}
