import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkUserRoleIntoProject, getPermissionByRole } from "../../Api/Admin/AllAPIs";

let initialState = {
    data: [],
    loader: false,
    isError: false
};

export const getRolesAndPermissionData = createAsyncThunk('getRolesAndPermissionData', async (data) => {
    try {
        let res = await checkUserRoleIntoProject(data)
        if (res?.data?.data) {
            return res.data.data
        }
        else {
            return ([])
        }
    }
    catch (err) {
        return ([])

    }
},
)

export const getOuterRolesAndPermissionData = createAsyncThunk('getOuterRolesAndPermissionData', async (id) => {
    try {
        let res = await getPermissionByRole(id)
      
        if (res?.data?.data) {
            return res?.data?.data
        }
        else {
            return ([])
        }
    }
    catch (err) {
        return ([])

    }
},
)
export const RolesAndPermissionSlice = createSlice({
    name: "rolesAndPermissions",
    initialState,
    reducers: {
        cleanRolesAndPermissions: (state, action) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRolesAndPermissionData.fulfilled, (state, action) => {
            state.data = action?.payload?.[0]?.permission_tab || ([])
            state.loader = false;
            state.isError = false;
        })
        builder.addCase(getRolesAndPermissionData.rejected, (state, action) => {
            state.data = []
            state.loader = false;
            state.isError = true;
        })
        builder.addCase(getRolesAndPermissionData.pending, (state, action) => {
            state.data = []
            state.loader = true;
            state.isError = false;
        })

        builder.addCase(getOuterRolesAndPermissionData.fulfilled, (state, action) => {
            state.data = action?.payload?.[0]?.permission_tab || ([])
            state.loader = false;
            state.isError = false;
        })
        builder.addCase(getOuterRolesAndPermissionData.rejected, (state, action) => {
            state.data = []
            state.loader = false;
            state.isError = true;
        })
        builder.addCase(getOuterRolesAndPermissionData.pending, (state, action) => {
            state.data = state.data
            state.loader = true;
            state.isError = false;
        })
    }
})

export const { cleanRolesAndPermissions } = RolesAndPermissionSlice.actions