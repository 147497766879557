import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleByClick } from "../../Store/Slice/CommonSlice";
import { getProject } from "../../Api/Admin/AllAPIs";
import { BellIcon } from "../Icons/BellIcon";
import { useNavigate } from "react-router-dom";

export function TopBar({ children, layer, roleLayerKey }) {
  const navigate = useNavigate()
  const openToggle = useSelector((state) => state.ToggleButtonState);
  const dispatch = useDispatch();
  const [projectData, setProjectData] = useState();
  const projectId = localStorage.getItem("projectId");
  const getProjectData = async (id) => {
    try {
      const res = await getProject(id);
      
      setProjectData(res.data.data?.[0])

    }
    catch (err) {
      console.log(err)
    }
  };
  useEffect(() => {
    if (projectId) {
      getProjectData(projectId);
    }
  }, [projectId]);
  return (
    <div
      className={`app-bar ${openToggle ? "" : "topbar-p"
        } sticky-top align-items-center d-flex ps-3`}
    >
      <img
        src={
          openToggle ? "/images/toggle-left.png" : "/images/toggle-right.png"
        }
        className="pointer"
        alt="Toggle"
        onClick={() => dispatch(toggleByClick())}
        style={{ width: "20px", height: "15px" }}
      />
      <div
        style={{ display: layer == "project" ? "block" : "none" }}
        className="project_headline_for_topbar"
      >

        <div className="d-flex align-items-center">
          <div className="project_logo_img">
            {projectData?.project_logo?.[0]?.fileUrl && <img src={projectData?.project_logo?.[0]?.fileUrl} />}
          </div>
          <div className="">
            {projectData?.project_name}
          </div>
        </div>

      </div>
      <div className="w-100 d-flex  end  gap-4 align-items-center">

        {/* <img src="/images/cdclogo.svg" alt="" width={45} /> */}


        <div className='pointer' onClick={() => {
          if (roleLayerKey == 'adminProject') {
            navigate('/projectManagement/notifications')
          } else {

          }
        }}>
          <BellIcon />
        </div>


      </div>
      {/* <div className="w-100">
        <span className="  nav234 ">
          <ul className="d-flex mb-0 right_sec gap-2">
            <li>
              <img
                src="/images/question_icon.png"
                alt="Question Icon"
                className="cursor"
              />
            </li>
            <li>
              <img
                src="/images/bell_icon.png"
                alt="Bell Icon"
                className="cursor"
              />
            </li>
            <li>
              <img src="/images/Avatar.png" alt="Avatar" className="cursor" />
            </li>
          </ul>
        </span>
      </div> */}
    </div>
  );
}
