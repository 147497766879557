import axios from 'axios';
import fileDownload from 'js-file-download';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { toast } from '../../utils';

export default function ViewDrawings({ open, setOpenViewModal, data }) {

    const [rowClick, setRowClick] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const toggle = () => {
        setOpenViewModal(false)
        setRowClick(true);
        setSelectedProducts([]);
    }

    const transformedData = [];

    data?.forEach(doc => {
        let fileCount = 1;
        doc?.files?.forEach(file => {
            transformedData.push({
                title: doc?.title,
                fileName: `${doc?.title} 0${fileCount++}`,
                _id: file?._id,
                fileUrl: file?.fileUrl,
            });
        });
    });


    const handleSelected = (e) => {
        const selectedItems = e.value;
        setSelectedProducts(selectedItems);
       
    };




    const handleDownload = () => {

        if (selectedProducts?.length === 0) {
            toast.info("Please Choose one file!");
            return
        }

        const fileUrls = selectedProducts?.map(item => item?.fileUrl);
        Promise.all(
            fileUrls?.map(url =>
                axios.get(url, { responseType: 'blob' })
            )
        )
            .then(responses => {
                responses.forEach((response, index) => {

                    const fileUrl = fileUrls[index];
                    const fileName = fileUrl?.split('/')?.pop();
                    const fileExtension = fileName?.split('.')?.pop();
                    fileDownload(response?.data, `File_${index + 1}.${fileExtension}`);
                });
            })
            .catch(err => {
                console.error("Error downloading files", err);
            });
        toggle();
    };





    return (
        <>
            <Dialog header='View and Download Drawings'
                visible={open}
                style={{ width: '40vw' }}
                onHide={toggle}
                breakpoints={{ '1200px': '60vw', '960px': '75vw', '641px': '90vw' }}>
                <div className='mb-3'>
                    <DataTable className='border'
                        selectionMode={rowClick ? null : 'checkbox'}
                        selection={selectedProducts}
                        onSelectionChange={handleSelected}
                        dataKey="_id"
                        value={transformedData}>
                        <Column headerStyle={{ width: '70px' }} selectionMode="multiple"></Column>
                        <Column field='name' header='S.No.' body={(rowData, e) => e?.rowIndex + 1}></Column>
                        <Column field='name' header='Drawing Title' body={(rowData, e) => rowData?.title}></Column>
                        <Column field='name' header='File Name' body={(rowData) => (
                            <span style={{ textDecoration: "underline" }}>{rowData?.fileName}</span>
                        )}></Column>
                    </DataTable>
                </div>
                <div className="d-flex justify-content-end gap-3 mt-4">
                    <span
                        className="btn border  px-2 py-2"
                        onClick={toggle}
                    >
                        Cancel
                    </span>

                    <span className="btn btn-primary px-3 py-2" onClick={handleDownload} >
                        Download
                    </span>
                </div>

            </Dialog >
        </>
    )
}
