import React from "react";
import { ExportPdfButton } from "../../../../Components/Buttons/ExportPdfButton";
import { PrimaryButton } from "../../../../Components/Buttons/PrimaryButton";
import { SearchInput } from "../../../../Components/Inputs/SearchInput";
import { BorderedTable } from "../../../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Popover from "../../../../Components/Common/Popover";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useNavigate } from "react-router-dom";

function PrimeContract() {
  const navigate = useNavigate();

  const demodata = [
    {
      id: "1000",
      code: "f230fh0g3",
      Owner: "Owner Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      Owner: "Owner Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      Owner: "Owner Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      Owner: "Owner Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      Owner: "Owner Enterprise",
      Title: "GC Prime Contract",
      Status: "Out for Signature",
      Executed: "yes",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 0.00",
      Revised_Contract_Amount: "$ 0.00",
      Pending_Cost_Changes: "$ 0.00",
      Draft_Change_Orders: "$ 0.00",
      Invoiced: "$ 0.00",
      payment_Received: "$ 0.00",
    },
  ];

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Grand Total:"
          colSpan={2}
          footerStyle={{ textAlign: "center" }}
        />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 0.00"} />
        <Column
          footer={(rowData, meta) => (
            <div className="flex justify-content-center">
              <Popover
                options={["Edit", "delete"]}
                onSelect={(index, event) => {
                  if (index === 0){}
                    // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                
                }}
              />
            </div>
          )}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <div>
      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">PRIME CONTRACT</div>

        <div className="d-flex align_center justify-content-end gap-2 mt-sm-0 mt-3">
          <div className="right d-flex gap-2 align-items-center">
            <ExportPdfButton />
          </div>
          <PrimaryButton
            addIcon
            text={"Create Contract"}
            onClick={() => navigate("createcontract")}
          />
        </div>
      </div>
      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput

          // value={search}
          // onChange={searchData}
          />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <button className="filter_btn w-100">Filter Contracts</button>
          <button className="filter_btn w-100">Clear All</button>
        </div>
      </div>

      <div className="tools_table_scroll mt-2">
        <BorderedTable>
          <DataTable
            scrollable
            scrollHeight="flex"
            value={demodata}
            footerColumnGroup={footerGroup}
            tableStyle={{ minWidth: "100rem" }}
          >
            <Column
              field="#"
              header="#"
              body={(rowData, rowIndex) => (
                <div>
                  {rowIndex.rowIndex < 9 ? "0" : ""}
                  {rowIndex.rowIndex + 1}
                </div>
              )}
            ></Column>
            <Column
              field="Owner"
              header="Owner/Client"
              body={(rowData, rowIndex) => (
                <span
                  style={{
                    cursor: "pointer",
                    // color: "blue",
                    // textDecoration: "underline",
                  }}
                  onClick={() => navigate("ViewContract")}
                >
                  {rowData.Owner}
                </span>
              )}
            />

            <Column field="Title" header="Title"></Column>
            <Column field="Status" header="Status"></Column>
            <Column field="Executed" header=" Executed"></Column>
            <Column
              header="Original Contract Amount"
              field="Original_Contract_Amount"
              // body={(rowData) => moment(rowData.initiatedDate).format("MM-DD-YYYY")}
            ></Column>
            <Column
              field="Approved_Change_Orders"
              header="Approved Change Orders"
            ></Column>

            <Column
              header="Revised Contract Amount"
              field="Revised_Contract_Amount"
              // style={{ width: "5rem" }}
            ></Column>

            <Column
              field="Pending_Cost_Changes"
              header="Pending Cost Changes"
              // style={{ width: "4rem", color: "#121212" }}
            ></Column>
            <Column
              field="Draft_Change_Orders"
              header="Draft Change Orders"
              // style={{ width: "4rem", color: "#121212" }}
            ></Column>
            <Column
              field="Invoiced"
              header="Invoiced"
              // style={{ width: "4rem", color: "#121212" }}
            ></Column>
            <Column
              field="payment_Received"
              header="Payment Received"
              // style={{ width: "4rem", color: "#121212" }}
            ></Column>
            <Column
              field=""
              header=""
              // style={{ width: "4rem", color: "#121212" }}
              body={(rowData, meta) => (
                <div className="flex justify-content-center">
                  <Popover
                    options={["Edit", "delete"]}
                    onSelect={(index, event) => {
                      if (index === 0){}
                        // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                        
                    }}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </BorderedTable>
      </div>
    </div>
  );
}

export default PrimeContract;
