import { Skeleton } from 'primereact/skeleton'
import React from 'react'

const GridSkeleton = ({cardCount}) => {
  
  return (
    <>
      <div className='d-flex justify-content-between gap-4 flex-wrap'>
        {
            [...Array(cardCount || 4)].map((_,key)=>(
                <Skeleton key={key} width="23%" height="300px"></Skeleton>
            ))
        }
    
      {/* <Skeleton width="23%" height="300px"></Skeleton>
      <Skeleton width="23%" height="300px"></Skeleton>
      <Skeleton width="23%" height="300px"></Skeleton> */}
      </div>
    </>
  )
}

export default GridSkeleton
