import React, { useRef, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import style from "./button.module.css"



export const ExportPdfButton = ({ exportToPDF, csvLink, className, csvBtn, PassData }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [format, setFormat] = useState(null)
  const Data = [
    { name: 'PDF', code: 'pd' },
    { name: csvBtn, code: 'cs' }


  ];
  const csvButtonRef = useRef(null);
  return (
    <div className="">
      <div>  <Dropdown
        className={style.pdf_dropDown} value={format}
        options={PassData ? PassData : Data}
        optionLabel="name"
        placeholder="Export"
        itemTemplate={(option) => option.name}
        onChange={(e) => {
          // setFormat(e.value);
          if (e.value.code === "pd") {
            exportToPDF();
          } else if (e.value.code === "cs") {
            csvButtonRef.current.children[0].click()

          }
        }}
      />
        <a ref={csvButtonRef} href={csvLink} download="export.csv" style={{ display: "none" }}>
        {csvBtn}
      </a>
      </div>
    </div>
  )
}