import React, { useEffect, useRef, useState } from "react";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { useNavigate, useParams } from "react-router-dom";
import Filter from "./Filter";
import { deleteForms, filterForms, getFormByProjectId, getForms, updateForms } from "../../Api/Admin/AllAPIs";
import Loader from "../../Components/Common/Loader";
import { getUserId, searchDataWithKeys, toast } from "../../utils";
import { CSVLink } from "react-csv";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import Popover from "../../Components/Common/Popover";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { Statuscomp } from "../../Components/Common/StatusComp";
import ChangeStatusModal from "../../Components/ChangeStatusModal/ChangeStatusModal";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import { HtmlIntoString, HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import moment from "moment";
import Pagination from "../../Components/Common/Pagination/Pagination";

export const Forms = () => {
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visible, setVisible] = useState(false)
  const [product, setProduct] = useState([]);
  const [loader, setLoader] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [originalForms, setOriginalForms] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({})
  const [search, setSearch] = useState();
  const [filteredData, setFilteredData] = useState([])
  const [formId, setFormId] = useState(null)
  const [filtered, setFiltered] = useState(false)
  const { id } = useParams()
  const projectId = localStorage.getItem('projectId')



  const [open, setOpen] = useState(null);
  const [dropdownvalue, setDropDownValue] = useState(null);
  const [comment, setComment] = useState("");
  const [type, setType] = useState("");
  const [itemId, setItemId] = useState("");
  const [statusRequired, setStatusRequired] = useState(false);



  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPageData = 10;
  const [allData, setAllData] = useState([])
  const [formData, setFormData] = useState([])



  async function changeStatus(e) {
    e.preventDefault();

    if (dropdownvalue == null) {
      setStatusRequired(true);
      return;
    }

    setOpen(false);
    setLoader(true);

    try {
      if (type == "reviewer") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            form_review_status: "approved",
            reviewer_comment: comment,
            form_approval_status: "under_review",
            status: 'close'
          };

          await updateForms(itemId, payload);
        } else {
          let payload = {
            form_review_status: "unapproved",
            reviewer_comment: comment,
            status: "reopen",
            form_approval_status: "pending",
          };
          await updateForms(itemId, payload);
        }
      } else if (type == "approver") {
        if (dropdownvalue.code == "approved") {
          let payload = {
            form_approval_status: "approved",
            approver_comment: comment,
            form_review_status: "approved",
          };
          await updateForms(itemId, payload);
        } else {
          let payload = {
            form_review_status: "under_review",
            form_approval_status: "unapproved",
            approver_comment: comment,
          };
          await updateForms(itemId, payload);
        }
      } else {
     
      }
    } catch (err) {
      console.log(err);
    } finally {
      await handleGetForms();
      setItemId("");
      setType("");
      setLoader(false);
      setDropDownValue(null);
    }
  }





  function changeToCap(str) {
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`
  }






  const handleGetForms = async () => {
    try {
      let params = {
        page,
        perPageData
      }
      setSkeletonLoader(true);
      const response = await getFormByProjectId(projectId, params);
     
      setFormData(response?.data?.data || [])
      setAllData(response?.data?.data || [])
      setTotalCount(response?.data?.totalCount || 0)
      setOriginalForms(response?.data?.data || [])
      setLoader(false)
    }
    catch (err) {
      console.error(err)
    }
    finally {
      setSkeletonLoader(false);
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    return formattedDate
  }

  const handleDelete = async (id) => {
    setVisibleDelete(true)
    setFormId(id)

  }
  const confirmDelete = async () => {
    setLoader(true);
    try {
      await deleteForms(formId)
      toast.success("Form Deleted Successfully")
      handleGetForms()
    }
    catch (err) {
      console.error(err)
    }
  };

  const filterData = async (criteria) => {
   
    try {
      const res = await filterForms(criteria)
   

      const Data = res?.data?.data.map((item) => ({
        ...item,
        forms_date_created: formatDate(item.forms_date_created)

      })).reverse()
      setProduct(Data)
      setFilteredData(Data)
      setLoader(false)
      setFiltered(true);

    }
    catch (err) {
      console.error(err)
    }
  }


  useEffect(() => {
    if (Object.keys(filterCriteria).length > 0) {
      filterData(filterCriteria);
    }
    else {
      handleGetForms()
    }

  }, [filterCriteria])

  useEffect(() => {
    const searchWord = search && search.trim();
    if (!searchWord) {
      setProduct(originalForms);
    }
    else {
      const filteredData = originalForms.filter((item) =>


        item.forms_name?.toLowerCase()?.includes(searchWord?.toLowerCase()) ||
        item.forms_date_created?.includes(searchWord) ||
        item.forms_description?.toLowerCase()?.includes(searchWord?.toLowerCase())
        // item.forms_created_by.toLowerCase().includes(searchWord.toLowerCase()
      );
  
      setProduct(filteredData)
    }

  }, [search, originalForms])

  const handleClearAll = () => {
    handleGetForms()
    setFilteredData([])
    setFiltered(false);
  }


  useEffect(() => {
    if (projectId) {
      handleGetForms()
    }

  }, [page])

  let navigate = useNavigate();

  const csvData = originalForms.map(item => ({
    "Form Name": item.forms_name,
    "Forms Description": HtmlIntoString({ html: item.forms_description }),
    "Forms Created Date": item.forms_date_created,
    "Deleted": item.is_deleted,

  }));


  const csvHeaders = [
    { label: 'Form Name', key: 'Form Name' },
    { label: 'Forms Description', key: 'Forms Description' },
    { label: 'Forms Created Date', key: 'Forms Created Date' },
    { label: 'Deleted', key: 'Deleted' },

  ];

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      html: tableRef.current,
      // columnStyles: { 0: { columnWidth: 50 }, 1: { columnWidth: 50 }, 2: { columnWidth: 50 }, ... },
      didParseCell: function (data) {
        // if (data.row.index === 0) {
        //   // Scale down font size for header row
        data.cell.styles.fontSize = 2;
        // }
      },
    });
    doc.save('form.pdf');
  };

  const customFooter = (
    <div>
      <div className="d-flex gap-3" style={{ color: "#121212" }}>
        <div className="d-flex gap-2 align-items-center">
          <FaCheck className="body_green" />
          <label htmlFor="">Approved</label>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <ImCross className="body_red" />
          <label htmlFor="">Unapproved</label>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <FaRegClock className="body_blue" />
          <label htmlFor="">Under Review</label>
        </div>
        <div className="d-flex gap-2">
          <label htmlFor="">-- Pending</label>
        </div>
      </div>
    </div>
  );
  function isAbletoAccess(rowId) {
    return getUserId() == rowId
  }

  return (
    <>
      <ChangeStatusModal
        open={open}
        setOpen={setOpen}
        value={dropdownvalue}
        setValue={setDropDownValue}
        onSubmit={changeStatus}
        setComment={setComment}
        statusRequired={statusRequired}
        setStatusRequired={setStatusRequired}
      />

      <div>
        <div>
          <div className="between">
            <h1 className="page_heading">FORMS</h1>
            <div className="flex align_center gap-2">
              <div className="right d-flex gap-1 align-items-center">
                <ExportPdfButton exportToPDF={exportToPDF}
                  csvBtn={<CSVLink
                    className="pe-1"
                    data={csvData}
                    headers={csvHeaders}
                    filename={"Form.csv"}
                  >
                    CSV
                  </CSVLink>
                  }
                />
              </div>
              <PrimaryButton
                onClick={() => navigate("createForms")}
                addIcon
                text={"Create Forms"}
              />
            </div>
          </div>
          <div style={{ height: '0', overflow: 'hidden' }}>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Form Name</th>
                  <th>Forms Description</th>
                  <th>Forms Created Date</th>
                  <th>Deleted</th>

                </tr>
              </thead>
              <tbody>
                {originalForms?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.forms_name}</td>
                      <td><HtmlIntoText html={item.forms_description} /></td>
                      <td>{item.forms_date_created}</td>
                      <td>{item.is_deleted ? 'Yes' : 'No'}</td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <div className="searchInput">
              <SearchInput value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setFormData(searchDataWithKeys(["forms_name"], allData, e.target.value))
                }}
                onCancel={() => {
                  setSearch("")
                  setFormData(allData)
                }}
              />
            </div>
            {/* <div className=" col-md-9 d-flex justify-content-end">
              <button
                className="filter_btn me-3" onClick={(e) => setVisible(true)}>
                Filter Forms{" "}
              </button>
              <button className={`clear_btn  ${!filtered ? 'disabled' : ""}`} onClick={handleClearAll} >Clear All</button>
            </div> */}
          </div>



          <br />
          <div>
            <div>
              <div className="tools_table_scroll">
                {
                  skeletonLoader ?
                    <TableSkelton
                      header={[
                        "Form Name",
                        "Status",
                        "Description",
                        "Date Created",
                        "Created By",
                        "Approval Status",
                        "",
                      ]}
                    />
                    :
                    <BorderedTable>
                      <DataTable value={formData}>
                        <Column field="forms_name" header=" Form Name"
                          body={(rowData) => (
                            <div
                              className=""
                              // onClick={() =>
                              //   navigate(`view/${rowData?._id}`)
                              // }
                              style={{ cursor: "pointer" }}
                            >
                              {rowData?.forms_name}
                            </div>
                          )}
                        ></Column>
                        <Column
                          field="status"
                          header="Status"
                          style={{ width: "5rem" }}
                          body={(rowData) => (
                            <div className="d-flex justify-content-center">
                              {rowData?.status?.toLowerCase() === "open" ? (
                                <Statuscomp className={"open_status"} />
                              ) : rowData?.status?.toLowerCase() ===
                                "close" ? (
                                <Statuscomp className={"close_status"} />
                              ) : rowData?.status?.toLowerCase() ===
                                "reopen" ? (
                                <Statuscomp className={"reopen_status"} />
                              ) : rowData?.status?.toLowerCase() ===
                                "onhold" ? (
                                <Statuscomp className={"on_hold"} />
                              ) : null}
                            </div>
                          )}
                        ></Column>
                        <Column field="forms_description"
                          body={(rowData) => <HtmlIntoText html={rowData?.forms_description} />}
                          header="Description"></Column>

                        <Column field="forms_date_created" header="Date Created"></Column>

                        <Column field="createby" header="Created By">
                          body={(rowData) => (
                            <div>
                              {rowData.createby ? rowData.createby : "Static Data"}

                            </div>

                          )}
                        </Column>
                        <Column
                          field="approve"
                          header="Approval Status"
                          body={(rowData, meta) => {


                            if (rowData?.status === "close") {
                              if (rowData?.form_review_status === "under_review" || rowData?.form_review_status === "unapproved") {
                                return (
                                  <>
                                    <div
                                      className={`d-flex  align-items-center`}
                                    >
                                      {rowData.form_review_status === "approved" && (
                                        <FaCheck className={`body_green ${rowData?.form_approval_status !== 'approved' ? 'pointer' : ''}`}
                                        // onClick={rowData?.form_approval_status !== 'approved' ? () => {
                                        //   isAbletoAccess(rowData?.reviewer_id)&&setOpen(true);
                                        //   setType("reviewer");
                                        //   setItemId(rowData._id);
                                        //   setDropDownValue({ name: changeToCap(rowData.form_review_status), code: rowData.form_review_status })
                                        // } : () => { }}
                                        />
                                      )}
                                      {rowData.form_review_status === "unapproved" && (
                                        <ImCross className="body_red pointer" onClick={() => {
                                          isAbletoAccess(rowData?.reviewer_id) && setOpen(true);
                                          setType("reviewer");
                                          setItemId(rowData._id);
                                          setDropDownValue({ name: changeToCap(rowData.form_review_status), code: rowData.form_review_status })
                                        }} />
                                      )}
                                      {rowData.form_review_status === "pending" && "--"}
                                      {rowData.form_review_status === "under_review" && (
                                        <FaRegClock className="body_blue pointer" onClick={() => {
                                          isAbletoAccess(rowData?.reviewer_id) && setOpen(true);
                                          setType("reviewer");
                                          setItemId(rowData._id);
                                        }} />
                                      )}
                                    </div>
                                  </>
                                )
                              }
                              else if (rowData?.form_approval_status === "under_review") {
                                return (
                                  <>
                                    <div
                                      className={`d-flex align-items-center`}
                                    >
                                      {rowData.form_approval_status === "approved" && (
                                        <FaCheck className="body_green pointer"
                                        //  onClick={() => {
                                        //   isAbletoAccess(rowData?.approver_id) &&setOpen(true);
                                        //   setType("approver");
                                        //   setItemId(rowData._id);
                                        //   setDropDownValue({ name: changeToCap(rowData.form_approval_status), code: rowData.form_approval_status })
                                        // }} 
                                        />
                                      )}
                                      {rowData.form_approval_status === "unapproved" && (
                                        <ImCross className="body_red pointer"
                                          onClick={() => {
                                            isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                            setType("approver");
                                            setItemId(rowData._id);
                                            setDropDownValue({ name: changeToCap(rowData.form_approval_status), code: rowData.form_approval_status })
                                          }} />
                                      )}
                                      {rowData.form_approval_status === "pending" && "--"}
                                      {rowData.form_approval_status === "under_review" && (
                                        <FaRegClock className="body_blue pointer"
                                          onClick={() => {
                                            isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                            setType("approver");
                                            setItemId(rowData._id);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                )
                              }
                              else if (rowData?.form_approval_status === "approved" && rowData?.form_review_status === "approved") {
                                return (
                                  <>
                                    <FaCheck className="body_green pointer" />
                                  </>
                                )
                              }

                            }
                            else {
                              return "---"
                            }


                          }
                          }
                          style={{ width: "5rem" }}
                        ></Column>
                        {/* <Column
                          field="approve"
                          header="Approval"
                          body={(rowData, meta) => (
                            <div
                              className={`d-flex justify-content-center align-items-center`}
                            >
                              {rowData.form_approval_status === "approved" && (
                                <FaCheck className="body_green pointer"
                                //  onClick={() => {
                                //   isAbletoAccess(rowData?.approver_id) &&setOpen(true);
                                //   setType("approver");
                                //   setItemId(rowData._id);
                                //   setDropDownValue({ name: changeToCap(rowData.form_approval_status), code: rowData.form_approval_status })
                                // }} 
                                />
                              )}
                              {rowData.form_approval_status === "unapproved" && (
                                <ImCross className="body_red pointer"
                                  onClick={() => {
                                    isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                    setType("approver");
                                    setItemId(rowData._id);
                                    setDropDownValue({ name: changeToCap(rowData.form_approval_status), code: rowData.form_approval_status })
                                  }} />
                              )}
                              {rowData.form_approval_status === "pending" && "--"}
                              {rowData.form_approval_status === "under_review" && (
                                <FaRegClock className="body_blue pointer"
                                  onClick={() => {
                                    isAbletoAccess(rowData?.approver_id) && setOpen(true);
                                    setType("approver");
                                    setItemId(rowData._id);
                                  }}
                                />
                              )}
                            </div>
                          )}
                          style={{ width: "5rem" }}
                        ></Column> */}
                        <Column
                          body={(rowData) => (

                            <div className="d-flex  justify-content-center">
                              <Popover
                                options={["Version History", "Edit", "Delete", "View",

                                  <DownloadPdfAndSendEmail
                                    heading={"FORM"}
                                    id={rowData._id}
                                    files={rowData?.forms_file?.map(e => e.url)}
                                  >
                                    <ViewPagesPdfTemplate
                                      header={`#Forms ${rowData?.forms_name}`}
                                      tablerowData1={[
                                        { "Form Name": rowData?.forms_name },
                                        { "Created On": rowData?.forms_date_created && moment(rowData?.forms_date_created).format("DD/MM/YYYY") || "--" },
                                        { "status": rowData?.status }
                                        // { "Latest Version": "--" },
                                        // { "Created By": "--" },
                                      ]}
                                      description={{
                                        key: "Forms Description",
                                        value: <HtmlIntoText html={rowData?.forms_description} />
                                      }}

                                    />
                                  </DownloadPdfAndSendEmail>,
                                  "Send Via Post"

                                ]}
                                onSelect={(index) => {
                                  if (index === 0) {
                                    navigate(`/projectManagement/version-history/FORM/${rowData?.forms_name}/${rowData?._id}`);
                                  }
                                  else if (index === 1) {
                                    navigate(`editForm/${rowData?._id}`);

                                    // navigate(`${rowData?._id}`);
                                  }
                                  else if (index === 2) handleDelete(rowData?._id)
                                  else if (index === 3) navigate(`view/FORM/${rowData?.forms_name}/${rowData?._id}`)
                                  else if (index === 4) navigate(`/projectManagement/addressListing/FORM/${rowData?.forms_name}/${rowData?._id}`)
                                  else if (index === 5) {
                                    navigate(`/projectManagement/addressListing/FORMS/${rowData?.forms_name}/${rowData?._id}`)
                                  }
                                }}
                              />
                              {/* <EditButton onClick={() => navigate(`/editForm/${rowData?._id}`)} />
                              &nbsp;&nbsp;
                              <span onClick={() => handleDelete(rowData?._id)}>
                                <DeleteButton
                                  visible={!!formId}
                                  onCancel={() => setFormId(null)}
                                  onConfirm={confirmDelete}
                                  header={"Form"}
                                />
                              </span> */}
                            </div>
                          )}

                          style={{ width: '6rem' }}
                        ></Column>
                      </DataTable>
                    </BorderedTable>}
              </div>

              <br />

              <Pagination
                currentPage={page}
                totalCount={totalCount}
                pageSize={perPageData}
                onPageChange={setPage}
              />

            </div>
          </div>
        </div>
        <DeleteDialog
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onCancel={() => setFormId(null)}
          onConfirm={confirmDelete}
          header={"Submittal"}
        />
        <Filter visible={visible} setVisible={setVisible} res={product} applyFilter={filterData} />
      </div>

    </>

  );
};
