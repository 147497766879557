import React, { useEffect, useState } from 'react'
import "./SignIn.css"
import { useNavigate } from 'react-router-dom'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { CRYPTO_HASH_KEY, GOOGLE_SIGNIN_KEY } from '../../constants'
import { jwtDecode } from 'jwt-decode'
import { googleLogin, verifyEmail } from '../../Api/Admin/AllAPIs'
import { toast } from '../../utils'
import { MdErrorOutline } from "react-icons/md";
import Loader from '../../Components/Common/Loader'

import CryptoJS from "crypto-js";
export const VerifyAccount = () => {
    let token = new URLSearchParams(window.location.search).get("token");
    let userId = new URLSearchParams(window.location.search).get("userId");
    let email = new URLSearchParams(window.location.search).get("email");
    let name = new URLSearchParams(window.location.search).get("name");


    let [userVerificationStatus, setUserVerificationStatus] = useState({
        loading: true,
        isVerified: false
    })

    // useEffect(() => {
    //     if (!token || !userId || !email) {
    //         navigate("/")
    //     }
    // }, [])

    let navigate = useNavigate()
    const switchRole = (userType, data) => {

        localStorage.setItem("isLoggedIn", "true");
        // localStorage.setItem("userData", JSON.stringify(data))
      
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), CRYPTO_HASH_KEY).toString();
               
            localStorage.setItem("hashUserData", encryptedData)

        switch (userType) {
            case "admin":
                navigate("/mainOverview");
                break;
            case "user":
                navigate("/projects");
                localStorage.setItem("projectId", data?.project_id);
                break;
            case "individual":
                navigate("/projects");
                localStorage.setItem("projectId", data?.project_id);
                break;
            default:
                break;
        }
    };
    const responseGoogle = (response) => {
        var decoded = jwtDecode(response.credential);
       
        const token = {
            tokenId: response.credential,
            id: decoded.sub,
            email: decoded.email,
            user_id: userId
        }
      
        googleLogin(token).then((res) => {
            let user_type = res.data.data.user_type;
            let userData = res.data.data;
            if (userData && user_type) {
                switchRole(user_type, userData)
            }

            else {
                toast.error("User not found")
            }

        }).catch((err) => {
        
            toast.error(err?.response?.data?.data || "User not found")
            //   setLoader(false)
        })
    };
    function onFailure() {
        toast.error("Some Error Occured")
    }

    useEffect(() => {
        verifyUser()
    }, [])

    async function verifyUser() {
        try {
            setUserVerificationStatus({
                loading: true,
                isVerified: false
            })
            let res = await verifyEmail(userId, { token })
        
            setUserVerificationStatus({
                loading: false,
                isVerified: true
            })

        }
        catch (err) {
      
            toast.error("Some Error Occured")
            setUserVerificationStatus({
                loading: false,
                isVerified: false
            })
        }
    }

    return (
        <div className='verifyAccount_container'>

            <div className="verify_account_left_con">

                <div className='verify_account_logo'>
                    <img src="/images/cdc_logo.png" width='35' />
                </div>

                <div className='h-75 d-flex align-items-center justify-content-center'>

                    {userVerificationStatus.loading ? <Loader /> :
                        <>
                            {userVerificationStatus.isVerified ?
                                <div>
                                    <div className='logIn'>Your Account Is Verified</div>
                                    <div className="mt-3 pointer w-100" onClick={() => navigate("/")}>
                                        Sign in
                                    </div>
                                    <div className='mt-5 text-center'>
                                        <GoogleOAuthProvider clientId={GOOGLE_SIGNIN_KEY}>
                                            <div className="w-100">
                                                <GoogleLogin
                                                    buttonText="Login with Google"
                                                    onSuccess={responseGoogle}
                                                    onFailure={onFailure}
                                                    cookiePolicy={"single_host_origin"}
                                                    style={{
                                                        marginTop: "100px",
                                                        boxShadow: "none !important",
                                                    }}
                                                    isSignedIn={true}
                                                />
                                            </div>
                                        </GoogleOAuthProvider>
                                    </div>

                                </div> :
                                <div>
                                    <div className="logIn fs-1 text-center mb-3">

                                        <MdErrorOutline />
                                    </div>

                                    <div className='fs-4 logIn'>
                                        Some error occured in verification
                                    </div>

                                    <div className="test-center mt-3">Resend Email</div>
                                </div>
                            }

                        </>

                    }


                </div>
            </div>

            <div className="verify_account_right_con"></div>


        </div>
    )
}
