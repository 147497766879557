import React, { useEffect, useRef, useState } from 'react'
import FormSkeleton from '../../Components/Common/FormSkeleton';
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { ViewPageTable } from '../../Components/Common/ViewPageComponents/ViewPageTable';
import { ViewPageDocumentName } from '../../Components/Common/ViewPageComponents/ViewPageDocumentName';
import { useNavigate, useParams } from 'react-router-dom';
import { oneFile, sendEmailWithAttachment } from '../../Api/Admin/AllAPIs';
import moment from 'moment';
import ViewVersion from '../../Components/Common/ViewVersion';
import { downloadPdf } from '../../Components/Common/ViewPageComponents/downloadPdf';
import jsPDF from 'jspdf';
import { toPng } from 'html-to-image';
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';

export default function ViewParticularDrawing({ role, includeVersionHistory, usedAsComponent }) {
    const navigate = useNavigate()
    const { id } = useParams();
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [infoData, setInfoData] = useState([])

    const projectInfo = JSON.parse(localStorage.getItem("projectData"));
  

    const skeletonData = [
        { qty: 1, height: "2.5rem" },
        { qty: 2, height: "2.5rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 2, height: "1.4rem" },
        { qty: 1, height: "2.4rem" },
        { qty: 1, height: "5rem" },
    ];

    function pageFor() {
        switch (role) {
            case 'drawings':
                return 'drawing'
                break;
            case 'photos':
                return 'photo'
                break;
            case 'models':
                return 'model'
                break;
            case 'documents':
                return 'document'
                break;

            default:
                break;
        }
    }


    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await oneFile(id);
                let ans = res?.data?.data[0];
               
                setInfoData(ans)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()

    }, [id])


    let pdfRef = useRef();

    function downloadPdf2() {
        const content = pdfRef?.current;
        if (!content) {
            console.error("pdfRef.current is not valid");
            return;
        }


        let urlLinks = [];
        if (Array.isArray(infoData?.files)) {
            urlLinks.push(...infoData.files.map((e) => e.fileUrl).filter(Boolean));
        }

        if (Array.isArray(infoData?.response)) {
            urlLinks.push(
                ...infoData.response
                    .flatMap((res) => res?.files?.map((sa) => sa?.fileUrl))
                    .filter(Boolean)
            );
        }

        downloadPdf(pdfRef.current, [
            {
                heading: "Attached Documents", files: urlLinks
            }
        ], `${pageFor()}`)
        return


        const array1 = infoData?.files;
        const array2 = [];
        toPng(content, { quality: 1.0 })
            .then((dataUrl) => {
                const pdf = new jsPDF("p", "mm", "a4");
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
                let startY = pdfHeight + 10; // Starting position for the first heading and link
                // Function to add links with a heading
                const addLinksWithHeading = (heading, links) => {
                    pdf.setFontSize(14);
                    // pdf.setFont("helvetica", "bold");
                    pdf.setTextColor(71, 83, 103); // Set text color to red
                    pdf.text(heading, 10, startY);
                    startY += 10; // Space between heading and first link

                    pdf.setFontSize(12);
                    pdf.setTextColor(0, 0, 255); // Set text color to blue
                    pdf.setFont("helvetica", "normal");

                    links.forEach((link, index) => {
                        const text = link;
                        const textX = 10; // Align text to the left
                        const textWidth = pdf.getTextWidth(text);

                        // Check if text exceeds the width of the page
                        if (textWidth > pdfWidth - 20) {
                            const splittedText = pdf.splitTextToSize(text, pdfWidth - 20);
                            let textY = startY;
                            splittedText.forEach((line) => {
                                pdf.text(line, textX, textY);
                                textY += 7; // Line height
                            });
                            startY = textY;
                        } else {
                            pdf.text(text, textX, startY);
                            startY += 10; // Move to the next line for the next link
                        }

                        const linkHeight = 10; // Height of link area
                        pdf.link(textX, startY - linkHeight, textWidth, linkHeight, {
                            url: link,
                        }); // Adjust y position to align with text

                        startY += 5; // Add some space between links
                    });

                    startY += 5; // Add some space after all links
                };

                if (array1.length !== 0) {
                    addLinksWithHeading("Attached Documents", array1);
                }
                if (array2.length !== 0) {
                    addLinksWithHeading("Linked Drawings", array2);
                }

                pdf.save("download.pdf");
            })
            .catch((err) => {
                console.error("Error generating PDF", err);
            });
    }



    async function okok(dd) {
        const doc = new jsPDF();

        try {

            let res = await downloadPdf(
                pdfRef.current,
                [
                    {
                        heading: "Attached Documents",
                        files: infoData?.files?.map((e) => e?.fileUrl),
                    },
                ],
                `${pageFor()}`,
                true
            );

            const reader = new FileReader();
            reader.readAsDataURL(res);
            reader.onloadend = async function () {
                const base64data = reader.result.split(",")[1];
                const formattedAttachment = {
                    content: base64data,
                    filename: `${pageFor()}.pdf`,
                    type: "application/pdf",
                    disposition: "attachment",
                };

                const body = {
                    subject: "Email header",
                    to: dd.to,
                    text: "This is text of email",
                    cc: dd.cc,
                    bcc: dd.bcc,
                    attachments: formattedAttachment,
                    htmlData: dd.text,
                };

                await sendEmailWithAttachment(body);
            };
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <div className={`rfiView card`}>
                <div className="rfiparent">
                    {skeletonLoader ? (
                        <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
                    ) : (
                        <>
                            {true && (
                                <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
                                    <div className="w-100">
                                        <span
                                            className="open_Rfi_text pointer text-capitalize"
                                            // onClick={() => navigate(`/projectManagement/${pageFor()}s`)}
                                            onClick={() => navigate(-1)}
                                        >
                                            {pageFor()}s
                                        </span>
                                        <span className="px-1 open_Rfi_text ">/</span>
                                        <span className="createNew_text">{infoData?.title}</span>
                                    </div>
                                </div>
                            )}

                            <div className="rfibody d-flex flex-column gap-4">
                                {true && (
                                    // {!usedAsComponent && (
                                    <div className="d-flex justify-content-between">
                                        <h1 className="headdingRFI">{`#${projectInfo.projectNumber} ${projectInfo.project_name}`}</h1>
                                        <div className="d-flex align-items-center gap-2">
                                            <div
                                                className="d-flex gap-2 align-items-center"
                                                style={{ cursor: "pointer" }}
                                                onClick={downloadPdf2}
                                            >
                                                <AiOutlineExport />
                                                Export
                                                <MdOutlineKeyboardArrowDown />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='fs-5 fw-500 mt-2'>General Details</div>

                                <div className="d-flex flex-column gap-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <ViewPageTable
                                                tableData={[
                                                    { "Scope": infoData?.scope, },
                                                    { "Version": infoData?.version },
                                                    { "Reviewer": infoData?.reviewer?.first_name },
                                                    { "Created By": infoData?.createdByInfo?.first_name },
                                                    { "Updated By": infoData?.updatedByInfo?.first_name },
                                                ]}
                                            />

                                        </div>
                                        <div className="col-6">
                                            <ViewPageTable
                                                tableData={[
                                                    { "Workflow Status": infoData?.workflow_status },
                                                    { "Approver": infoData?.approver?.first_name },
                                                    {
                                                        "Created On": moment(infoData?.createdAt).format(
                                                            "DD/MM/YYYY h:mm:ss A"
                                                        ) || "--",
                                                    },
                                                    {
                                                        "Updated On": moment(infoData?.updatedAt).format(
                                                            "DD/MM/YYYY h:mm:ss A"
                                                        ) || "--",
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='fs-5 fw-500 mt-2'>Folder Details</div>

                                <div className="d-flex flex-column gap-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <ViewPageTable
                                                tableData={[
                                                    { "Phase": infoData?.phase_details?.name || "--", },
                                                    { "Category": infoData?.category_details?.name || "--" },
                                                ]}
                                            />

                                        </div>
                                        <div className="col-6">
                                            <ViewPageTable
                                                tableData={[
                                                    { "Sub-Category": infoData?.subcategory_details?.name || "--" },
                                                    { "Document Type": infoData?.doctype_details?.name || "--" }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="view_page_para_head mt-4 mb-2">Comment:</div>
                            <div className="view_page_bold_text">
                                <HtmlIntoText html={infoData?.comment} />
                            </div>
                            <div className="view_page_para_head mt-4 mb-2">Hashtags:</div>
                            <div className="view_page_bold_text">
                                {infoData?.hashtag_names?.map((item) => `#${item}`)?.join(", ")}
                            </div>
                            <div className="view_page_para_head mt-4 mb-2">
                                Attached Document:
                            </div>

                            <div className='flex flex-wrap gap-2'>
                                {infoData?.files?.map((res, key) => {
                                    return (
                                        <ViewPageDocumentName
                                            key={key}
                                            docUrl={res?.fileUrl}
                                            name={res?.fileName}
                                        />
                                    );
                                })}
                            </div>
                            {(!usedAsComponent || includeVersionHistory) && (
                                <>
                                    <hr />
                                    <ViewVersion isView={true} />
                                    <br />
                                    <br />
                                </>
                            )}

                        </>
                    )}
                </div>
            </div>

            <div style={{ height: "0px", overflow: "hidden" }}>
                <div ref={pdfRef}>
                    <div className="card p-5">

                        <h1 className="headdingRFI"> {infoData?.title}</h1>
                        <div className="d-flex flex-column gap-3 mt-3">
                            <div className="row">
                                <div className="col-6">
                                    <ViewPageTable
                                        tableData={[
                                            { "Scope": infoData?.scope, },
                                            { "Version": infoData?.version },
                                            { "Reviewer": infoData?.reviewer?.first_name },
                                            { "Created By": infoData?.createdByInfo?.first_name },
                                            { "Updated By": infoData?.updatedByInfo?.first_name },
                                        ]}
                                    />
                                </div>
                                <div className="col-6">
                                    <ViewPageTable
                                        tableData={[
                                            { "Workflow Status": infoData?.workflow_status },
                                            { "Approver": infoData?.approver?.first_name },
                                            {
                                                "Created On": moment(infoData?.createdAt).format(
                                                    "DD/MM/YYYY h:mm:ss A"
                                                ) || "--",
                                            },
                                            {
                                                "Updated On": moment(infoData?.updatedAt).format(
                                                    "DD/MM/YYYY h:mm:ss A"
                                                ) || "--",
                                            }
                                        ]}
                                    />
                                </div>
                                <div className='fs-5 fw-500 mt-2'>Folder Details</div>

                                <div className="d-flex flex-column gap-3">
                                    <div className="row">
                                        <div className="col-6">
                                            <ViewPageTable
                                                tableData={[
                                                    { "Phase": infoData?.phase_details?.name || "--", },
                                                    { "Category": infoData?.category_details?.name || "--" },
                                                    { "Sub-Category": infoData?.subcategory_details?.name || "--" },
                                                    { "Document Type": infoData?.doctype_details?.name || "--" }
                                                ]}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(!usedAsComponent || includeVersionHistory) && (
                            <>
                                <hr />
                                <ViewVersion isView={true} />
                                <br />
                                <br />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end mt-2">
                <button
                    className="btn border"
                    onClick={() => {
                        if (role === "documents") {
                            navigate(`/${role}`)
                        }
                        else {
                            navigate(`/projectManagement/${role}`)
                        }
                    }}
                >
                    Back
                </button>
            </div>
            <br />
        </>
    )
}
