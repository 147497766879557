import React, { useEffect, useState } from "react";
import style from "./meeting.module.css";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import Calender from "../../Components/Common/Calender";
import { Editor } from "primereact/editor";
import { MdOutlineAttachment } from "react-icons/md";
import {
  getHeadName,
  getMeetingById,
  updateMeeting,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { getTimeZoneOptions } from "../../Components/Common/timeZone";
import { IoMdAdd } from "react-icons/io";
import { getUserName, parseHashedData, toast } from "../../utils";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import ReactQuill from "react-quill";

export const EditMeeting = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)


  const userObj = userData;
  const userId = userObj?._id;
  const userName =  getUserName();
 

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "12rem" },
    { qty: 1, height: "7rem" },

  ]


  const [headName, setHeadName] = useState();
  const [selectHeadName, setSelectHeadName] = useState();
  const [meeting, setMeeting] = useState();
  const [meetingName, setMeetingName] = useState("");
  const [videoLink, setVideoLink] = useState();
  const [location, setLocation] = useState();
  const [meetingDate, setMeetingDate] = useState(null);
  const [timeZone, setTimeZone] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [description, setDescription] = useState();
  const [selectedFiles, setSelectedFiles] = useState();
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [visible, setVisible] = useState(false);
  const [createHeadName, setCreateHeadName] = useState();
  const [getHeadNameRes, setGetHeadNameRes] = useState([]);
  const [meetingNo, setMeetingNo] = useState();
  const [status, setStatus] = useState();
  const [getData, setGetData] = useState([]);
  const [Validation, setValidation] = useState({
    selectHeadName: false,
    meeting: false,
    meetingNo: false,
    videoLink: false,
    location: false,
    meetingDate: false,
    timeZone: false,
    endTime: false,
    startTime: false,
    status: false,
  });

  const projectId = localStorage.getItem('projectId')
  const checkValidation = () => {
    // if (!headName) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     headName: true,
    //   }));
    // }
    // if (!selectHeadName) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     selectHeadName: true,
    //   }));
    // }
    // if (!meeting) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     meeting: true,
    //   }));
    // }
    // if (!videoLink) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     videoLink: true,
    //   }));
    // }
    // if (!location) {
    //   console.log("jadoo1");
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     location: true,
    //   }));
    // }
    // if (!meetingDate) {
    //   console.log("jadoo2");
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     meetingDate: true,
    //   }));
    // }
    // if (!timeZone) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     timeZone: true,
    //   }));
    // }
    // if (!startTime) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     startTime: true,
    //   }));
    // }
    // if (!endTime) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     endTime: true,
    //   }));
    // }

    // if (!status) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     status: true,
    //   }));
    // }
    // if (!meetingNo) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     meetingNo: true,
    //   }));
    // }

    // if (
    //   !startTime ||
    //   !endTime ||
    //   !status ||
    //   !location ||
    //   !timeZone ||
    //   !videoLink ||
    //   !meetingNo ||
    //   !meeting ||
    //   !meetingDate
    // ) {
    //   toast.info("Please Fill all the Fields Marked with * are Mandatory");
    //   return true;
    // }
    // return false;
  };
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const statusAdd = [{ name: "open" }, { name: "close" }];
  const timeZoneOptions = getTimeZoneOptions();

  const handleFileInput = (event) => {
    setSelectedFiles(event);
  };
  const fetchData = async () => {
    try {
      setSkeletonLoader(true);
      const [headNameRes, meetingByIdRes] = await Promise.all([
        getHeadName(),
        getMeetingById(id),
      ]);

      const headNames = headNameRes?.data?.data || [];
      const meeting = meetingByIdRes?.data?.data;

      setGetHeadNameRes(headNames);

      const currentHead = headNames.find(
        (head) => head._id === meeting.head_id
      );
      if (currentHead) {
        setSelectHeadName(currentHead._id);
      }

      setMeeting(meeting.meeting_name);
      setVideoLink(meeting.videoConferencingLink);
      setLocation(meeting.meetingLocation);
      setMeetingDate(new Date(meeting.meetingDate));
      setTimeZone(meeting.timeZone);
      setStartTime(new Date(meeting.startTime));
      setEndTime(new Date(meeting.endTime));
      setDescription(meeting.description);
      setFileName(
        meeting.attachment.map((file) => ({
          name: file.fileName,
          id: file._id,
        }))
      );
      setPreviousFiles([...meeting.attachment]);
    } catch (error) {
      console.log( error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };
  useEffect(() => {

    fetchData();
  }, [id]);

  const handleUpdate = async () => {
    if (checkValidation()) {
      return;
    }

    try {
      let uploadedFiles = [];

      if (selectedFiles && selectedFiles.length > 0) {
        const formData = new FormData();
        for (const file of selectedFiles) {
          formData.append("upload", file);
        }

        const uploadResponse = await uploadDocs(formData);

        uploadedFiles = uploadResponse.data.map((item) => ({
          fileName: item.fileName,
          fileUrl: item.fileUrl,
        }));
      }

      const payload = {
        meeting_name: meeting,
        videoConferencingLink: videoLink,
        meetingLocation: location,
        meetingDate: meetingDate.toLocaleString(),
        timeZone: timeZone,
        startTime: startTime,
        endTime: endTime,
        description: description,
        attachment: [...previousFiles, ...uploadedFiles],
        deleted_file: removePhoto,
        project_id: projectId,
        userName: userName,
        userId: userId,
        meeting_attendees: []
      };

      await updateMeeting(id, payload);
      toast.success("Meeting updated successfully");
      navigate("/projectManagement/meetings");
    } catch (error) {
      toast.error("Something went wrong, please try again!");
      console.error(error);
    }
  };


  useEffect(() => {
    // handleGetHeadName();
  }, []);
  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  return (
    <div className={` ${style.createPunch_con}`}>
      <div>
        <div className="row m-0 p-0 mb-4">
          <div className="col-12 py-3 ">
            <span
              className="open_Rfi_text"
              onClick={() => navigate("/projectManagement/meetings")}
            >
              {" "}
              Meetings List
            </span>
            <span className="px-1 open_Rfi_text"> /</span>
            <span className="createNew_text"> Edit Meetings</span>
          </div>
        </div>
        <h1>EDIT MEETINGS</h1>

        <br />
        {
          skeletonLoader ?
            <FormSkeleton data={skeletonData} mt={"mt-3"} />
            :
            <div className="row m-0 p-0">
              {/* <div className="col-6 mt-2">
            <div>
              <label htmlFor="" className="py-2 labelCss">
                Select Head Name: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                value={selectHeadName}
                placeholder="Select Head Name"
                options={getHeadNameRes.map((res) => ({
                  label: res?.heading_name,
                  value: res?._id,
                }))}
                onChange={(e) => setSelectHeadName(e.target.value)}
                onClick={() =>
                  setValidation({ ...Validation, selectHeadName: false })
                }
                className={Validation.selectHeadName ? "Validation" : ""}
              />
            </div>
          </div> */}

              <div className="col-md-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Name: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder=" Enter Meeting Name"
                  value={meeting}
                  onChange={(e) => setMeeting(e.target.value)}
                  onClick={() => setValidation({ ...Validation, meeting: false })}
                  className={Validation.meeting ? "Validation" : ""}
                />
              </div>

              {/* <div className="col-6 mt-2">
            <label htmlFor="" className="py-2 labelCss">
              Meeting #: <span className="text-danger">*</span>
            </label>
            <TextInput
              placeholder=" Enter Meeting No"
              value={meetingNo}
              onChange={(e) => setMeetingNo(e.target.value)}
              onClick={() => setValidation({ ...Validation, meetingNo: false })}
              className={Validation.meetingNo ? "Validation" : ""}
            />
          </div> */}
              {/* <div className="col-12">
        <hr className="my-4" />
      </div> */}

              {/* <div className="col-6 mt-2">
            <div>
              <label htmlFor="" className="py-2 labelCss">
                Status: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                value={status}
                placeholder="  Status"
                options={statusAdd.map((res) => ({
                  label: res?.name,
                  value: res?.name,
                }))}
                onChange={(e) => setStatus(e.target.value)}
                onClick={() => setValidation({ ...Validation, status: false })}
                className={Validation.status ? "Validation" : ""}
              />
            </div>
          </div> */}
              <div className="col-md-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Video Conferencing Link: <span className="text-danger">*</span>
                </label>

                <TextInput
                  value={videoLink}
                  placeholder=" Conferencing Link"
                  onChange={(e) => setVideoLink(e.target.value)}
                  onClick={() => setValidation({ ...Validation, videoLink: false })}
                  className={Validation.videoLink ? "Validation" : ""}
                />
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Meeting Location: <span className="text-danger">*</span>
                </label>
                <TextInput
                  placeholder=" Meeting Location"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                  onClick={() => setValidation({ ...Validation, location: false })}
                  className={Validation.location ? "Validation" : ""}
                />
              </div>

              <div className="col-md-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Meeting Date: <span className="text-danger">*</span>
                </label>

                {/* <Calender
                  value={meetingDate}
                  placeholder="Meeting Date"
                  onChange={(e) => setMeetingDate(e.target.value)}
                  onClick={() =>
                    setValidation({ ...Validation, meetingDate: false })
                  }
                  className={Validation.meetingDate ? "Validation" : ""}
                /> */}
                <input
                  type="date"
                  style={{ height: "50px" }}
                  value={formatDate(meetingDate)}
                  placeholder="Meeting Date"
                  onChange={(e) => setMeetingDate(e.target.value)}
                  onClick={() =>
                    setValidation({ ...Validation, meetingDate: false })
                  }
                  className={Validation.meetingDate ? "Validation" : "form-control"}
                />
              </div>

              <div className="col-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Timezone: <span className="text-danger">*</span>
                </label>

                <SingleSelect
                  value={timeZone}
                  onChange={(e) => setTimeZone(e.target.value)}
                  placeholder="TimeZone"
                  options={timeZoneOptions}
                  onClick={() => setValidation({ ...Validation, timeZone: false })}
                  className={Validation.timeZone ? "Validation" : ""}
                />
              </div>

              <div className="col-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Start Time: <span className="text-danger">*</span>
                </label>

                {/* <Calender
                  placeholder="Start Time"
                  timeOnly
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  onClick={() => setValidation({ ...Validation, startTime: false })}
                  className={Validation.startTime ? "Validation" : ""}
                /> */}
                <input
                  type="date"
                  style={{ height: "50px" }}
                  placeholder="Start Time"
                  timeOnly
                  value={formatDate(startTime)}
                  onChange={(e) => setStartTime(e.target.value)}
                  onClick={() => setValidation({ ...Validation, startTime: false })}
                  className={Validation.startTime ? "Validation" : "form-control"}
                />
              </div>

              <div className="col-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  End Time: <span className="text-danger">*</span>
                </label>

                {/* <Calender
                  value={endTime}
                  placeholder=" End Time"
                  timeOnly
                  onChange={(e) => setEndTime(e.target.value)}
                  onClick={() => setValidation({ ...Validation, endTime: false })}
                  className={Validation.endTime ? "Validation" : ""}
                /> */}
                <input
                  type="date"
                  style={{ height: "50px" }}
                  value={formatDate(endTime)}
                  placeholder=" End Time"
                  timeOnly
                  onChange={(e) => setEndTime(e.target.value)}
                  onClick={() => setValidation({ ...Validation, endTime: false })}
                  className={Validation.endTime ? "Validation" : "form-control"}
                />
              </div>

              <div className="col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Description:
                </label>
                <div className="card">
                  {/* <Editor
                    value={description}
                    onTextChange={(e) => setDescription(stripHtml(e.htmlValue))}
                    style={{ minHeight: "140px" }}
                  /> */}

                  <ReactQuill theme="snow"
                    value={description}
                    onChange={setDescription}
                  />


                </div>
              </div>

              <div className="col-12 mt-2">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={setSelectedFiles}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                />
              </div>
            </div>
        }
        <div className="row m-0 p-0">

          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              className="btn border me-3"
              onClick={() => navigate("/projectManagement/meetings")}
            >
              Cancel
            </button>
            <PrimaryButton text={"Update Meetings"} onClick={handleUpdate} />
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};
