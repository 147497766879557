import React, { useEffect, useState } from "react";
import style from "./observation.module.css";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import Calender from "../../Components/Common/Calender";
import { MdAdd, MdOutlineAttachment } from "react-icons/md";
import { upload } from "@testing-library/user-event/dist/upload";
import {
  addObservation,
  addSubmittalCategory,
  addTradeT,
  getObservationById,
  getSubmittalCategory,
  getTradeT,
  getUserByProjectId,
  updateObservation,
  uploadDocs,
  obserVationAddStatus,
  getAllObserVationDataById,
  getTransmittalUid,
  getObservationUid,
} from "../../Api/Admin/AllAPIs";
import {
  getActionsPermission,
  getProjectName,
  getUserId,
  getUserName,
  parseHashedData,
  toast,
} from "../../utils";
import { useParams } from "react-router-dom";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import AddSets from "../../Components/Common/AddSets";
import TimeInput from "../../Components/Inputs/TimeSelector";
import moment from "moment";
import { FormHeader } from "../../Components/Common/FormHeader";
import { useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import { useFetchAllParentsTaskByTool } from "../../hooks/useFetchAllParentsTaskByTool";
import ReactQuill from "react-quill";

export const CreateObservation = ({ reqFor }) => {
  const [visible, setVisible] = useState(false);
  const [visibleTrade, setVisibleTrde] = useState(false);
  const [visibleObserver, setVisibleObserver] = useState(false);
  const [hoursSpent, setHoursSpent] = useState("");
  const [submittalCategory, setSubmittalCategory] = useState("");
  const [getSubmittalCategorys, setGetSubmittalCategorys] = useState([]);
  const [tradeValue, setTradeValue] = useState("");
  const [workFlowStatus, setWorkFlowstatus] = useState("");
  const [ID, setID] = useState();
  const [description, setDescription] = useState("")

  const [tradeData, setTradeData] = useState([]);

  const [addObservationStatus, setAddObservationStatus] = useState("");
  const [getObservationData, setGetObserVationData] = useState([]);

  const [title, setTitle] = useState("");
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [assignee, setAssignee] = useState(null);

  const [trade, setTrade] = useState(null);
  const [statusData, setStatusData] = useState(null);

  const [eventTime, setEventTime] = useState(null);
  const [location, setLocation] = useState("");
  const [dueDate, setDueDate] = useState("");
  // const [attchFile, setAttchFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);

  const projectId = localStorage.getItem("projectId");
  const [options, setOptions] = useState([]);
  const [oldobservation_review_status, setoldobservation_review_status] =
    useState("");

  const [approver, setApprover] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({
    approver: {},
    reviewer: {},
  });

  const [allUsersOfProjectStatus, setAllUsersOfProjectStatus] = useState([]);
  const [allData1, setAllData1] = useState({});
  const [reviewerComment, setReviewerComment] = useState("");
  const [approverComment, setApproverComment] = useState("");

  const userName = getUserName()
  const { id } = useParams();
  const { data: parentsToolData, isLoading, getData: getParentOfObservationData } = useFetchAllParentsTaskByTool("Observation", id)
  const [parentId, setParentId] = useState([]);

  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const [checkStatus, setCheckStatus] = useState("draft");
  const [loading, setLoading] = useState(false);

  const workType = [
    "Work In Progress",
    "Incomplete",
    "Completed",
    "Ready for Review",
    "Not Started",
  ];

  const domain = window.location.origin;

  const [Validation, setValidation] = useState({
    title: false,
    dueDate: false,
    location: false,
    trade: false,
    assignee: false,
    // status: false,
    type: false,
    approver: false,
    reviewer: false,
    // approver: false,
    // reviewer: false,
    ID: false,
    statusData: false,
  });

  const isApproverAccess = getActionsPermission("Observation Approver");
  const isReviewerAccess = getActionsPermission("Observation Reviewer");
  let s = useSelector(e => e.rolesAndPermissionData)

  const [skeletonLoader, setSkeletonLoader] = useState(
    reqFor === "edit" ? true : false
  );
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1 },
    { qty: 1, height: "7rem" },
  ];

  const autoGenarateUid = async () => {
    if (!reqFor)
      await getObservationUid(projectId)
        .then((res) => {

          setID(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  };
  useEffect(() => {
    getParentOfObservationData()
    autoGenarateUid();
  }, []);

  const handleCreateAddIcon = async (type) => {
    try {
      if (type === "category" && !submittalCategory) {
        toast.info(
          "Please fill  the  Observation Category field marked with * are mandatory"
        );
        return;
      }
      if (type === "trade" && !tradeValue) {
        toast.info(
          "Please fill  the  Observation Trade field marked with * are mandatory"
        );
        return;
      }
      if (type === "ObsStatus" && !addObservationStatus) {
        toast.info(
          "Please fill  the  Observation Status field marked with * are mandatory"
        );
        return;
      }

      if (type === "category") {
        setLoading(true);
        await addSubmittalCategory({
          name: submittalCategory,
          project_id: projectId,
        });
        setSubmittalCategory("");
        getByProjectId(projectId);
        setVisible(false);
        setLoading(false);
      }

      if (type === "trade") {
        setLoading(true);
        await addTradeT({
          name: tradeValue,
          project_id: projectId,
        });
        setTradeValue("");
        getByProjectId(projectId);
        setVisibleTrde(false);
        setLoading(false);
      }

      if (type === "ObsStatus") {
        setLoading(true);
        await obserVationAddStatus({
          name: addObservationStatus,
          project_id: projectId,
        });
        setAddObservationStatus("");
        getByProjectId(projectId);
        setVisibleObserver(false);
        setLoading(false);
      }
    } catch { }
  };

  const getByProjectId = async (id) => {
    try {
      const results = await Promise.allSettled([
        getSubmittalCategory(id),
        getTradeT(id),
        getAllObserVationDataById(id),
      ]);
      const [
        getSubmittalCategoryById,
        getTradeDatasById,
        getStatusObservationById,
      ] = results;
      if (getSubmittalCategoryById.status === "fulfilled") {
        setGetSubmittalCategorys(getSubmittalCategoryById.value?.data?.data);
      } else {

      }
      if (getTradeDatasById.status === "fulfilled") {
        setTradeData(getTradeDatasById.value?.data?.data);
      } else {

      }
      if (getStatusObservationById.status === "fulfilled") {
        setGetObserVationData(getStatusObservationById.value?.data?.data);
      } else {

      }
    } catch { }
  };

  useEffect(() => {
    if (projectId) {
      getByProjectId(projectId);
    }
  }, []);
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  let navigate = useNavigate();


  const handleFileInput = (event) => {
    setSelectedFiles(event);
  };


  // check validation
  const checkValidation = () => {



    if (!title?.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        title: !title?.trim(),
      }));
    }
    if (!type) {
      setValidation((prevState) => ({
        ...prevState,
        type: !type,
      }));
    }
    if (!statusData) {
      setValidation((prevState) => ({
        ...prevState,
        statusData: !statusData,
      }));
    }
    if (!assignee) {
      setValidation((prevState) => ({
        ...prevState,
        assignee: !assignee,
      }));
    }
    if (!ID) {
      setValidation((prevState) => ({
        ...prevState,
        ID: !ID,
      }));
    }
    if (!trade) {
      setValidation((prevState) => ({
        ...prevState,
        trade: !trade,
      }));
    }
    if (!location) {
      setValidation((prevState) => ({
        ...prevState,
        location: !location,
      }));
    }
    if (!dueDate) {
      setValidation((prevState) => ({
        ...prevState,
        dueDate: !dueDate,
      }));
    }

    // if (isApproverAccess) {
    //   if (!approver) {
    //     setValidation((prevState) => ({
    //       ...prevState,
    //       approver: !dueDate,
    //     }));
    //   }
    // }
    // if (isReviewerAccess) {
    //   if (!reviewer) {
    //     setValidation((prevState) => ({
    //       ...prevState,
    //       reviewer: !dueDate,
    //     }));
    //   }
    // }

    if (!title?.trim() || !type || !assignee || !trade || !location || !dueDate || !statusData) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const handleCreate = async () => {


    if (selectedFiles.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (checkValidation()) {
      return;
    }

    setSkeletonLoader(true);

    let emailData = [];

    try {
      const formData = new FormData();
      for (const file of selectedFiles) {

        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);
      const fileData = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        url: item.fileUrl,
      }));

      let emailData = [];

      let findEmail = allUsersOfProjectStatus?.find(
        (res) => res._id == reviewer
      );
      emailData.push({
        name: findEmail?.first_name,
        to: findEmail?.email_address,
        tool_number: ID,
        tool: "Observation",
        tool_name: title,
        subject: `Action Required: Appointed as the Reviewer to  Task #${ID} ${title}`,
        project_name: getProjectName(),
        project_id: projectData?.project_code,
        template_type: "createdToolEmailToReviewer",
        url: `${domain}/projectManagement/observation`,
        created_by: userName,

        // type: "Reviewer",

        // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
      });
      let findEmailA = allUsersOfProjectStatus?.find(
        (res) => res._id == approver
      );
      emailData.push({
        name: findEmailA?.first_name,
        to: findEmailA?.email_address,
        tool_number: ID,
        tool: "Observation",
        tool_name: title,
        subject: `Action Required: Appointed as the Approver to  Task #${ID} ${title}`,
        project_name: getProjectName(),
        project_id: projectData?.project_code,
        template_type: "createdToolEmailToApprover",
        url: `${domain}/projectManagement/observation`,
        created_by: userName,

        // type: "Approver",

        // createdAt: moment().format("DD MMM YYYY  hh:mm A"),
      });

      const payload = {
        title: title,
        category: type,
        observationId: ID,
        parent_id: parentId,
        // status: reqFor === "edit" ? status : "draft",
        observedBy: assignee,
        description: description,
        trade: trade,
        location: location,
        observedDate: dueDate,
        observationStatus: statusData,
        hoursSpent: hoursSpent,
        project_id: projectId,
        reviewer_id: reviewer || null,
        approver_id: approver || null,
        email_data: emailData,
        workflowStatus: workFlowStatus,
        attachments: [...previousFiles, ...fileData],
        email_data: emailData,
        creator_email: getUserId(),
        status: checkStatus,
        user_name: userName,
        project_name: projectData.project_name,
        project_number: projectData.projectNumber,
      };

      if (reqFor === "edit") {
        if (payload.status == "draft") {
          if (payload.reviewer_id && payload.approver_id) {
            payload.status = "inreview";
          } else {
          }
        } else {
          if (payload.reviewer_id && payload.approver_id) {
          } else {
            payload.status = "draft";
          }
        }
        payload.user_name = getUserName()
        const res = await updateObservation(id, payload);

        toast.success("Observation Updated Successfully!");
      } else {
        if (payload.reviewer_id && payload.approver_id) {
          payload.status = "inreview";
        } else {
          payload.status = "draft";
        }
        const res = await addObservation(payload);

        toast.success("Observation Created Successfully!");
      }

      setTitle("");
      setType("");
      setStatus("");
      setAssignee("");
      setTrade("");
      setLocation("");
      setDueDate("");
      setSelectedFiles([]);
      // setAttchFile("");

      navigate(-1);
      setSkeletonLoader(false);
    } catch (error) {
      toast.error("Something Wents Wrong, Please Try Again!");
      console.error("Error creating incident:", error);
      setSkeletonLoader(false);
      if (
        error?.response?.data?.message === "Observation number already exists"
      ) {
        toast.error("Observation number already exists");
      }
    }
  };

  const [allData, setAllData] = useState({});

  // feching data for update
  async function fetchDataById() {

    if (reqFor !== "edit") {
      return;
    }

    try {
      setSkeletonLoader(true);
      const res = await getObservationById(id);
      const data = res?.data?.data?.[0] || {};
      setAllData(data);
      setAllData1(data);
      setApproverComment(data?.approver_comment);
      setReviewerComment(data?.reviewer_comment);

      setoldobservation_review_status(data?.observation_review_status);
      setTitle(data?.title);
      setParentId(data?.parent_id || [])
      setType(typeOption?.find((item) => item?.type === data?.type));
      setStatus(data.status);
      setApprover(data?.approver_id);
      setReviewer(data?.reviewer_id);
      setAssignee(data?.observedBy?.[0]?._id);
      setTrade(tradeOptions?.find((item) => item?.trade === data?.trade));
      setLocation(data?.location);
      setDueDate(new Date(data?.observedDate));
      setSelectedFiles([]);
      setRemovePhoto([]);
      setStatusData(data?.observationStatus?.[0]?._id);
      setPreviousFiles([...data?.attachments]);
      setHoursSpent(data?.hoursSpent);
      setWorkFlowstatus(data?.workflowStatus);
      setTrade(data?.trade?.[0]?._id);
      setType(data?.category?.[0]?._id);
      setID(data?.observationId);
      setCheckStatus(data?.status);

      setFileName(
        data?.attachments.map((item) => ({
          name: item?.fileName,
          id: item?._id,
        }))
      );

      let statuss = data.status;

      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", statuss),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", statuss),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", statuss),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", statuss),
        },
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  }



  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
      if (reqFor === "edit") {
        setAllUsersOfProjectStatus(allUsers);
      } else {
        setAllUsersOfProjectStatus(allUsers);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchDataById();
    getAllUsersOfProject();
  }, []);

  const ReceivedFrom = [
    {
      entity_id: 1,
      entity_name: "Roy Construction",
    },
    {
      entity_id: 2,
      entity_name: "Jonshon Builders",
    },
    {
      entity_id: 3,
      entity_name: "A&C Constructors",
    },
    {
      entity_id: 4,
      entity_name: "Smith Engineering",
    },
  ];
  const typeOption = [
    { id: 1, type: "Environmental" },
    { id: 2, type: "Health and Safety" },
    { id: 3, type: "Materials" },
    { id: 4, type: "Permit Compliance" },
    { id: 5, type: "QA/QC" },
    { id: 6, type: "Quality Control" },
    { id: 7, type: "Three-Phase Quality Control" },
  ];
  const tradeOptions = [
    {
      id: 1,
      trade: "Electrical",
    },
    {
      id: 2,
      trade: "Plumbing",
    },
    {
      id: 3,
      trade: "Carpentry",
    },
    {
      id: 4,
      trade: "Concrete",
    },
    {
      id: 5,
      trade: "HVAC (Heating, Ventilation, and Air Conditioning)",
    },
    {
      id: 6,
      trade: "Masonry",
    },
    {
      id: 7,
      trade: "Painting",
    },
    {
      id: 8,
      trade: "Roofing",
    },
    {
      id: 9,
      trade: "Demolition",
    },
    {
      id: 10,
      trade: "Landscaping",
    },
  ];

  // console.log(status);

  function convertHourlySpentStringIntoObject(str) {
    if (typeof str === "string") {
      return {
        hours: parseInt(str.split("-")[0]) || "00",
        minutes: parseInt(str.split("-")[1]) || "00",
      };
    } else {
      return {
        hours: "0",
        minutes: "0",
      };
    }
  }
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className={` ${style.createPunch_con}`}>
        <div>

          <FormHeader
            to={"/projectManagement/observation"}
            formHeader={"Observation"}
            breadcrumItem={["Observation", `${reqFor === "edit" ? "Edit" : "Create"} Observation`]} />


          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} />
          ) : (
            <div className="row m-0 p-0">
              <div className="col-md-6 ">
                <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                  Title: <span className="text-danger">*</span>
                </label>
                <TextInput
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  placeholder="Title"
                  className={Validation.title ? "Validation" : ""}
                  onClick={() => setValidation({ ...Validation, title: false })}
                />
              </div>
              <div className="col-md-6 d-flex">
                {/* <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                  Type: <span className="text-danger">*</span>
                </label>
                <SingleSelect
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                  options={typeOption}
                  optionLabel="type"
                  placeholder={"Type"}
                  className={Validation.type ? "Validation" : ""}
                  onClick={() => setValidation({ ...Validation, type: false })}
                /> */}
                <div className="maindiv_plusIcon">
                  <label htmlFor="" className="py-2 labelCss">
                    Type Category: <span className="text-danger">*</span>
                  </label>
                  <AutoCompleteSelect
                    options={getSubmittalCategorys.map((item) => ({
                      label: capitalizeFirstLetter(item?.name),
                      value: item?._id,
                    }))}
                    value={type}
                    onChange={(value) => {
                      setType(value);
                      setValidation({ ...Validation, type: false });
                    }}
                    placeholder="Select Type Category"
                    className={Validation.type ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, type: false })
                    }
                  />
                </div>
                <span className="ml-3 mt-5">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />
                </span>
              </div>
              {/* <div className="col-md-12">
            <hr className="my-4" />
          </div> */}
              {/* {reqFor === "edit" ? (
                <div className="col-md-6 mt-2">
                  <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                    Status: <span className="text-danger">*</span>
                  </label>

                  <SingleSelect
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    options={options}
                    optionLabel="label"
                    placeholder={"status"}
                    className={Validation.status ? "Validation" : ""}
                    disabled={
                      allData.observation_approval_status == "approved" ||
                      allData.observation_review_status == "approved"
                    }
                  />
                </div>
              ) : (
                ""
              )} */}
              <div className="col-md-6 mt-2">
                <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                  Observed by: <span className="text-danger">*</span>
                </label>
                {/* <SingleSelect
                  className={`p-0 ${Validation.assignee ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, assignee: false })
                  }
                  placeholder="Observed By"
                  options={allUsersOfProject.map((item) => ({
                    label: item?.first_name,
                    value: item?._id,
                  }))}
                  value={assignee}
                  onChange={(e) => {
                    setAssignee(e.target.value);
                    // setPersonData(e.target.value.person);
                  }}
                /> */}
                <AutoCompleteSelect
                  className={`p-0 ${Validation.assignee ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, assignee: false })
                  }
                  placeholder="Observed By"
                  options={allUsersOfProject.map((item) => ({
                    label: item?.first_name,
                    value: item?._id,
                  }))}
                  value={assignee}
                  onChange={(value) => {
                    setAssignee(value);
                    setValidation({ ...Validation, assignee: false });
                    // setPersonData(e.target.value.person);
                  }}
                />
              </div>
              <div className="col-md-6 mt-2 d-flex">
                {/* <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                  Trade: <span className="text-danger">*</span>
                </label>
                <SingleSelect
                  onChange={(e) => setTrade(e.target.value)}
                  value={trade}
                  options={tradeOptions}
                  optionLabel="trade"
                  placeholder={"Trade"}
                  className={Validation.trade ? "Validation" : ""}
                  onClick={() => setValidation({ ...Validation, trade: false })}
                /> */}
                <div className="maindiv_plusIcon">
                  <label htmlFor="" className="py-2 labelCss">
                    Trade: <span className="text-danger">*</span>
                  </label>
                  <AutoCompleteSelect
                    options={tradeData.map((item) => ({
                      label: capitalizeFirstLetter(item?.name),
                      value: item?._id,
                    }))}
                    value={trade}
                    onChange={(value) => {
                      setTrade(value);
                      setValidation({ ...Validation, trade: false });
                    }}
                    placeholder="Select Trade"
                    className={Validation.trade ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, trade: false })
                    }
                  />
                </div>
                <span className="ml-3 mt-5">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisibleTrde(true)}
                  />
                </span>
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                  Location: <span className="text-danger">*</span>
                </label>

                <TextInput
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                  placeholder="Location"
                  className={Validation.location ? "Validation" : ""}
                  onClick={() =>
                    setValidation({ ...Validation, location: false })
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor="" className={`py-2 ${style.labelCss}`}>
                  Due Date: <span className="text-danger">*</span>
                </label>
                {/* <Calender
                  value={dueDate}
                  selected={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  placeholder="Due Date"
                  // clearButtonClassName ="p-secondary-button"
                  className={Validation.dueDate ? "Validation" : ""}
                  onClick={() => setValidation({ ...Validation, dueDate: false })}
                /> */}
                <input
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  style={{ height: "50px" }}
                  value={formatDate(dueDate)}
                  selected={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  placeholder="Due Date"
                  // clearButtonClassName ="p-secondary-button"
                  className={`form-control ${Validation.dueDate ? "Validation" : ""
                    }`}
                  onClick={() =>
                    setValidation({ ...Validation, dueDate: false })
                  }
                />
              </div>

              {/* <div className="row"> */}

              {isReviewerAccess && (
                <div className="col-md-6 col-12 mt-2">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>

                  <AutoCompleteSelect
                    options={allUsersOfProjectStatus?.map((item) => {
                      return {
                        label: item?.first_name,
                        value: item?._id,
                      };
                    })}
                    value={reviewer}
                    // value={allUsersOfProject?.filter((dd) => dd?._id.toString() == reviewer.toString())}
                    optionLabel="label"
                    onChange={(value) => {
                      setReviewer(value);
                    }}
                    className={`p-0`}
                    placeholder="Select Reviewer"
                  // disabled={
                  //   reviewer === getUserId() || allData1?.status == "approved"
                  // }
                  />
                </div>
              )}
              {isApproverAccess && (
                <div className="col-md-6 col-12 mt-2">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver:
                  </label>

                  <AutoCompleteSelect
                    options={allUsersOfProjectStatus?.map((item) => {
                      return {
                        label: item?.first_name,
                        value: item?._id,
                      };
                    })}
                    value={approver}
                    // value={allUsersOfProject.find((dd) => dd._id == approver)}

                    optionLabel="label"
                    onChange={(value) => {
                      setApprover(value);
                    }}
                    className={`p-0 `}
                    placeholder="Select Approver"
                  // disabled={
                  //   approver === getUserId() || allData1?.status == "approved"
                  // }
                  />
                </div>
              )}

              {reqFor === "edit" ? (
                <>
                  <>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer Comment:
                      </label>
                      <textarea
                        className="form-control"
                        // placeholder="Reviewer Comment"
                        value={reviewerComment}
                        // onChange={(e) => setReviewerComment(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver Comment:
                      </label>
                      <textarea
                        className="form-control"
                        // placeholder="Approver Comment"
                        value={approverComment}
                        // onChange={(e) => setApproverComment(e.target.value)}
                        disabled
                      />
                    </div>
                  </>
                </>
              ) : (
                <></>
              )}
              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Workflow Status:
                </label>
                <SingleSelect
                  placeholder="Status"
                  options={workType}
                  value={workFlowStatus}
                  onChange={(e) => {
                    setWorkFlowstatus(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 d-flex mt-2">
                <div className="maindiv_plusIcon">
                  <label htmlFor="" className="py-2 labelCss">
                    Observation Status:<span className="text-danger">*</span>
                  </label>
                  <AutoCompleteSelect
                    options={getObservationData.map((item) => ({
                      label: capitalizeFirstLetter(item?.name),
                      value: item?._id,
                    }))}
                    value={statusData}
                    onChange={(value) => {
                      setStatusData(value);
                      setValidation({ ...Validation, statusData: false });
                    }}
                    placeholder="Select Trade Category"
                    className={Validation.statusData ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, statusData: false })
                    }
                  />
                </div>
                <span className="ml-3 mt-5">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisibleObserver(true)}
                  />
                </span>
              </div>
              <div className="col-12 col-md-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Hours Spent:
                </label>
                <TimeInput
                  value={convertHourlySpentStringIntoObject(hoursSpent)}
                  onChange={(newValue) =>
                    setHoursSpent(`${newValue.hours}-${newValue.minutes}`)
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Observation Id:<span className="text-danger">*</span>
                </label>

                <TextInput
                  placeholder="Enter Observation Id"
                  type={"number"}
                  value={ID}
                  onChange={(e) => setID(e.target.value)}
                  className={Validation.ID ? "Validation" : ""}
                  onClick={() => setValidation({ ...Validation, ID: false })}
                ></TextInput>
              </div>
              {/* </div> */}


              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Parent Task:
                </label>


                <SingleSelect
                  showClear
                  placeholder="Select Parent"
                  options={parentsToolData}
                  optionLabel="title"
                  value={parentsToolData?.find((e) => e._id == parentId?.[0]) || null}
                  onChange={(e) => {
                    setParentId(e?.value?._id ? [e?.value?._id] : []);
                  }}
                  filter={true}
                  filterInputAutoFocus={true}
                />

              </div>
              <div className="col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Description:
                </label>


                <ReactQuill theme="snow"
                  value={description}
                  onChange={(e) => {
                    console.log(e)
                    setDescription(e)
                  }
                  }
                />
              </div>

              <div className="col-md-12 mt-2">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={selectedFiles}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                />
              </div>

              <div className="col-12 d-flex justify-content-end gap-3 mt-4">
                <button
                  className="btn border"
                  onClick={() => navigate("/projectManagement/observation")}
                >
                  Cancel
                </button>
                <PrimaryButton
                  text={`${reqFor === "edit" ? "Update" : "Create"}`}
                  onClick={handleCreate}
                />
              </div>
            </div>
          )}
        </div>
        <br />
      </div>

      <AddSets
        visible={visible}
        setVisible={setVisible}
        value={submittalCategory}
        setValue={setSubmittalCategory}
        CategoryName={"Observation Category "}
        label={"Category"}
        reqFor={"create"}
        onSubmit={() => handleCreateAddIcon("category")}
        loading={loading}
        setLoading={setLoading}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visibleTrade}
        setVisible={setVisibleTrde}
        CategoryName={"Trade"}
        label={"Trade"}
        reqFor={"create"}
        setValue={setTradeValue}
        value={tradeValue}
        onSubmit={() => handleCreateAddIcon("trade")}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visibleObserver}
        setVisible={setVisibleObserver}
        CategoryName={"Observation Status"}
        label={"Observation Status"}
        reqFor={"create"}
        setValue={setAddObservationStatus}
        value={addObservationStatus}
        onSubmit={() => handleCreateAddIcon("ObsStatus")}
      />
    </>
  );
};
