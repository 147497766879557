import React, { useEffect, useState } from "react";
import style from "./Overview.module.css";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { DonutChart } from "../../Components/Charts/DonutChart";
import { VerticalBarChart } from "../../Components/Charts/VerticalBarChart";
import { getProjectOverviewSummary } from "../../Api/Admin/AllAPIs";
import Carousel from "react-bootstrap/Carousel";
import { VerticalRowBarChart } from "../../Components/Charts/VerticalRowBarChart";
import LightShadowCard from "../../Components/Cards/LightShadowCard";
import { PROJECT_OVERVIEW_TOOLS } from "../../constants";

const Overview = () => {
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [{ qty: 1, height: "20rem" }];
  const [allData, setAllData] = useState({
    projectTracker: []
  })

  let [activeTool, setActiveTool] = useState("rfi")

  async function getAllDate() {
    try {
      let res = await getProjectOverviewSummary()

      if (res?.data?.data?.[0]) {
        let chartData = res.data.data[0];
        setAllData({
          projectTracker: chartData?.projectTracker || [],
          budget: chartData.budget || [],
          projectDrawings: chartData.projectDrawings.flat(Infinity) || [],
          verticalRowBarGraphData: chartData?.barGraphCount || [],
          allUsersChartData: chartData?.company || [],
          totalUser: chartData?.totalUsers || []
        });


      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    getAllDate()
  }, [])


  return (
    <div className={`row m-0 ${style.overview_main_container}`}>

      <div className="col-md-6 col-12 mt-2 px-3">

        <LightShadowCard headingClass={"chart_heading"} heading={"Project Summary"}>
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-1"} />
          ) : (
          allData?.projectTracker?.length?
            <DonutChart
              value={allData?.projectTracker || []}
              label={allData?.projectTracker?.map(e => `${e?.name}:${e?.value}`) || []}
            />:
            <div>No Data available...</div>
          )}
        </LightShadowCard>
      </div>

      <div className="col-md-6 col-12 mt-2  px-3">
        <LightShadowCard headingClass={"chart_heading"} heading={"Project Images"}>
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-1"} />
          ) : (
            allData?.projectDrawings?.length?<div>
              <Carousel
                interval={2000}
                slide={true}
                controls={false}
                indicators={false}
                style={{ height: "300px", overflow: "hidden" }}
              >
                {allData?.projectDrawings?.map((res, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={res}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>:
            <div style={{ height: "300px" }}>
            <b>No Images Available...</b>
            </div>
          )}
        </LightShadowCard>
      </div>

      <div className="col-12 mt-4">

        <LightShadowCard headingClass={"chart_heading"} heading={"Project Tools Summary"}>
          <VerticalRowBarChart
            rowItems={allData?.verticalRowBarGraphData?.map(d => d.name) || []}
            data={allData?.verticalRowBarGraphData?.length > 0 ? Object.keys(allData?.verticalRowBarGraphData?.[0][activeTool]).map((d => {
              return ({
                name: d,
                data: allData?.verticalRowBarGraphData?.map(dd => dd[activeTool][d])
              })
            })) || [] : [{name:"",data:[]}] || []} />


          <div className="d-flex gap-3 flex-wrap">
            {PROJECT_OVERVIEW_TOOLS.map((res, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    setActiveTool(res.value)
                  }}
                  className={`${style.project_tool_tab} ${activeTool === res.value && style.project_tool_active_tab} bg_primary`}>{res.title}</div>
              )
            })}
          </div>


        </LightShadowCard>
      </div>


      <div className="col-12 mt-4">

        <LightShadowCard headingClass={"chart_heading"} heading={"Individual | Contractor | Sub Contractor"}>

          <VerticalRowBarChart
            rowItems={allData?.allUsersChartData?.map(d => d.name) || []}
            data={
              [
                { name: "contractor", data: allData?.allUsersChartData?.map(e => e.contractor) || [] },
                { name: "subcontractor", data: allData?.allUsersChartData?.map(e => e.subcontractor) || [] },
                { name: "Individual", data: allData?.allUsersChartData?.map(e => e.Individual) || [] }
              ]
            } />

        </LightShadowCard>
      </div>

      <div className="col-12 mt-4">
        <LightShadowCard headingClass={"chart_heading"} heading={"Total Users"}>
          <VerticalRowBarChart
            rowItems={allData?.totalUser?.map(d => d.name) || []}
            data={
              [
                { name: "totalUser", data: allData?.totalUser?.map(e => e?.totalUser)?.filter(e => e) || [] },
              ]
            }


          />

        </LightShadowCard>
      </div>


      <div className={"col-12 mt-4"}>
        <LightShadowCard headingClass={"chart_heading"} heading={"Projects Budget Tracker"}>
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-4"} />
          ) : (
            <div>
              <VerticalBarChart
                value={allData?.budget || []} valueKey={"price"} />
            </div>
          )}
        </LightShadowCard>
      </div>


    </div>
  );
};

export default Overview;
