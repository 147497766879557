import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { getPrioritytype, getTradeT, getTypeT, getUserByProjectId } from '../../Api/Admin/AllAPIs';

function Filter({ filterData, setFilterData, onFilter, className }) {
    const [visible, setVisible] = useState(false);
    const [allUsersOfProject, setAllUsersOfProject] = useState([]);
    const [tradeData, setTradeData] = useState([]);
    const [listingType, setListingType] = useState([]);
    const [priorityData, setPriorityData] = useState([]);

    const projectId = localStorage.getItem("projectId");

    useEffect(() => {
        async function fetchData() {
            try {
                const tradeRes = await getTradeT(projectId);
                setTradeData(tradeRes?.data?.data || []);

                const priorityRes = await getPrioritytype(projectId);
                setPriorityData(priorityRes?.data?.data || []);

                const typeRes = await getTypeT(projectId);
                setListingType(typeRes?.data?.data || []);

                const userRes = await getUserByProjectId(projectId);
                setAllUsersOfProject(userRes?.data?.data?.[0]?.combined_users || []);
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, [projectId]);

    function inpChange(event) {
        const { name, value } = event.target;
        setFilterData(prev => ({ ...prev, [name]: value }));
    }

    function handleFilter() {
        onFilter();
        setVisible(false);
    }

    return (
        <>
            <div className={`card flex justify-content-center ${className}`}>
                <button
                    className="filter_btn w-100"
                    onClick={() => setVisible(true)}
                >
                    Filter
                </button>

                <Dialog
                    header="Filter Issue"
                    visible={visible}
                    maximizable
                    style={{ width: "50vw", color: "#101928" }}
                    onHide={() => setVisible(false)}
                    headerClassName="addBorder_bottom"
                    draggable={false}
                >
                    <div className="row mt-4">
                        <div className="col-md-6 mt-3">
                            <label className="labels mb-1">Issue Trade</label>
                            <div className="card flex justify-content-center">
                            
                                <Dropdown
                                    value={filterData?.issue_trade}
                                    onChange={(e) => inpChange({ target: { name: 'issue_trade', value: e?.value } })}
                                    options={tradeData?.map(item => ({
                                        label: item?.name || '',
                                        value: item?._id
                                    })) || []}
                                    name="issue_trade"
                                    placeholder="Issue Trade"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="labels mb-1">Issue Priority:</label>
                            <div className="card flex justify-content-center">
                                <Dropdown
                                    value={filterData?.issue_priority}
                                    onChange={(e) => inpChange({ target: { name: 'issue_priority', value: e.value } })}
                                    options={priorityData?.map(item => ({
                                        label: item?.name,
                                        value: item?._id
                                    })) || []}
                                    name="issue_priority"
                                    placeholder="Issue Priority"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="labels mb-1">Responsible Person:</label>
                            <div className="card flex justify-content-center">
                                <Dropdown
                                    value={filterData?.responsiblePerson}
                                    onChange={(e) => inpChange({ target: { name: 'responsiblePerson', value: e.value } })}
                                    options={allUsersOfProject?.map(d => ({
                                        label: d.first_name,
                                        value: d._id
                                    })) || []}
                                    name="responsiblePerson"
                                    placeholder="All Users"
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="labels mb-1">Issue Type:</label>
                            <div className="card flex justify-content-center">
                                <Dropdown
                                    value={filterData?.issue_type}
                                    onChange={(e) => inpChange({ target: { name: 'issue_type', value: e.value } })}
                                    options={listingType?.map(item => ({
                                        label: item?.name,
                                        value: item?._id
                                    })) || []}
                                    name="issue_type"
                                    placeholder="Issue Type"
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-5 d-flex justify-content-end">
                            <div className="d-flex align-items-center gap-3">
                                <button className="filter_btn" onClick={() => setVisible(false)}>
                                    Cancel
                                </button>
                                <Button className="Apply_filter_btn" onClick={handleFilter}>Apply Filter</Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default Filter;
