import { useCallback, useState } from 'react'
import {
  getdailyLogParentData,
  getIncidentParentData,
  getInspectionParentData,
  getIssueParentData,
  getObservationParentData,
  getPunchParentData,
  getRfiParentData,
  getSpecificationsParentData,
  getSubmittalsParentData,
  getTaskParentData,
  getTransmittalParentData
} from '../Api/Admin/AllAPIs'
import { getProjectId } from '../utils'

export const useFetchAllParentsTaskByTool = (toolName,toolId) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const getData = useCallback(async () => {
    setIsError(false)
    setIsLoading(true)
    try {
      let body = { id: getProjectId(),toolId }
      if (toolName === 'Task') {
        let res = await getTaskParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Rfi') {
        let res = await getRfiParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Submittals') {
        let res = await getSubmittalsParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Punch') {
        let res = await getPunchParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Observation') {
        let res = await getObservationParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Specification') {
        let res = await getSpecificationsParentData(body)
        setData(res.data.data)
      } else if (toolName === 'DailyLog') {
        let res = await getdailyLogParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Transmittal') {
        let res = await getTransmittalParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Issue') {
        let res = await getIssueParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Incident') {
        let res = await getIncidentParentData(body)
        setData(res.data.data)
      } else if (toolName === 'Inspection') {
        let res = await getInspectionParentData(body)
        setData(res.data.data)
      }
    } catch (error) {
      console.log(error)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { getData, data, isLoading, isError }
}
