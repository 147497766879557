// ImageUpload.js
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import style from './input.module.css'
import { RxCross2 } from 'react-icons/rx'
import './customInputDragAndDrop.css'
import { MdOutlineAttachment } from 'react-icons/md'

const CustomDragAndDrop = ({
  className,
  label,
  onChange,
  value,
  setDeleted,
  disabled
}) => {
  const [files, setFiles] = useState([...value])

  useEffect(() => {
    setFiles([...value])
  }, [value])

  const onDrop = acceptedFiles => {
  
    if (disabled) {
    } else {
      setFiles(prevImages => {
        let arr = [
          ...prevImages,
          ...acceptedFiles.map(file => {
            let rr = {
              file: file,
              fileUrl: URL.createObjectURL(file),
              fileName: file.name
            }
            return rr
          })
        ]
        onChange(arr)
        return arr
      })
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  })

  function removeImage (event, file, ind) {
    event.stopPropagation()
    if (!file.file) {
      setDeleted && setDeleted(pre => [...pre, file.fileName])
    }
    setFiles(pre => {
      pre.splice(ind, 1)
      return [...pre]
    })
    let dd = [...files]
    dd.splice(ind, 1)
    onChange(dd)
  }

  return (
    <div className='image-upload'>
      <div
        {...getRootProps({
          className: 'dropzone'
        })}
      >
            <input {...getInputProps()} />

        <span
          className='attText'
          style={{
            fontWeight: 500,
            fontSize: '14px',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          <div>
            <MdOutlineAttachment size={22} /> {label || 'Attach File:'}
          </div>
        </span>

        <div
          className={`${style.dragAndDrop_main_con} ${className} w-100 d-flex`}
        >
          {files.length === 0 ? (
            <>
              <label>
                <br />
                <br />
              </label>
            </>
          ) : (
            <>
              <div className='customInputDragAndDrop_images_flex_con'>
                {files.map((file, index) => (
                  <div className='drop_image_con p-2' key={index}>
                    {disabled ? (
                      ''
                    ) : (
                      <div
                        onClick={e => removeImage(e, file, index)}
                        className={'drop_remove_icon'}
                      >
                        <RxCross2 />
                      </div>
                    )}
                    <div key={index} className='image-container'>
                      {file?.fileName?.length > 8
                        ? file?.fileName.substr(0, 8) + '...'
                        : file?.fileName}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomDragAndDrop
