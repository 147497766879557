
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';

import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import Popover from '../../../../Components/Common/Popover';
import { useNavigate } from 'react-router-dom';
import { Statuscomp } from '../../../../Components/Common/StatusComp';
import Pagination from '../../../../Components/Common/Pagination/Pagination';

const sampleData = [
  { id: 1, To: '01-002 Instructions', From: '01-006 Temporary Facilities', Transfer_Amount: '$ 30,000.00', Modified_by: 'Joshua Templeton', Modified_on: '11/05/2024', },
  { id: 1, To: '02-015 Site Planning', From: '02-005 Site Cleaning', Transfer_Amount: '$ 12,000.00', Modified_by: 'Ericc Pager', Modified_on: '25/04/2024', },

  { id: 1, To: '01-001 General', From: '01-011 Contraction Facilities', Transfer_Amount: '$ 5,000.00', Modified_by: 'Joshua Templeton', Modified_on: '14/06/2024', },

  
  
];

export const Modifications = () => {

  const [budgetChanges, setBudgetChanges] = useState([]);
  const [page, setPage] = useState(1)
const [totalRecords, setTotalRecords] = useState(0)
const perPageData = 10
  const navigate = useNavigate();

  useEffect(() => {
    setBudgetChanges(sampleData);
    setTotalRecords(5)
  }, []);
  return (
    <div>
    <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
      <div className="searchInput">
        <SearchInput
          placeholder={"search"}
        />
      </div>
      <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
        <button className="filter_btn w-100"> Filter Modifications</button>
        <button className="filter_btn w-100">Clear Filter</button>
      </div>
    </div>

    <div className="card mt-4">
      <DataTable value={budgetChanges} rows={5}>
        <Column field="id" header="#" />

        <Column field="To" header="To" />
        <Column field="From" header="From" />
        <Column field="Transfer_Amount" header="Transfer Amount" />
        <Column field="Modified_by" header="Modified by" />
        <Column field="Modified_on" header="Modified on" />
        <Column
          header=""
          body={(rowData, meta) => (
            <div className="flex justify-content-center">
              <Popover
                options={["Mark as Approved","Mark as Void"]}
                onSelect={(index, event) => {
                  if (index === 0)
                    navigate(``);
                }}
              />
          
            </div>
          )}
        />
      </DataTable>
    </div>
      
      <div className='mt-2'>
      <Pagination
                  currentPage={page}
                  totalCount={totalRecords}
                  pageSize={perPageData}
                  onPageChange={page => {
                   
                      setPage(page)
                  }}
              />
      </div>
  </div>
  )
}
