import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import TextInput from "../../Components/Inputs/TextInput";
import SingleSelect from "../../Components/Common/SingleSelect";

const Filter = ({ filterData, setFilterData, onFilter }) => {

  const [visible, setVisible] = useState(false)

  function handleApplyFilter() {
    onFilter()
    setVisible(false)
  }

  function inpChange(e) {
    let { name, value } = e.target;
    setFilterData(pre => ({ ...pre, [name]: value }))
  }

  let visitStatus = ["Access Granted", "Access Rejected"]

  return (
    <div className="card flex justify-content-center">
      <button
        className="filter_btn"
        onClick={() => setVisible(true)}
      >
        Filter
      </button>
      <Dialog
        draggable={false}
        header="Filter Daily Logs"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row ">
          <div className="col-md-6">
            <label className="labels py-2">Visitor Company Name:</label>

            <div className="card flex justify-content-center  ">
              <TextInput
                value={filterData?.visitorCompanyName}
                onChange={inpChange}
                name="visitorCompanyName"
                placeholder={"Visitor Company Name"}
              />
            </div>
          </div>
          <div className="col-md-6">

            <label className="labels py-2">Visitor Status</label>
            <div className="card flex justify-content-center">
              <SingleSelect
                placeholder="Visitor Status"
                options={visitStatus}
                value={filterData?.visitStatus}
                name="visitStatus"
                onChange={inpChange}
              />
            </div>
          </div>


          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;
