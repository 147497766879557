import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { IoIosArrowForward } from "react-icons/io";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IoMdDownload } from "react-icons/io";
import fileDownload from 'js-file-download'
import axios from 'axios'

export default function ViewTempletes({ open, toggle, data, breadcrum, breadCrumData }) {
  const [loader, setLoader] = useState(false)

  const handleFileClick = (fileUrl) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  const handleDownloadClick = (data) => {

    return
    setLoader(true)
    axios.get(data?.fileUrl, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, data?.fileName)
        setLoader(false)
      })
  };

  return (
    <>
      <Dialog header={'View Templates'} visible={open} style={{ width: '40vw' }} onHide={toggle} breakpoints={{ '1200px': '60vw', '960px': '75vw', '641px': '90vw' }}>
        <div className=''>
          <div className='d-flex align-items-center fs-14'>
            <div>{breadCrumData.phase_name}</div> <IoIosArrowForward />
            <div>{breadCrumData.category_name}</div> <IoIosArrowForward />
            <div>{breadCrumData.sub_category_name}</div> <IoIosArrowForward />
            <div>{breadCrumData.document_name}</div>


            {/* <div>PDFs</div> */}
          </div>
          <div className='my-3'>
            <DataTable className='border' value={data}>
              <Column style={{ width: '50px' }} field='' body={(rowData, e) => e.rowIndex + 1}></Column>
              <Column field='fileName' header='Templates' body={(rowData) => <div className='pointer' onClick={() => handleFileClick(rowData?.fileUrl)}>{rowData?.fileName}</div>}></Column>
              <Column style={{ width: '50px' }} field='' header='' body={(rowData) => (
                <>
                  {/* {loader ? <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> : <span onClick={() => handleDownloadClick(rowData)}><IoMdDownload className='pointer' /></span>} */}
                  <DownloadFile rowData={rowData} />
                </>
              )}></Column>
            </DataTable>
          </div>
        </div>
      </Dialog>
    </>
  )
}

function DownloadFile({ rowData }) {
  let [loader, setLoader] = useState(false)


  const handleDownloadClick = (data) => {



    setLoader(true)
    axios.get(data?.fileUrl, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, data?.fileName)
        setLoader(false)
      })
  };
  return (
    <>
      {loader ? <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div> : <span onClick={() => handleDownloadClick(rowData)}><IoMdDownload className='pointer' /></span>}
    </>
  )
}

