import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import ReactQuill from "react-quill"; // Importing ReactQuill
import "react-quill/dist/quill.snow.css"; // Importing ReactQuill styles

export default function ScheduleTaskModal({ open,data,onCloseModal, toggle }) {
  const [description, setDescription] = useState(""); // State for storing description

  

  return (
    <Modal isOpen={open} toggle={toggle} size="xl" centered scrollable>
      <ModalHeader toggle={toggle}>
        <div>
          <span className="fs-4">Schedule/ </span>
          <strong className="fs-2 fw-500">{data?.name}</strong>
        </div>
      </ModalHeader>

      <ModalBody>
        <div className="p-4">
          <div className="row p-0">
            <div className="col-6">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="p-3 ">Task ID</td>
                    <td className="p-3 ">{data?.taskId}</td>
                  </tr>
                  <tr>
                    <td className="p-3 ">Task Name</td>
                    <td className="p-3 ">{data?.name}</td>
                  </tr>
                  <tr>
                    <td className="p-3 ">Contractor Company</td>
                    <td className="p-3 ">L&T</td>
                  </tr>
                  <tr>
                    <td className="p-3 ">Approver</td>
                    <td className="p-3 ">Chris Gayle</td>
                  </tr>
                  <tr>
                    <td className="p-3 ">Reviewer</td>
                    <td className="p-3 ">Virat Kohli</td>
                  </tr>
                  <tr>
                    <td className="p-3 ">Due Date</td>
                    <td className="p-3 ">15/01/2025</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-6">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="p-3 ">Task Manager</td>
                    <td className="p-3 ">MS Dhoni</td>
                  </tr>
                  <tr>
                    <td className="p-3 ">Approver Comment</td>
                    <td className="p-3 ">Still Not Ready?</td>
                  </tr>
                  <tr>
                    <td className="p-3">Reviewer Comment</td>
                    <td className="p-3 ">Still Not Ready?</td>
                  </tr>
                  <tr className="">
                    <td className="p-3 ">Responsible Person</td>
                    <td className="p-3 ">Yuvraj Singh</td>
                  </tr>
                  <tr className="">
                    <td className="p-3 ">Category</td>
                    <td className="p-3 ">Electrical</td>
                  </tr>
                  <tr className="">
                    <td className="p-3 ">Duration</td>
                    <td className="p-3 ">5 days, 18 hrs 30 mins</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="border-bottom my-4"></div>

        {/* <div className="fw-bold fs-6">SCHEDULE CHANGE</div>
        <div className="row mt-3">
          <div className="col-6">
            <label className=" mb-1">New Start Date</label>
            <div className="input-group">
              <input type="date" className="form-control" />
            </div>
          </div>

          <div className="col-6">
            <label className=" mb-1">New End Date</label>
            <div className="input-group">
              <input type="date" className="form-control" />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <label className=" mb-1">Reason</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            className="rounded border"
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['bold', 'italic', 'underline'],
                ['link']
              ]
            }}
          />
        </div> */}

        <div className="d-flex justify-content-end mt-3">
  <div className="btn btn-primary" onClick={onCloseModal}>Close</div>
</div>


      </ModalBody>
    </Modal>
  );
}
