import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { IoMdAdd, IoMdTrash } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { createStep2, getProject } from "../../../../Api/Admin/AllAPIs";
import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import "./projectStages.css";
import SevedModal from "../../../Common/SavedModal";
import { getUserName, parseHashedData } from "../../../../utils";

export const PROJECTSTAGES = ({ mode }) => {
  const [idStore, setIdStore] = useState();
  const navigate = useNavigate();
  const [skeletonLoader, setSkeletonLoader] = useState(mode === "Edit");
  const [isVersionHistory, setIsVersionHistory] = useState(true);
  const [submitType, setSubmitType] = useState("");
  const [cancelVisible, setCancelVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [backVisible, setBackVisible] = useState(false);
  let [btnLoader, setBtnLoader] = useState(false)
  const domain = window.location.origin;
  useEffect(() => {
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, []);

  useEffect(() => {
    if (idStore) {
      getProjectes();
    }
  }, [idStore]);

  const getProjectes = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getProject(idStore);
      const getData = res?.data?.data[0];
  
      if (getData && getData?.project_stages?.length !== 0) {
        setIsVersionHistory(true)
        const stages2 = getData.project_stages.map((stage) => ({
          stage_name: stage?.stage_name,
          expected_start_date: stage?.expected_start_date,
          expected_end_date: stage?.expected_end_date,
        }));
        reset({ project_stages: stages2 });
      } else {
        setIsVersionHistory(false)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      project_stages: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "project_stages"
  });

  const handleSaveAndContinue = () => {
    setSubmitType("saveAndContinue");
    setVisible(true);
  };

  const handleBack = () => {
    setSubmitType("back");
    setBackVisible(true); // Show confirmation dialog
  };

  const onSubmit = async (data) => {


    const userName = getUserName();
    try {
      let body = {
        id: idStore,
        project_stages: data.project_stages,
        isVersionHistory: isVersionHistory,
        user_name: userName,
        project_id: idStore
      };
      setBtnLoader(true);
      await createStep2({ ...body, updated_by: getUserName(), url: domain });
      setBtnLoader(false);
      setVisible(false)
      setVisible2(false)
      if (submitType === "saveAndContinue") {
        navigate(mode === "Edit" ? "/coretools/admin/projectform/step-6" : "/portfolio/projectform/step-6");
      } else if (submitType === "save") {
        // navigate("/projects");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="row pl-4 mt-5">
      <div className="col-12 px-3 mb-2">
        <span className="project_name_text">2. PROJECT STAGES</span>
      </div>

      <div className="project_stages_form_grid_con">
        <div></div>
        <div className="table_head_item">Stage Name</div>
        <div className="table_head_item">Start Date</div>
        <div className="table_head_item">End Date</div>
        <div></div>
      </div>
      <br />
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((item, index) => (
          <div className="project_stages_form_grid_con mb-4" key={item.id}>
            <div>{index + 1}</div>
            <div>
              <input
                className={`${errors?.project_stages?.[index]?.stage_name ? "TextInput Validation" : "TextInput"} form-control common_input`}
                {...register(`project_stages.${index}.stage_name`, { required: "Name is required" })}
                placeholder="Enter Stage Name"
                defaultValue={item.stage_name}
              />
            </div>
            <div>
              <input
                type="date"
                className={`${errors?.project_stages?.[index]?.expected_start_date ? "TextInput Validation" : "TextInput"} form-control common_input`}
                {...register(`project_stages.${index}.expected_start_date`, { required: "Start date is required" })}
                placeholder="Select Date"
                defaultValue={moment(item.expected_start_date).format("YYYY-MM-DD")}

              />
            </div>
            <div>
              <input
                type="date"
                className={`${errors?.project_stages?.[index]?.expected_end_date ? "TextInput Validation" : "TextInput"} form-control common_input`}
                {...register(`project_stages.${index}.expected_end_date`, { required: "End date is required" })}
                placeholder="Select Date"
                defaultValue={moment(item.expected_end_date).format("YYY-MM-DD")}

              />
            </div>
            <div>
              <IoMdTrash
                className="deleteIconColor"
                size={20}
                onClick={() => remove(index)}
              />
            </div>
          </div>
        ))}
        <span className="mt-4">
          <span onClick={() => append({ stage_name: "", expected_start_date: "", expected_end_date: "" })} className="add_icon_text">
            <IoMdAdd size={15} /> Add Stage
          </span>
        </span>
        <div className="controlButtons d-flex justify-content-between mt-3">
          <div className="d-flex gap-3">
            <PrimaryButton type="button" text="Cancel" onClick={() => setCancelVisible(true)} />
            <PrimaryButton
              text="Back"
              onClick={handleBack}
            />
            {/* <PrimaryButton type="button" className="skip filter_btn" onClick={handleBack} text="Back" /> */}
          </div>
          <div className="d-flex gap-3">
            <PrimaryButton text="Save" type="button" onClick={() => setVisible2(true)} />
            <PrimaryButton text="Save & Continue" type="button" onClick={handleSaveAndContinue} />
          </div>
        </div>
      </form>

      <SevedModal
        yes={() => navigate("/projects")}
        visible={cancelVisible}
        setVisible={setCancelVisible}
        heading='Confirm Cancellation'
        type='warning'
        headingText={`Are you sure you want to discard this data?`}
      />

      <SevedModal
        yes={() => {
          setBackVisible(false);
          navigate("/portfolio/projectform/step-4");
        }}
        visible={backVisible}
        setVisible={setBackVisible}
        heading='Go Back to Previous Step'
        type='warning'
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}
      />

      <SevedModal
        yes={() => {
          setVisible(false);
          handleSubmit(onSubmit)();
        }}
        visible={visible}
        setVisible={setVisible}
        heading='Proceed to Next Step'
        type='info'
        loader={btnLoader}
        headingText={`Are you sure you want to save your progress and continue?`}
      />
      <SevedModal
        yes={() => {
          setVisible(false);
          setSubmitType("save")
          handleSubmit(onSubmit)();
        }}
        loader={btnLoader}
        visible={visible2}
        setVisible={setVisible2}
        heading='Create the Project'
        type='info'
        headingText={`Are you sure want to save your Data?`}
      />
    </div>
  );
};
