import { useNavigate } from "react-router-dom"
import SingleSelect from "../../../../Components/Common/SingleSelect"
import TextInput from "../../../../Components/Inputs/TextInput"
import { Editor } from "primereact/editor"
import { DragAndDrop } from "../../../../Components/Inputs/DragAndDrop"
import ReactQuill from "react-quill"

export const CreateBudget = ({ reqFor }) => {

  const navigate = useNavigate()
  return (
    <>
      <div className=" main-container">
        <div>
          <div className=" m-0">
            <div className="col-md-12 py-3">
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/financialManagement/budget")}
              >
                financialManagement/budget
              </span>
              <span className="px-1 open_Rfi_text">/</span>
              <span className="createNew_text"> {reqFor === "edit" ? "Edit" : "Add"} Budget</span>
            </div>
            <div className="col-md-12 ">
              <span className="newRfi_text">  {reqFor === "edit" ? "Edit" : "Add"} BUDGET LINE ITEM </span>
            </div>

            <div className="mt-3 row">
              <div className="col-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Cost Division:
                  <span className="text-danger">*</span>
                </label>
                <SingleSelect

                  placeholder={"  Enter Cost Division"}
                  option={["add", "add"]}

                />
              </div>
              <div className="col-6 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Cost Code:
                  <span className="text-danger">*</span>
                </label>
                <SingleSelect

                  placeholder={"  Enter Cost Code"}
                  option={["add", "add"]}

                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Category:
                  <span className="text-danger">*</span>
                </label>
                <SingleSelect

                  placeholder={"  Enter  Category"}
                  option={["add", "add"]}

                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Calculation Method:
                  <span className="text-danger">*</span>
                </label>
                <SingleSelect

                  placeholder={"  Enter Calculation Method"}
                  option={["add", "add"]}

                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Unit Quantity:
                  <span className="text-danger">*</span>
                </label>
                <TextInput placeholder={"Enter Unit Quantity"} />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Unit of Measurement:
                  <span className="text-danger">*</span>
                </label>
                <SingleSelect

                  placeholder={"  Enter Unit of Measurement"}
                  option={["add", "add"]}

                />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Unit Cost:
                  <span className="text-danger">*</span>
                </label>
                <TextInput placeholder={" Enter Unit Cost"} />

              </div>
              <div className="col-6 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Original Budget Amount:
                  <span className="text-danger">*</span>
                </label>
                <TextInput placeholder={" Enter Original Budget Amount"} />
              </div>

              <div className="col-md-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Budget Line Description:
                </label>
                <div className="card">

                  {/* <Editor
                      value={""}
                      onTextChange={""}
                      style={{ height: "200px" }}
                    /> */}

                  <ReactQuill theme="snow"
                    value={""}
                    onChange={() => { }}
                  />

                </div>
              </div>

              <div className="col-md-12 mt-3">
                <DragAndDrop

                />
              </div>

              <div className="btn_main my-5 ">
                <span
                  className="cancelBtn  px-2 py-2"
                  onClick={() => navigate("/financialManagement/budget")}
                >
                  {" "}
                  Cancel
                </span>

                <span className="CreateRfi px-2 py-2" >
                  Create Budget
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}