import React, { useEffect, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TableSkelton } from "../../Components/Common/TableSkelton";

const ViewProjectTools = ({ toolData,hidePagination }) => {
  const [projectToolData, setProjectToolData] = useState([]);
  useEffect(() => {
    if (toolData) {
      setProjectToolData(toolData);
    }
  }, [toolData]);
  return (
    <>
      {toolData ? (
        <div style={{width:'100%',overflow:'auto'}}>
          {hidePagination?<DataTable
            value={projectToolData}
            // paginator
            // rows={5}
            // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column
              field="#"
              header=""
              style={{ Width: "12%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  {meta?.rowIndex + 1}
                </div>
              )}
            />
            <Column
              field="#"
              header=""
              style={{ Width: "12%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  <img src={rowData.img} />
                </div>
              )}
            />
            <Column
              field="project_name"
              header="Name"
              style={{ width: "30%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 align-items-center">
                  <p className="m-0">{rowData.name}</p>
                </div>
              )}
            ></Column>
            <Column
              field="createdOn"
              header="Description"
              body={(rowData, meta) => <>{rowData.description}</>}
              style={{ width: "48%" }}
            ></Column>
          </DataTable>:<DataTable
            value={projectToolData}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column
              field="#"
              header=""
              style={{ Width: "12%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  {meta?.rowIndex + 1}
                </div>
              )}
            />
            <Column
              field="#"
              header=""
              style={{ Width: "12%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  <img src={rowData.img} />
                </div>
              )}
            />
            <Column
              field="project_name"
              header="Name"
              style={{ width: "30%" }}
              body={(rowData, meta) => (
                <div className="d-flex gap-2 align-items-center">
                  <p className="m-0">{rowData.name}</p>
                </div>
              )}
            ></Column>
            <Column
              field="createdOn"
              header="Description"
              body={(rowData, meta) => <>{rowData.description}</>}
              style={{ width: "48%" }}
            ></Column>
          </DataTable>}
          
        </div>
      ) : (
        <TableSkelton header={["", "", "Name", "Description"]} />
      )}
    </>
  );
};

export default ViewProjectTools;
