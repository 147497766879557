import React from 'react'
import AddUserInfo from '../ProjectDirectories/AddUserInfo'

export const CreateUser = () => {

    let formType = "Create"

    return (
        <AddUserInfo formType={formType} />
    )
}
