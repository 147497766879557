

import { Box, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { addRole } from '../../Api/Admin/AllAPIs'
import { ProgressSpinner } from 'primereact/progressspinner'
import { toast } from '../../utils'

export const CreateRole = ({refresh}) => {

    let navigate = useNavigate()
    const [role, setRole] = useState("")
    const [visible, setVisible] = useState(false)
    const [loader, setLoader] = useState(false)

    async function createRole(e) {
        e.preventDefault()
        setLoader(true)
        try {
           
            let res=await addRole({role})
            refresh()
            setVisible(false)
           
        }
        catch (err) {
            toast.error(err?.response?.data?.message || "Some Error occured while creating role")
         
        }
        finally {
            setLoader(false)
        }
    }


    return (
        <div>
            <PrimaryButton addIcon text={"Create Role"} onClick={() => setVisible(true)} />
            <Dialog open={visible}>
                <Box p={3}>
                    <form onSubmit={createRole}>

                        <label className='mb-2'>Role Name</label>
                        <input required className='form-control' onChange={(e) => setRole(e.target.value)} type='text' />
                        <br />
                        <div className="d-flex justify-content-end gap-3 ">
                            <button type='button' className='border btn' onClick={() => setVisible(false)} >Cancel</button>
                            <PrimaryButton
                            style={{width:"90px"}}
                          className={"d-flex justify-content-center"}
                            text={loader?<ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8"/>:"Save"}
                            
                            disabled={loader} />
                        </div>
                    </form>
                </Box>
            </Dialog>

        </div>
    )
}
