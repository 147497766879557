import React, { useEffect, useState } from "react";
import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";

import { useNavigate, useParams } from "react-router-dom";

import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { FaAngleRight } from "react-icons/fa";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";

import {
  addPrioritytype,
  addTypeT,
  getIncidentById,
  getPrioritytype,
  getTypeT,
  getUserByProjectId,
  updateIncident,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import {
  getActionsPermission,
  getProjectName,
  getUserId,
  getUserName,
  toast,
} from "../../utils";
import { Editor } from "primereact/editor";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { useSelector } from "react-redux";
import SingleSelect from "../../Components/Common/SingleSelect";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import { MdAdd } from "react-icons/md";
import AddSets from "../../Components/Common/AddSets";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import { FormHeader } from "../../Components/Common/FormHeader";
import ReactQuill from "react-quill";
import { MultiSelect } from "primereact/multiselect";

export const EditIncident = () => {
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [incidentTime, setIncidentTime] = useState(null);
  const [attachFile, setAttachFile] = useState([]);
  const [timeUnknown, setTimeUnknown] = useState(false);
  const [status, setStatus] = useState();
  const [description, setDescription] = useState();
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [validationTitle, setValidationTitle] = useState(false);
  const [validationLocation, setValidationLocation] = useState(false);
  const [validationHazard, setValidationHazard] = useState(false);
  const [validationEventDate, setValidationEventDate] = useState(false);
  const [validationEventTime, setValidationEventTime] = useState(false);
  const [validationStatus, setValidationStatus] = useState(false);
  const [validationDueDate, setValidationDueDate] = useState(false);
  const [validationIncidentType, setValidationIncidentType] = useState(false);
  const [validationIncidentID, setValidationIncidentID] = useState(false);
  const [validationPriority, setValidationPriority] = useState(false);
  const [validationUser, setValidationUser] = useState(false);
  const [validationIncidentTime, setValidationIncidentTime] = useState(false);
  const [validationIncidentDate, setValidationIncidentDate] = useState(false);
  const projectId = localStorage.getItem("projectId");


  
  const userName = getUserName();
  const [allData, setAllData] = useState({});
  const [oldincident_review_status, setOldincident_review_status] =
    useState("");

  const isApproverAccess = getActionsPermission("Specification Approver");
  const isReviewerAccess = getActionsPermission("Specification Reviewer");
  const [approver, setApprover] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({});
  let set = useSelector((d) => d.rolesAndPermissionData);

  const [submitted, setSubmitted] = useState(false);
  const [options, setOptions] = useState([]);

  const [incidentID, setIncidentID] = useState("");
  const [incidentType, setIncidentType] = useState();
  const [types, setTypes] = useState([]);
  const [addType, setAddType] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [user, setUser] = useState("");
  const [priority, setPriority] = useState("");
  const [priorities, setPriorities] = useState([]);
  const [addPriority, setAddPriority] = useState("");
  const [checked, setChecked] = useState(false);
  const [closeDate, setCloseDate] = useState(new Date());
  const [notifyChecked, setNotifyChecked] = useState(false);
  const [reviewerComment, setReviewerComment] = useState("");
  const [approverComment, setApproverComment] = useState("");
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const skeletonData = [
    { qty: 1 },
    { qty: 1 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "15rem" },
    { qty: 1 },
    { qty: 1, height: "7rem" },
  ];

  const { id } = useParams();
  let navigate = useNavigate();

  const domain = window.location.origin;
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleFileSubmit = (event) => {
    setAttachFile(event);
  };
  const checkValidation = () => {
    if (!title.trim()) {
      setValidationTitle(true);
    }
    if (!location.trim()) {
      setValidationLocation(true);
    }
    if (!incidentDate) {
      setValidationIncidentDate(true);
    }
    if (!incidentTime) {
      setValidationIncidentTime(true);
    }
    if (!incidentType) {
      setValidationIncidentType(true);
    }
    if (!incidentID) {
      setValidationIncidentID(true);
    }
    if (
      incidentID === "" ||
      title.trim() === "" ||
      location.trim() === "" ||
      !incidentDate ||
      incidentTime === "" ||
      status === "" ||
      !incidentType ||
      !user ||
      !priority
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  
  const handleGetIncident = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getIncidentById(id);
      const incident = response?.data?.data?.[0];
      // const imgUlr=incident.attachFile.map((res)=>res.fileName)
    
      let approverData = incident?.approver?.[0] || {};
      let reviewerData = incident?.reviewer?.[0] || {};

      setOldApproverReviewwerData({
        approver: approverData?._id || null,
        reviewer: reviewerData?._id || null,
      });

      setApprover(approverData._id);
      setReviewer(reviewerData._id);
      setApproverComment(incident?.approver_comment);
      setReviewerComment(incident?.reviewer_comment);
      setOldincident_review_status(incident?.incident_review_status);
      setAllData(incident);
      setCloseDate(
        moment(incident?.incident_closeDate).format("DD MMM YYYY  hh:mm A")
      );
      setTitle(incident?.title);
      setIncidentID(incident?.incidentID);
      setLocation(incident?.location);
      setPriority(incident.priority[0]._id);
      setIncidentType(incident.incident_type[0]._id);
      setUser(incident?.incident_identifiedBy[0]._id);
      setIncidentDate(incident?.incident_date);
      setIncidentTime(incident?.incident_time);
      setChecked(incident?.incident_closeDate ? true : false);
      setNotifyChecked(incident?.notification ? true : false);
   

      setFileName(
        incident.attachFile.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );
      setPreviousFiles([...incident.attachFile]);
      setDescription(incident?.description);
      setStatus(incident?.status);

      let status = incident.status;

      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", status),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", status),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", status),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", status),
        },
      ]);

      setTimeUnknown(false);
    } catch (error) {
      console.log("Error fetching incident:", error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }


  const handleUpdateIncident = async () => {
    if (attachFile.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }
    if (checkValidation()) {
      return;
    }
    let uploadedFiles = [];

    if (attachFile && attachFile.length > 0) {
      const formData = new FormData();
      for (const file of attachFile) {
        formData.append("upload", file);
      }

      const uploadResponse = await uploadDocs(formData);
    
      uploadedFiles = uploadResponse.data.map((item) => ({
        fileName: item.fileName,
        url: item.fileUrl,
      }));
    }
  

    let reviewStatus =
      oldincident_review_status === "pending"
        ? status?.toLowerCase() === "close"
          ? "under_review"
          : "pending"
        : oldincident_review_status;

    let email_data = [];

    if (reviewer) {
      let reviewerData = allUsersOfProject.find((e) => e._id == reviewer);
      email_data.push({
        name: reviewerData?.first_name,
        to: reviewerData?.email_address,
        tool_number: incidentID,
        tool: "Incident",
        tool_name: title,
        project_name: getProjectName(),
        project_id: "8767",
        template_type: "createdToolEmailToReviewer",
        url: `${domain}/coretools/tasktable`,
        created_by: getUserName(),
      });
    }
    // send email to reviewer if selected
    if (approver) {
      let approverData = allUsersOfProject.find((e) => e._id == approver);
      email_data.push({
        name: approverData?.first_name,
        to: approverData?.email_address,
        tool_number: incidentID,
        tool: "Incident",
        tool_name: title,
        project_name: getProjectName(),
        project_id: "8767",
        template_type: "createdToolEmailToApprover",
        url: `${domain}/coretools/tasktable`,
        created_by: getUserName(),
      });
    }

    //   user?.forEach((userId) => {
    //     let currentUser = allUsersOfProject.find((e) => e._id == userId)
    //     if (currentUser) {
    //         email_data.push({
    //             name: currentUser?.first_name,
    //             to: currentUser?.email_address,
    //             tool_number: incidentID,
    //             tool: "Incident",
    //             tool_name: title,
    //             project_name: getProjectName(),
    //             project_id: "8767",
    //             template_type: "createdToolEmailToAssociatedUsers",
    //             userType: "Incident identified by",
    //             url: `${domain}/coretools/tasktable`,
    //             created_by: getUserName()
    //         })
    //     }
    // })

    const payload = {
      title: title,
      incidentID: incidentID,
      location: location,
      priority: priority,
      incident_closeDate: checked === true ? new Date() : undefined,
      incident_time: incidentTime,
      incident_date: moment(incidentDate).format("YYYY/MM/DD "),
      status: reviewer !== null && approver !== null ? "inreview" : "draft",
      description: description,
      project_id: projectId,
      attachFile: [...previousFiles, ...uploadedFiles],
      approver_id: approver ? approver : null,
      reviewer_id: reviewer ? reviewer : null,
      incident_identifiedBy: user,
      incident_type: incidentType,
      email_data: email_data,
      notification: notifyChecked,
      created_by: userName,
      project_name: projectData.project_name,
      project_number: projectData.projectNumber,
      user_name: getUserName()
    };
   

    try {
      let t = await updateIncident(id, payload);
  

      toast.success("Incident Updated Successfully!");
      setSubmitted(true);
      navigate("/projectManagement/incident");
    } catch (error) {
      if (error?.response?.data?.message === "Incident number already exists") {
        toast.error(error?.response?.data?.message);
       
      } else {
        console.error("Error updating incident:", error);
        toast.error("Something Wents Wrong, Please Try Again!");
      }
    }
  };

  useEffect(() => {
    handleGetIncident();
  }, []);

  const statusCheck = [{ name: "Open" }, { name: "Close" }];

  const handleCancel = () => {
    navigate("/projectManagement/incident");
  };

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
   
    } catch (err) {
      console.log(err);
    }
  }

  const getIncidentPriority = async (projectId) => {
    try {
      const res = await getPrioritytype(projectId);
      const typee = res?.data?.data;
      const capitalizedPriority = typee.map((priority) => ({
        ...priority,
        name: capitalizeFirstLetter(priority.name),
      }));
      setPriorities(capitalizedPriority);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIncidentPriority(projectId);
  }, []);

  // const submitIncidentPriority = async () => {

  //   try {
  //     const payload = {
  //       name: addPriority,
  //       project_id: projectId
  //     }
  //     await addPrioritytype(payload)
  //     setAddPriority('')
  //     getIncidentPriority(projectId)
  //     setVisible2(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getIncidentType = async (projectId) => {
    try {
      const res = await getTypeT(projectId);
      const typee = res?.data?.data;
      const capitalizedtype = typee.map((type) => ({
        ...type,
        name: capitalizeFirstLetter(type.name),
      }));
      setTypes(capitalizedtype);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIncidentType(projectId);
  }, []);

  // const submitIncidentType = async () => {

  //   try {
  //     const payload = {
  //       name: addType,
  //       project_id: projectId
  //     }
  //     await addTypeT(payload)
  //     setAddType('')
  //     getIncidentType(projectId)
  //     setVisible2(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  useEffect(() => {
    getAllUsersOfProject();
  }, []);

  const handleCreateAddIcon = async (typ) => {
    try {
      if (typ === "type" && !addType) {
        toast.info(
          "Please fill  the  Submittal Category field marked with * are mandatory"
        );
        return;
      }

      if (typ === "priority" && !addPriority) {
        toast.info(
          "Please fill  the  Submittal Package field marked with * are mandatory"
        );
        return;
      }

      if (typ === "type") {
        setLoading(true);
        await addTypeT({
          name: addType,
          project_id: projectId,
        });
        setAddType("");
        getIncidentType(projectId);
        setVisible(false);
        setLoading(false);
      } else if (typ === "priority") {
        setLoading(true);
        await addPrioritytype({
          name: addPriority,
          project_id: projectId,
        });
        setAddPriority("");
        setVisible2(false);
        getIncidentPriority(projectId);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" incident">
      <div className="show-data ">


        <FormHeader
          to={"/projectManagement/incident"}
          formHeader={"Incident"}
          breadcrumItem={["Incident", `Edit Incident`]} />


        {skeletonLoader ? (
          <FormSkeleton data={skeletonData} />
        ) : (
          <>
            <div className="row m-0 p-0">
              <div className="col-md-6 col-12 ">
                <span className=" labelCss"> Incident Title:</span>{" "}
                <span className="text-danger">*</span>
                <TextInput
                  className={`my-2 ${validationTitle ? "Validation" : ""}`}
                  onClick={() => setValidationTitle(false)}
                  placeholder="Title Required"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  
                  }}
                />
              </div>

              <div className="col-md-6 col-12 ">
                <span className=" labelCss"> Incident ID:</span>{" "}
                <span className="text-danger">*</span>
                <TextInput
                  //  className="my-2"
                  className={`my-2 ${validationIncidentID ? "Validation" : ""}`}
                  onClick={() => setValidationIncidentID(false)}
                  placeholder="Incident ID"
                  value={incidentID}
                  onChange={(e) => setIncidentID(e.target.value)}
                />
              </div>
              {/* <div className="col-md-6 col-12 mt-1">
                  <span className=" py-2 labelCss">Status:</span>{" "}
                  <span className="text-danger">*</span>
                  <Dropdown
                    value={status}
                    onChange={(e) => setStatus(e.value)}
                    options={options}
                    placeholder="Select Status"
                    className={`mt-1 w-100 ${validationStatus ? "Validation" : ""}`}
                    onClick={() => setValidationStatus(false)}
                    disabled={
                      allData.incident_review_status == "approved" ||
                        allData.incident_approval_status == "approved"
                        ? true
                        : false
                    }
                  />
                </div> */}
            </div>
            <div className="row m-0 p-0 mt-1 ">
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <div className="card flex justify-content-center  ">
                    <label htmlFor="" className="py-2 labelCss">
                      Incident Identified By:{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject.map((item) => ({
                        label: item?.first_name,
                        value: item?._id,
                      }))}
                      value={user}
                      optionLabel="trade_name"
                      onChange={(value) => {
                        setUser(value);
                        setValidationUser(false);
                      }}
                      className={`mt-1 ${validationUser ? "Validation" : ""}`}
                      onClick={() => setValidationUser(false)}
                      placeholder="Select Incident Identified By"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <div className="card flex justify-content-center  ">
                    <label htmlFor="" className="py-2 labelCss">
                      Priority: <span className="text-danger">*</span>
                    </label>
                    <AutoCompleteSelect
                      options={priorities.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={priority}
                      optionLabel="priority_name"
                      onChange={(value) => {
                        setPriority(value);
                        setValidationPriority(false);
                      }}
                      className={`mt-1 ${validationPriority ? "Validation" : ""
                        }`}
                      onClick={() => setValidationPriority(false)}
                      placeholder="Select Priority"
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible2(true)}
                  />
                </div>
              </div>
            </div>

            <div className="row m-0 p-0 mt-3 ">
              <div className="col-md-6 col-12">
                <span className=" py-2 labelCss">Location:</span>{" "}
                <span className="text-danger">*</span>
                {/* <SingleSelect options={[]} label={"Location:"} placeholder={"Location"} /> */}
                <TextInput
                  placeholder="Enter Location"
                  style={{}}
                  className={`mt-1 ${validationLocation ? "Validation" : ""}`}
                  onClick={() => setValidationLocation(false)}
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                ></TextInput>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-end">
                <div className="w-100">
                  <label htmlFor="" className=" labelCss">
                    Incident Type: <span className="text-danger">*</span>
                  </label>
                  <div className="card flex justify-content-center  ">
                    <AutoCompleteSelect
                      options={types.map((item) => ({
                        label: item?.name,
                        value: item?._id,
                      }))}
                      value={incidentType}
                      optionLabel="incident_type"
                      onChange={(value) => {
                        setIncidentType(value);
                        setValidationIncidentType(false);
                      }}
                      className={`mt-1 ${validationIncidentType ? "Validation" : ""
                        }`}
                      onClick={() => setValidationIncidentType(false)}
                      placeholder="Select Incident Type"
                    />
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-end ">
                  <MdAdd
                    className="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />
                </div>
              </div>
            </div>

            <div className="row m-0 p-0 mt-3 ">
              <div className="col-md-6 col-12">
                <span className=" py-2 labelCss">Incident Date:</span>{" "}
                <span className="text-danger">*</span>
                <input
                  type="date"
                  min={new Date().toISOString().split("T")[0]} 
                  style={{ height: "50px" }}
                  selected={incidentDate}
                  value={formatDate(incidentDate)}
                  className={` mt-1 ${validationIncidentDate ? "Validation" : ""
                    } form-control`}
                  onClick={() => setValidationIncidentDate(false)}
                  placeholder="Incident Date"
                  onChange={(e) => setIncidentDate(e.target.value)}
                />
              </div>

              <div className="col-md-6 col-12">
                <span className="labelCss">Incident Time:</span>{" "}
                <span className="text-danger">*</span>
                <input
                  type="time"
                  style={{ height: "50px" }}
                  className={`my-2 ${validationIncidentTime ? "Validation" : ""
                    } form-control`}
                  onClick={() => setValidationIncidentTime(false)}
                  value={incidentTime}
                  placeholder=" Incident Time"
                  onChange={(e) => setIncidentTime(e.target.value)}
                  // disabled={timeUnknown}
                  timeOnly
                />
              </div>
            </div>

            <div className="row m-0 p-0">
              {isReviewerAccess && (
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    value={reviewer}
                    optionLabel="label"
                    onChange={(value) => {
                      setReviewer(value);
                    }}
                    className={`p-0 `}
                    // disabled={
                    //   reviewer === getUserId() || allData?.status == "approved"
                    // }
                    placeholder="Select Reviewer"
                  />
                </div>
              )}

              {isApproverAccess && (
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver:
                  </label>
                  <AutoCompleteSelect
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    value={approver}
                    optionLabel="label"
                    onChange={(value) => {
                      setApprover(value);
                    }}
                    className={`p-0 `}
                    placeholder="Select Approver"
                  // disabled={approver === getUserId()}
                  />
                </div>
              )}
            </div>
            <div className="row m-0 p-0">
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Reviewer Comment:
                </label>
                <TextInput
                  placeholder=" Reviewer Comment"
                  value={reviewerComment}
                  onChange={(e) => setReviewerComment(e.target.value)}
                  disabled
                />
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Approver Comment:
                </label>
                <TextInput
                  placeholder=" Approver Comment"
                  value={approverComment}
                  onChange={(e) => setApproverComment(e.target.value)}
                  disabled
                />
              </div>

              <div className="col-md-6 col-12 mt-2">
                <label htmlFor="" className="py-2 labelCss">
                  Select Sub Tasks:
                </label>

                <MultiSelect
                  filter={true}
                  value={[]}
                  placeholder="Select Sub Tasks"
                  onChange={(e) => {
                    console.log(e)
                  }}
                  optionLabel="first_name"
                  options={[]}
                  className={`w-100`}
                />

              </div>



            </div>

            <div className="row m-0 p-0 mt-3 ">
              <div className="col-12">
                <span className=" py-2 labelCss">Description:</span>
                {/* <Editor
                  value={description}
                  onTextChange={(e) => setDescription(e.htmlValue)}
                  style={{ height: "200px" }}
                  className="mt-1"
                /> */}

                <ReactQuill theme="snow"
                  value={description}
                  onChange={setDescription}
                />

              </div>
            </div>

            <div className="row m-0 p-0 mt-2">
              <div className="col-md-3 d-flex align-items-center gap-2">
                <label htmlFor="" className="py-2 labelCss">
                  Incident Closed:
                </label>
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
                {checked === true ? (
                  <div className="col-md-12">
                    {" "}
                    <input
                      className="form-control"
                      style={{ height: "40px" }}
                      placeholder=""
                      value={moment(closeDate).format("DD MM YYYY  hh:mm A")}
                      onChange={(e) => setCloseDate(e.target.value)}
                      disabled
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row m-0 p-0 mt-2">
              <div className="col-md-6 col-12 d-flex align-items-center gap-2">
                <label htmlFor="" className="py-2 labelCss">
                  Notify Project Team:
                </label>
                <Checkbox
                  onChange={(e) => setNotifyChecked(e.checked)}
                  checked={notifyChecked}
                ></Checkbox>
              </div>
            </div>

            {/* {!timeUnknown && (
                <div className="row m-0 p-0 mt-3">
                  <div className="col-12">
                    <span className=" labelCss">Incident Time:</span>{" "}
                    <span className="text-danger">*</span>
                     <input
                     type="date"
                     style={{height : "50px"}}
                      className={`my-2 ${validationEventTime ? "Validation" : ""} form-control`}
                      onClick={() => setValidationEventTime(false)}
                      selected={eventTime}
                      value={formatDate(eventTime)}
                      placeholder="Event Time"
                      onChange={(e) => setEventTime(e.value)} // Update eventTime state with the selected time
                      disabled={timeUnknown}
                      timeOnly
                    />
                  </div>
                </div>
              )} */}

            <div className="row m-0 p-0 mt-4">
              <div className="col-12">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={setAttachFile}
                  onChange={handleFileSubmit}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFiles}
                  label={"Incident Attachments"}
                />
              </div>
            </div>
          </>
        )}

        <div className="row m-0 p-0">
          <div className="d-flex justify-content-end mt-3">
            <button
              onClick={handleCancel}
              className="btn border me-3"
            // onClick={() => navigate("/incident")}
            >
              Cancel
            </button>
            <PrimaryButton text={"Update"} onClick={handleUpdateIncident} />
          </div>
        </div>
      </div>
      <br />

      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Incident Type"}
        label={"Incident Type"}
        reqFor={"create"}
        value={addType}
        setValue={setAddType}
        onSubmit={() => handleCreateAddIcon("type")}
      />
      <AddSets
        loading={loading}
        setLoading={setLoading}
        visible={visible2}
        setVisible={setVisible2}
        CategoryName={"Priority"}
        label={"Priority"}
        reqFor={"create"}
        value={addPriority}
        setValue={setAddPriority}
        onSubmit={() => handleCreateAddIcon("priority")}
      />
    </div>
  );
};
