import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoMdAdd } from 'react-icons/io'
import TextInput from '../../../../Components/Inputs/TextInput'
import { Dropdown } from 'primereact/dropdown'
import { Editor } from 'primereact/editor'
import { PrimaryButton } from '../../../../Components/Buttons/PrimaryButton'
import { getProjectId } from '../../../../utils'
import { getCodeCost } from '../../../../Api/Admin/AllAPIs'
import ReactQuill from 'react-quill'

export const AddItemLine = ({ onSubmit }) => {
    const [visible, setVisible] = useState(false)

    const { control, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            budget_parent_code: "",
            budget_parent_name: "",
            budget_child_code: "",
            budget_child_name: "",
            tax_code: "",
            quantity: "",
            unity_measure: "",
            unit_cost: "",
            amount: "",
            costLine_description: ""
        }
    });

    let [costDivision, setCostDivision] = useState([]);
    let [costCode, setCostCode] = useState([]);
    const budget_parent_code = watch("budget_parent_code")


    async function fetchCostCodes() {
        try {
            let res = await getCodeCost(getProjectId())
          

            setCostDivision(res?.data?.data || [])
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (visible) {
            fetchCostCodes()
        }
    }, [visible])


    useEffect(() => {
        if (budget_parent_code) {
            setValue("budget_child_code", "")
            setCostCode(costDivision?.find(d => d.code === budget_parent_code)?.children || [])
        }
    }, [budget_parent_code])


    function formSubmit(data) {
        data.budget_child_name = costDivision
            ?.find(d => d?.code === data?.budget_parent_code)?.children
            ?.find(c => c?.code === data?.budget_child_code)?.description
        data.budget_parent_name = costDivision?.find(d => d?.code === data?.budget_parent_code)?.description

        onSubmit(data)
        reset()
        setVisible(false)
    }


    return (
        <div>
            <span className="mt-4 " onClick={() => setVisible(true)}>
                <span className="add_icon_text" > <IoMdAdd size={15} /> Add Line Item </span>
            </span>
            <Dialog
                style={{ width: "60%" }}
                visible={visible}
                closable={false}
                draggable={false}
                onHide={() => setVisible(false)}
                header="Add Cost Line Item"
            >

                <form onSubmit={(event) => {
                    event.stopPropagation();
                    handleSubmit(formSubmit)(event);
                }}>
                    <div className="row">
                        <div className="col-6 mt-3">
                            <label>Cost Division:</label>
                            <Controller
                                name="budget_parent_code"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            className={`w-100 ${errors?.budget_parent_code && "Validation"}`}
                                            placeholder='Type'
                                            options={costDivision.map((dd) => ({ label: dd.description, value: dd.code }))}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Cost Code:</label>
                            <Controller
                                name="budget_child_code"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            className={`w-100 ${errors?.budget_child_code && "Validation"}`}

                                            placeholder='Type'
                                            options={costCode.map((dd) => ({ label: dd.description, value: dd.code }))}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Tax Code:</label>
                            <Controller
                                name="tax_code"

                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            className={`w-100 ${errors?.tax_code && "Validation"}`}
                                            placeholder='Type'
                                            options={[]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Quantity:</label>
                            <Controller
                                name="quantity"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={`w-100 ${errors?.quantity && "Validation"}`}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Quantity"}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Unit of Measure (UOM):</label>
                            <Controller
                                name="unity_measure"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            value={value}
                                            className={`w-100 ${errors?.unity_measure && "Validation"}`}
                                            onChange={onChange}
                                            placeholder={"UOM"}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Unit Cost:</label>
                            <Controller
                                name="unit_cost"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={`w-100 ${errors?.unit_cost && "Validation"}`}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Unit Cost"}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <label>Amount:</label>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={`w-100 ${errors?.amount && "Validation"}`}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Amount"}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            <label>Description:</label>
                            <Controller
                                name="costLine_description"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        // <Editor
                                        //     className={`w-100 ${errors?.costLine_description && "Validation"}`}
                                        //     style={{ minHeight: "100px" }}
                                        //     value={value}
                                        //     onTextChange={(e) => onChange(e.htmlValue)}
                                        // />
                                        <ReactQuill theme="snow"
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </div>
                    </div>

                    <div className="end mt-3 gap-2">
                        <button className='btn border' onClick={() => setVisible(false)} type='button' >Cancel</button>
                        <PrimaryButton addIcon type="submit" text={"Add Line Item"} />
                    </div>
                </form>


            </Dialog>


        </div>
    )
}
