import React, { useEffect, useState } from "react";
import "./SignIn.css";
import { toast } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { googleLogin, resetUserPassword } from "../../Api/Admin/AllAPIs";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { CRYPTO_HASH_KEY, GOOGLE_SIGNIN_KEY } from "../../constants";
import CryptoJS from "crypto-js";

function ResetPassword() {
    let token = new URLSearchParams(window.location.search).get("token");
    let userId = new URLSearchParams(window.location.search).get("userId");
    let email = new URLSearchParams(window.location.search).get("email");
    let name = new URLSearchParams(window.location.search).get("name");

    let navigate = useNavigate();

    useEffect(() => {
        if (!token || !userId || !email) {
            navigate("/")
        }
    }, [])


    const [formData, setformData] = useState({
        password: "",
        confirmPassword: ""
    })

    async function handleSubmit(e) {
        e.preventDefault();

        const { password, confirmPassword } = formData;

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(password)) {
            toast.error("Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character");
            return;
        }

        let body = {
            token,
            password: formData.password
        }
        try {
            let res = await resetUserPassword(userId, body)
        
            navigate("/")
            toast.success(res?.data?.message)
        }
        catch (err) {
            toast.error(err?.response?.data?.message)
            // console.log(err?.response?.data?.message)

        }
    }
    const switchRole = (userType, data) => {
        localStorage.setItem("isLoggedIn", "true");
        // localStorage.setItem("userData", JSON.stringify(data))
       const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), CRYPTO_HASH_KEY).toString();
            
         localStorage.setItem("hashUserData", encryptedData)

        switch (userType) {
            case "admin":
                navigate("/mainOverview");
                break;
            case "user":
                navigate("/projects");
                localStorage.setItem("projectId", data?.project_id);
                break;
            case "individual":
                navigate("/projects");
                localStorage.setItem("projectId", data?.project_id);
                break;
            default:
                break;
        }
    };

    const responseGoogle = (response) => {
        var decoded = jwtDecode(response.credential);
      
        const token = {
            tokenId: response.credential,
            id: decoded.sub,
            email: decoded.email,
            user_id: userId
        }
       
        // setLoader(true)
        googleLogin(token).then((res) => {
          
            let user_type = res.data.data.user_type;
            let userData = res.data.data;
            if (userData && user_type) {
                switchRole(user_type, userData)
            }

            else {
                toast.error("User not found")
            }

        }).catch((err) => {
          
            toast.error(err?.response?.data?.data || "User not found")
            //   setLoader(false)
        })
    };

    const onFailure = (res) => {
     
    };


    return (
        <>
            <div className="maincontainer">
                <div className="left-contain">
                    <div className="logoFixed">
                        <span className="eLogo d-flex align-items-center">
                            <img src="/images/cdc_logo.png" className="me-2" alt="" />
                            CDC
                        </span>
                    </div>
                </div>
                <div className="right-contain bg-white">
                    <div className="right-content-div ">
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="d-flex justify-content-center ">
                                <span className="logIn">Set Password</span>
                            </div>
                            <div className="mt-2 name_text  text-center">
                                <span className="reset_user_name">Hello {name}, </span>
                                <span> Please reset your password</span>
                            </div>
                            <div className="mt-3">
                                <label className="HelveticaMedium mb-2">Email</label>
                                <input
                                    placeholder="Email"

                                    value={email}
                                    disabled
                                    className="form-control"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="mt-3">
                                <label className="HelveticaMedium mb-2">Password</label>
                                <input
                                    placeholder="Enter Password"
                                    id="loginEmail"
                                    className="form-control"
                                    type="text"
                                    value={formData.password}
                                    onChange={(e) => {
                                        setformData({
                                            ...formData,
                                            password: e.target.value
                                        })
                                    }}
                                    name="password"
                                    required
                                />
                            </div>
                            <div className="form-password mt-4">
                                <label className="HelveticaMedium mb-2">Confirm Password</label>
                                <input
                                    placeholder="Confirm Password"
                                    className="form-control"
                                    value={formData.confirmPassword}
                                    onChange={(e) => {
                                        setformData({
                                            ...formData,
                                            confirmPassword: e.target.value
                                        })
                                    }}
                                    name="confirmpassword"
                                    required
                                />

                            </div>

                            <div className="d-flex justify-content-end mt-4">

                            </div>
                            <div className="mt-4">
                                <button
                                    className="continueButton w-100"
                                    type="submit"
                                    id="loginsubmit"
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                        <div className="mt-2 mb-3 pointer w-100 text-center" onClick={()=>navigate("/")}>
                            Back to Sign In
                        </div>
                        <div className="mt-2">
                            <GoogleOAuthProvider clientId={GOOGLE_SIGNIN_KEY}>
                                <div className="w-100">
                                    <GoogleLogin
                                        buttonText="Login with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={onFailure}
                                        cookiePolicy={"single_host_origin"}
                                        style={{
                                            marginTop: "100px",
                                            boxShadow: "none !important",
                                        }}
                                        isSignedIn={true}
                                    />
                                </div>
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
