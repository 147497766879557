
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import style from './UpcomingTask.module.css'
import moment from 'moment/moment';

function UpcomingTask({overViewGetData}) {
 

    const formatDate = (dateString) => {
        if (dateString) {
    
          const date = new Date(dateString);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1
            }/${date.getFullYear()}`;
          return formattedDate;
        } else {
          return ""
        }
      };

    // useEffect(() => {
    //     ProductService.getProductsMini().then(data => setProducts(data));
    // }, []);

    return (
        <div className="card">
           <DataTable value={overViewGetData} tableStyle={{ minWidth: '50rem' }} className={`custom-datatable-daily-task`}>
                <Column field=""  body={(index,resdata)=><span>{resdata.rowIndex+1}</span>}></Column>
                <Column field="task_name"></Column>
                {/* <Column field="category"></Column> */}
                <Column field="" body={(rowData)=><span>{moment(rowData.due_date).format("YYYY-MM-DD")}</span>}></Column>
            </DataTable>
        </div>
    );
}

export default UpcomingTask;