import { Editor } from "primereact/editor";
import { DragAndDrop } from "../../../Inputs/DragAndDrop";
import SingleSelect from "../../../Common/SingleSelect";
import TextInput from "../../../Inputs/TextInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import style from "./project.module.css";
import { getTimeZoneOptions } from "../../../Common/timeZone";
import { getActionsPermission, getProjectCode, getProjectName, getUserData, getUserId, getUserName, parseHashedData, toast } from "../../../../utils";
import { Country, State, City } from "country-state-city";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  addProjectDepartmens,
  addUnit,
  createProjectInformation,
  createProjectType,
  getProject,
  getProjectDepartmens,
  getProjectNumber,
  getProjectType,
  getUnit,
  getUserByProjectId,
  uploadDocs,
} from "../../../../Api/Admin/AllAPIs";
import { setDate } from "date-fns";
import FormSkeleton from "../../../Common/FormSkeleton";
import moment from "moment";
import AddSets from "../../../Common/AddSets";
import { MdAdd } from "react-icons/md";
import { currency } from "../../../../utils/currency"
import AutoCompleteSelect from "../../../Common/AutoCompleteSelect";
import SevedModal from "../../../Common/SavedModal";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";


export const ProjectInformation = ({ mode }) => {
  const countries = Country.getAllCountries();
  const d = useSelector(e => e.rolesAndPermissionData);

    let hash = localStorage.getItem('hashUserData')
    const userD = parseHashedData(hash) || {}

  const [idStore, setIdStore] = useState();
  const [fileUpload, setFileUpload] = useState([]);
  const [backVisible, setBackVisible] = useState(false);
  const [backVisible2, setBackVisible2] = useState(false);
  const [backVisible3, setBackVisible3] = useState(false);
  const [projectScale, setProjectScale] = useState("sq.m");
  const [allData, setAllData] = useState({})
  const [fileName, setFileName] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [fileUpload2, setFileUpload2] = useState([]);
  const [fileName2, setFileName2] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const domain = window.location.origin;
  const [previousFile2, setPreviousFile2] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [visible, setVisible] = useState(false);
  const [createProjectTypes, setCreateProjectTypes] = useState(" ");
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [projectDescription, setProjectDescription] = useState("");
  const [value, setValue] = useState();
  const [projectTypes, setProjectTypes] = useState([]);
  const [projectDepartmentVisible, setProjectDepartmentVisible] = useState(false)
  const [addProjectDepartment, setAddProjectDepartment] = useState('')
  const [reviewer, setReviewer] = useState(null)
  const [approver, setApprover] = useState(null)
  const [loader, setLoader] = useState(mode == "Edit" ? true : false);
  const [projectNumber, setProjectNumber] = useState('')
  const [approvalStatus, setApprovalStatus] = useState("")
  const [county, setCounty] = useState("")
  const [getProjectDepartment, setGetProjectDepartment] = useState([])
  const [userData, setUserData] = useState([])
  const [unit, setUnit] = useState("")
  const [getUnits, setGetUnits] = useState([])
  const [unitVisible, setUnitVisible] = useState(false)
  const [locality, setLocality] = useState("")
  const [projectDepartment, setProjectDepartment] = useState("")
  const [addProjectType, setAddProjectType] = useState("")
  const [estimatedProjectValue, setEstimatedProjectValue] = useState({})
  const [btnLoader, setBtnLoader] = useState(false)
  const skeletonData = [
    { qty: 1 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: '15rem' },
    { qty: 1 },
    { qty: 1 }
  ]
  const [sameLabel, setSameLabel] = useState([])

  const [formData, setFormData] = useState({
    project_name: "",
    // project_code: Math.random().toString(33).slice(6),
    project_status: "",
    estimated_project_value: "",
    project_square_feet: "",
    no_of_floors: "",
    no_of_sections: "",
    start_date: null,
    completion_date: null,
    time_zone: "",
    address: "",
    zip_code: "",
  });

  const [validation, setValidation] = useState({
    project_name: false,
    // project_code: false,
    project_department: false,
    project_status: false,
    project_type: false,
    estimated_project_value: false,
    project_square_feet: false,
    no_of_floors: false,
    // no_of_sections: false,
    start_date: false,
    completion_date: false,
    time_zone: false,
    address: false,
    zip_code: false,
    phone: false,
    projectNumber: false,
    // projectLogo: false,
    // projectPhotos: false
  });

  const statusArr = [
    { label: "Active", value: "active" },
    { label: "Completed", value: "completed" },
    { label: "Draft", value: "draft" },
    { label: "Incomplete", value: "incomplete" },
    { label: "On Hold", value: "onHold" },
    { label: "Under Maintenace", value: "undermaintenace" },
  ]

  const checkValidation = () => {

  

    if (!formData.address.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        address: true,
      }));
    }

    if (!formData.completion_date || formData.completion_date === null) {
      setValidation((prevState) => ({
        ...prevState,
        completion_date: true,
      }));
    }

    if (!formData.estimated_project_value) {
      setValidation((prevState) => ({
        ...prevState,
        estimated_project_value: true,
      }));
    }
    if (!formData.no_of_floors) {
      setValidation((prevState) => ({
        ...prevState,
        no_of_floors: true,
      }));
    }
    if (!isPossiblePhoneNumber(value)) {
      setValidation((prevState) => ({
        ...prevState,
        phone: true,
      }));
    }
    if (!projectDepartment?.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        project_department: true,
      }));
    }
    if (!formData?.project_name?.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        project_name: true,
      }));
    }

    if (!formData.project_status) {
      setValidation((prevState) => ({
        ...prevState,
        project_status: true,
      }));
    }

    if (!projectNumber) {
      setValidation((prevState) => ({
        ...prevState,
        projectNumber: true,
      }));
    }

    if (!formData.project_square_feet.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        project_square_feet: true,
      }));
    }

    if (!addProjectType.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        project_type: true,
      }));
    }

    if (!formData.start_date || formData.start_date === null) {
      setValidation((prevState) => ({
        ...prevState,
        start_date: true,
      }));
    }

    if (!formData.zip_code) {
      setValidation((prevState) => ({
        ...prevState,
        zip_code: true,
      }));
    }

    if (!formData.project_name.toString().trim()) {
      setValidation((prevState) => ({
        ...prevState,
        project_square_feet: true,
      }));
    }
    if (!selectedCountry) {
      setValidation((prevState) => ({
        ...prevState,
        country: true,
      }));
    }
    if (!selectedState) {
      setValidation((prevState) => ({
        ...prevState,
        state: true,
      }));
    }
    if (!formData.time_zone.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        time_zone: true,
      }));
    }
    // if (selectedFiles.length == 0) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     projectLogo: true,
    //   }));
    // }
    // if (selectedFiles2.length == 0) {
    //   setValidation((prevState) => ({
    //     ...prevState,
    //     projectPhotos: true,
    //   }));
    // }
 
    if (
      !formData.project_name ||
      !formData.project_status ||
      // !formData.project_code ||
      !projectDepartment ||
      !addProjectType ||
      !formData.estimated_project_value ||
      !formData.no_of_floors ||
      // !formData.no_of_sections ||
      !formData.start_date ||
      !formData.completion_date ||
      !selectedCountry ||
      !formData.time_zone ||
      !formData.address ||
      !formData.zip_code ||
      !projectNumber || 
      !isPossiblePhoneNumber(value) ||
      !selectedState
      // selectedFiles.length == 0 ||
      // selectedFiles2.length == 0
    ) {
      toast.info("Please fill in all the required fields marked with *");
      return true;
    }
    return false;
  };
  let project_id = localStorage.getItem("projectId");
  useEffect(() => {
    if (mode !== "Edit") {
      setLoader(false);
    }
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, []);


  const getStates = (countryName, countryIsoCode, defult, citys) => {
    setSelectedState("");
    // setSelectedCity("");
    const states = State.getStatesOfCountry(countryIsoCode);

    setStates(states);

    if (defult) {
      let dataState = states.filter((e) => e.name === defult)[0];
      setSelectedState(dataState);
      getCities(dataState, citys);
    }
  };

  const getCities = (state, citys) => {
    let isoCode = state?.isoCode;
    let cCode = state?.countryCode;

    // setSelectedCity("");

    const cities = City.getCitiesOfState(cCode, isoCode);
    setCities(cities);
    if (citys) {
   
      // setSelectedCity(cities.filter((e) => e.name === citys)[0].name);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCreate = async (type) => {

    try {
      if (type == 'project type') {
        if (createProjectTypes.trim() === "") {
          toast.info("Please Fill the Fields Marked with 1 * are Mandatory");
          return;
        }
        setVisible(false);
        await createProjectType({
          project_type_name: capitalizeFirstLetter(createProjectTypes?.trim()),
        });
        setCreateProjectTypes("");
        fetchProjectTypes();
      } else if (type == "project Department") {
        if (addProjectDepartment.trim() === "") {
          toast.info("Please Fill the Fields Marked with 2 * are Mandatory");
          return;
        }

        const payload = {
          name: capitalizeFirstLetter(addProjectDepartment.trim()),
          project_id: null
        }
        setProjectDepartmentVisible(false);
        await addProjectDepartmens(payload);
        let departments = await getProjectDepartmens()
        setGetProjectDepartment(departments?.data?.data);
        setAddProjectDepartment("");
      }
      else {
        setUnitVisible(false)
        await addUnit({ unit: unit })
        fetchProjectTypes()
        setUnit("")
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleFileInput1 = (event) => {
 

    setSelectedFiles(pre => [...event, ...pre]);
  };

  const handleFileInput2 = (event) => {
 

    setSelectedFiles2(pre => [...event, ...pre]);
  };

  const handleSubmit = async (btnName) => {

    if (checkValidation()) {
      return;
    }

    try {
      let uploadedFiles = [];
      setBtnLoader(true)
      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        for (const file of selectedFiles) {
          formData.append("upload", file);
        }
        const uploadResponse = await uploadDocs(formData);
     
        uploadedFiles = [...uploadedFiles, ...uploadResponse.data];
      }

      let uploadedFiles2 = [];
      if (selectedFiles2.length !== 0) {
        const formData = new FormData();
        for (const file of selectedFiles2) {
          formData.append("upload", file);
        }
        const uploadResponse = await uploadDocs(formData);
        uploadedFiles2 = [...uploadedFiles2, ...uploadResponse.data];
      }
     
      const userName = getUserName()

      const payload = {
        email_data: [],
        id1: idStore ? idStore : "",
        project_name: formData.project_name.trim(),
        postcode_country: county,
        reviewer_comment: "",
        approver_comment: "",
        project_code: '',
        project_department: projectDepartment,
        project_type: addProjectType?.trim(),
        estimated_project_value: {
          price: formData?.estimated_project_value,
          unit: estimatedProjectValue
        },
        project_square_feet: {
          measurement: formData.project_square_feet,
          unit: projectScale
        },
        no_of_floors: formData.no_of_floors,
        no_of_sections: formData.no_of_sections,
        start_date: formData.start_date,
        completion_date: doneDate,
        country: selectedCountry.name,
        time_zone: formData.time_zone,
        address: formData.address,
        city: selectedCity ? selectedCity : "",
        state: selectedState.name ? selectedState.name : "",
        zip_code: formData.zip_code,
        phone: value,
        project_description: projectDescription,
        project_logo: [...previousFile, ...uploadedFiles],
        project_photos: [...previousFile2, ...uploadedFiles2],
        deleted_file: removePhoto,
        project_status: formData.project_status,
        projectNumber: projectNumber,
        approval_status: allData?.approval_status,
        user_name: userName,
        locality: locality
      };

      if (approver !== null) {
        payload.approver_id = approver
      }
      if (reviewer !== null) {
        payload.reviewer_id = reviewer
      }

      if (allData?.approval_status !== undefined) {
        if (approver !== null && reviewer !== null && allData?.approval_status == 'draft') {
          payload.approval_status = 'inreview'

       
          let approverData = userData.find(e => e._id == payload.approver_id)
          let reviewerData = userData.find(e => e._id == payload.reviewer_id)

          // email to approver after status changes to inreview after edit 
          payload.email_data.push({
            name: approverData?.first_name || "--",
            to: approverData?.email_address || "--",
            reviewer_name: reviewerData?.first_name || "--",
            approver_name: approverData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Change in ${getProjectName()} Project’s Approval Status`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToApproverAfterEditedByProjectCreator",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            updated_by: getUserName(),
            url: `${domain}/coretools/admin/projectform/step-1`
          });

          // email to reviewer after status changes to inreview after edit 
          payload.email_data.push({
            name: reviewerData?.first_name || "--",
            to: reviewerData?.email_address || "--",
            reviewer_name: reviewerData?.first_name || "--",
            approver_name: approverData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `Action Required: Change in Project’s Approval Status`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToReviewerAfterEditedByProjectCreator",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`,
            updated_by: getUserName(),
          });



        }
      } else {
        if (approver !== null && reviewer !== null) {
          payload.approval_status = 'inreview'
        } else {
          payload.approval_status = 'draft'
        }
      }


    
      // // return

 

      if (payload?.reviewer_id) {


        // send email to all users and reivewer on reviewer change 
        if (allData?.reviewer_id !== payload?.reviewer_id) {

          let approverData = userData?.find(e => e._id == payload.approver_id)
          let reviewerData = userData?.find(e => e._id == payload.reviewer_id)
          // add data of email to approver 
          payload?.email_data?.push({
            name: reviewerData?.first_name || "--",
            to: reviewerData?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `Action Required: Appointed as the Reviewer to ${getProjectName()}`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToReviewerAfterAddingToProject",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`
          });

          // email to approver 
          payload.email_data.push({
            name: approverData?.first_name || "--",
            to: approverData?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Reviewer Added to ${getProjectName()}`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToUsersAfterAddingReviewerToProject",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`
          });
          // email to creator 
          payload.email_data.push({
            name: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name || "--",
            to: allData?.creator_email?.[0]?.email || allData?.creator_email?.[0]?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Reviewer Added to ${getProjectName()}`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToUsersAfterAddingReviewerToProject",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`
          });

          // email to all users 
          allData.individual_users.forEach((res) => {
            let user = userData.find(e => e._id == res.user)
            payload.email_data.push({
              name: user?.first_name || "--",
              to: user?.email_address || "--",
              approver_name: approverData?.first_name || "--",
              reviewer_name: reviewerData?.first_name || "--",
              project_name: getProjectName() || "--",
              subject: `FYI: Reviewer Added to ${getProjectName()}`,
              project_id: getProjectCode(),
              project_status: allData?.project_status,
              template_type: "emailToUsersAfterAddingReviewerToProject",
              created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
              url: `${domain}/coretools/admin/projectform/step-1`
            }
            )
          })

        }
      }
      if (payload?.approver_id) {

        // send email to all users and reivewer on approver change 
        if (allData.approver_id !== payload.approver_id) {

          let approverData = userData.find(e => e._id == payload.approver_id)
          let reviewerData = userData.find(e => e._id == payload.reviewer_id)
          // add data of email to approver 
          payload.email_data.push({
            name: approverData?.first_name || "--",
            to: approverData?.email_address || "--",
            reviewer_name: reviewerData?.first_name || "--",
            approver_name: approverData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `Action Required: Appointed as the Approver to ${getProjectName()}`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToApproverAfterAddingToProject",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`
          });

          // email to reviewer 
          payload.email_data.push({
            name: reviewerData?.first_name || "--",
            to: reviewerData?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Approver Added to ${getProjectName()}`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToUsersAfterAddingReviewerToProject",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`
          });


          // email to creator 
          payload.email_data.push({
            name: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name || "--",
            to: allData?.creator_email?.[0]?.email || allData?.creator_email?.[0]?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Approver Added to ${getProjectName()}`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToUsersAfterAddingReviewerToProject",
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`
          });
          // email to all users 
          allData?.individual_users?.forEach((res) => {
            let user = userData?.find(e => e._id == res.user)
            payload.email_data.push({
              name: user?.first_name || "--",
              to: user?.email_address || "--",
              approver_name: approverData?.first_name || "--",
              reviewer_name: reviewerData?.first_name || "--",
              project_name: getProjectName() || "--",
              subject: `FYI: Approver Added to ${getProjectName()}`,
              project_id: getProjectCode(),
              project_status: allData?.project_status,
              template_type: "emailToUsersAfterAddingApproverToProject",
              created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
              url: `${domain}/coretools/admin/projectform/step-1`
            }
            )
          })
        }


        // send email to approver ,reviewer,all internal user and creator on status change 


        if (allData.project_status !== payload.project_status) {

          let approverData = userData.find(e => e._id == payload.approver_id)
          let reviewerData = userData.find(e => e._id == payload.reviewer_id)
          // add data of email to approver 
          payload.email_data.push({
            name: approverData?.first_name || "--",
            to: approverData?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Project Status Changed`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToAllAfterChangingStatus",
            project_statusData: `${allData.project_status} To ${payload.project_status}`,
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            updated_by: getUserName(),
            url: `${domain}/coretools/admin/projectform/step-1`
          });
          // add data of email to reviewer 
          payload.email_data.push({
            name: reviewerData?.first_name || "--",
            to: reviewerData?.email_address || "--",
            approver_name: approverData?.first_name || "--",
            reviewer_name: reviewerData?.first_name || "--",
            project_name: getProjectName() || "--",
            subject: `FYI: Project Status Changed`,
            project_id: getProjectCode(),
            project_status: allData?.project_status,
            template_type: "emailToAllAfterChangingStatus",
            project_statusData: `${allData.project_status} To ${payload.project_status}`,
            created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
            url: `${domain}/coretools/admin/projectform/step-1`,
            updated_by: getUserName(),
          });

          allData.individual_users.forEach((res) => {
            let user = userData.find(e => e._id == res.user)
            payload.email_data.push({
              name: user?.first_name || "--",
              to: user?.email_address || "--",
              approver_name: approverData?.first_name || "--",
              reviewer_name: reviewerData?.first_name || "--",
              project_name: getProjectName() || "--",
              subject: `FYI: Project Status Changed`,
              project_id: getProjectCode(),
              project_status: allData?.project_status,
              project_statusData: `${allData.project_status} To ${payload.project_status}`,
              template_type: "emailToAllAfterChangingStatus",
              created_by: allData?.creator_email?.[0]?.first_name || allData?.creator_email?.[0]?.name,
              url: `${domain}/coretools/admin/projectform/step-1`,
              updated_by: getUserName(),
            }
            )
          })



        }


      }

      const res = await createProjectInformation({ ...payload, creater_id: getUserId(), url: domain });
      setBackVisible2(false)
      setBackVisible3(false)
  
      setBtnLoader(false)
      const projectId = res.data.data?._id;
      if (mode !== "Edit") {
        localStorage.setItem("projectId", projectId);

      }


      if (btnName !== "save") {
        navigate(
          mode === "Edit"
            ? "/coretools/admin/projectform/step-2"
            : "/portfolio/projectform/step-2"
        );
      } else {
        // navigate(
        //   mode === "Edit"
        //     ? `/coretools/admin/projectView/${project_id}`
        //     : "/projects"
        // )
      }

    } catch (error) {
      setBtnLoader(false)
      console.log(error);
      if (error?.response?.data?.message !== undefined) {
        if (error?.response?.data?.message?.includes('estimated_project_value')) {
          toast?.error('Currency is required for Estimated Project Value')

        } else if (error?.response?.data?.message?.includes('project_square_feet')) {
          toast?.error('Unit is required for Project Area')

        } else {
          toast?.error(error?.response?.data?.message)
        }
      } else {
        toast?.error('Somthing went wroung please check and try')
      }
    }
  };

  useEffect(() => {
    fetchProjectTypes();
    if (project_id) {
      getuserById(project_id)
    }
  }, [allData]);

  useEffect(() => {
    fetchProjectTypes();
  }, []);




  const fetchProjectTypes = async () => {
    try {

      const [projectType, getDepartment, getallUnit] = await Promise.all([
        getProjectType(),
        getProjectDepartmens(),
        getUnit()
      ]);
      setProjectTypes(projectType?.data?.data);
      setGetProjectDepartment(getDepartment?.data?.data);
      setGetUnits(getallUnit?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };




  const getuserById = async (id) => {
    try {
      const res = await getUserByProjectId(id);
      const usersData = res.data.data;
      const [combinedUsers] = usersData?.map((res) => res?.combined_users)
      if (combinedUsers !== undefined) {
        let newData = combinedUsers?.filter(res => res?._id !== userD?._id)
        setUserData(newData);
        setSameLabel([{ label: `${userD?.first_name} ${userD?.last_name}`, value: userD?._id }])
      }
    
    } catch (error) {
      console.log(error);
    }
  };



  async function getProjectNumberCount() {
    try {
      let res = await getProjectNumber()
      let data = res?.data?.data
      setProjectNumber(data)
    } catch (err) {
      console.log(err)
    } finally {
      // setLoader(false)
    }
  }



  const Project = [
    { project_type_name: "Civil" },
    { project_type_name: "Commercial" },
    { project_type_name: "Heavy Industrial" },
    { project_type_name: "Marine Construction" },
    { project_type_name: "Public Works" },
    { project_type_name: "Residential" },
    { project_type_name: "Technical Improvement" },
  ];

  const timeZoneOptions = getTimeZoneOptions();
  const [date, setdate] = useState();
  const [doneDate, setDoneDate] = useState();

  const handleChange = (e, type) => {
    if (type == 'time_zone') {
      setFormData({
        ...formData,
        [type]: e,
      });
    } else {

      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value.toString(),
      });
    }
  };

  const handleNumberChange = (inputName, value) => {
    let numericValue = null;
    if (value.value !== null && value.value !== "-") {
      numericValue = Number(value.value);
      if (value.value < 0) {
        toast.info("Please enter valid Info");
        return;
      }
    }
    setFormData({
      ...formData,
      [inputName]: numericValue,
    });

  };

  const getProjectes = async () => {
    try {
      setLoader(true);
      const res = await getProject(idStore);
      const getData = res?.data?.data[0];

      if (getData) {

      
      
        setAllData(getData)
        setDoneDate(new Date(getData.completion_date));
        setDate(new Date(getData.start_date));
        setFileName(
          getData?.project_logo?.map((res) => ({
            name: res?.fileName,
            id: res?._id,
          }))
        );

        setFileName2(
          getData?.project_photos?.map((res) => ({
            name: res?.fileName,
            id: res?._id,
          }))
        );
        setValue(getData?.phone);
        setReviewer(getData?.reviewer_id || null)
        setApprover(getData?.approver_id || null)
        const cont = getData?.phone;
        setValue(cont);
        setProjectDescription(getData.project_description);

   

        let country = countries.filter((e) => e.name === getData.country)[0];
        setSelectedCountry(country);
        setSelectedCity(getData.city)
        getStates(country.name, country.isoCode, getData.state, getData.city);
        setPreviousFile(getData.project_logo ? [...getData?.project_logo] : []);
        setPreviousFile2(
          getData.project_photos ? [...getData.project_photos] : []
        );
        setApprovalStatus(getData?.approval_status)
        setCounty(getData?.postcode_country)
        setProjectScale(getData?.project_square_feet?.unit);
        setProjectDepartment(getData.project_department)
        setAddProjectType(getData.project_type)
        setProjectNumber(getData?.projectNumber)
        setEstimatedProjectValue(getData?.estimated_project_value?.unit)
        setLocality(getData?.locality)
        setFormData({
          ...formData,
          project_name: getData.project_name || "",
          project_status: getData.project_status || "",
          // project_code: getData.project_code || "",
          estimated_project_value: getData?.estimated_project_value?.price || "",
          project_square_feet: getData?.project_square_feet?.measurement || "",
          no_of_floors: getData.no_of_floors || "",
          no_of_sections: getData.no_of_sections || "",
          start_date: new Date(getData.start_date) || "",
          completion_date: new Date(getData.completion_date) || "",
          time_zone: getData.time_zone || "",
          address: getData.address || "",
          zip_code: getData.zip_code || "",
          phone: getData.phone || "",
        });
      }
      setLoader(false);
    } catch (error) {
      console.error(error);

    }
    finally {
      setLoader(false);
    }
  };



  useEffect(() => {
    if (idStore) {
      getProjectes();

    } else {

      getProjectNumberCount()
    }
  }, [idStore]);
  const navigate = useNavigate();

  return (
    <>

      <div className={`${style.outerBox}`}>
        <div className={`${style.headingBox} pt-2`}>
          <span className="project_name_text "> 1. PROJECT INFORMATION</span>
        </div>

        {
          loader ?
            <FormSkeleton data={skeletonData} />
            :
            <>
              <div className={`row m-0 p-0 ${style.tableBox}`}>


                {/*  New section End */}

                <div className="row m-0 p-0 mt-3">
                  <div className="col-6">
                    <label htmlFor="" className="py-2 labelCss">
                      Project Name: <span className="text-danger">*</span>
                    </label>
                    <TextInput
                      placeholder="Enter Project Name"
                      name="project_name"
                      value={formData.project_name}
                      onChange={handleChange}
                      className={validation.project_name ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...validation, project_name: false })
                      }
                    >
                      {" "}
                    </TextInput>
                  </div>


                  <div className="col-md-6 col-12">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="py-2 labelCss">
                        Project Number:<span className="text-danger">*</span>
                      </label>
                      <TextInput
                        type='number'
                        placeholder='Project Number'
                        value={projectNumber}
                        className={validation.projectNumber ? "Validation" : ""}
                        onNumberChange={(e) => {
                       
                          setProjectNumber(e.value)
                        }}
                        onClick={() =>
                          setValidation({ ...validation, projectNumber: false })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="py-2 labelCss">
                      Project Status:<span className="text-danger">*</span>
                    </label>
                    <SingleSelect
                      placeholder="Select Project Status"
                      name="project_status"
                      value={formData.project_status}
                      onChange={handleChange}
                      options={statusArr}
                      className={`p-0 ${validation.project_status ? "Validation" : ""
                        }`}
                      onClick={() =>
                        setValidation({ ...validation, project_status: false })
                      }
                    />
                  </div>
                </div>

                {mode === "Edit" ? (
                  <>

                    {getActionsPermission("assignReviewer") && <div className="col-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer :
                      </label>
                      <AutoCompleteSelect
                        options={reviewer == userD?._id ? sameLabel : userData?.map((items) => ({
                          label: `${items.first_name} ${items?.last_name} `,
                          value: items._id,
                        }))}
                        value={reviewer}
                        optionLabel="label"
                        onChange={(value) => {
                          setReviewer(value);
                        }}
                        className={`p-0`}
                        disabled={reviewer == userD?._id || approvalStatus == 'approved'}
                        placeholder="Select Reviewer"
                      />
                    </div>
                    }


                    {getActionsPermission("assignApprover") && <div className="col-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver :
                      </label>
                      <AutoCompleteSelect
                        options={approver == userD?._id ? sameLabel : userData?.map((items) => ({
                          label: `${items.first_name} ${items?.last_name}`,
                          value: items._id,
                        }))}
                        value={approver}
                        optionLabel="label"
                        onChange={(value) => {
                          setApprover(value);
                        }}
                        disabled={approver == userD?._id || approvalStatus == 'approved'}
                        className={`p-0`}
                        placeholder="Select Approver"
                      />
                    </div>}
                  </>) : ""}

                {mode === "Edit" ? (
                  <>

                    <div className="col-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer Comments  : <span className="text-danger">*</span>
                      </label>

                      <TextInput
                        placeholder="Reviewer Comments"
                        disabled
                        value={allData?.reviewer_comment}
                      />
                    </div>



                    <div className="col-6 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Approver Comments  : <span className="text-danger">*</span>
                      </label>

                      <TextInput
                        placeholder="Approver  Comments"
                        disabled
                        value={allData?.approver_comment}
                      />
                    </div>


                  </>) : ""}

                {/*  calender section start */}
                <div className="col-md-6 col-12 mt-3">
                  <div className="maindiv_plusIcon d-flex align-items-center">
                    <div className="flex-grow-1">
                      <label htmlFor="" className="py-2 labelCss">
                        Project Department: <span className="text-danger">*</span>
                      </label>
                      <AutoCompleteSelect
                        placeholder="Enter Project Department"
                        name="project_department"
                        value={projectDepartment}
                        onChange={(value) => {
                          setProjectDepartment(value)
                          setValidation({ ...validation, project_department: false })
                        }}
                        options={getProjectDepartment?.map((items) => ({
                          label: capitalizeFirstLetter(items.name),
                          value: items.name,
                        }))}
                        className={`p-0 ${validation.project_department ? "Validation" : ""
                          }`}

                      />
                    </div>
                    <span className="ml-3 mt-5">
                      <MdAdd
                        className="h2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setProjectDepartmentVisible(true)}
                      />
                    </span>
                  </div>
                </div>

                <div className="col-md-6 col-12 mt-3 pe-0">
                  <div className="maindiv_plusIcon d-flex align-items-center">
                    <div className="flex-grow-1">
                      <label htmlFor="" className="py-2 labelCss">
                        Project Type: <span className="text-danger">*</span>
                      </label>
                      <AutoCompleteSelect
                        className={`p-0 ${validation.project_type ? "Validation" : ""}`}

                        placeholder="Enter Project Type"
                        onChange={(value) => {
                          setAddProjectType(value)
                          setValidation({ ...validation, project_type: false })
                        }}
                        options={[
                          ...projectTypes.map((type) => ({
                            label: capitalizeFirstLetter(type.project_type_name),
                            value: type.project_type_name,
                          })),
                          ...Project.map((project) => ({
                            label: capitalizeFirstLetter(project.project_type_name),
                            value: project.project_type_name,
                          })),
                        ]}
                        value={addProjectType}
                      />
                    </div>
                    <span className="ps-3 mt-5">
                      <MdAdd
                        className="h2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setVisible(true)}
                      />
                    </span>
                  </div>
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Estimated Project Value
                    <span className="text-danger">*</span>
                  </label>

                  <div className="row m-0 p-0">
                    <div className="col-8 ps-0">

                      <TextInput
                        type={"number"}
                        placeholder="Enter Estimated Project Value"
                        name="estimated_project_value"
                        value={formData.estimated_project_value}
                        onNumberChange={(value) => {
                          handleNumberChange("estimated_project_value", value)
                          setValidation({
                            ...validation,
                            estimated_project_value: false,
                          })
                        }}
                        className={
                          validation.estimated_project_value ? "Validation" : ""
                        }

                      >
                        {" "}
                      </TextInput>
                    </div>

                    <div className="col-4 pe-0">
                      <AutoCompleteSelect
                        placeholder="Select currency"
                        value={estimatedProjectValue}
                        onChange={(value) => setEstimatedProjectValue(value)}
                        options={currency?.map((item) => ({
                          label: `${item.code} - ${item.name}`,
                          value: item.name,
                        }))}
                        className={`p-0`}

                      />
                    </div>

                  </div>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Project Area:<span className="text-danger">*</span>
                  </label>
                  <div className="row m-0 p-0">
                    <div className="col-8 ps-0">
                      <TextInput
                        placeholder="Enter Project Square feet"
                        name="project_square_feet"
                        value={formData.project_square_feet}
                        onChange={handleChange}
                        className={
                          validation.project_square_feet
                            ? `Validation w-100  ${style["card.card-add"]}`
                            : `w-100  ${style["card.card-add"]}`
                        }
                        onClick={() =>
                          setValidation({
                            ...validation,
                            project_square_feet: false,
                          })
                        }
                      >
                        {" "}
                      </TextInput>
                    </div>
                    <div className="col-4 pe-0 d-flex align-items-center">
                      <div className={style.selectContainer}>
                        <AutoCompleteSelect
                          options={getUnits?.map((items) => ({
                            label: items.unit,
                            value: items.unit,
                          }))}
                          value={projectScale}
                          optionLabel="label"
                          onChange={(e) => setProjectScale(e)}
                          className={`p-0 flex-grow-1 `}
                          // onClick={() =>
                          //   setValidation({ ...validation, project_status: false })
                          // }
                          placeholder="Select Unit"

                        />
                      </div>
                      <span className="ml-3 mt-2">
                        <MdAdd
                          className="h2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setUnitVisible(true)}
                        />
                      </span>
                    </div>

                  </div>
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Number of floors:
                    <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    type={"number"}
                    placeholder="Enter Number of floors in Project"
                    name="no_of_floors"
                    value={formData.no_of_floors}
                    onNumberChange={(value) =>
                      handleNumberChange("no_of_floors", value)
                    }
                    code={true}
                    className={validation.no_of_floors ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...validation, no_of_floors: false })
                    }
                  />
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Number of Zone
                  </label>
                  <TextInput
                    type={"number"}
                    placeholder="Enter Number of Sections in Project"
                    name="no_of_sections"
                    value={formData.no_of_sections}
                    onNumberChange={(value) =>
                      handleNumberChange("no_of_sections", value)
                    }
                    code={true}
                  // className={validation.no_of_sections ? "Validation" : ""}
                  // onClick={() =>
                  //   setValidation({ ...validation, no_of_sections: false })
                  // }
                  />
                </div>


                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Expected Start Date:<span className="text-danger">*</span>
                  </label>

                  <input type="date"
                    placeholder="dd/mm/yy"
                    nobuttonBar={true}
                    name="start_date"
                    value={date ? date : moment(formData.start_date).format("yyyy-MM-DD")}
                    onChange={(e) => {
                      handleChange(e);
                      setdate(e.target.value);
                    }}
                    style={{ height: "50px" }}
                    className={`${validation.start_date ? "Validation" : ""} form-control`}
                    onClick={() =>
                      setValidation({ ...validation, start_date: false })
                    }
                  />

                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Expected Completion Date:
                    <span className="text-danger">*</span>
                  </label>


                  <input
                    type="date"
                    placeholder="dd/mm/yy"
                    nobuttonBar={true}
                    name="completion_date"
                    min={date ? date : moment().format('yyyy-MM-DD')}
                    value={formData.completion_date && moment(formData.completion_date).format("yyyy-MM-DD") || ""}
                    minDate={new Date(date ? date : formData.start_date)}
                    onChange={(e) => {
                      handleChange(e);
                      setDoneDate(e.target.value);
                    }}
                    style={{ height: "50px" }}
                    disabled={formData.start_date ? false : true}
                    className={`${validation.completion_date ? "Validation" : ""} form-control`}
                    onClick={() =>
                      setValidation({ ...validation, completion_date: false })
                    }
                  />






                  {/* <TextInput className="" placeholder="Enter Drawing Number" value={drawingNumber} onChange={(e) => { setDrawingNumber(e.target.value) }} /> */}
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Street Address: <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    placeholder="Enter Street Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={validation.address ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...validation, address: false })
                    }
                  />
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Locality :
                  </label>

                  <TextInput value={locality} onChange={(e) => setLocality(e.target.value)} placeholder="Enter Locality" />

                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Country:<span className="text-danger">*</span>
                  </label>

                  <AutoCompleteSelect
                    options={countries.map((country) => ({
                      label: country.name,
                      value: country,
                    }))}
                    value={selectedCountry}
                    optionLabel="label"
                    onChange={(e) => {
                      const selectedCountry = e;
                      setSelectedCountry(e);
                      getStates(selectedCountry.name, selectedCountry.isoCode);
                      setValidation({ ...validation, country: false })
                    }}
                    className={validation.country ? "Validation" : ""}

                    placeholder="Select Country"

                  />

                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    State :<span className="text-danger">*</span>
                  </label>

                  <AutoCompleteSelect
                    options={Array.from(states)?.map((state) => ({
                      label: state.name,
                      value: state,
                    }))}
                    value={selectedState}
                    optionLabel="label"
                    onChange={(e) => {
                      setSelectedState(e);
                      getCities(e);
                      setValidation({ ...validation, state: false })
                    }}
                    className={validation.state ? "Validation" : ""}
                    placeholder="Select State"

                  />
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    County: <span className="text-danger"></span>
                  </label>


                  <TextInput
                    placeholder="Enter City Name"
                    onChange={(e) => setSelectedCity(e.target.value)}
                    value={selectedCity}

                  />

                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    City/Town: <span className="text-danger"></span>
                  </label>
                  <TextInput
                    placeholder="Enter County"
                    onChange={(e) => setCounty(e.target.value)}
                    value={county}

                  />
                  {/* <AutoCompleteSelect
                    options={cities.map((city) => ({
                      label: city.name,
                      value: city.name,
                    }))}
                    value={selectedCity}
                    optionLabel="label"
                    onChange={(e) => {
                      setSelectedCity(e);
                    }}
                    className={validation.city ? "Validation" : ""}
                    onClick={() => setValidation({ ...validation, city: false })}
                    placeholder="Select City"

                  /> */}
                  {/* <SingleSelect
      className={validation.city ? "Validation" : ""}
      onClick={() => setValidation({ ...validation, city: false })}
      placeholder=" Select City"
      options={cities.map((city) => ({
        label: city.name,
        value: city.name,
      }))}
      value={selectedCity}
      onChange={(e) => {
        setSelectedCity(e.target.value);
   
      }}
    /> */}
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    PostCode:<span className="text-danger">*</span>
                  </label>
                  <TextInput
                    type='text'
                    className={validation.zip_code ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...validation, zip_code: false })
                    }
                    placeholder="Enter Post Code"
                    name="zip_code"
                    code={true}
                    value={formData.zip_code}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        zip_code: e.target.value,
                      });

                    }}
                  />
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Time Zone : <span className="text-danger">*</span>
                  </label>
                  <AutoCompleteSelect
                    className={validation.time_zone ? "Validation" : ""}
                    options={timeZoneOptions}
                    value={formData.time_zone}
                    optionLabel="label"
                    onChange={(e) => {
                      handleChange(e, 'time_zone')
                      setValidation({ ...validation, time_zone: false })
                    }}
                    placeholder="Select Time Zone"

                  />
                </div>

                <div className="mt-3 col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Phone:
                  </label>

                  <PhoneInput
                    placeholder="Enter phone number"
                    value={value}
                    onChange={setValue}
                    defaultCountry="GB"
                    className={ validation.phone &&"phoneErrorInput"}
                    // inputClassName={
                    //   validation.phone ? "PhoneInput border border-danger Validation" : "border border-danger"
                    // }
                    
                    onClick={() => setValidation({ ...validation, phone: false })}
                  />
                </div>

                <div className="row m-0 p-0 mt-3">
                  <div className="col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Project Description:
                    </label>
                    <div className="card">
                      {/* <Editor
      value={question}   onChange={(e) => { setQuestion(e.target.value) }}
      onTextChange={(e) => setQuestion(e.htmlValue)}
      style={{ minHeight: "140px" }}
    /> */}
                      {/* <Editor
                        style={{ height: "200px" }}
                        value={projectDescription}
                        defaultValue={"<p>Testtttttttttttttttttttttttt</p>"}
                        onTextChange={(e) => setProjectDescription(e.htmlValue)}
                      /> */}
<ReactQuill theme="snow" value={projectDescription} onChange={setProjectDescription} />


                  
                    </div>
                  </div>
                </div>

                <div className="row m-0 p-0 mt-3">
                  <div className="col-12">
                    <DragAndDrop
                      requiredMark={false}
                      setRemovePhoto={setRemovePhoto}
                      value={fileName}
                      setFiles={setFileUpload}
                      onChange={handleFileInput1}
                      setValue={setFileName}
                      setPreviousFiles={setPreviousFile}
                      label={"Project Logo"}
                    />
                  </div>
                </div>

                <div className="row m-0 p-0 mt-3">
                  <div className="col-12">
                    <DragAndDrop
                      setRemovePhoto={setRemovePhoto}
                      value={fileName2}
                      setFiles={setFileUpload2}
                      onChange={handleFileInput2}
                      setValue={setFileName2}
                      setPreviousFiles={setPreviousFile2}
                      label={"Project Photos"}
                      requiredMark={false}
                      multiSelect={true}
                    />
                  </div>
                </div>
              </div>
              <div className={`${style.buttonBox}`}>
                <div className="d-flex mt-2 justify-content-between">
                  <PrimaryButton
                    className=" "
                    onClick={() => setBackVisible(true)}
                    text={"Cancel"}
                  />

                  <div className="d-flex  gap-3">
                    <PrimaryButton text={"Save"} onClick={() => setBackVisible2(true)} />
                    <PrimaryButton text={"Save & Continue"} onClick={() => setBackVisible3(true)} />
                  </div>
                </div>
              </div>
            </>
        }
      </div>
      <SevedModal
        heading='Confirm Cancellation'
        yes={() => navigate(
          mode === "Edit"
            ? `/coretools/admin/viewproject/${project_id}`
            : "/projects"
        )}
        visible={backVisible}
        type='warning'

        setVisible={setBackVisible}
        headingText={`Are you sure you want to discard this data?`}
      />
      <SevedModal
        yes={() => handleSubmit("save")}
        visible={backVisible2}
        setVisible={setBackVisible2}
        heading='Create the Project'
        type='info'
        loader={btnLoader}
        headingText={`Are you sure want to save your Data?`}
      />
      <SevedModal
        yes={() => handleSubmit("Save & Continue")}
        visible={backVisible3}
        loader={btnLoader}
        setVisible={setBackVisible3}
        type='info'
        heading='Proceed to Next Step'
        headingText={`Are you sure you want to save your progress and continue?`}
      />



      <AddSets
        visible={visible}
        style={{ width: "50vw" }}
        draggable={false}
        setVisible={setVisible}
        onSubmit={() => handleCreate("project type")}
        value={createProjectTypes}
        setValue={setCreateProjectTypes}
        label="Project type"
      >
      </AddSets>



      <AddSets

        visible={projectDepartmentVisible}
        style={{ width: "50vw" }}

        draggable={false}
        setVisible={setProjectDepartmentVisible}
        onSubmit={() => handleCreate("project Department")}
        value={addProjectDepartment}
        setValue={setAddProjectDepartment}
        label="Project Department"
      >

      </AddSets>


      <AddSets

        visible={unitVisible}
        style={{ width: "50vw" }}

        draggable={false}
        setVisible={setUnitVisible}
        onSubmit={() => handleCreate("unit")}
        value={unit}
        setValue={setUnit}
        label="Unit"
      >

      </AddSets>
    </>
  );
};
