import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import TextInput from "../../../Inputs/TextInput";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { IoMdAdd } from "react-icons/io";
import { Dropdown } from "primereact/dropdown";
import { addCodes } from "../../../../Api/Admin/AllAPIs";
import "./CostCodes.css"

export const AddCode = ({
  allParents,
  project_id,
  reload,
  setSecondTableData,
  secondTableData,
}) => {
  let [visible, setVisible] = useState(false);
  let [error, setError] = useState(0);

  let [codeArr, setCodeArr] = useState([]);


  let [codeData, setCodeData] = useState({
    description: "",
    code: "",
    parentCode: "",
  });
  async function formsubmit() {
    let payload = {
      ...codeData,
      project_id,
    };
    if (validation()) {
      try {

        // let res = await addCodes(payload);
        let resData = secondTableData.map((s) => {
          if (s.code === payload.parentCode) {
            s.children.push({
              description: payload.description,
              code: payload.code,
            });
          }
          return s;
        });
      
        setVisible(false);

        setSecondTableData(resData);
        setCodeData({ description: "", code: "", parentCode: "" });
        // reload();
      } catch (err) {
        console.log(err);
      }
    }
  }
  const validation = () => {

    if (codeData.parentCode?.trim().length === 0) {
      setError(2);

      return false;
    } else if (codeData.code?.trim().length === 0) {
      setError(3);
      return false;
    } else if (codeData.description?.trim().length === 0) {
      setError(4);

      return false;
    } else {
      setError(0);
      return true;
    }
  };

  return (
    <>
      <PrimaryButton
        onClick={() => setVisible(true)}
        text={"Add Code"} addIcon />


      <Dialog
        header="Add New Cost Code to Project"
        visible={visible}
        draggable={false}
        closable={false}
        style={{ width: "50%" }}
      >
        <div className="row mt-3">
          <div className="col-12">
            <label htmlFor="" className="py-2 labelCss">
              Select an existing Division
            </label>

            <Dropdown
              className="w-100"
              placeholder="Select Division"
              options={allParents}
              optionLabel="description"
              value={
                allParents.find((e) => e.code == codeData.parentCode) || null
              }
              onChange={(e) => {
                setCodeData((pre) => ({
                  ...pre,
                  parentCode: e.target.value.code,
                }));
                setError(0);
                setCodeArr(e.target.value.children.map((e) => e.code));
              }}
            />
            {error === 2 && (
              <>
                <p className="text-danger mt-1">Please select Division</p>
              </>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <label htmlFor="" className="py-2 labelCss">
              Code
            </label>

            <TextInput
              placeholder="Code"
              value={codeData.code}
              onChange={(e) => {
                setCodeData((pre) => ({ ...pre, code: e.target.value }));

                if (codeArr.includes(e.target.value)) {
                  setError(1);
                } else {
                  setError(0);
                }
              }}
            >
              {" "}
            </TextInput>
            {error === 1 && (
              <>
                <p className="text-danger mt-1">Code already Exists</p>
              </>
            )}
            {error === 3 && (
              <>
                <p className="text-danger mt-1">Please Enter Code</p>
              </>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <label htmlFor="" className="py-2 labelCss">
              Description
            </label>

            <TextInput
              placeholder="Description"
              value={codeData.description}
              onChange={(e) => {
                setCodeData((pre) => ({ ...pre, description: e.target.value }));
                setError(0);
              }}
            ></TextInput>
            {error === 4 && (
              <>
                <p className="text-danger mt-1">Please Enter Description</p>
              </>
            )}
          </div>
        </div>
        <div className="end mt-5">
          <button className="btn border me-3" onClick={() => setVisible(false)}>
            Cancel
          </button>
          <PrimaryButton onClick={formsubmit} text={"Submit"} />
        </div>
      </Dialog>
    </>
  );
};
