import React, { forwardRef } from 'react'

export const ViewPageTable = ({ tableData }) => {
    return (
        <table>
            <tbody>
                {tableData.map((res, ind) => {
                    return (
                        <tr>
                            <td className="gray-lt text-nowrap pb-2" >{Object?.keys(res)[0]}: </td>
                            <td className="gray-d ps-5 pb-2">{Object?.values(res)[0]}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
