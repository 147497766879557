import React, { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import "./SingleSelect.css";

const MultiSelects = ({ placeholder, options, label, value, setValue }) => {
  const [selectedCities, setSelectedCities] = useState(value);


  return (
    <>
      <div className="card flex justify-content-center">
        <MultiSelect
          value={value}
          onChange={(e) => {
      
            setValue(e.value);
            setSelectedCities(e.value);
          }}
          options={options}
          optionLabel={label}
          display="chip"
          placeholder={placeholder}
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
      </div>
    </>
  );
};

export default MultiSelects;
