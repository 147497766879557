import React from 'react'
import style from "./table.module.css"

export const BorderedTable = ({ children }) => {
    return (
        <div className={style.borderedTable_main_con2}>
            <div>
            {children}
            </div>
        </div>
    )
}
