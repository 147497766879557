export let baseUrl = "https://cdc-client-0ef747c68214.herokuapp.com/";
// export const baseUrl = 'http://192.168.1.25:4000/';


export const API = {
  login: "login",
  microsoftLogin: "microsoftLogin",
  forgotPassword: "user/forgotPassword",
  checkUserToeknIsValid: "user/checkUserToeknIsValid",
  changePassword: "user/changePassword",
  CreateIncident: "incident/addIncident",
  resetUserPassword: "user/resetPassword/",
  getIncident: "incident/getIncident",
  getIncidentById: "incident/getIncidentById/",
  updateIncident: "incident/updateIncidentById/",
  deleteIcident: "incident/deleteIncidentById/",
  filterIncident: "incident/getIncidentByFilter",
  getIncidentDataByProjectId: "incident/getIncidentDataByProjectId/",
  getCountForIncidentByProjectId: "incident/getCountForIncidentByProjectId/",
  getincidentVersionHistory: "incident/getincidentVersionHistory",
  projectOverView: "project/overviewProject/",
  getProjectOverviewSummary: "overview/getProjectSummary",
  googleApi: "googleLogin",
  createRfi: "rfi/addRfi",
  getRfi: "rfi/getRfi",
  getRfiById: "rfi/getRfiById/",
  updateRfi: "rfi/updateRfiById/",
  deleteRfi: "rfi/deleteRfiById/",
  filterRfi: "rfi/getRfiByFilter",
  getRfiCount: "rfi/getCount",
  addResponseInRfi: "rfi/addResponseInRfi/",
  addCategory: "rfi/addCategory",
  getAllCategory: "rfi/getAllCategory/",
  createObservation: "observation/addObservation",
  getRfiByProjectId: "rfi/getRfiByProjectId/",
  uploadDocs: "upload/uploadMultipleDocsData",
  updateFileName: "upload/updateFileName",
  getRfiCount: "rfi/getCountForRfiByProjectId/",
  getRFIVersionHistory: "rfi/getRFIVersionHistory",
  getProjectCount: "project/getCountForProject",
  getProjectVersionHistory: "project/getVersionHistoryForProject/",
  // Submittals:
  getSubmittal: "submittal/getSubmittal",
  addSubmittal: "submittal/addSubmittal",
  deleteSubmittalById: "submittal/deleteSubmittalById/",
  getSubmittalById: "submittal/getSubmittalById/",
  updateSubmittalById: "submittal/updateSubmittalById/",
  getSubmittalDataByProjectId: "submittal/getSubmittalDataByProjectId/",
  getSubmittalByFilter: "submittal/getSubmittalByFilter",
  submittalCategory: "submittal/addCategory",
  submittalRes: "submittal/addSubmittalResponsibleItem",
  getAllSubmitResById: "submittal/getSubmittalResponsibleItemByProjectId/",
  getsubmittalCategory: "submittal/getAllCategory/",
  addPackage: "package/addpackage",
  getPackage: "package/getAllpackage/",
  addSubmittalType: "submittalType/addSubmittalType",
  getSubmitallType: "submittalType/getAllSubmittalType/",
  getSubmitallCount: "submittal/getCountForSubmittalByProjectId/",
  getSubmittalVersionHistory: "submittal/getSubmittalVersionHistory",

  //  Specifications

  createSpecifications: "specification/addSpecification",
  getSpecification: "specification/getSpecification",
  getSpecificationByProjectId:
    "specification/getProjectSpecificationDataByProjectId/",
  addSpecificationInCreateProject:
    "specification/addUpdateDeleteSpecificationData",
  getSpecificationById: "specification/getSpecificationById/",
  updateSpecification: "specification/updateSpecificationById/",
  filterSpecification: "specification/getSpecificationByFilter/",
  getSpecificationDataByProjectId:
    "specification/getSpecificationDataByProjectId/",
  deleteSpecificationById: "specification/deleteSpecification/",
  getCountForSpecificationsByProjectId:
    "specification/getCountForSpecificationsByProjectId/",
  addName: "nameFormatLanguage/addName",
  addFormat: "nameFormatLanguage/addFormat",
  addlanguage: "nameFormatLanguage/addLanguage",
  getSpecificationVersionHistory:
    "specification/getSpecificationVersionHistory",
  getNameByProjectId: "nameFormatLanguage/getNameByProjectId/",
  getFormatByProjectId: "nameFormatLanguage/getFormatByProjectId/",
  getLanguageByProjectId: "nameFormatLanguage/getLanguageByProjectId/",

  //Forms
  createForms: "form/addForm",
  getForms: "form/getForm",
  getFormsById: "form/getFormById/",
  updateForms: "form/updateFormById/",
  deleteForms: "form/deleteFormById/",
  filterForms: "form/getFormByFilter",
  getFormByProjectId: "form/getFormByProjectId/",

  //  photos
  createPhotos: "photo/addPhotoData",
  getPhotoDataByProjectId: "photo/getPhotoDataByProjectId/",
  getPhotoDataById: "photo/getPhotoDataById/",
  getPhotoById: "photo/getPhotoDataById/",
  updatePhotos: "photo/updatePhotoData/",
  deletPhotos: "photo/deletePhotoData/",
  filterPhotoData: "photo/filterPhotoData",
  //observation

  //Modals:
  addModel: "model/addModel",
  getModel: "model/getModel",
  getModelById: "model/getModelById/",
  deleteModelById: "model/deleteModelById/",
  updateModelById: "model/updateModelById/",
  getModelByFilter: "model/getModelByFilter",
  getModelByProjectId: "model/getModelByProjectId/",

  //Transmittal

  getTransmittal: "transmittal/getTransmittalData",
  createTransmittal: "transmittal/addTransmittalData",
  getTransmittalById: "transmittal/getTransmittalDataById/",
  updateTransmittal: "transmittal/updateTransmittalData/",
  deleteTransmittal: "transmittal/deleteTransmittalData/",
  getTransmittalDataByProjectId: "transmittal/getTransmittalDataByProjectId/",

  getAutoIdInTransmittal: "transmittal/getCountForTransmittalsByProjectId/",
  updateTransmittals: "transmittal/updateTransmittalData/",
  getTransmittalVersionHistory: "transmittal/gettransMittalVersionHistory",

  //Punch
  getPunch: "punch/getPunch",
  createPunch: "punch/addPunch",
  getPunchById: "punch/getPunchbyid/",
  updatePunch: "punch/updatePunch/",
  deletePunch: "Punch/deletePunch/",
  filterPunch: "Punch/getPunchByFilter",
  getPunchByProjectId: "punch/getPunchByProjectId/",
  getCountForPunchByProjectId: "Punch/getCountForPunchByProjectId/",
  getpunchVersionHistory: "punch/getpunchVersionHistory",
  //observation
  getObservation: "observation/getObservation/",
  filterObservation: "observation/getObservationByFilter",
  getObservationById: "observation/getObservationById/",
  updateObservation: "observation/updateObservationById/",
  deleteObservation: "observation/deleteObservationbyId/",
  addObservation: "observation/addObservation",

  getObservation: "observation/getObservation/",
  getObservationByProjectId: "observation/getObservationByProjectId/",
  addObservationStatus: "observation/addObservationStatus",
  getAllObservationStatusById: "observation/getObservationStatusByProjectId/",
  getAutoIdInObservation: "observation/getCountForObservationByProjectId/",
  getObservationVersionHistory: "observation/getObservationVersionHistory",

  // issues

  getIssue: "issue/getIssue",
  createIssue: "issue/addIssue",
  getIssueById: "issue/getissueById/",
  updateIssue: "issue/updateIssueById/",
  deleteIssue: "issue/deleteIssueById/",
  filterIssue: "issue/getIssueByFilter/",
  getIssueByProjectId: "issue/getIssueByProjectId/",
  getCountIssueId: "issue/getCountForIssueByProjectId/",
  getIssueVersionHistory: "issue/getIssueVersionHistory",
  //  Drawings

  createDrawing: "drawing/addDrawing",
  getAllDrawings: "drawing/getDrawing",
  getDrawingsById: "drawing/getDrawingById/",
  updateDrawings: "drawing/updateDrawingById/",
  deleteDrawings: "drawing/deleteDrawingById/",
  filterDrawings: "drawing/getDrawingByFilter",
  addDrawingSet: "drawingSet/addDrawingSet",
  addDrawingCategory: "drawingCategory/addDrawingCategory",
  getAllDrawingSet: "drawingSet/getAllDrawingSet",
  updateDrawingSetById: "drawingSet/updateDrawingSetById/",
  updateDrawingCategoryById: "drawingCategory/updateDrawingCategoryById/",
  getDrawingByProjectId: "drawing/getDrawingByProjectId/",
  deleteMultipleDrawing: "drawing/deleteMultipleDrawing",
  getAllDrawingCategory: "drawingCategory/getAllDrawingCategory",

  //bservation

  // meeting

  createMeetings: "meeting/addMeeting",
  getMeetings: "meeting/getmeeting",
  getAllMeetings: "meeting/getAllMeeting",
  getUserAssignedToProjectById: "meeting/getUserAssignedToProjectById/",
  getMeetingById: "meeting/getMeetingById/",
  getMeetingByProjectId: "meeting/getMeetingByProjectId/",
  updateMeeting: "meeting/updateMeeting/",
  deletMeeting: "meeting/deleteMeeting/",
  filterMeeting: "meeting/getMeetingByFilter/",
  createTemplate: "template/addTemplate",
  getTemplate: "template/getAllTemplate",
  cerateHeadName: "head/addHead",
  getheadName: "head/getAllHead",
  getTemplateById: "template/getTemplateById/",
  updateTemplate: "template/updateTemplateById/",
  deletTemplate: "template/deleteTemplateById/",
  getMeetingVersionHistory: "meeting/getMeetingVersionHistory",


  // Inspection:
  addInspectionData: "inspection/addInspectionData",
  deleteInspectionData: "inspection/deleteInspectionData/",
  getInspectionDataById: "inspection/getInspectionDataById/",
  getInspectionData: "inspection/getInspectionData",
  updateInspectionData: "inspection/updateInspectionData/",
  getInspectionByFilter: "inspection/getInspectionByFilter",
  getInspectionDataByProjectId: "inspection/getInspectionDataByProjectId/",
  getCountForInspectionByProjectId: "inspection/getCountForInspectionByProjectId/",
  addInspectionType: "inspection/addInspectionType",
  getInspectionTypeByProjectId: "inspection/getInspectionTypeByProjectId/",
  getInspectionVersionHistory: "inspection/getInspectionVersionHistory",

  //Documents
  createFolder: "documentation/createFolder",
  getFolders: "documentation/getFolders/",
  renameFolder: "documentation/renameFolder/",
  deleteFolder: "documentation/deleteFolder/",
  updateFolder: "documentation/updateFolder/",
  renameFile: "documentation/renameFile/",
  deleteFile: "documentation/deleteFile/",
  moveFile: "documentation/moveFile/",

  //  create project  apis
  getProjectByStatus: "project/getProjectsByStatus/",
  projectType: "projectType/addProjectType",
  ProjectCount: "project/getProjectCount",
  getProjectType: "projectType/getAllProjectType",
  addProjectInformation: "project/addProject",
  projectStages: "project/project_stages",
  getProjectById: "project/getProjectData/",
  getTemplateAndRole: "project/getTemplateAndRole/",
  addProjectVersionHistory: "project/createVersionHistory",

  projectTools: "project/project_tools",
  getProjectDirectory: "project/getCompanyAndPerson",
  getContractorSubIndCount: "project/getContractorSubIndCount",
  getCompanyAndPersonData: "project/getCompanyAndPersonData",
  ProjectDrawings: "project/project_drawings",
  addspecifications: "project/project_specifications",
  projectSchedule: "project/project_schedule",
  getAllProjects: "project/getAllProjectData",
  getAllPersonAndCompany: "project/getPersonAndCompany",
  addAssignPersonCompany: "project/addTemplateAndRole/",
  getVersionHistoryById: "project/getVersionHistoryById/",
  getCountOfProjects: "project/getCountOfProjects",
  getProjectCompanyData: "project/getProjectCompanyData/",
  getUserOfCompanyInsideProject: "project/getUserOfCompanyInsideProject/",
  getManyUsersByCompanyIds: "project/getManyUsersByCompanyIds/",

  getCompanyByProjectId: "project/getCompanyByProjectId/",
  getUsersByCompanyId: "project/getManyUsersByCompanyIds/",
  addprojectDepartment: "department/addDepartment",
  getprojectDepartment: "department/getAllDepartment",
  addunit: "measure/addUnit",
  getunit: "measure/getAllUnit",

  // Cost codes apis

  copyAllCodes: "code/copyAllCodes",
  addCodes: "code/addCode/",
  updateCode: "code/updateCode/",
  deleteMany: "code/deleteMany",
  addDivision: "code/addDivision",
  getDivisions: "code/getDivisions",
  getCostCode: "code/getCodes/",
  createCostCode: "code/UpdateCreateCostCodeData",

  //project Directory apis
  addCompany: "company/addCompany",
  getCompany: "company/getCompany",
  getCompanyById: "company/getCompanyById/",
  updateCompany: "company/updateCompany/",
  deleteCompany: "company/deleteCompany/",
  getProjectList: "company/getProjectBelongToAndNot/",
  removeProject: "company/removeFromBelongToList/",
  addProject: "company/addFromNotBelongToList/",

  //project Directory user Api
  addUser: "user/addUser",
  getUser: "user/getAllUser",
  getUserById: "user/getUserById/",
  updateUser: "user/updateUser/",
  deleteUser: "user/deleteUser/",
  getUserByProjectId: "user/getUserByProjectId/",
  assignReviewerApprover: "project/projectApproverAndReviewer/",
  sendEmailWithAttachment: "user/sendAttachment",
  verifyEmail: "user/verifyEmail/",

  //task
  getallrole: "role/getAllRole",
  addRole: "role/addRole",
  getTask: "task/getAllTask",

  // task apis
  addTask: "task/addTask",
  updatetask: "task/updateTask/",
  deletetask: "task/deleteTask/",
  gettaskbyid: "task/getTaskById/",
  filtertask: "task/getTaskByFilter",
  getdatabyid: "task/getTaskByProjectId/",
  getCountForTaskByProjectId: "task/getCountForTaskByProjectId/",
  getTaskVersionHistory: "task/getTaskVersionHistory",

  //permission endpoints

  getPermissionByRole: "permission/getPermissionByRole/",
  updatePermissionById: "permission/updatePermissionById/",
  checkUserRoleIntoProject: "user/checkUserRoleIntoProject",

  // address

  addAddress: "address/addAddress",
  getAddressByToolId: "address/getAddressByToolId/",
  deleteAddress: "address/deleteAddress",

  // direct cost
  addDirectCost: "directCost/addDirectCost",
  getAllDirectCost: "directCost/getAllDirectCost",
  getPrimeContractById: "primeContract/getPrimeContractById/",
  getDirectCostById: "directCost/getDirectCostById/",

  // daily log
  addDailyLogData: "dailyLog/addDailyLogData",
  getDailyLogDataById: "dailyLog/getDailyLogDataById/",
  getAllDailyLogData: "dailyLog/getDailyLogData/",
  updateDailyLogData: "dailyLog/updateDailyLogData/",
  deleteDailyLogData: "dailyLog/deleteDailyLogData/",
  getCountForDailyByProjectId: "dailyLog/getCountForDailyByProjectId/",
  getdailyLogVersionHistory: "dailyLog/getdailyLogVersionHistory",
  getTradeByProjectId: "typePriorityTrade/getTradeByProjectId/",
  addTrade: "typePriorityTrade/addTrade",

  // priority
  addType: "typePriorityTrade/addPriority",
  getType: "typePriorityTrade/getPriorityByProjectId/",

  //Type
  addTypeT: "typePriorityTrade/addType",
  getTypeT: "typePriorityTrade/getTypeByProjectId/",

  // Trade
  addTradeT: "typePriorityTrade/addTrade",
  getTradeT: "typePriorityTrade/getTradeByProjectId/",
  deleteTradeT: "typePriorityTrade/deleteById/",

  // email
  createGroup: "fromEmail/addFromEmail",
  getAllEmailGroup: "fromEmail/getAllFromEmail",
  getGroupById: "fromEmail/getFromEmail/",
  updateGroup: "fromEmail/updateFromEmail",
  deleteGroup: "fromEmail/deleteFromEmail/",

  // tag apis
  createTag: "fromEmail/createTag",
  getTag: "fromEmail/getTag/",
  getFromEmailByUser: "fromEmail/getFromEmailByUser/",

  // email apis
  addEmail: "email/addEmail",
  getEmail: "email/getEmail",
  updateEmailById: "email/updateEmailById/",

  //picklist api routes
  getPicklistData: "peaklist/phaseDetailsList",
  updatePeaklistTemp: "peaklist/updateTemplate/",
  addPhase: "peaklist/addPhase",
  getAllPhase: "peaklist/getAllPhase",
  deletePhase: 'peaklist/deletePhase',

  getCategoryByPhase: "peaklist/categoryByPhase/",
  addCategory: "peaklist/addCategory",

  getSubCategoryByPhase: "peaklist/subCategoryByCategory/",
  addSubCategory: "peaklist/addSubcategory",

  getDocTypeByPhase: "peaklist/doctypeBySubcategory/",
  addDocType: "peaklist/addDoctype",

  addPicklistData: "peaklist/addPhaseDetails",
  checkForTemplateUpdate: "peaklist/checkForTemplateUpdate/",

  getNotification: "rfi/getNotificationByProjectId/",


  // file Management-----

  createFile: "file/addFile",
  updateFile: "file/updateFile/",
  getAllFiles: "file/fileByProjectId/",
  importGlobalData: "file/importGlobal",
  getDocTemplate: "peaklist/phaseDetails/",
  getOneFile: "file/fileById/",
  deleteFile: "file/deleteFile/",
  getFileVersionHistory: "file/getVersionHistory",
  getAllInfo: "file/getAllFiles",
  getCategories: "peaklist/getAllCategories",
  getSubCategories: "peaklist/getAllSubCategories",
  getDocTypes: "peaklist/getAllDocumentTypes",
  addHashTag: "peaklist/createhashTag",
  getTagList: "peaklist/gethashTag",

  // schedule apis

  getAllScheduleValue: "scheduleValue/getAllScheduleValue/",
  getScheduleDataWithPagination: "scheduleValue/getScheduleDataWithPagination/",
  // client request apis

  addClientRequest: "clientRequest/addClientRequest",
  getAllClientRequestForm: "clientRequest/getAllClientRequestForm",


  // fetch parent  of tools apis endpoints
  getTaskParentData: "task/getTaskParentData",
  getRfiParentData: "rfi/getRfiParentData",
  getSubmittalsParentData: "submittal/getSubmittalParentData",

  getPunchParentData: "punch/getPunchParentData",
  getObservationParentData: "observation/getObservationParentData",
  getSpecificationsParentData: "specification/getSpecificationParentData",
  getdailyLogParentData: "dailyLog/getdailyLogParentData",
  getTransmittalParentData: "transmittal/getTransmittalParentData",
  getIssueParentData: "issue/getIssueParentData",
  getIncidentParentData: "incident/getIncidentParentData",
  getInspectionParentData: "inspection/getInspectionParentData",

  // document types apis

  getAllDocumentTypes: "documentType/getAllDocumentTypeData",
  addDocumentType: "documentType/addDocumentType"


};
