import React, { useRef, useState, useEffect } from 'react';
import { SlArrowDown } from "react-icons/sl";
import { Editor } from "primereact/editor";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from '../../Components/Inputs/TextInput';
import SingleSelect from '../../Components/Common/SingleSelect';
import { DragAndDrop } from '../../Components/Inputs/DragAndDrop';
import { Checkbox } from "primereact/checkbox";
import { FaAngleRight } from "react-icons/fa";
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { MdOutlineAttachment } from "react-icons/md";
import { getTransmittalbyid, updateTransmittal } from "../../Api/Admin/AllAPIs";
import FormSkeleton from '../../Components/Common/FormSkeleton';
import { getUserName, parseHashedData } from '../../utils';
import ReactQuill from 'react-quill';

function EditTransmittals() {
  const { id } = useParams();

  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)

  const userObj = userData;
  const userId = userObj?._id;
  const userName = getUserName()

  const [selectedCity, setSelectedCity] = useState([]);
  const [text, setText] = useState("");
  const [checked, setChecked] = useState(false);
  const [ID, setID] = useState();
  const [Title, setTitle] = useState("");
  const [ContractCompany, setContractCompany] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 1 },
    { qty: 1, height: '15rem' },
    { qty: 1, height: "7rem" },
  ]

  const [InvoiceContracts, setInvoiceContracts] = useState([]);
  const [Status, setStatus] = useState([]);
  const [DefaultRetainage, setDefaultRetainage] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const projectData = JSON.parse(localStorage.getItem("projectData"));
  const contractCompany = [
    "Elicia Katherine (Lorem & Co.)",
    "Dennis Paul ",
    "Sheryll Lacie (Lorem & Co.)",
    "Dennis Paul ",
  ];
  const categories = [
    { name: 'Make this visible only to administrators and the following users.', key: 'A' },
    { name: 'Allow These Users To See SOV Items', key: 'M' },

  ];
  const [selectedCategories, setSelectedCategories] = useState([categories[1]]);
  const Invoice = [
    "Build",
    "Constructabilty",
    "Coordinations",
  ];
  const type = [
    "Build",
    "Clash",
    "Client Feedback ",
    "Constructabilty",
    "Coordinations",
    "low"
  ];
  const handleGetTransmittal = async () => {

    try {
      setSkeletonLoader(true);
      const response = await getTransmittalbyid(id);
      const transmittal = response?.data?.data;

      setTitle(transmittal.Title);

      setID(transmittal.ID);

      setInvoiceContracts(transmittal.Invoice_Contacts);


      //setEventDate(new Date(punch.eventDate));
      // setEventTime(new Date(incident.eventTime));
      setDefaultRetainage(transmittal.default_Percentage);

      setStatus(transmittal.Status);

      setContractCompany(transmittal.contract_Company);

      setText(transmittal.question);
      //   setTypes(punch.Type);
      //  setPunchItemManager(punch.punchItemManager);
      //  setTrade(punch.Trade);
      //  setDescription(punch.discription);
      //  setDrawing(punch.linkDrawing);
      //  setTableData(punch.assignees);

    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    handleGetTransmittal();
  }, []);
  const handleUpdateTransmittal = async () => {
  

    const payload = {
      id: ID,

      title: Title,
      status: Status,
      contract_Company: ContractCompany,
      default_Percentage: DefaultRetainage,
      invoice_Contacts: InvoiceContracts,
      question: text,
      userName: userName,
      userId: userId,
      created_by: userName,
      project_name: projectData.project_name,
      project_number: projectData.projectNumber,
      user_name: getUserName()
      // discription:description,
      // linkDrawing:drawing,
      // attachfile:[],
      // assignees:tableData




    };


    payload.user_name = getUserName()
    try {
      await updateTransmittal(id, payload);


    } catch (error) {
      console.log(error);
    }
    navigate("/projectManagement/transmittals")
  };

  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];

    if (e.checked)
      _selectedCategories.push(e.value);
    else
      _selectedCategories = _selectedCategories.filter(category => category.key !== e.value.key);

    setSelectedCategories(_selectedCategories);
  };


  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
  };


  return (
    <div className=' create-trans'>
      <div>
        <div className="row m-0 p-0 mb-3">
          <div className="col-md-12 mb-3" >
            <span
              className="open_Rfi_text "
              onClick={() => navigate("/projectManagement/transmittals")}
            >
              Transmittals
            </span>
            <span className="px-1 open_Rfi_text">
              <FaAngleRight size={15} />{" "}
            </span>
            <span className="createNew_text">Edit Transmittals</span>
          </div>
        </div>
        <div className='d-flex gap-2 '>
          <h5>Transmittals </h5>
          <span> <SlArrowDown style={{ fontSize: '12', marginTop: '2px', color: '#f97b06' }} /></span>
        </div>
        {
          skeletonLoader ?
            <FormSkeleton data={skeletonData} />
            : <>
              <div className="row  m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Transmittals Number:
                  </label>
                  <TextInput value={ID} onChange={(e) => { setID(e.target.value) }} placeholder="Enter Transmittals No."></TextInput>
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Subject:
                  </label>
                  <TextInput value={Title} onChange={(e) => { setTitle(e.target.value) }} placeholder="Enter Subject"> </TextInput>
                </div>
              </div>

              {/*  calender  section start */}
              <div className="row  m-0 p-0">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Contract Company:
                  </label>
                  <SingleSelect placeholder="Contract Company" options={contractCompany} value={ContractCompany} onChange={(e) => { setContractCompany(e.target.value) }} />

                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Invoice Contacts:
                  </label>
                  <SingleSelect
                    placeholder="Invoice Contacts"
                    options={Invoice}
                    value={InvoiceContracts} onChange={(e) => { setInvoiceContracts(e.target.value) }}
                  />
                </div>
              </div>

              {/*  calender section start */}
              <div className="row  m-0 p-0 gap-0 ">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Status:
                  </label>
                  <SingleSelect placeholder="Status" options={type} value={Status} onChange={(e) => setStatus(e.target.value)} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Deafult Retainage:
                  </label>
                  <div className='d-flex gap-1'>
                    <TextInput className="retainage"> </TextInput><span style={{ fontSize: '20px', marginTop: '8px' }}>%</span>
                  </div>

                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">Free Text</label>
                  <input className="form-control" placeholder='Enter Free Text' />
                </div>
              </div>


              <div className="row  m-0 p-0">
                <div className="col-md-3 d-flex between center   ">
                  <label htmlFor="" className="py-2 labelCss">
                    Executed:
                  </label>
                  <Checkbox style={{ color: 'blue' }} onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>



                </div>

              </div>

              <div className="row m-0 p-0">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Private:
                  </label>
                  <div className="flex flex-column gap-3">
                    {categories.map((category) => {
                      return (
                        <div key={category.key} className="flex align-items-center">
                          <Checkbox inputId={category.key} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item.key === category.key)} />
                          <label htmlFor={category.key} className="ml-2 ">
                            {category.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <SingleSelect
                    className="p-0 mt-2"
                    placeholder="Question"
                    options={[]}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                  </label>
                </div>
              </div>

              <div className="row  m-0 p-0">
                <div className="col-md-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Question:
                  </label>
                  <div className="card">
                    {/* <Editor
                      value={text}
                      onTextChange={(e) => setText(e.htmlValue)}
                      style={{ height: "200px" }}
                    /> */}

                    <ReactQuill theme="snow" value={text}
                      onChange={setText} />




                  </div>
                </div>
              </div>

              <div className="row   m-0 p-0 css_com">
                <div className="col-md-12">

                  <label htmlFor="" className="py-2 labelCss text-decoration-underline">
                    {/* <MdOutlineAttachment size={22} /> Attach File(s): */}
                  </label>
                  <DragAndDrop />

                </div>
              </div>
            </>}
        <div className="row m-0 p-0">

          <div className="col-12 d-flex justify-content-end mt-3">
            <button className='btn border me-3' onClick={() => navigate("/projectManagement/transmittals")}>Cancel</button>
            <PrimaryButton text={"Edit"} onClick={handleUpdateTransmittal} />
          </div>
        </div>

      </div>


      <br />


    </div>

  )
}

export default EditTransmittals