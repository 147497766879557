import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Statuscomp } from "../../../../../../Components/Common/StatusComp";


function ChangeOrder_Purchase() {
  const sampleData = [
    {
      id: 1,
      cost_division: "General Requirements",
      cost_code: "$ 1,250.00",
      edited_by: "Joshua Templeton",
      Status: "Approved",
      status: "open",
      edited_on: "2023-01-01",
    },
    {
      id: 2,
      cost_division: "Structural",
      cost_code: "$ 500.00",
      edited_by: "Megan Brown",
      Status: "Pending",
      status: "close",
      edited_on: "2023-02-15",
    },
    {
      id: 3,
      cost_division: "Electrical",
      cost_code: "$ 210.00",
      edited_by: "Alice Green",
      Status: "Approved",
      status: "reopen",
      edited_on: "2023-03-10",
    },
    
  ];

  return (
    <div>
      <div className="card mt-4">
        <DataTable value={sampleData} >
          <Column field="#" header="#"  body={(rowData, rowIndex) => (
                <div>
                  {rowIndex.rowIndex < 9 ? "0" : ""}
                  {rowIndex.rowIndex + 1}
                </div>
              )} />
          <Column
            field="status"
            header="Status"
            style={{ width: "5rem" }}
            body={(rowData) => (
              <div className="d-flex justify-content-center">
                {rowData?.status?.toLowerCase() === "open" ? (
                  <Statuscomp className={"open_status"} />
                ) : rowData?.status?.toLowerCase() === "close" ? (
                  <Statuscomp className={"close_status"} />
                ) : rowData?.status?.toLowerCase() === "reopen" ? (
                  <Statuscomp className={"reopen_status"} />
                ) : rowData?.status?.toLowerCase() === "onhold" ? (
                  <Statuscomp className={"on_hold"} />
                ) : null}
              </div>
            )}
          ></Column>
          <Column field="cost_division" header="Title" />
          <Column field="edited_on" header="Date Initiated" />
          <Column field="edited_on" header="Due Date" />
          <Column field="edited_on" header="Review Date" />
          <Column field="edited_by" header="Reviewer" />
          <Column field="cost_code" header="Amount" />
        </DataTable>
      </div>
    </div>
  );
}

export default ChangeOrder_Purchase;
