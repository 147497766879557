import React, { useState } from 'react'
import { TOOL_STATUS } from '../../constants';
import { getProjectCode, getProjectName, getUserId } from '../../utils';
import ChangeStatusModal from '../ChangeStatusModal/ChangeStatusModal';

export const ChangeApprovalStatus = ({ status, toolData, onSubmit, allData }) => {

    let [open, setOpen] = useState(false);


    const [dropdownvalue, setDropDownValue] = useState(null);
    const [type, setType] = useState("");
    const [comment, setComment] = useState("");
    const [statusRequired, setStatusRequired] = useState(false);
    const [itemId, setItemId] = useState("");

    function changeStatusUi(status) {
        switch (status) {
            case "draft":
                return (
                    <div className="statusOuterD">
                        <div className="statusDotD" />
                        <div className="StatusD">Draft</div>
                    </div>
                );
                break;
            case "inreview":
                return (
                    <div className="statusOuterI">
                        <div className="statusDotI" />
                        <div className="StatusI">In Review</div>
                    </div>
                );
                break;
            case "pendingapproval":
                return (
                    <div className="statusOuterP">
                        <div className="statusDotP" />
                        <div className="StatusP">Pending Approval</div>
                    </div>
                );
                break;
            case "approved":
                return (
                    <div className="statusOuterA">
                        <div className="statusDotA" />
                        <div className="StatusA">Approved</div>
                    </div>
                );
                break;

            default:
                break;
        }
    }



    async function changeStatus(e) {
        e.preventDefault()
        if (dropdownvalue === null) {
            setStatusRequired(true);
            return;
        }

        let email_data = [];

        if (dropdownvalue.name === "Reviewed") {
            // reviewer accept status send email to approver and user

            email_data.push({
                name: toolData?.approver_name,
                to: toolData?.approver_email,
                tool_number: toolData.tool_number,
                tool: toolData.tool,
                approver_name: toolData?.approver_name,
                tool_name: toolData.tool_name,
                project_name: getProjectName(),
                reviewer_name: toolData?.reviewer_name,
                subject: `Action Required:  Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                project_id: getProjectCode(),
                template_type: "reviewedEmailToApprover",
                status: dropdownvalue.code,
            })
            email_data.push({
                name: toolData?.creator_name,
                to: toolData?.creator_email,
                tool_number: toolData.tool_number,
                tool: toolData.tool,
                approver_name: toolData?.approver_name,
                tool_name: toolData.tool_name,
                project_name: getProjectName(),
                reviewer_name: toolData?.reviewer_name,
                project_id: getProjectCode(),
                subject: `FYI:  Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                template_type: "reviewedEmailToUser",
                status: dropdownvalue.code,
            })

        }

        else if (dropdownvalue.name === "Approved") {
            // approver accept status send email to user only

            email_data.push({
                name: toolData?.creator_name,
                to: toolData?.creator_email,
                tool_number: toolData.tool_number,
                tool: toolData.tool,
                tool_name: toolData.tool_name,
                subject: `FYI:Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                project_name: getProjectName(),
                approver_name: toolData?.approver_name,
                reviewer_name: toolData?.reviewer_name,
                project_id: getProjectCode(),
                template_type: "approvedEmailToUser",
                status: dropdownvalue.code,
            })
            email_data.push({
                name: toolData?.reviewer_name,
                to: toolData?.reviewer_email,
                tool_number: toolData.tool_number,
                tool: toolData.tool,
                tool_name: toolData.tool_name,
                subject: `FYI:Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                project_name: getProjectName(),
                approver_name: toolData?.approver_name,
                reviewer_name: toolData?.reviewer_name,
                project_id: getProjectCode(),
                template_type: "approvedEmailToUser",
                status: dropdownvalue.code,
            })

            // +++++++++++++++++++++++ email to reviewer,and all users also of tool approved +++++++++++++++++++++++++++

        }

        else {
            // approver or reviewer rejects request 

            // send email to user in both cases of approval or rejection

            if (type === "approver") {
                // if approver reject request send email to reviewer also with user
                email_data.push({
                    name: toolData.reviewer_name,
                    to: toolData.reviewer_email,
                    tool_number: toolData.tool_number,
                    tool: toolData.tool,
                    tool_name: toolData.tool_name,
                    project_name: getProjectName(),
                    subject: `FYI:Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                    approver_name: toolData?.approver_name,
                    reviewer_name: toolData?.reviewer_name,
                    project_id: getProjectCode(),
                    template_type: "draftEmailToReviewer",
                    status: dropdownvalue.code,
                })

                email_data.push({

                    name: toolData.creator_name,
                    to: toolData.creator_email,
                    tool_number: toolData.tool_number,
                    tool: toolData.tool,
                    tool_name: toolData.tool_name,
                    project_name: getProjectName(),
                    subject: `FYI:Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                    approver_name: toolData?.approver_name,
                    reviewer_name: toolData?.reviewer_name,
                    project_id: getProjectCode(),
                    template_type: "draftReviewerEmailToUser",
                    status: dropdownvalue.code,

                })


            }
            else {
                // send email to creator 
                email_data.push({

                    name: toolData.creator_name,
                    to: toolData.creator_email,
                    tool_number: toolData.tool_number,
                    tool: toolData.tool,
                    tool_name: toolData.tool_name,
                    project_name: getProjectName(),
                    approver_name: toolData?.approver_name,
                    reviewer_name: toolData?.reviewer_name,
                    project_id: getProjectCode(),
                    subject: `FYI:Change in ${toolData.tool} #${toolData.tool_number} ${toolData.tool_name} Approval Status`,
                    template_type: "draftReviewerEmailToUser",
                    status: dropdownvalue.code,

                })
                // ++++++++++++++++++++++ Send email to approver itself ++++++++++++++++++++++++++++++++
            }
        }

  

        let statusComment = {}

        if (type === "approver") {
            statusComment.approver_comment = comment
        }
        if (type === "reviewer") {
            statusComment.reviewer_comment = comment
        }

        onSubmit({ email_data, status: dropdownvalue.code }, toolData._id, toolData?.tool_number, statusComment, allData)
        setOpen(false)




    }

    return (

        <div>

            <div className={`d-flex align-items-center`}>
                {status === TOOL_STATUS.approved && (
                    <div className="w-100">
                        {changeStatusUi(status)}
                    </div>
                )}
                {status === TOOL_STATUS.pendingapproval && (
                    <div
                        className="w-100"
                        title="Pending Approval"
                        onClick={() => {
                            if (getUserId() === toolData.approver_id) {
                                setOpen(true);
                                setType("approver");
                                setItemId(toolData._id);
                            }
                        }}
                    >
                        {changeStatusUi(status)}
                    </div>
                )}
                {status === TOOL_STATUS.draft &&
                    changeStatusUi(status)}
                {status === TOOL_STATUS.inreview && (
                    <div
                        className="w-100"
                        title="Pending review"
                        onClick={() => {
                            if (getUserId() === toolData?.reviewer_id) {
                                setOpen(true);
                                setType("reviewer");
                                setItemId(toolData._id);
                            }
                        }}
                    >
                        {changeStatusUi(status)}
                    </div>
                )}
            </div>

            <ChangeStatusModal
                open={open}
                setOpen={setOpen}
                value={dropdownvalue}
                setValue={setDropDownValue}
                onSubmit={changeStatus}
                setComment={setComment}
                type={type}
                statusRequired={statusRequired}
                setStatusRequired={setStatusRequired}
            />

        </div>

    )

};