import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
// Import Font Awesome Icons (or use another library)
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { getAllScheduleData } from "../../Api/Admin/AllAPIs";
import { getProjectId, loader } from "../../utils";

export default function ScheduleCalendar() {
  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([])

  // State for controlling current date and view
  const [currentDate, setCurrentDate] = useState(new Date());

  // Custom navigation handlers
  // const goToNext = () => {
  //   setCurrentDate(moment(currentDate).add(1, "month").toDate())


  // };

  // const goToPrev = () => setCurrentDate(moment(currentDate).subtract(1, "month").toDate());


  const colors = {
    task: "#FFA726",
    rfi: "#42A5F5",
    submittal: "#66BB6A",
    dailyLog: "#26C6DA",
    observation: "#AB47BC",
    punch: '#FF7043',
    issue: "#EC407A",
    incident: "#005FAE",
    inspection: "#8F9BE3",
    observation: "#AB47BC"
  };


  // Event styling
  const eventStyleGetter = (event) => {

    return ({
      style: {
        backgroundColor: colors[event.tool],
        borderRadius: "5px",
        opacity: 0.9,
        color: "white",
        border: "none",
        display: "block",
        padding: "5px",
      },
    })
  };

  useEffect(() => {
    getAllData()
  }, []);

  async function getAllData() {
    try {
      loader.start()
      let res = await getAllScheduleData(getProjectId())
      let dd = []
      let { taskData = [], rfiData = [], submittalData = [], observationData = [], dailylogData = [], incidentsData = [],
        inspectionData = [], issuesData = [], punchData = [] } = res.data.data[0]
      // rfi data 
      rfiData.forEach((res) => {
        dd.push({
          title: res.subject,
          tool: "rfi",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.dueDate).endOf('day').toDate()
        })
      })
      submittalData.forEach((res) => {
        dd.push({
          title: res.submittal_title,
          tool: "submittal",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.submittal_finalDueDate).endOf('day').toDate()
        })
      })
      taskData.forEach((res) => {
        dd.push({
          title: res.task_name,
          tool: "task",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.dueDate).endOf('day').toDate()
        })
      })
      observationData.forEach((res) => {
        dd.push({
          title: res.title,
          tool: "observation",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.observedDate).endOf('day').toDate()
        })
      })
      dailylogData.forEach((res) => {
        dd.push({
          title: res.visitorName,
          tool: "dailyLog",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.actualEndDate).endOf('day').toDate()
        })
      })
      incidentsData.forEach((res) => {
        dd.push({
          title: res.title,
          tool: "incident",
          start: moment(res.incident_date).startOf("day").toDate(),
          end: moment(res.incident_date).endOf('day').toDate()
        })
      })
      inspectionData.forEach((res) => {
        dd.push({
          title: res.name,
          tool: "inspection",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.created_at).endOf('day').toDate()
        })
      })
      issuesData.forEach((res) => {
        dd.push({
          title: res.issue_title,
          tool: "issue",
          start: moment(res.created_at).startOf("day").toDate(),
          end: moment(res.issue_dueDate).endOf('day').toDate()
        })
      })
      punchData.forEach((res) => {
        dd.push({
          title: res.PunchItemName,
          tool: "punch",
          start: moment(res.date).startOf("day").toDate(),
          end: moment(res.date).endOf('day').toDate()
        })
      })
      setEvents(dd)

    }
    catch (error) {
      console.log(error)
    }
    finally {
      loader.stop()
    }
  }

  return (
    <div className="mt-4">
   
      {/* <div style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        padding: "10px",
       
        borderRadius: "5px",
      }}>
     
        <FaArrowLeft onClick={goToPrev} style={{ cursor: "pointer", fontSize: "20px", marginRight: "10px" }} />

     
        <h2 style={{ margin: 0 }}>{moment(currentDate).format("MMMM YYYY")}</h2>

     
        <FaArrowRight onClick={goToNext} style={{ cursor: "pointer", fontSize: "20px", marginLeft: "10px" }} />
      </div> */}


      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={[Views.MONTH]}
        style={{ height: 800 }}
        date={currentDate}
        onNavigate={(date) => setCurrentDate(date)}
        onSelectEvent={(e) => { }}
        eventPropGetter={eventStyleGetter}
      />

      <br />
      <br />

    </div>
  );
}
