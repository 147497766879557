import React from 'react'
import { ViewPageDocumentName } from '../../../../Components/Common/ViewPageComponents/ViewPageDocumentName'
import { ViewPageTable } from '../../../../Components/Common/ViewPageComponents/ViewPageTable'
import { AiOutlineExport } from 'react-icons/ai'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import moment from 'moment/moment'

const CCOView = () => {

  const commitmentsData = {
    subject: "#C002 Commitments",
    commitments: "General Instructions",
    reason:"10%",
    designatedReview: "Pat Johnson",
    scheduleImpact: "5days",
    amount: "$15000",
    created_by:"Ennis Whale",
    status:"Approved",
    dueDate: "25/04/2024",
    invoicedDate: "26/04/2024",
    paidDate: "11/05/2024",
    reviewDate: "13/05/2024",
    created_on:"02/04/2024",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam nesciunt, quos quod alias suscipit ut, nulla quaerat necessitatibus nihil mollitia, molestias vero quas dignissimos perspiciatis ipsa? Corporis facilis a aut.",
    attachFile: [
      { fileName: "changeOrder_document.pdf", fileUrl: "#" },

    ],
  }
  return(
    <>
     <div>
       <div>
         <div className='w-100 breadcrum d-flex justify-content-between align-items-center'>
           <div className='w-100'>
             <div className=''>
                <div className="budgetbody d-flex flex-column gap-4">
                   <div className="d-flex justify-content-between">
                     <h1 className="headdingBudget"> {commitmentsData?.subject}</h1>
                     <div className="d-flex align-items-center gap-2">
                       <div
                         className="d-flex gap-2 align-items-center"
                         style={{ cursor: "pointer" }}
                         onClick={() => {}}
                       >
                         <AiOutlineExport />
                         Export
                         <MdOutlineKeyboardArrowDown />
                       </div>
                     </div>
                   </div>
 
                 <div className="d-flex flex-column gap-3">
                   <div className="row">
                     <div className="  col-12 col-md-6">
                       <ViewPageTable
                         tableData={[
                           { "commitments": commitmentsData?.commitments },
                           { "Reason": commitmentsData?.reason },
                           { "Designated Review": commitmentsData?.designatedReview },
                           { "Schedule Impact": commitmentsData?.scheduleImpact },
                           { "Amount": commitmentsData?.amount },
                           { "Created By": commitmentsData?.created_by },
                          
                         ]}
                       />
                     </div>
                     <div className="col-12 col-md-6">
                       <ViewPageTable
                         tableData={[
                           { "Status": commitmentsData?.status },
                           { "Due Date": moment(commitmentsData?.dueDate).format("MM/DD/YYYY") },
                           { "Invoiced Date": moment(commitmentsData?.invoicedDate).format("MM/DD/YYYY") },
                           { "Paid Date": moment(commitmentsData?.paidDate).format("MM/DD/YYYY") },
                           { "Review Date": moment(commitmentsData?.reviewDate).format("MM/DD/YYYY") },
                           { "Created On":moment(commitmentsData?.created_on).format("MM/DD/YYYY") },
                       
                         ]}
                       />
                     </div>
                   </div>
                 </div>
 
               </div>
               <div className='view_page_para_head mt-4 mb-2'>Description:</div>
               <div className="view_page_bold_text">
                  <div dangerouslySetInnerHTML={{ __html: commitmentsData?.description }}></div>
               </div>
               <div className="view_page_para_head  mt-4 mb-2">Attached Document:</div>
               {commitmentsData?.attachFile?.map((res, key) => (
                      <ViewPageDocumentName key={key} docUrl={res?.fileUrl} name={res?.fileName} />
            
               ))}
             </div>
 
           </div>
 
         </div>
       </div>
     </div>
    </>
   )
}

export default CCOView