import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import React, { useState } from 'react'
import { Controller } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom'
import CustomDragAndDrop from '../../../../Components/Inputs/CustomDragAndDrop';
import { PrimaryButton } from '../../../../Components/Buttons/PrimaryButton';
import TextInput from '../../../../Components/Inputs/TextInput';
import Calender from '../../../../Components/Common/Calender';
import { Editor } from 'primereact/editor';
import { DragAndDrop } from '../../../../Components/Inputs/DragAndDrop';
import { toast } from '../../../../utils';
import { uploadDocs } from '../../../../Api/Admin/AllAPIs';
import ReactQuill from 'react-quill';

const CreatePCCO = () => {

  const [title, setTitle] = useState('')
  const [primeContract, setPrimeContract] = useState('')
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [designatedReview, setDesignatedReview] = useState('')
  const [scheduleImpact, setScheduleImpact] = useState('')
  const [amount, setAmount] = useState('');
  const [dueDate, setDueDate] = useState('')
  const [invoiceDate, setInvoiceDate] = useState('')
  const [paidDate, setPaidDate] = useState('');
  const [reviewedDate, setReviewedDate] = useState('')
  const [description, setDescription] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const statusArr = [{ name: "Draft" }, { name: "Pending" }, { name: "Rejected" }, { name: "Void" }, { name: "Approved" }]

  const [validateTitle, setValidateTitle] = useState(false)
  const [validatePrimeContract, setValidatePrimeContract] = useState(false)
  const [validateInvoiceDate, setValidateInvoiceDate] = useState(false)
  const [validateReviewDate, setValidateReviewDate] = useState(false)
  const [validateDueDate, setValidateDueDate] = useState(false)
  const [validateStatus, setValidateStatus] = useState(false)
  const [validateAmt, setValidateAmt] = useState(false)
  const [validateReason, setValidateReason] = useState(false)
  const [validatePaidDate, setValidatePaidDate] = useState(false)
  let navigate = useNavigate();


  const checkValidation = () => {
    if (!title.trim()) {
      setValidateTitle(true);
    }
    if (!primeContract) {
      setValidatePrimeContract(true)
    }
    if (!invoiceDate) {
      setValidateInvoiceDate(true)
    }
    if (!dueDate) {
      setValidateDueDate(true)
    }
    if (!amount) {
      setValidateAmt(true)
    }
    if (!status) {
      setValidateStatus(true)
    }
    if (!reason) {
      setValidateReason(true)
    }
    if (
      title.trim() === "" ||
      invoiceDate === "" ||
      status === "" ||
      dueDate === "" ||
      primeContract === "" ||
      amount === "" ||
      dueDate === "") {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  }


  const handleCreate = async () => {
    if (selectedFiles.length > 10) {
      toast.info("You can add only 10 attachments");
      return;
    }

    if (checkValidation()) {
      return;
    }

    try {

      let uploadResponse = {};

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        for (const file of selectedFiles) {
          formData.append("upload", file);
        }
        uploadResponse = await uploadDocs(formData);
      }

      const payload = {
        title: title,
        primeContract: primeContract,
        status: status,
        reason: reason,
        desinatedReview: designatedReview,
        scheduleImpact: scheduleImpact,
        amount: amount,
        dueDate: dueDate,
        invoiceDate: invoiceDate,
        paidDate: paidDate,
        reviewedDate: reviewedDate,
        description: description,
      }
  
      toast.success("PCCO Created Successfully!");
      setTitle("");
      setPrimeContract("");
      setStatus("");
      setReason("");
      setDesignatedReview("");
      setScheduleImpact("");
      setAmount("");
      setDueDate("");
      setInvoiceDate("");
      setPaidDate("");
      setReviewedDate("");
      setSelectedFiles([]);
      setDescription("")
      navigate("/FinantialManagement/changeOrder");
    }
    catch (error) {
      console.error("Error creating PCCO:", error)
    }
  }


  const handleFileInput = (event) => {
    setSelectedFiles(event);
  };
  return (
    <>
      <div>
        <div>
          <div className="row m-0 p-0 mb-4">
            <div className="col-md-12 py-3 ">
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/financialManagement/changeOrder")}
              >
                {" "}

                Prime Contract Change Order
              </span>
              <span className="px-1 open_Rfi_text"> /</span>
              <span className="createNew_text"> Create Prime Contract Change Order </span>
            </div>
          </div>
          <div>

            <div className="page_heading">CREATE PRIME CONTRACT CHANGE ORDER</div>
            <div className='row m-0 p-0 mt-4'>
              <div className='col-md-12'>
                <span className='labelCss'>Title:</span>
                <span className='text-danger'>*</span>
                <TextInput
                  className={`my-2 ${validateTitle ? "Validation" : ""}`}
                  placeholder="Title required"
                  onChange={(e) => setTitle(e.target.value)}
                  onClick={() => setValidateTitle(false)}
                />

              </div>
              <div className='row m-0 p-0 mt-4'>
                <div className='col-md-6 col-12'>
                  <span className='labelCss'>PrimeContract:</span>
                  <span className='text-danger'>*</span>
                  <Dropdown
                    placeholder='Prime contract'
                    options={primeContract}
                    onChange={(e) => setPrimeContract(e.target.value)}
                    className={`w-100 mt-1 ${validatePrimeContract ? "Validation" : ""}`}
                    onClick={() => setValidatePrimeContract(false)} />

                </div>
                <div className='col-md-6 col-12'>
                  <span className='labelCss'>Status:</span>
                  <span className='text-danger'>*</span>
                  <Dropdown
                    placeholder='Status'
                    options={statusArr}
                    onChange={(e) => setStatus(e.target.value)}
                    className={`w-100 mt-1 ${validateStatus ? "Validation" : ""}`}
                    onClick={() => setValidateStatus(false)} />

                </div>

              </div>

              <div className='row m-0 p-0 mt-4'>
                <div className='col-md-6 col-12'>
                  <span className="py-2 labelCss">Reason:</span>{" "}
                  <span className="text-danger">*</span>
                  <Dropdown
                    placeholder='Reason'
                    className={`w-100 mt-1 ${validateReason ? "Validation" : ""}`} />
                </div>
                <div className='col-md-6 col-12'>
                  <span className="py-2 labelCss">Designated Reviewe:</span>{" "}
                  <span className="text-danger">*</span>
                  <Dropdown
                    placeholder='Designated Reviewer'
                    className={`w-100 mt-1`} />
                </div>
              </div>
              <div className='row m-0 p-0 mt-4'>
                <div className='col-md-6 col-12'>
                  <span className="py-2 labelCss">Schedule Impact:</span>{" "}
                  <span className="text-danger">*</span>
                  <Dropdown
                    placeholder='Schedule Impact'
                    className={`w-100 mt-1`} />
                </div>
                <div className='col-md-6 col-12'>
                  <span className="py-2 labelCss">Amount:</span>{" "}
                  <span className="text-danger">*</span>
                  <TextInput
                    className={`my-2 ${validateAmt ? "Validation" : ""}`}
                    placeholder="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                    onClick={() => setValidateAmt(false)}
                  />
                </div>
              </div>
              <div className='row m-0 p-0 mt-4'>
                <div className="col-md-6">
                  <span className="py-2 labelCss">Due Date:</span>

                  <Calender
                    selected={dueDate}
                    className={`p-0 mt-1 ${validateDueDate ? "Validation" : ""} `}
                    // ${validationDueDate ? "Validation" : ""}
                    // onClick={() => setValidationDueDate(false)}
                    placeholder="Due Date"
                    onChange={(e) => setDueDate(e.value || null)}
                    onClick={() => setValidateDueDate(false)}
                  />
                </div>
                <div className="col-md-6">
                  <span className="py-2 labelCss">Invoiced Date:</span>

                  <Calender
                    selected={invoiceDate}
                    className={`p-0 mt-1  `}
                    // ${validationDueDate ? "Validation" : ""}
                    // onClick={() => setValidationDueDate(false)}
                    placeholder="Due Date"
                    onChange={(e) => setInvoiceDate(e.value || null)}
                    onClick={() => setValidateInvoiceDate(false)}
                  />
                </div>
              </div>
              <div className='row m-0 p-0 mt-4'>
                <div className="col-md-6">
                  <span className="py-2 labelCss">Paid Date:</span>

                  <Calender
                    selected={paidDate}
                    className={`p-0 mt-1 ${validatePaidDate ? "Validation" : ""} `}
                    // ${validationDueDate ? "Validation" : ""}
                    // onClick={() => setValidationDueDate(false)}
                    placeholder="Paid Date"
                    onChange={(e) => setPaidDate(e.value || null)}
                    onClick={() => setValidatePaidDate(false)}
                  />
                </div>
                <div className="col-md-6">
                  <span className="py-2 labelCss">Reviewed Date:</span>

                  <Calender
                    selected={reviewedDate}
                    className={`p-0 mt-1  `}
                    // ${validationDueDate ? "Validation" : ""}
                    onClick={() => setValidateReviewDate(false)}
                    placeholder="Reviewed Date"
                    onChange={(e) => setReviewedDate(e.value || null)}
                  />
                </div>
              </div>
              <div className="row m-0 p-0 mt-4  ">
                <div className="col-md-12">
                  <span className="py-2 labelCss ">Description:</span>
                  {/* <SingleSelect options={[]} label={"Location:"} placeholder={"Location"} /> */}
                  {/* <TextInput
                  placeholder="Enter Description"
                  style={{}}
                  className="mt-1"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></TextInput> */}
                  {/* <Editor
                    value={description}
                    onTextChange={(e) => setDescription(e.htmlValue)}
                    style={{ height: "200px" }}
                    className="mt-1"
                  /> */}

                  <ReactQuill theme="snow"
                    value={description}
                    onChange={setDescription}
                  />

                </div>
              </div>

              <div className="row m-0 p-0 mt-4  ">
                <div className="col-md-12">
                  <DragAndDrop
                    setRemovePhoto={setRemovePhoto}
                    value={fileName}
                    setFiles={selectedFiles}
                    onChange={handleFileInput}
                    setValue={setFileName}
                    setPreviousFiles={setPreviousFiles}
                  />
                </div>
              </div>
              <div className="row m-0 p-0 mt-4 mb-4">
                <div className="col-12 d-flex justify-content-end mt-3 mb-4">
                  <button
                    className="btn border me-3"
                    onClick={() => navigate("/financialManagement/orderChange")}
                  >
                    Cancel
                  </button>
                  <PrimaryButton text={"Create"} onClick={handleCreate} />
                </div>
              </div>



            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default CreatePCCO