import React from 'react'
import ReactApexChart from 'react-apexcharts';

export const DonutChart = ({value,label}) => {

 

    let series = value.map(e=>e.value)

    const options = {
        chart: {
          type: "donut",
        },
        labels:label,
        dataLabels: {
          enabled: false,
        },
        colors: ["#ffce54", "#a0d468", "#4fc1e9"],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total Responses",
                  formatter: function (w) {
                    // Return the sum of all series data
                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  },
                },
              },
            },
          },
        },
    
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: false,
                    },
                  },
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
    
        legend: {
          fontSize: "16px", // Customize font size here
          fontFamily: "Inter", // Customize font family here
          fontWeight: 400, // Customize font weight here, 400 for normal, 700 for bold
    
          formatter: function (val, opts) {
            return val;
          },
        },
      };

    return (
        <ReactApexChart
            options={options}
            // series={donutData.map((item) => item.data)}
            series={series}
            type="donut"
        />
    )
};
