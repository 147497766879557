import React from 'react'
import { ViewPageTable } from '../../../../Components/Common/ViewPageComponents/ViewPageTable'
import moment from 'moment/moment'
import { AiOutlineExport } from 'react-icons/ai'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { ViewPageDocumentName } from '../../../../Components/Common/ViewPageComponents/ViewPageDocumentName'

const PCCOView = () => {

  const primeContractData = {
    subject: "#PC002 Change Order for April",
    primeContract: "General Instructions",
    reason:"10%",
    designatedReview: "Pat Johnson",
    scheduleImpact: "5days",
    amount: "$15000",
    created_by:"Ennis Whale",
    status:"Approved",
    dueDate: "25/04/2024",
    invoicedDate: "26/04/2024",
    paidDate: "11/05/2024",
    reviewDate: "13/05/2024",
    created_on:"02/04/2024",
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam nesciunt, quos quod alias suscipit ut, nulla quaerat necessitatibus nihil mollitia, molestias vero quas dignissimos perspiciatis ipsa? Corporis facilis a aut.",
    attachFile: [
      { fileName: "changeOrder_document.pdf", fileUrl: "#" },

    ],
  }
  return (
   <>
    <div>
      <div>
        <div className='w-100 breadcrum d-flex justify-content-between align-items-center'>
          <div className='w-100'>
            <div className=''>
            <div className="budgetbody d-flex flex-column gap-4">
                  <div className="d-flex justify-content-between">
                    <h1 className="headdingBudget"> {primeContractData?.subject}</h1>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {}}
                      >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                      </div>
                    </div>
                  </div>

                <div className="d-flex flex-column gap-3">
                  <div className="row">
                    <div className="  col-12 col-md-6">
                      <ViewPageTable
                        tableData={[
                          { "primeContract": primeContractData?.primeContract },
                          { "Reason": primeContractData?.reason },
                          { "Designated Review": primeContractData?.designatedReview },
                          { "Schedule Impact": primeContractData?.scheduleImpact },
                          { "Amount": primeContractData?.amount },
                          { "Created By": primeContractData?.created_by },
                         
                        ]}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <ViewPageTable
                        tableData={[
                          { "Status": primeContractData?.status },
                          { "Due Date": moment(primeContractData?.dueDate).format("MM/DD/YYYY") },
                          { "Invoiced Date": moment(primeContractData?.invoicedDate).format("MM/DD/YYYY") },
                          { "Paid Date": moment(primeContractData?.paidDate).format("MM/DD/YYYY") },
                          { "Review Date": moment(primeContractData?.reviewDate).format("MM/DD/YYYY") },
                          { "Created On":moment(primeContractData?.created_on).format("MM/DD/YYYY") },
                      
                        ]}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className='view_page_para_head mt-4 mb-2'>Description:</div>
              <div className="view_page_bold_text">
                 <div dangerouslySetInnerHTML={{ __html: primeContractData?.description }}></div>
              </div>
              <div className="view_page_para_head  mt-4 mb-2">Attached Document:</div>
              {primeContractData?.attachFile?.map((res, key) => (
                     <ViewPageDocumentName key={key} docUrl={res?.fileUrl} name={res?.fileName} />
           
              ))}
            </div>

          </div>

        </div>
      </div>
    </div>
   </>
  )
}

export default PCCOView