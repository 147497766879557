import React from 'react'

export const HtmlIntoText = ({ html ,className}) => {
    return (
        <div className={className}
            dangerouslySetInnerHTML={{ __html: html }} />
    )
}
export const HtmlIntoString = ({ html }) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };