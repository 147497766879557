import { useCallback, useEffect, useState } from 'react'
import { getUserByProjectId } from '../Api/Admin/AllAPIs'

export const useFetchAllUsersOfProject = (project_id) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  let getData = useCallback(async () => {
    try {
      setIsLoading(true)
      let res = await getUserByProjectId(project_id)
      setData(res?.data?.data?.[0].combined_users || [])
    } catch (error) {
      console.log(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  return { data, isLoading, error, getData }
}
