import React, { useEffect, useState, useRef } from "react";
import { EditButton } from "../../Components/Buttons/EditButton";
import style from "./meeting.module.css";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import { DeleteButton } from "../../Components/Buttons/DeleteButton";
import { useNavigate, useParams } from "react-router-dom";
import { getMeetingById } from "../../Api/Admin/AllAPIs";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import moment from 'moment/moment';
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { downloadPdf } from "../../Components/Common/ViewPageComponents/downloadPdf";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import ViewVersion from "../../Components/Common/ViewVersion";

export const ViewMeeting = ({ usedAsComponent, includeVersionHistory }) => {

  let [data, setData] = useState({})
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "5rem" },


  ]

  const [getData, setGetData] = useState()
  const { id } = useParams()
  const navigate = useNavigate();
  let pdfRef = useRef()
  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents", files: getData?.attachment?.map(e => e?.fileUrl)
      }
    ])
  }

  const handleGetData = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getMeetingById(id)
    
      setGetData(res?.data?.data?.[0])

    } catch (error) {
      console.log(error)
    }
    finally {
      setSkeletonLoader(false);
    }
  }
  useEffect(() => {
    handleGetData()
  }, [])

  return (


    <div className="rfiView">
      {
        skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-1"} gap={"gap-3"} /> :
          <div className="rfiparent">
            <div className="w-100 breadcrum mb-2">
              <div className="w-100">
                <span
                  className="open_Rfi_text"
                  onClick={() => navigate("/projectManagement/meetings")}
                >
                  Meetings
                </span>
                <span className="px-1 open_Rfi_text">/</span>
                <span className="createNew_text"> {getData?.meeting_name}</span>
              </div>
            </div>

            <div className="rfibody d-flex flex-column gap-4">
              <div className="d-flex justify-content-between">
                <h1 className="headdingRFI">{getData?.meeting_name}</h1>
                <div className="d-flex align-items-center gap-2" onClick={pdfDownload}>
                  <div
                    className="d-flex gap-2 align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <AiOutlineExport />
                    Export
                    <MdOutlineKeyboardArrowDown />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column gap-3">
                <div className="row">
                  <div className="col-6">
                    <ViewPageTable tableData={[
                      { "Meeting Name": getData?.meeting_name || "--" },
                      { "Video Conferencing Link": getData?.videoConferencingLink },
                      { "Meeting Location": getData?.meetingLocation },
                      { "Meeting Date": moment(getData?.meetingDate).format("DD/MM/YYYY") || "--" },
                      { 'Meeting Guests': getData?.meeting_guest?.join(",") || "--" }
                    ]} />
                  </div>
                  <div className="col-6">
                    <ViewPageTable tableData={[
                      { "Time Zone": getData?.timeZone },
                      { "Start Time": getData?.startTime || "--" },
                      { 'End Time': getData?.endTime || "--" },
                      { 'Meeting Attendees': getData?.meeting_attendees.map(e => e.first_name).join(",") || "--" },

                    ]} />
                  </div>

                </div>
              </div>
            </div>

            <div className="view_page_para_head mt-4 mb-2">Meeting Description:</div>
            <div className="view_page_bold_text">
              <HtmlIntoText html={getData?.description} />
            </div>
            <div className="view_page_para_head mt-4 mb-2">Attached Document:</div>
            <div className="d-flex flex-wrap">

              {getData?.attachment?.map((res, key) => {
           
                return (
                  <ViewPageDocumentName name={res.fileName} />
                )
              })}
            </div>
          </div>}


      {(!usedAsComponent || includeVersionHistory) &&
        <>
          <hr />
          <ViewVersion isView={true} />
        </>
      }

      {!usedAsComponent &&
        <div className="d-flex justify-content-end my-3">
          <button className='btn border' onClick={() => navigate("/projectManagement/meetings")}>Back</button>
        </div>
      }



      <div style={{ height: "0px", overflow: "hidden" }}>
        <div ref={pdfRef}>
          <ViewPagesPdfTemplate
            header={`${getData?.meeting_name}`}
            description={{
              key: "Meetings Description",
              value: <HtmlIntoText html={getData?.description} />
            }}
            tableData1={[
              { "Meeting Name": getData?.meeting_name || "--" },
              { "Video Conferencing Link": getData?.videoConferencingLink },
              { "Meeting Location": getData?.meetingLocation },
              { "Meeting Date": moment(getData?.meetingDate).format("DD/MM/YYYY") || "--" },
              { 'Meeting Guests': getData?.meeting_guest?.join(",") || "--" },
              { "Time Zone": getData?.timeZone },
              { "Start Time": getData?.startTime || "--" },
              { 'End Time': getData?.endTime || "--" },
              { 'Meeting Attendees': getData?.meeting_attendees.map(e => e.first_name).join(",") || "--" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
