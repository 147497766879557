import React,{useState, useEffect} from 'react';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Calender from '../../Components/Common/Calender';

function Filter({visible, setVisible, res, applyFilter}) {

  const [name, setName]=useState(null);
  const [create, setCreate] = useState(null);
  const [datecreated, setDateCreated]= useState(null)
  const [clearBtn, setClearBtn] = useState(false);

  useEffect(() => {
    const isFilterSelected = name !== null ||  datecreated !== null;
  
    setClearBtn(isFilterSelected);
  }, [name,  datecreated]);


  const handleFilter=async()=>{
    const filterCriteria={
      forms_name: name,
      forms_date_created: datecreated,
      forms_created_by: "string"
    }
    try{
      applyFilter(filterCriteria)
      setName(null);
      setDateCreated(null)
      setVisible(false)

    }
    catch(err)
    {
      console.error(err)
    }

  }
  const handleClearAll=()=>{
    setName(null)
    setDateCreated(null)
   
  }

  return (
   <>
    <div className="card flex justify-content-center ">
      <Dialog
        header="Filter Forms"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
        draggable={false}
      >
        <div className="row mt-4">
          <div className="col-md-6 ">
            <label className="labels mb-2">Name:</label>

            <div className="card flex justify-content-center  ">
              <Dropdown
                value={name}
                onChange={(e)=>setName(e.target.value)}
                options={res?.map((item)=>({label:item?.forms_name, value:item?.forms_name}))}
                optionLabel="label"
                placeholder="Name"
                // className="w-full md:w-14rem dropdown_box"
              />
            </div>
          </div>
       
         
       
          <div className="col-md-6">
            <label className="labels mb-2">Created By:</label>
            <div className="card flex justify-content-center  ">
              <Dropdown
               
               options={[]}
                optionLabel="label"
                placeholder="Created By "
                // className="w-full md:w-14rem dropdown_box"
              />
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <label className="labels mb-2">Date Created:</label>

            <Calender placeholder="mm/dd/yy" value={datecreated} selected={datecreated} onChange={(e)=>setDateCreated(e.target.value)} />

          </div>


        
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center  gap-3">
              <span   className={`clear_btn  ${clearBtn ? "" : "disabled"}`}  onClick={handleClearAll} >Clear All</span>
            
              <button className="filter_btn"  onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn " onClick={handleFilter}>Apply Filter</Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
   </>
  )
}

export default Filter