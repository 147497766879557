import React, { useState, useEffect, useRef } from "react";
import "primeicons/primeicons.css";
import style from "../Roles.module.css";

import { NavLink, useLocation } from "react-router-dom";
import { MdOutlineManageAccounts } from "react-icons/md";
import { RiExchangeDollarLine } from "react-icons/ri";
import { IoArrowBack } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import { getActionsPermission, getProjectId, getUserType } from "../../../utils";
import { ADMIN_ROLES } from "../../../constants";
import { cleanRolesAndPermissions } from "../../../Store/Slice/RolesAndPermissionsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LiaEditSolid } from "react-icons/lia";
import { PiToolboxLight } from "react-icons/pi";
import { GrTask } from "react-icons/gr";
import {
  DailyLogIcon,
  DrawingIcon,
  EmailIcon,
  FormsIcon,
  IncidentIcon,
  InspectionIcon,
  IssueIcon,
  MeetingsIcon,
  ModelIcon,
  ObservationIcon,
  PhotosIcon,
  PunchIcon,
  RfiIcon,
  ScheduleIcon,
  SpecificationIcon,
  SubmittalIcon,
  TransmittalIcon,
} from "../../Icons/SidebarIcons";
import { FiEdit } from "react-icons/fi";
import { getProject, getProjectAssignData } from "../../../Api/Admin/AllAPIs";

const AdminProject = ({ opentoggle }) => {
  const [openToggle, setopenToggle] = useState(opentoggle);
  const [openFinancial, setOpenFinancial] = useState(false);
  let location = useLocation();


  const [openCoreTools, setOpenCoreTools] = useState(false);
  const [allowTools, setAllowTools] = useState([]);
  const [project_id, setProject_id] = useState({});

  let sel = useSelector((e) => e.rolesAndPermissionData);
  let emailTabAccess = useSelector((e) => e.emailTabAccessData.isAllow);
  let dispatch = useDispatch();
  const navRefs = useRef({});

  let isAdmin = ADMIN_ROLES.includes(getUserType());

  const storedData = localStorage.getItem("projectId");


  const getProjectData = async () => {
    try {
      if (!getProjectId()) {
        return
      }
      const res = await getProject(localStorage.getItem("projectId"));
      if (res?.data?.data?.length !== 0) {
        let data = res?.data?.data[0]
        let arr = data?.project_tools || []
      
        setAllowTools(arr)
      }
    }
    catch (err) {
      console.log(err)
    }

  };
  useEffect(() => {
    getProjectData();
  }, []);


  function checkToolAllow(tool) {
    let tools = [...allowTools]
    let tName = tools?.map(res => res?.name)
    return tName?.includes(tool)
  }


  useEffect(() => {
    setProject_id(storedData);

    if (storedData) {
    }
  }, []);

  useEffect(() => {
    if (location.pathname && navRefs.current[location.pathname]) {
      navRefs.current[location.pathname].scrollIntoView({ block: "center" });
    }
  }, []);

  return (
    <div>
      <NavLink
        style={{ textDecoration: "none" }}
        to={`${isAdmin ? "/mainOverview" : "/projects"}`}
      >
        <div
          className={`navlink ${!openToggle && "d-flex justify-content-center"
            }`}
        >
          <div className="fs-6">
            <IoArrowBack />
          </div>
          <div className="mt-1">
            {openToggle ? <span className="">Home</span> : ""}
          </div>
        </div>
      </NavLink>

      {getActionsPermission("Overview") && (
        <NavLink style={{ textDecoration: "none" }} to={`/overviewproject`}>
          <div
            className={`navlink ${!openToggle && "d-flex justify-content-center"
              }`}
          >
            <div className="fs-6">
              <RiExchangeDollarLine />
            </div>
            {openToggle && (
              <div className="mt-1">
                <span className="">Project Overview</span>
              </div>
            )}
          </div>
        </NavLink>
      )}

      {getActionsPermission("View Project") && (
        <NavLink
          className={({ isActive }) =>
            isActive ? "superadmin_dashboard_sub_navlink" : ""
          }
          ref={(el) =>
          (navRefs.current[
            `/coretools/admin/viewproject/${storedData}`
          ] = el)
          }
          style={{ textDecoration: "none" }}
          // to={`/projectManagement/admin/projectform`}
          to={`/coretools/admin/viewproject/${project_id}`}
        >

          <div className={`navlink ${!openToggle && "d-flex justify-content-center"
            }`}>
            <div className="fs-6">
              <LiaEditSolid />
            </div>
            {openToggle && (<div className="mt-1">  <div className="">Edit Project</div></div>)}
          </div>
        </NavLink>
      )}


      {getActionsPermission("Core tools") && (
        <NavLink
          style={{ textDecoration: "none" }}
          to={`/projectManagement`}
          ref={(el) => (navRefs.current["/projectManagement"] = el)}
          onClick={(e) => {
            setOpenCoreTools(!openCoreTools);
            e.preventDefault();
          }}
        >
          <div
            className={`navlink ${!openToggle && "d-flex justify-content-center"
              }`}
          >
            <div className="fs-6">
              <PiToolboxLight />
            </div>
            {openToggle && (
              <div className="mt-1">
                <span className="">Tools</span>
              </div>
            )}
          </div>
        </NavLink>
      )}

      <div className={`${style.sub_navlinks}`}>
        <div
          className={`${style.toggle_of_sub_navlinks} toggle_of_sub_navlinks`}
          style={{
            display:
              openCoreTools || location.pathname.includes("projectManagement") || location.pathname.includes("/coretools/tasktable")
                ? "flex"
                : "none",
          }}
        >
          {openToggle && (
            <>
              {getActionsPermission("Task") && checkToolAllow('Task') && (
                <NavLink
                  ref={(el) =>
                    (navRefs.current[`/coretools/tasktable/${storedData}`] = el)
                  }
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/coretools/tasktable`}
                >
                  <div className="navlink">
                    <GrTask />
                    <div className="subNav">Task</div>
                  </div>
                </NavLink>
              )}
              <>
                {checkToolAllow('RFIs') && getActionsPermission("Rfi") && (
                  <NavLink
                    ref={(el) =>
                      (navRefs.current["/projectManagement/openrfis"] = el)
                    }
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/openrfis`}
                  >
                    <div className="navlink">
                      <RfiIcon />
                      {openToggle && <div className="subNav">RFIs</div>}
                    </div>
                  </NavLink>
                )}


                {checkToolAllow('Submittals') && getActionsPermission("Submittals") && (
                  <NavLink
                    ref={(el) =>
                      (navRefs.current["/projectManagement/submittals"] = el)
                    }
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/submittals`}
                  >
                    <div className="navlink">
                      <SubmittalIcon />
                      {openToggle && <div className="subNav">Submittals</div>}
                    </div>
                  </NavLink>
                )}



                {checkToolAllow('Emails') && getActionsPermission("Email") && emailTabAccess && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/email`}
                    ref={(el) => (navRefs.current["/projectManagement/email"] = el)}
                  >
                    <div className="navlink">
                      <EmailIcon />
                      {openToggle && <div className="subNav">Emails</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Punch List') && getActionsPermission("Punch") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/punch`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/punch"] = el)
                    }
                  >
                    <div className="navlink">
                      <PunchIcon />
                      {openToggle && <div className="subNav">Punch Lists</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Observations') && getActionsPermission("Observation") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/observation`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/observation"] = el)
                    }
                  >
                    <div className="navlink">
                      <ObservationIcon />
                      {openToggle && <div className="subNav">Observations</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Specification') && getActionsPermission("Specification") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/Specifications`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/Specifications"] = el)
                    }
                  >
                    <div className="navlink">
                      <SpecificationIcon />
                      {openToggle && <div className="subNav">Specifications</div>}
                    </div>
                  </NavLink>
                )}

                {checkToolAllow('Daily log') && getActionsPermission("Daily log") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/dailyLog`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/dailyLog"] = el)
                    }
                  >
                    <div className="navlink">
                      <DailyLogIcon />
                      {openToggle && <div className="subNav">Daily Log</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Transmittals') && getActionsPermission("Transmittal") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    ref={(el) =>
                      (navRefs.current["/projectManagement/transmittals"] = el)
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/transmittals`}
                  >
                    <div className="navlink">
                      <TransmittalIcon />
                      {openToggle && <div className="subNav">Transmittals</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Issues') && getActionsPermission("Issue") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    ref={(el) =>
                    (navRefs.current["/projectManagement/coordinationIssues"] =
                      el)
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/coordinationIssues`}
                  >
                    <div className="navlink">
                      <IssueIcon />
                      {openToggle && <div className="subNav">Issues</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Incidents') && getActionsPermission("Incident") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    ref={(el) =>
                      (navRefs.current["/projectManagement/incident"] = el)
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/incident`}
                  >
                    <div className="navlink">
                      <IncidentIcon />
                      {openToggle && <div className="subNav">Incidents</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Inspections') && getActionsPermission("Inspection") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/inspection`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/inspection"] = el)
                    }
                  >
                    <div className="navlink">
                      <InspectionIcon />
                      {openToggle && <div className="subNav">Inspections</div>}
                    </div>
                  </NavLink>
                )}

                {checkToolAllow("Schedule")&& getActionsPermission("Schedule")  && <NavLink
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/projectManagement/schedule`}
                  ref={(el) =>
                    (navRefs.current["/projectManagement/schedule"] = el)
                  }
                >
                  <div className="navlink">
                    <ScheduleIcon />
                    {openToggle && <div className="subNav">Schedule</div>}
                  </div>
                </NavLink>

                }





                {checkToolAllow('Drawings') && getActionsPermission("Drawing") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/drawings`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/drawings"] = el)
                    }
                  >
                    <div className="navlink">
                      <DrawingIcon />
                      {openToggle && <div className="subNav">Drawings</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Photos') && getActionsPermission("Photo") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/photos`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/photos"] = el)
                    }
                  >
                    <div className="navlink">
                      <PhotosIcon />
                      {openToggle && <div className="subNav">Photos</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Models') && getActionsPermission("Model") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/models`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/models"] = el)
                    }
                  >
                    <div className="navlink">
                      <ModelIcon />
                      {openToggle && <div className="subNav">Models</div>}
                    </div>
                  </NavLink>
                )}
                {checkToolAllow('Meetings') && getActionsPermission("Meeting") && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "superadmin_dashboard_sub_navlink" : ""
                    }
                    style={{ textDecoration: "none" }}
                    to={`/projectManagement/meetings`}
                    ref={(el) =>
                      (navRefs.current["/projectManagement/meetings"] = el)
                    }
                  >
                    <div className="navlink">
                      <MeetingsIcon />
                      {openToggle && <div className="subNav">Meetings</div>}
                    </div>
                  </NavLink>
                )}
                {/* {getActionsPermission("Form") && (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  ref={(el) =>
                    (navRefs.current["/projectManagement/forms"] = el)
                  }
                  style={{ textDecoration: "none" }}
                  to={`/projectManagement/forms`}
                >
                  <div className="navlink">
                    <FormsIcon />
                    {openToggle && <div className="subNav">Forms</div>}
                  </div>
                </NavLink>
              )} */}
              </>

              {/* {getActionsPermission("Directory") && (
                <NavLink
                  ref={(el) => (navRefs.current["/projectManagement/directory"] = el)}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/projectManagement/directory`}
                >
                  <div className="navlink">
                    <div className="subNav">Directory</div>
                  </div>
                </NavLink>
              )} */}

              {/* {getActionsPermission("Documents") && (
                <NavLink
                  ref={(el) => (navRefs.current["projectManagement/documents"] = el)}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`projectManagement/documents`}
                >
                  <div className="navlink">
                    <div className="subNav">Documents</div>
                  </div>
                </NavLink>
              )} */}
              {/* <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/manageaccounts/lawyer`}
          > */}
              {/* <div className="navlink">
                <div className="subNav">Reports</div>
              </div> */}
            </>
          )}

          {/* </NavLink> */}
        </div>
      </div>
      {getActionsPermission("Documents") && (
        <NavLink style={{ textDecoration: "none" }} to={`/documents`}>
          <div
            className={`navlink ${!openToggle && "d-flex justify-content-center"
              }`}
          >
            <div className="fs-6">
              <MdOutlineManageAccounts />
            </div>
            {openToggle && (
              <div className="mt-1">
                <span className="">Document Management</span>
              </div>
            )}
          </div>
        </NavLink>
      )}
      {getActionsPermission("Reports") && (
        <NavLink style={{ textDecoration: "none" }} to={`/reports`}>
          <div
            className={`navlink ${!openToggle && "d-flex justify-content-center"
              }`}
          >
            <div className="fs-6">
              <MdOutlineManageAccounts />
            </div>
            {openToggle && (
              <div className="mt-1">
                <span className="">Reports</span>
              </div>
            )}
          </div>
        </NavLink>
      )}


      {/* {getActionsPermission("Financial Management") && (
        <NavLink
          style={{ textDecoration: "none" }}
          ref={(el) => (navRefs.current["/financialManagement"] = el)}
          to={`/financialManagement`}
          onClick={(e) => {
            setOpenFinancial(!openFinancial);
            e.preventDefault();
          }}
        >
          <div
            className={`navlink ${
              !openToggle && "d-flex justify-content-center"
            }`}
          >
            <div className="fs-6">
              <MdOutlineManageAccounts />
            </div>
            {openToggle && (
              <div className="mt-1">
                <span className="">Financial Management</span>
              </div>
            )}
          </div>
        </NavLink>
      )} */}
      <div className={`${style.sub_navlinks}`}>
        <div
          className={`${style.toggle_of_sub_navlinks} toggle_of_sub_navlinks`}
          style={{
            display:
              openFinancial || location.pathname.includes("financialManagement")
                ? "flex"
                : "none",
          }}
        >
          {openToggle && (
            <>
              {getActionsPermission("Budget") && (
                <NavLink
                  // ref={(el) => navRefs.current['/financialManagement/budget'] = el}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/financialManagement/budget`}
                >
                  <div className="navlink">
                    <RfiIcon />
                    {openToggle && <div className="subNav">Budget</div>}
                  </div>
                </NavLink>
              )}
              {getActionsPermission("Direct Cost") && (
                <NavLink
                  // ref={(el) => navRefs.current['/financialManagement/budget'] = el}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/financialManagement/directCost`}
                >
                  <div className="navlink">
                    <RfiIcon />
                    {openToggle && <div className="subNav">Direct Cost</div>}
                  </div>
                </NavLink>
              )}
              {getActionsPermission("Prime Contract") && (
                <NavLink
                  // ref={(el) => navRefs.current['/financialManagement/budget'] = el}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/financialManagement/Primecontract`}
                >
                  <div className="navlink">
                    <RfiIcon />
                    {openToggle && <div className="subNav">Prime Contract</div>}
                  </div>
                </NavLink>
              )}

              {getActionsPermission("Commitment") && (
                <NavLink
                  // ref={(el) => navRefs.current['/financialManagement/budget'] = el}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/financialManagement/commitment`}
                >
                  <div className="navlink">
                    <RfiIcon />
                    {openToggle && <div className="subNav">Commitment</div>}
                  </div>
                </NavLink>
              )}
              {getActionsPermission("Change Order") && (
                <NavLink
                  // ref={(el) => navRefs.current['/financialManagement/budget'] = el}
                  className={({ isActive }) =>
                    isActive ? "superadmin_dashboard_sub_navlink" : ""
                  }
                  style={{ textDecoration: "none" }}
                  to={`/financialManagement/changeOrder`}
                >
                  <div className="navlink">
                    <RfiIcon />
                    {openToggle && <div className="subNav">Change Order</div>}
                  </div>
                </NavLink>
              )}
            </>
          )}
        </div>
      </div>

      {/* <NavLink onClick={() => {
        dispatch(cleanRolesAndPermissions())
      }} style={{ textDecoration: "none" }} to={`/`}>
        <div className="navlink">
          <div className="fs-6">
            <CiLogout />
          </div>
          <div className="mt-1">
            {openToggle ? <span className="">Logout</span> : ""}
          </div>
        </div>
      </NavLink> */}
    </div>
  );
};
export default AdminProject;
