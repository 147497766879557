import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "primereact/editor";

import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import TextInput from "../../Components/Inputs/TextInput";

import { Checkbox } from "primereact/checkbox";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IoMdAdd } from "react-icons/io";
import AddMeetingItem from "./AddMeetingItem";
import { InputText } from "primereact/inputtext";
import {
  getTemplateById,
  updateTemplate,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { EditButton } from "../../Components/Buttons/EditButton";
import DeleteButton from "../../Components/Buttons/DeleteButton";
import EditAgenda from "./EditAgenda";
import { toast } from "../../utils";
import ReactQuill from "react-quill";

export const EditTemplate = () => {
  const [fileUpload, setFileUpload] = useState([]);
  const [fileName, setFileName] = useState();
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [privates, setPrivate] = useState(false);
  const [sections, setSections] = useState([]);
  const [meetingName, setMeetingName] = useState("");
  const [question, setQuestion] = useState();
  const [visible, setVisible] = useState(false);
  const [itemIndex, setitemIndex] = useState(false);
  const [getAgenda, setGetAgenda] = useState([]);
  const [section, setSection] = useState([]);
  const [sectionValue, setSectionValue] = useState();
  const [getHeadName, setGetHeadName] = useState();
  const [headerInitialization, setHeaderInitialization] = useState(false);
  const [editDailg, setEditDailog] = useState(false);
  const [idSend, setIdSend] = useState("");
  const [selectedAgendaItem, setSelectedAgendaItem] = useState(null);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [Validation, setValidation] = useState({
    meetingName: false,
  });

  const checkValidation = () => {
    if (!meetingName) {
      setValidation((prevState) => ({
        ...prevState,
        meetingName: true,
      }));
    }

    if (!meetingName) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const handleFileInput = (event) => {
    setFileUpload(event);
  };

  // const handleAddItems = (sectionId) => {
  //   setVisible(true);
  //   setitemIndex(sectionId);
  // };
  // const handleAddSection = () => {
  //   const newId = section.length + 1;
  //   const newSection = { id: newId, header: "", tableData: [] };
  //   setSection([...section, newSection]);
  // };

  const handleEditAgendaItem = (item) => {
    setSelectedAgendaItem(item);
    setEditDailog(true);
  };

  const handleUpdateAgendaItem = (updatedItem) => {
  
    const updatedSections = section.map((sec) => {
      if (sec.tableData.find((item) => item.id === updatedItem.id)) {
        return {
          ...sec,
          tableData: sec.tableData.map((item) =>
            item.id === updatedItem.id ? updatedItem : item
          ),
        };
      }
      return sec;
    });
    setSection(updatedSections);
    setEditDialogVisible(false);
    setSelectedAgendaItem(null);
  };



  const handleHeaderChange = (sectionId, event) => {
 
    const newValue = event.target.value;
    setSection((prevSections) =>
      prevSections.map((sec) => {
        if (sec.id === sectionId) {
          return { ...sec, header: newValue };
        }

        return sec;
      })
    );
  };

  // const handleEditDailog = (id) => {
  //   setIdSend(id);
  //   setEditDailog(true);
  // };

  const getTemplate = async () => {
    try {
      const res = await getTemplateById(id);
      const templateData = res?.data?.data[0];
      setFileName(
        templateData.template_attachment.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );
      setMeetingName(templateData.template_meeting_name);
      setQuestion(templateData.template_overview);
      setPrivate(templateData.template_private_meeting);
      setPreviousFile([...templateData.template_attachment]);

    
      const agendaData = templateData.template_data.map((data) => ({
        id: data._id,
        header: data.template_agenda,

        tableData: data.template_meeting_item.map((item) => ({
          template_meeting_title: item.template_meeting_title,
          template_meeting_priority: item.template_meeting_priority,
          template_meeting_attachment: item.template_meeting_attachment,
          id: item._id,

          template_meeting_description: item?.template_meeting_description,
          template_meeting_category: item?.template_meeting_category,
        })),
      }));
      setSection(agendaData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    if (checkValidation()) {
      return;
    }
    try {
      let uploadedFiles = [];

      if (fileUpload && fileUpload.length > 0) {
        const formData = new FormData();
        fileUpload.forEach((file) => formData.append("upload", file));

        const uploadResponse = await uploadDocs(formData);

        uploadedFiles = uploadResponse.data;
      }
      const payload = {
        template_meeting_name: meetingName,
        template_private_meeting: privates,
        template_overview: question,
        template_attachment: [...previousFile, ...uploadedFiles],
        deleted_file: removePhoto,
        template_data: section.map((sec) => ({
          template_agenda: sec.header,
          template_meeting_item: sec.tableData.map((item) => ({
            template_meeting_title: item.template_meeting_title,
            template_meeting_priority: item.template_meeting_priority,
            template_meeting_category: item.template_meeting_category,
            template_meeting_description: item.template_meeting_description,
            template_meeting_attachment: item.template_meeting_attachment || [],
            deleted_file: removePhoto,
          })),
        })),
      };

      await updateTemplate(id, payload);
      toast.success('Template Updated Succesfully!');
      navigate("/meetings/template");
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again!");
      console.error("Error updating template:", error);
    }
  };

  useEffect(() => {
    getTemplate();
  }, []);

  return (
    <>
      <div className="mainContainer main-container">
        <div className="card p-md-4 p-sm-3 p-2 ">
          <div className="row">
            <div className="col-12 py-3  px-4 " style={{ marginLeft: "4px" }}>
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/meetings")}
              >
                {" "}
                Meeting
              </span>
              <span className="px-1 open_Rfi_text">/</span>
              <span className="createNew_text"> Edit Template</span>
            </div>
          </div>

          <div className="row px-4 mt-4">
            <div className="col-12 px-3">
              <span className="newRfi_text"> Edit Template </span>
            </div>

            <div className="row mt-3  px-3">
              <div className="col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Meeting:
                </label>
                <TextInput
                  placeholder="Enter Drawing Number"
                  value={meetingName}
                  onChange={(e) => {
                    setMeetingName(e.target.value);
                  }}
                  onClick={() =>
                    setValidation({ ...Validation, meetingName: false })
                  }
                  className={Validation.meetingName ? "Validation" : ""}
                />
              </div>
            </div>

            <div className="row mt-3  px-3">
              <div className="col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Question:
                </label>
                <div className="card">
                  {/* <Editor
                    value={question}
                    onTextChange={(e) => setQuestion(e.htmlValue)}
                    style={{ height: "100px" }}
                  /> */}

                  <ReactQuill theme="snow"
                    value={question}
                    onChange={setQuestion}
                  />


                </div>
              </div>
            </div>

            <div className="row mt-3  px-3 css_com">
              <div className="col-12">
                <DragAndDrop
                  setRemovePhoto={setRemovePhoto}
                  value={fileName}
                  setFiles={setFileUpload}
                  onChange={handleFileInput}
                  setValue={setFileName}
                  setPreviousFiles={setPreviousFile}
                />
              </div>
            </div>

            <div className="d-flex  gap-2 mt-3 ">
              <Checkbox
                inputId="ingredient1"
                name="category"
                onChange={(e) => setPrivate(e.checked)}
                checked={privates}
              />

              <label htmlFor="ingredient1" className="ml-2">
                Private Meeting
              </label>
            </div>

            {/* <div className="my-4">
              <button
                className="filter_btn d-flex align-items-center gap-2"
                onClick={handleAddSection}
              >
                <IoMdAdd /> Add Section
              </button>
            </div> */}
            {/* 
            <div className="my-5">
              <span className="filter_btn" onClick={handleAddSection}>
                <IoMdAdd /> Add Agenda
              </span>
            </div> */}

            {section.map((sections, index) => (
              <div key={sections.id} className="mt-4">
                <div className="row my-2">
                  <div className="col-4 d-flex gap-2 align-items-center">
                    <span>{index + 1}</span>

                    <InputText
                      className="w-100"
                      value={sections.header}
                      onChange={(e) => handleHeaderChange(sections.id, e)}
                      placeholder="Untitled Section"
                    />
                  </div>
                </div>
                <BorderedTable style={{ width: "100%" }}>
                  <DataTable
                    value={sections.tableData}
                    style={{ width: "100%" }}
                  >
                    <Column
                      field="template_meeting_title"
                      style={{ width: "50%" }}
                      header={
                        !headerInitialization ? (
                          <>
                            <div className="d-flex w-100">Title</div>
                          </>
                        ) : (
                          section.header
                        )
                      }
                      body={(rowData, rowIndex) => {
                        return (
                          <div className="d-flex gap-3 align-items-center">
                            <div>{rowData?.template_meeting_title}</div>
                          </div>
                        );
                      }}
                    ></Column>
                    <Column
                      field="template_meeting_priority"
                      style={{ width: "20%" }}
                      header="Priority"
                      body={(rowData) => {
                        return <div>{rowData?.template_meeting_priority}</div>;
                      }}
                    ></Column>
                    <Column
                      field="attach"
                      header="Attachments"
                      style={{ width: "20%" }}
                      body={(rowData) => {
                        const getImg = rowData.template_meeting_attachment.map(
                          (imgName) => imgName.fileName
                        );
                        return (
                          <div className="col-md-8 col-12 d-flex">{getImg}</div>
                        );
                      }}
                    ></Column>

                    <Column
                      field=""
                      header=""
                      body={(rowData) => (
                        <div className="d-flex align-items-normal">
                          <span onClick={() => handleEditAgendaItem(rowData)}>
                            <EditButton />
                          </span>
                        </div>
                      )}
                    ></Column>
                  </DataTable>
                </BorderedTable>
                <br />
                <div>
                  {/* <button
                    className="filter_btn d-flex align-items-center gap-2"
                    // onClick={() => handleAddRow(section.id)}

                    onClick={() => {
                      handleAddItems(section.id);
                      setitemIndex(index);
                    }}
                  >
                    <IoMdAdd /> Add Items
                  </button> */}
                </div>
                <br />
              </div>
            ))}

            <div className="btn_main px-5 mt-4 ">
              <span
                className="cancelBtn  px-3 py-2"
                onClick={() => navigate("/meetings")}
              >
                {" "}
                Cancel
              </span>

              <span className="CreateRfi px-3 py-2" onClick={handleUpdate}>
                Update Template
              </span>
            </div>
          </div>
        </div>
      </div>

      <EditAgenda
        editDailg={editDailg}
        setEdit={setEditDailog}
        agendaItem={selectedAgendaItem}
        onSubmit={handleUpdateAgendaItem}
      />
    </>
  );
};
