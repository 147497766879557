import React, { useEffect, useState } from "react";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Popover from "../../Components/Common/Popover";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { getCompanyAndPersonData } from "../../Api/Admin/AllAPIs";
import { TableSkelton } from "../../Components/Common/TableSkelton";

function SubContractor({tableData}) {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [skeletonLoader,setSkeletonLoader]= useState(true)
  const [rowClick, setRowClick] = useState(true);
  const [search, setSearch] = useState("");
  let project_id=localStorage.getItem("projectId");
  let [subContractors, setSubContractors] = useState([])
  async function getCompay() {
    try {
      setSkeletonLoader(true);
      let body = { type: "subcontractor" }
      const res = await getCompanyAndPersonData(body,project_id);
     

      setSubContractors(res.data.data)

    } catch (err) {
      console.log(err);
    }
    finally{
      setSkeletonLoader(false);
    }
  }
  useEffect(() => {
    // fetchCompany()
    getCompay();
  }, []);
  return (
    <>
      <div>
        <br/>
        {/* <div className="row">
          <div className="col-md-3 SearchInput">
            <SearchInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-9 d-flex justify-content-end mb-3 gap-3">
            <button className="filter_btn">Filter Directory</button>

            <button className={`clear_btn  `}>Clear All</button>
          </div>
        </div> */}
       { 
         skeletonLoader? <TableSkelton header={["Full Name","Phone","Email","Address",""]}/> :
       <BorderedTable>
          <DataTable
            value={tableData}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={5}

            rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
          >
            <Column field="company_name" header="Full Name"></Column>
            <Column field="business_phone" header="Phone"></Column>
            <Column field="email_address" header="Email"></Column>
            <Column field="address" header="Address"></Column>
            <Column field="address" header=""
              body={(rowData) => (
                <div className="`d-flex align-items-center gap-2">
                  <Popover
                    options={["Edit", "view", "Delete"]}
                  />
                </div>
              )}
            >
            </Column>
          </DataTable>
        </BorderedTable>}
      </div>
    </>
  );
}

export default SubContractor;
