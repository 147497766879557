import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import Chart from "react-apexcharts";
import {
  getProject,
} from "../../../../Api/Admin/AllAPIs";
import FormSkeleton from "../../../Common/FormSkeleton";
import SevedModal from "../../../Common/SavedModal";

export const Projectschedule = ({ mode }) => {
  const [idStore, setIdStore] = useState();
  const [skeletonLoader, setSkeletonLoader] = useState(mode == "Edit" ? true : false)
  const [visible, setVisible] = useState(false)
  const [backVisible, setBackVisible] = useState(false)
  const [saveVisible, setSavedVisible] = useState(false)
  const [saveVisible2, setSavedVisible2] = useState(false)
  let [btnLoader, setBtnLoader] = useState(false)
  const [options] = useState({
    chart: {
      type: 'rangeBar',
      distributed: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        distributed: true,
        borderRadius: 15,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      x: {
        format: 'dd MMM',
        // format: 'dd MMM HH:mm',
      },
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [series, setSeries] = useState([
    { data: [] }
  ])




  useEffect(() => {
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, []);


  const handleAdd = async () => {
    navigate("/projects");
  };


  useEffect(() => {
    if (idStore) {
      getProjectes();
    }
  }, [idStore]);

  const getProjectes = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getProject(idStore);
      const getData = res?.data?.data[0];
      if (getData && getData?.project_stages?.length !== 0) {
        const transformedData = getData?.project_stages?.map(item => ({
          x: item.stage_name,
          y: [
            new Date(item.expected_start_date).getTime(),
            new Date(item.expected_end_date).getTime()
          ]
        }));
      
        setSeries([{
          data: transformedData
        }])

      } else {
        // setIsVersionHistory(false)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  };
  const skeletonData = [
    { qty: 1 },
    { qty: 1 },
    { qty: 1 },
    { qty: 1 },
  ]

  const navigate = useNavigate();
  return (
    <>
      <div className="row pl-4 mt-5">
        <div className="col-12 px-3">
          <span className="project_name_text"> 8. PROJECT SCHEDULE</span>
        </div>

        <div className="py-3">
          {skeletonLoader ? <FormSkeleton data={skeletonData} /> : series[0]?.data !== undefined && series[0]?.data?.length !== 0 ? <Chart options={options} series={series} type="rangeBar" height={400} /> : <div>No data available</div>}

        </div>

        <div className="contoleButtons mt-3 d-flex justify-content-between">


          <div className="d-flex gap-3">
            <PrimaryButton text={"Cancel"}
              onClick={() => setVisible(true)}
            />
            <PrimaryButton text={"Back"}
              onClick={() => setBackVisible(true)}
            />


            {/* <button
              className="btn border me-3"
              onClick={() => setBackVisible(true)}
            >
              Back
            </button> */}

          </div>




          <div className="right d-flex gap-3">
            {/* <button
              className="skip filter_btn"
              onClick={() => setSavedVisible(true)}
            >
              Save
            </button> */}
            <PrimaryButton
              text={"Save"}
              onClick={() => setSavedVisible2(true)}
            />
            <PrimaryButton
              text={"Save & Exit"}
              onClick={() => setSavedVisible(true)}
            />
          </div>
        </div>
      </div>


      <SevedModal
        yes={() => navigate("/portfolio/projectform/step-7")}
        visible={backVisible}
        setVisible={setBackVisible}
        heading='Go Back to Previous Step'
        type='warning'
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}
      />
      <SevedModal
        yes={() => handleAdd()}
        visible={saveVisible}
        setVisible={setSavedVisible}
        heading='Create the Project'
        type='info'
        loader={btnLoader}
        headingText={`Are you sure you want to save the changes and exit successfully?`}

      />
      <SevedModal
        yes={() => navigate("/projects")}
        visible={visible}
        setVisible={setVisible}
        heading='Confirm Cancellation'
        type='warning'
        headingText={`Are you sure you want to discard this data?`}
      />

      <SevedModal
        yes={() => handleAdd()}
        visible={saveVisible2}
        setVisible={setSavedVisible2}
        heading='Create the Project'
        type='info'
        loader={btnLoader}
        headingText={`Are you sure want to save your Data?`}
      />


    </>
  );
};
