import React, { useState, useEffect } from "react";
import Loader from "../../Components/Common/Loader";
import TextInput from "../../Components/Inputs/TextInput";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import SingleSelect from "../../Components/Common/SingleSelect";
import { getActionsPermission, getUserId, getUserName, parseHashedData, toast } from "../../utils";
import { Editor } from "primereact/editor";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  addHashTag,
  createFile,
  getAllCategoriesByPhase,
  getAllDocType,
  getAllSubCategoriesByPhase,
  getAllTags,
  getDocTemplate,
  getUserByProjectId,
  oneFile,
  updateFile,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import CustomDragAndDrop from "../../Components/Inputs/CustomDragAndDrop";
import { getAllPhase } from "./../../Api/Admin/AllAPIs";
import { LiaDownloadSolid } from "react-icons/lia";
import fileDownload from "js-file-download";
import axios from "axios";
import { MultiSelect } from "primereact/multiselect";
import { MdAdd } from "react-icons/md";
import AddInternalAttribute from "./AddInternalAttribute";
import { TOOL_STATUS } from "../../constants";
import { FormHeader } from "../../Components/Common/FormHeader";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { CreateDocType } from "./CreateDocType";
import { useFetchAllDocumentTypes } from "../../hooks/useFetchAllDocumentTypes";

export const CreateUpdateDrawing = ({ role }) => {
  const navigate = useNavigate();
  const d = useSelector(e => e.rolesAndPermissionData);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const { data: allDocumentTypes, getData: fetchDocumentTypeData } = useFetchAllDocumentTypes()

  const statusOptions = [
    { label: "Not Started", value: "Not Started" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
    { label: "Ready for Review", value: "Ready for Review" },
    { label: "Incomplete", value: "Incomplete" },
  ];

  const scopeOptions = [
    { label: "Global", value: "global" },
    { label: "Project", value: "project" },
  ];

  function pageFor() {
    switch (role) {
      case "drawings":
        return "drawing";
        break;
      case "photos":
        return "photo";
        break;
      case "models":
        return "model";
        break;
      case "documents":
        return "document";
        break;

      default:
        break;
    }
  }


  const [addTag, setAddTag] = useState();
  const [tagListing, setTagListing] = useState([]);
  const [documentNumber, setDocumentNumber] = useState("")
  const [type, setType] = useState("")
  const [] = useState("")

  const [hashTagsList, setHashTagsList] = useState(null);
  const [openModal1, setOpenModal1] = useState(false)
  const [allPhase, setAllPhase] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allDocTypeData, setAllDocTypeData] = useState([]);
  const [file, setFile] = useState([]);
  const [deleteFile, setDeleteFile] = useState([]);
  const [docTitle, setDocTitle] = useState(null);
  const [scope, setScope] = useState(null);
  const [workStatus, setWorkStatus] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [updatedBy, setUpdatedBy] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [approver, setApprover] = useState(null);
  const [comments, setComments] = useState(null);
  const [approverComments, setApproverComments] = useState(null);
  const [reviewerComments, setReviewerComments] = useState(null);
  const [status, setStatus] = useState(null);
  // const [hashTag, setHashTag] = useState(null);

  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const projectData = JSON.parse(localStorage.getItem("projectData"));

  const userName = getUserName();

  const [formData, setFormData] = useState({
    phase_id: null,
    category_id: null,
    sub_category_id: null,
    doctype_id: null,
  });

  const [Validation, setValidation] = useState({
    docTitle: false,
    template: false,
    phase_id: false,
    category_id: false,
    doctype_id: false,
    scope: false,
    workStatus: false,
    createdBy: false,
    updatedBy: false,
    reviewer: false,
    approver: false,
    comments: false,
    docNumber: false,
    type: false
    // hashTag: false,
  });
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoader(true);
          const response = await oneFile(id);
          let data = response?.data?.data[0];
          console.log(data, "[[[[[[[[[[[")
          setDocTitle(data?.title);
          setFile(data?.files?.map((item) => item));
          setFormData((prevFormData) => ({
            ...prevFormData,
            phase_id: data?.phase_details?._id,
            category_id: data?.category_details?._id,
            sub_category_id: data?.subcategory_details?._id,
            doctype_id: data?.doctype_details?._id,
          }));
          setScope(data?.scope);
          setWorkStatus(data?.workflow_status);
          setComments(data?.comment);
          setHashTagsList(data?.hashtag?.map((item) => item));
          setUpdatedBy(data?.updatedBy);
          setCreatedBy(data?.createdBy);
          setReviewer(data?.reviewer_id);
          setApprover(data?.approver_id);
          setApproverComments(data?.approver_comment || "");
          setReviewerComments(data?.reviewer_comment || "");
          setType(data?.documentTypeData?._id)
          setDocumentNumber(data?.document_number)
          setStatus(data.status);


          setLoader(false)
        } catch (error) {
          setLoader(false)
          console.log(error);
        }
      }
    };

    fetchData();
  }, [id]);



  const getAllPhaseData = async () => {
    try {
      let res = await getAllPhase();
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));
      setAllPhase(finalData);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCategoriesData = async (id) => {
    try {
      let res = await getAllCategoriesByPhase(id);
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));
      setAllCategory(finalData);
    } catch (err) {
      console.log(err);
    }
  };

  async function getAllSubCategoriesData(id) {
    try {
      let res = await getAllSubCategoriesByPhase(id);
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));

      setAllSubCategory(finalData);
    } catch (err) {
      console.log(err);
    }
  }

  async function getAllDocTypeData(id, role) {

    try {
      let res = await getAllDocType(id, role);
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.name,
        value: res?._id,
      }));

      setAllDocTypeData(finalData);
    } catch (err) {
      console.log(err);
    }
  }



  useEffect(() => {
    if (formData?.phase_id) {
      getAllCategoriesData(formData?.phase_id)
    }
  }, [formData?.phase_id])


  useEffect(() => {
    if (formData?.category_id) {

      getAllSubCategoriesData(formData?.category_id);
      if (!formData?.sub_category_id) {

        getAllDocTypeData(formData?.category_id, "category");
      }

    }
  }, [formData?.category_id])


  useEffect(() => {
    if (formData?.sub_category_id) {

      getAllDocTypeData(formData?.sub_category_id, "subcategory");
    }
  }, [formData?.sub_category_id])


  const handleChange = async (e, key) => {
    setFormData((pre) => {
      return {
        ...pre,
        [key]: e,
      };
    });

    if (key == "phase_id") {
      if (e == null) {
        setAllCategory([]);
      } else {
        // await getAllCategoriesData(e);
      }
      setFormData((pre) => {
        return {
          ...pre,
          category_id: null,
          sub_category_id: null,
          doctype_id: null,
        };
      });
    }
    if (key == "category_id") {
      if (e == null) {
        setAllSubCategory([]);
        setAllDocTypeData([]);
      } else {
        // await getAllSubCategoriesData(e);
        // await getAllDocTypeData(e, "category");
      }
      setFormData((pre) => {
        return {
          ...pre,
          sub_category_id: null,
          doctype_id: null,
        };
      });
    }

    if (key == "sub_category_id") {
      if (e == null) {
        setAllDocTypeData([]);
      } else {
        // await getAllDocTypeData(e, "subcategory");
      }
      setFormData((pre) => {
        return {
          ...pre,
          doctype_id: null,
        };
      });
    }
  };

  const getAllUsersOfProject = async () => {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      let allUsersData = allUsers
        ?.map((item) => ({
          label: item?.first_name,
          value: item?._id,
        }));

      setAllUsersOfProject(allUsersData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllUsersOfProject();
    getAllPhaseData();
  }, []);



  const checkValidation = () => {
    if (docTitle == null) {
      setValidation((prevState) => ({
        ...prevState,
        docTitle: true,
      }));
    }
    if (formData?.phase_id == null) {
      setValidation((prevState) => ({
        ...prevState,
        phase_id: true,
      }));
    }
    if (formData?.category_id == null) {
      setValidation((prevState) => ({
        ...prevState,
        category_id: true,
      }));
    }
    if (formData?.doctype_id == null) {
      setValidation((prevState) => ({
        ...prevState,
        doctype_id: true,
      }));
    }

    if (scope === null) {
      setValidation((prevState) => ({
        ...prevState,
        scope: true,
      }));
    }
    if (workStatus === null) {
      setValidation((prevState) => ({
        ...prevState,
        workStatus: true,
      }));
    }

    if (file.length == 0) {
      toast.info("Templates are mandatory to upload");
      setValidation((prevState) => ({
        ...prevState,
        template: true,
      }));
    }

    if (
      workStatus == null ||
      scope == null ||
      docTitle == null ||
      formData?.phase_id == null ||
      formData?.category_id == null ||
      formData?.doctype_id == null ||
      file?.length == 0
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };



  const handleDocument = async () => {
    if (checkValidation()) {
      return;
    }

    // let currentStatus = status;

    // if (reviewer !== undefined && approver !== undefined) {
    //   currentStatus = "draft";
    //   setStatus(currentStatus);
    // }
    const payload = {
      document_number: documentNumber,
      type: type,
      phase_id: formData?.phase_id,
      category_id: formData?.category_id,
      sub_category_id: formData?.sub_category_id,
      doctype_id: formData?.doctype_id,
      title: docTitle,
      scope: scope,
      workflow_status: workStatus,
      createdBy: createdBy,
      updatedBy: updatedBy,
      reviewer_id: reviewer,
      approver_id: approver,
      reviewer_comment: reviewerComments || "",
      approver_comment: approverComments || "",
      comment: comments,
      hashtag: hashTagsList,

      files: [
        {
          fileName: "",
          fileUrl: "",
        },
      ],
      project_id: projectId,
      role: pageFor(),
      creator_email: getUserId(),
      created_by: userName,
      project_name: projectData.project_name,
      project_number: projectData.projectNumber,
    };
    // manage approver status accordingly 

    if (id) {
      if (status === TOOL_STATUS.draft && approver && reviewer) {
        payload.status = TOOL_STATUS.inreview
      }
    }
    else {
      payload.status = approver && reviewer ? TOOL_STATUS.inreview : TOOL_STATUS.draft
    }

    try {
      setLoader(true);

      console.log(payload)


      if (id) {

        let docs = [];
        if (file?.length !== 0) {
          let forUpload = file?.filter((res) => res?.file?.name !== undefined);
          let notForUpload = file?.filter(
            (res) =>
              res?.file?.name === undefined &&
              !deleteFile?.includes(res?.fileName)
          );
          let uploadResponse = { data: [] };
          if (forUpload?.length !== 0) {
            const formData = new FormData();
            forUpload?.forEach((res) => formData.append("upload", res?.file));
            uploadResponse = await uploadDocs(formData);
          }
          docs = [...notForUpload, ...uploadResponse?.data];
          payload.files = docs;
          payload.deleted_file = deleteFile;

        }
        payload.user_name = getUserName()
        let res = await updateFile(id, payload)
        if (res) {
          setLoader(false);
          if (pageFor() === "document")
            navigate("/documents");
          else {
            navigate(`/projectManagement/${pageFor()}s`)
          }
          setDeleteFile("");
        }
      }

      else {
        let docs = [];
        if (file?.length !== 0) {
          let forUpload = file?.filter((res) => res?.file?.name !== undefined);
          let notForUpload = file?.filter(
            (res) =>
              res?.file?.name === undefined &&
              !deleteFile?.includes(res?.fileName)
          );
          let uploadResponse = { data: [] };
          if (forUpload?.length !== 0) {
            const formData = new FormData();
            forUpload?.forEach((res) => formData.append("upload", res.file));
            uploadResponse = await uploadDocs(formData);
          }
          docs = [...notForUpload, ...uploadResponse?.data];
          payload.files = docs;

        }
        let res = await createFile(payload);
        if (res) {
          setLoader(false);
          if (pageFor() === "document")
            navigate("/documents");
          else {
            navigate(`/projectManagement/${pageFor()}s`)
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };



  const handleTemplate = async (id) => {
    try {
      const res = await getDocTemplate(id);

      let data = res?.data?.data[0]?.templates;

      data?.map(async (item) => {
        try {
          const fileUrl = item.fileUrl;

          const response = await axios.get(fileUrl, {
            responseType: 'blob',
          });

          const fileName = fileUrl.split('/').pop();
          fileDownload(response.data, fileName);
        } catch (err) {
          console.error("Error downloading file:", err);
        }
      });
    } catch (error) {
      // console.log("Error fetching templates:", error);
    }
  };





  const createNewTag = async (e) => {
    e.preventDefault();
    try {
      setLoader1(true)
      let payload = {
        tag_name: addTag
      }
      const res = await addHashTag(payload);

      setLoader1(false)
      fetchData()
      setAddTag("");
      setOpenModal1(false);
    } catch (error) {
      setLoader1(false)

      setOpenModal1(false)
    }
  }

  const fetchData = async () => {
    try {
      const res = await getAllTags();
      let data = res?.data?.data || [];
      let finalData = data?.map((res) => ({
        label: res?.tag_name,
        value: res?._id,
      }));

      setTagListing(finalData);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])





  return (
    <>
      <AddInternalAttribute
        visible={openModal1}
        toggle={() => {
          setAddTag("")
          setOpenModal1(false)
        }}
        dialogFor={"Tag"}
        label={"Tag"}
        value={addTag}
        setValue={setAddTag}
        onSubmit={createNewTag}
        loader={loader1}
      />
      <div className=" main-container">
        <div>




          <FormHeader
            to={pageFor() === "document" ? "/documents" : `/projectManagement/${pageFor()}s`}
            formHeader={`${id ? `Edit ${pageFor()}` : `New ${pageFor()}`}`}
            breadcrumItem={[`${pageFor()}s`, `${id ? `Edit ${pageFor()}s` : `Add New ${pageFor()}s`}`]} />


          {/* <div className="page_heading text-uppercase">{id ? `EDIT ${pageFor()}` : `NEW ${pageFor()}`}</div> */}
          {loader ? (
            <Loader />
          ) : (
            <div className="row m-0 p-0 mt-4">
              <div className="row  m-0 p-0">
                <div className="col-12">
                  <label htmlFor="" className="py-2 labelCss text-capitalize">
                    {pageFor()} Title:
                  </label>
                  <span className="text-danger">*</span>
                  <TextInput
                    placeholder="Enter title"
                    value={docTitle}
                    className={`${Validation.docTitle ? "Validation" : ""
                      } text-capitalize`}
                    onChange={(e) => {
                      setDocTitle(e.target.value);
                      setValidation({ ...Validation, docTitle: false });
                    }}
                  >
                    {" "}
                  </TextInput>
                </div>
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss text-capitalize">
                    Document Number
                  </label>
                  <span className="text-danger">*</span>
                  <TextInput
                    style={{ height: "55px" }}
                    placeholder="Enter Document Number"
                    value={documentNumber}
                    className={`${Validation.docNumber ? "Validation" : ""
                      } text-capitalize`}
                    onChange={(e) => {
                      setDocumentNumber(e.target.value);
                      setValidation({ ...Validation, docNumber: false });
                    }}
                  >
                    {" "}
                  </TextInput>
                </div>


                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss text-capitalize">
                    Type:
                  </label>
                  <span className="text-danger">*</span>
                  <div className="flex align_center">

                    <div className="w-100">

                      <AutoCompleteSelect

                        options={allDocumentTypes}
                        value={type}
                        optionLabel="label"
                        onChange={(e) => {
                          setType(e)
                          setValidation({ ...Validation, type: false });
                        }}
                        className={`p-0 ${Validation.type ? "Validation" : ""
                          } text-capitalize`}
                        placeholder="Select Category"
                      />
                    </div>

                    <CreateDocType onSubmit={fetchDocumentTypeData} />
                  </div>

                </div>






                <div className="col-12 mt-3">
                  <label className="labels mb-2">
                    Add Template: <span className="text-danger">*</span>
                  </label>
                  <CustomDragAndDrop
                    value={file}
                    label="Attach File(s)"
                    setDeleted={setDeleteFile}
                    onChange={(e) => {
                      setFile(e);
                      setValidation({ ...Validation, template: false });
                    }}
                    className={`p-0 ${Validation.template ? "Validation" : ""
                      } text-capitalize`}
                  />
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Phase:</label>
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      options={allPhase}
                      value={formData?.phase_id}
                      optionLabel="label"
                      onChange={(e) => {
                        handleChange(e, "phase_id");
                        setValidation({ ...Validation, phase_id: false });
                      }}
                      className={`p-0 ${Validation.phase_id ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Phase"
                    />
                  </div>
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Category:</label>
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      disabled={formData?.phase_id == null}
                      options={allCategory}
                      value={formData.category_id}
                      optionLabel="label"
                      onChange={(e) => {
                        handleChange(e, "category_id");
                        setValidation({ ...Validation, category_id: false });
                      }}
                      className={`p-0 ${Validation.category_id ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Category"
                    />
                  </div>
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Sub-Category:</label>
                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      disabled={
                        formData?.phase_id == null ||
                        formData?.category_id == null
                      }
                      options={allSubCategory}
                      value={formData?.sub_category_id}
                      optionLabel="label"
                      onChange={(e) => {
                        handleChange(e, "sub_category_id");
                        setValidation({
                          ...Validation,
                          sub_category_id: false,
                        });
                      }}
                      className={`p-0 ${Validation.sub_category_id ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Sub Category"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Document Type:</label>
                  <span className="text-danger">*</span>

                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      disabled={
                        formData?.phase_id == null ||
                        formData?.category_id == null
                        // formData?.sub_category_id == null
                      }
                      options={allDocTypeData}
                      value={formData?.doctype_id ? formData?.doctype_id : ""}
                      optionLabel="label"
                      onChange={(e) => {
                        handleChange(e, "doctype_id");
                        setValidation({ ...Validation, doctype_id: false });
                      }}
                      className={`p-0 ${Validation.doctype_id ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Document Type"
                    />
                  </div>
                </div>

                {formData?.doctype_id ? (
                  <div className="col-12 mt-3">
                    <label className="labels mb-2">
                      Available Template for Reference:
                    </label>
                    <div className="col-xxl-2 col-lg-3 col-md-4 col-3 card flex justify-content-center border rounded p-2">
                      <div
                        className="flex align-items-center justify-content-center cursor"
                        onClick={() => handleTemplate(formData?.doctype_id)}
                      >
                        <LiaDownloadSolid />
                        <div className="ms-1 labels">Download Template</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Scope:</label>
                  <span className="text-danger">*</span>
                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      options={scopeOptions}
                      value={scope}
                      // optionLabel="label"
                      onChange={(e) => {
                        setScope(e);
                        setValidation({ ...Validation, scope: false });
                      }}
                      className={`p-0 ${Validation.scope ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Scope"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label htmlFor="" className="py-1 labelCss">
                    Workflow Status: <span className="text-danger">*</span>
                  </label>
                  <AutoCompleteSelect
                    value={workStatus}
                    // optionLabel="label"
                    options={statusOptions}
                    placeholder=" Select Status"
                    onChange={(e) => {
                      setWorkStatus(e);
                      setValidation({ ...Validation, workStatus: false });
                    }}
                    className={`p-0 ${Validation.workStatus ? "Validation" : ""
                      } text-capitalize`}
                  // className={Validation.workStatus ? "Validation p-0" : "p-0"}
                  />
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Originator:</label>
                  {/* <span className="text-danger">*</span> */}
                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      options={allUsersOfProject}
                      value={createdBy}
                      optionLabel="created_by"
                      onChange={(value) => {
                        setCreatedBy(value);
                        setValidation({ ...Validation, createdBy: false });
                      }}
                      className={`p-0 ${Validation.createdBy ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Created By"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label className="labels mb-2">Updated By:</label>
                  {/* <span className="text-danger">*</span> */}
                  <div className="card flex justify-content-center">
                    <AutoCompleteSelect
                      options={allUsersOfProject}
                      value={updatedBy}
                      optionLabel="updated_by"
                      onChange={(value) => {
                        setUpdatedBy(value);
                        setValidation({ ...Validation, updatedBy: false });
                      }}
                      className={`p-0 ${Validation.updatedBy ? "Validation" : ""
                        } text-capitalize`}
                      placeholder="Select Updated By"
                    />
                  </div>
                </div>

                {(
                  (pageFor() === "drawing" && getActionsPermission("Drawing Reviewer")) ||
                  (pageFor() === "photo" && getActionsPermission("Photos Reviewer")) ||
                  (pageFor() === "model" && getActionsPermission("Model Reviewer")) ||
                  (pageFor() === "document" && getActionsPermission("Document Reviewer"))

                ) && (
                    // {isReviewerAccess && (
                    <div className="col-md-6 col-12 mt-3">
                      <label htmlFor="" className="py-2 labelCss">
                        Reviewer:
                      </label>
                      {/* <span className="text-danger">*</span> */}
                      <AutoCompleteSelect
                        options={allUsersOfProject}
                        value={reviewer}
                        optionLabel="reviewer"
                        onChange={(value) => {
                          setReviewer(value);
                          setValidation({ ...Validation, reviewer: false });
                        }}
                        className={`p-0 ${Validation.reviewer ? "Validation" : ""
                          }`}
                        // disabled={reviewer === getUserId()}
                        placeholder="Select Reviewer"
                      />
                    </div>
                  )}
                {(
                  (pageFor() === "drawing" && getActionsPermission("Drawing Approver")) ||
                  (pageFor() === "photo" && getActionsPermission("Photos Approver")) ||
                  (pageFor() === "model" && getActionsPermission("Model Approver")) ||
                  (pageFor() === "document" && getActionsPermission("Document Approver"))

                ) && (
                    <>
                      <div className="col-md-6 col-12 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                          Approver:
                        </label>
                        {/* <span className="text-danger">*</span> */}
                        <AutoCompleteSelect
                          options={allUsersOfProject}
                          value={approver}
                          optionLabel="approver"
                          onChange={(value) => {
                            setApprover(value);
                            setValidation({ ...Validation, approver: false });
                          }}
                          className={`p-0 ${Validation.approver ? "Validation" : ""
                            }`}
                          // disabled={approver === getUserId()}
                          placeholder="Select Approver"
                        />
                      </div>
                    </>
                  )}
                {id ?
                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer Comments:
                    </label>
                    {/* <span className="text-danger">*</span> */}
                    <div className="card">
                      <textarea value={reviewerComments} disabled />
                    </div>
                  </div>
                  : null}

                {/* {isApproverAccess && ( */}

                {id ?
                  <div className="col-md-6 col-12 mt-3">
                    <label htmlFor="" className="py-2 labelCss">
                      Approver Comments:
                    </label>
                    {/* <span className="text-danger">*</span> */}
                    <div className="card">
                      <textarea value={approverComments} disabled />
                      {/* <Editor
                        readOnly
                        value={approverComments}
                        style={{ height: "200px" }}
                      /> */}
                    </div>
                  </div>
                  : null}
              </div>


              <div className="row m-0 p-0">
                <div className="col-md-12 mt-3">
                  <label htmlFor="" className="py-2 labelCss">
                    Comments:
                  </label>
                  {/* <span className="text-danger">*</span> */}
                  <div className="card">
                    {/* <Editor
                      value={comments}
                      onTextChange={(e) => setComments(e.htmlValue)}
                      style={{ height: "200px" }}
                    /> */}

                    <ReactQuill theme="snow"
                      value={comments}
                      onChange={setComments}
                    />

                  </div>
                </div>
              </div>
              <div className="row m-0 p-0">

                <div className="col-12 mt-3 d-flex align-items-end">
                  <div className="w-100">
                    <label htmlFor="" className="py-2 labelCss">
                      Hashtags:
                    </label>
                    <MultiSelect
                      value={hashTagsList}
                      onChange={(e) => setHashTagsList(e.value)}
                      options={tagListing}
                      optionLabel="label"
                      filter placeholder="Select HashTags"
                      // maxSelectedLabels={3} 
                      style={{ width: "100%" }}
                      className={Validation.hashTag ? "Validation" : ""}
                    // onChange={(e) => {
                    //   // setHashTag(e.target.value);
                    //   setValidation({ ...Validation, hashTag: false });
                    // }}
                    />
                  </div>
                  <div className="mt-4 d-flex align-items-end ms-2">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenModal1(true)
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 my-4">
                <span
                  className="cancelBtn  px-2 py-2"
                  onClick={() => {
                    if (pageFor() === "document")
                      navigate("/documents");
                    else {
                      navigate(`/projectManagement/${pageFor()}s`)
                    }
                  }}
                >
                  {" "}
                  Cancel
                </span>

                <span
                  className="CreateRfi text-capitalize px-2 py-2"
                  onClick={handleDocument}
                >
                  {id ? `Edit ${pageFor()}` : `Add ${pageFor()}`}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>






    </>

  );
};
