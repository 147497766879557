import { useCallback, useEffect, useState } from 'react'
import { getAllClientRequestForm } from '../Api/Admin/AllAPIs'
import { loader, searchDataWithKeys } from '../utils'

export const useGetAllClientRequestData = () => {
  let [data, setData] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  const [allData, setAllData] = useState([])
  let [isError, setIsError] = useState(false)
  const [search,setSearch]=useState("")

  let getData = useCallback(async () => {
    try {
      setIsLoading(true)
      setIsError(false)
      loader.start()
      let res = await getAllClientRequestForm()
      setAllData(res.data.data)
    } catch (error) {
      setIsError(true)
    } finally {
      loader.stop()
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    setData(searchDataWithKeys(['fullName', 'email'], allData, search))
  }, [allData, search])

  return { data, getData,setSearch,search, isLoading, isError }
}
