import React, { useEffect, useState } from "react";

import TextInput from "../../Components/Inputs/TextInput";
import Calender from "../../Components/Common/Calender";
import SingleSelect from "../../Components/Common/SingleSelect";

import { useNavigate, useParams } from "react-router-dom";
import { DragAndDrop } from "../../Components/Inputs/DragAndDrop";
import {
  AddSpecificationFormat,
  AddSpecificationLanguage,
  AddSpecificationName,
  getCompanyByProjectId,
  getCountForSpecificationsByProjectId,
  getFormatByProjectId,
  getLanguageByProjectId,
  getNameByProjectId,
  getSpecificationById,
  getUserByProjectId,
  getUsersByCompanyId,
  updateSpecification,
  uploadDocs,
} from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getProjectCode, getProjectName, getUserId, getUserName, getUserRoleId, parseHashedData, toast } from "../../utils";
import { InputNumber } from "primereact/inputnumber";
import { Editor } from "primereact/editor";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import { useSelector } from "react-redux";
import AutoCompleteSelect from "../../Components/Common/AutoCompleteSelect";
import { MdAdd } from "react-icons/md";
import MultiSelects from "../../Components/Common/MultiSelects";
import { MultiSelect } from "primereact/multiselect";
import AddSets from "../../Components/Common/AddSets";
import TimeInput from "../../Components/Inputs/TimeSelector";
import { WORKFLOW_STATUS } from "../../constants";
import Loader from "../../Components/Common/Loader";
import { FormHeader } from "../../Components/Common/FormHeader";
import ReactQuill from "react-quill";
import { useFetchAllParentsTaskByTool } from "../../hooks/useFetchAllParentsTaskByTool";

export const EditSpecification = () => {
  const data = [
    { name: "Project Drawing" },
    { name: "1st Floor Drawing" },
    { name: "Architectural Files " },
    { name: "Electrical Files" },
    { name: "Layout Files" },
  ];
  const qus = [{ name: "Andrew" }, { name: "Joseph" }];
  const lang = [{ name: "English" }];
  const [formats, setFormats] = useState();
  const [name, setName] = useState()
  const isApproverAccess = getActionsPermission("Specification Approver")
  const isReviewerAccess = getActionsPermission("Specification Reviewer")
  const [approver, setApprover] = useState({});
  const [reviewer, setReviewer] = useState({});
  const [allUsersOfProject, setAllUsersOfProject] = useState([])
  const [oldApproverReviewwerData, setOldApproverReviewwerData] = useState({})
  const [formatvisible, setFormatVisible] = useState(false)
  const [languagevisible, setLanguagevisible] = useState(false)
  const [visible, setVisible] = useState(false)
  let set = useSelector(d => d.rolesAndPermissionData)
  const { id } = useParams();

  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)

  const userObj = userData;
  const userId = userObj?._id;
  const userName = getUserName();
  const [addName, setAddName] = useState()
  const [formatName, setFormatName] = useState()
  const [languageName, setLanguageName] = useState()
  const [languageData, setLanguageData] = useState()
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState();
  const [format, setFormat] = useState();
  const [revision, setRevision] = useState();
  const [alldata, setAlldata] = useState({});
  const [specManager, setSpecManager] = useState(null);
  const [loader, setLoader] = useState(false)
  const [noIgnore, setNoIgnore] = useState();
  const [language, setLanguage] = useState(null);
  const [issuedDate, setIssuedDate] = useState(new Date());
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [statusSelect, setStatusSelect] = useState(null);
  const [addValue, setAddvalue] = useState();
  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState("");
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const [approvestatus, setApproveStatus] = useState();
  const [membersResponsible, setMembersResponsible] = useState([]);
  const [responsibleContractor, setResponsibleContractor] = useState([]);
  const [oldspecification_review_status, setoldspecification_review_status] = useState("")
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState('')
  const [hourSpent, setHourSpent] = useState("")
  // const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [responsiblePerson, setResponsiblePerson] = useState([]);
  const [workflowStatus, setWorkflowStatus] = useState();
  const [reviewerComment, setReviewerComment] = useState('')
  const [approverComment, setApproverComment] = useState('')
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },
    { qty: 2 },


    { qty: 1, height: '15rem' },
    { qty: 1, height: "7rem" },

  ]
  const [specificationsCount, setSpecificationsCount] = useState();

  const domain = window.location.origin;

  const { data: parentsToolData, isLoading, getData: getParentOfSpecificationData } = useFetchAllParentsTaskByTool("Specification", id)
  const [parentId, setParentId] = useState([]);
  // useEffect(() => {

  //   const fetchSpecificationsCount = async () => {
  //     try {
  //       const count = await getCountForSpecificationsByProjectId(projectId); 
  //       setSpecificationsCount(count?.data?.data || ''); 
  //     } catch (error) {
  //       console.error('Error fetching specifications count:', error); 
  //     }
  //   }

  //   if (projectId) {
  //     fetchSpecificationsCount();
  //   }
  // }, []);

  // useEffect(() => {
  //   getParentOfSpecificationData()
  // }, [])


  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const [Validation, setValidation] = useState({
    title: false,
    issuedDate: false,
    format: false,
    revision: false,
    language: false,
    noIgnore: false,
    receivedDate: false,


    specManager: false,
    workflowStatus: false,


  });



  const statusOptions = [
    { label: "Work In Progress", value: "work in progress" },
    { label: "Incomplete", value: "incomplete" },
    { label: "Completed", value: "completed" },
    { label: "Ready for Review", value: "ready for review" },
    { label: "Not Started", value: "not started" }
  ]

  const approvelOptions = [
    { label: "Draft", value: "draft" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Approved", value: "approved" },

  ]
  // check validation


  const checkValidation = () => {
    if (!title) {
      setValidation((prevState) => ({
        ...prevState,
        title: true,
      }));
    }





    if (!issuedDate) {
      setValidation((prevState) => ({
        ...prevState,
        issuedDate: true,
      }));
    }
    if (!format) {
      setValidation((prevState) => ({
        ...prevState,
        format: true,
      }));
    }
    if (!responsibleContractor) {
      setValidation((prevState) => ({
        ...prevState,
        contractor: true,
      }));
    }
    if (!membersResponsible) {
      setValidation((prevState) => ({
        ...prevState,
        member: true,
      }));
    }
    if (!revision?.trim()) {
      setValidation((prevState) => ({
        ...prevState,
        revision: true,
      }));
    }
    if (!language) {
      setValidation((prevState) => ({
        ...prevState,
        language: true,
      }));
    }
    if (!noIgnore) {
      setValidation((prevState) => ({
        ...prevState,
        noIgnore: true,
      }));
    }
    if (!receivedDate) {
      setValidation((prevState) => ({
        ...prevState,
        receivedDate: true,
      }));
    }



    if (specManager?.length === 0) {
      setValidation((prevState) => ({
        ...prevState,
        specManager: true,
      }));
    }

    if (!workflowStatus) {
      setValidation((prevState) => ({
        ...prevState,
        workflowStatus: true,
      }));
    }


    if (
      !title ||
      !format ||
      !issuedDate ||
      !revision?.trim() ||
      !language ||
      !membersResponsible ||
      !responsibleContractor ||
      !specManager || specManager?.length === 0 ||
      !receivedDate ||


      !workflowStatus
    ) {
      toast.info("Please Fill all the Fields Marked with * are Mandatory");
      return true;
    }
    return false;
  };

  const handleFileSubmit = (event) => {
    setFileUpload(event);
  };

  const [allData, setAllData] = useState({});

  const handleGetRes = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getSpecificationById(id);
      const specification = response?.data?.data?.[0];


      setAllData(specification);
      // const attachFileGet =

      let approverData = specification?.approver?.[0] || {}
      let reviewerData = specification?.reviewer?.[0] || {}

      setOldApproverReviewwerData({
        approver: approverData?._id || null,
        reviewer: reviewerData?._id || null
      })

      setApprover(approverData?._id !== undefined ? approverData?._id : null);
      setReviewer(reviewerData?._id !== undefined ? reviewerData?._id : null);

      setoldspecification_review_status(approverData?.specification_review_status)


      setTitle(specification?.set[0]?._id);
      setDescription(specification?.description);
      setFormat(specification?.format[0]?._id);
      setAlldata(specification);
      setApproverComment(specification?.approver_comment)
      setReviewerComment(specification?.reviewer_comment)
      setRevision(specification?.defaultRevision);
      setNoIgnore(specification?.numberToIgnore);
      setLanguage(specification?.specificationLanguage[0]?._id);
      setIssuedDate(new Date(specification?.issuedDate));
      setParentId(specification?.parent_id || [])
      setReceivedDate(new Date(specification?.receivedDate));
      setResponsiblePerson(specification?.responsible_person?.map((e) => e._id));
      setSpecificationsCount(specification?.spec_id)
      setResponsibleContractor(specification.responsible_contractor_company?.map((e) => e._id));
      setMembersResponsible(specification.responsible_member?.map((e) => e._id))
      let data = {
        value: specification?.responsible_contractor_company?.map(res => res?._id)
      }
      await handleCompanyChange(data)
      setHourSpent(specification?.hours_spent)
      setFileName(
        specification.attachFile.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );

      setSpecManager(specification?.spec_manager?.map((e) => e._id))

      setWorkflowStatus(specification.workflow_status || '');

      const statusOptions = [
        { label: "Work In Progress", value: "work in progress" },
        { label: "Incomplete", value: "incomplete" },
        { label: "Completed", value: "completed" },
        { label: "Ready for Review", value: "ready for review" },
        { label: "Not Started", value: "not started" }
      ]

      setStatusSelect(specification?.status);
      setApproveStatus(specification?.status)

      let statuss = specification?.status;

      setOptions([
        {
          label: "On Hold",
          value: "onhold",
          disabled: !check("onhold", statuss),
        },
        {
          label: "Reopen",
          value: "reopen",
          disabled: !check("reopen", statuss),
        },
        {
          label: "Close",
          value: "close",
          disabled: !check("close", statuss),
        },
        {
          label: "Open",
          value: "open",
          disabled: !check("open", statuss),
        },
      ]);

      setPreviousFiles([...specification.attachFile]);
    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };



  const updatehandle = async () => {
    if (checkValidation()) {
      return;
    }
    setLoader(true)
    try {
      let fileData = [];

      const files = fileUpload || [];
      if (files.length !== 0) {
        const fromData = new FormData();

        for (const getFile of files) {
          fromData.append("upload", getFile);
        }

        const uploadFile = await uploadDocs(fromData);
        fileData = uploadFile?.data?.map((item) => ({
          fileName: item?.fileName,
          fileUrl: item?.fileUrl,
        }));
      }
      let emailData = []
      if (reviewer) {
        let approverData = allUsersOfProject.find(e => e._id == reviewer)

        emailData.push({
          to: approverData?.email_address,
          name: approverData?.first_name,
          tool_number: specificationsCount,
          tool_name: title,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Specification",
          subject: `:FYI: Specification updated in ${getProjectName()}`,
          url: `${domain}/coretools/Specifications`,
          template_type: "updatedToolEmailToReviewer",
          updated_by: getUserName(),
        })
      }
      if (approver) {
        let reviewerData = allUsersOfProject.find(e => e._id == approver)
        emailData.push({
          to: reviewerData.email_address,
          name: reviewerData.first_name,
          tool_number: specificationsCount,
          tool_name: title,
          subject: `FYI: Specification updated in ${getProjectName()}`,
          project_name: getProjectName(),
          project_id: getProjectCode(),
          created_by: getUserName(),
          tool: "Specification",
          url: `${domain}/coretools/Specifications`,
          template_type: "updatedToolEmailToApprover",
          updated_by: getUserName(),
        })
      }
      membersResponsible?.forEach((userId) => {
        let reviewerData = allUsersOfProject.find((e) => e._id == userId)
        if (reviewerData) {
          emailData.push({
            to: reviewerData?.email_address,
            name: reviewerData?.first_name,
            tool_number: specificationsCount,
            tool_name: title,
            project_name: getProjectName(),
            project_id: getProjectCode(),
            created_by: getUserName(),
            tool: "Specification",
            subject: `FYI: Specification updated in ${getProjectName()}`,
            userType: "Responsible Member",
            url: `${domain}/coretools/Specifications`,
            template_type: "emailToAllUserWhileUpdatingToolData",
            updated_by: getUserName(),
          })
        }
      })


      specManager?.forEach((userId) => {
        let reviewerData = allUsersOfProject.find((e) => e._id == userId)
        if (reviewerData) {
          emailData?.push({
            to: reviewerData?.email_address,
            name: reviewerData?.first_name,
            tool_number: specificationsCount,
            tool_name: title,
            project_name: getProjectName(),
            project_id: getProjectCode(),
            created_by: getUserName(),
            tool: "Specification",
            subject: `FYI: Specification updated in ${getProjectName()}`,
            userType: "Responsible Person",
            url: `${domain}/coretools/Specifications`,
            template_type: "emailToAllUserWhileUpdatingToolData",
            updated_by: getUserName(),
          })
        }
      })


      const reviewStatus = (oldspecification_review_status === "pending" ? statusSelect?.toLowerCase() === "close" ? "under_review" : "pending" : oldspecification_review_status) || "under_review"


      const payload = {


        set: title,
        format: format,
        issuedDate: issuedDate,
        receivedDate: receivedDate,
        responsible_contractor_company: responsibleContractor,
        responsible_member: membersResponsible,
        responsible_person: responsiblePerson,
        numberToIgnore: noIgnore,
        specificationLanguage: language,
        description: description,
        defaultRevision: revision,
        attachFile: [...previousFiles, ...fileData],
        deleted_file: removePhoto,
        userName: userName,
        userId: userId,
        reviewer_id: reviewer,
        approver_id: approver,
        email_data: emailData,
        hours_spent: hourSpent,
        spec_manager: specManager,
        spec_id: Number(specificationsCount),
        status: approvestatus,
        workflow_status: workflowStatus,
        project_id: projectId,
        user_name: getUserName()
      };
      // return

      let res = await updateSpecification(id, payload);

      if (res?.data?.message === "Specification number already exists") {
        toast.error("Specification number already exists.");
      } else {
        toast.success("Specification Updated Successfully!");
        navigate("/projectManagement/Specifications");

      }
      setLoader(false)
    } catch (error) {
      if (error.response?.data?.message === "Specification number already exists") {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred.");
        console.error("Error details:", error);
      }
    }


  };

  function check(option, status) {
    switch (status) {
      case "open":
        return ["close", "onhold"].includes(option);
      case "reopen":
        return ["close", "onhold"].includes(option);
      case "onhold":
        return ["close"].includes(option);
      case "close":
        return ["close"].includes(option);
      default:
        return true;
    }
  }
  const projectId = localStorage.getItem("projectId");
  useEffect(() => {
    handleGetRes();
  }, []);
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)

    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllUsersOfProject()
  }, [])
  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllUsersOfProject();
  }, []);
  const navigate = useNavigate();




  const getTitileName = async (projectId) => {
    try {
      const res = await getNameByProjectId(projectId)

      const Name = res?.data?.data
      setName(Name)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getTitileName(projectId)
  }, [])
  const UpdateName = async () => {

    try {
      const payload = {
        name: addName,
        project_id: projectId
      }
      let t = await AddSpecificationName(payload)

      setAddName('')
      getTitileName(projectId)
      setVisible(false)
    } catch (error) {
      console.log(error)
    }
  }
  const getFormatename = async (projectId) => {
    try {
      const res = await getFormatByProjectId(projectId)

      const Name = res?.data?.data
      setFormats(Name)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getFormatename(projectId)
  }, [])
  const UpdateFormat = async () => {

    try {
      const payload = {
        name: formatName,
        project_id: projectId
      }
      let t = await AddSpecificationFormat(payload)

      setFormatName('')
      getFormatename(projectId)
      setFormatVisible(false)
    } catch (error) {
      console.log(error)
    }
  }
  const getLanguagename = async (projectId) => {
    try {
      const res = await getLanguageByProjectId(projectId)

      // return
      const Name = res?.data?.data
      setLanguageData(Name)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getLanguagename(projectId)
  }, [])
  const UpdateLanguage = async () => {

    try {
      const payload = {
        name: languageName,
        project_id: projectId
      }
      let t = await AddSpecificationLanguage(payload)

      setLanguageName('')
      getLanguagename(projectId)
      setLanguagevisible(false)
    } catch (error) {
      console.log(error)
    }
  }




  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(projectId);

      if (res && res?.data) {

        const companyNames = res?.data?.data[0]?.companynames;

        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id

        }));

        setCompanies(formattedCompanies);

      } else {
        // console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getcompany()
  }, [])

  const handleCompanyChange = async (e) => {
    const selectedCompanyIds = e.value;

    setResponsibleContractor(selectedCompanyIds);


    // Fetch users for all selected companies
    if (selectedCompanyIds.length > 0) {
      const usersFromAllCompanies = [];

      for (const companyId of selectedCompanyIds) {
        try {
          const res = await getUsersByCompanyId(projectId, { company_id: [companyId] });

          if (res && res?.data?.data) {
            const usersFromCompany = res.data.data.map(user => ({
              label: user.name,
              value: user._id
            }));
            usersFromAllCompanies.push(...usersFromCompany);
          } else {
            console.error("Response data is not in the expected format");
          }
        } catch (error) {
          console.log(error);
        }
      }

      // Remove duplicate users
      const uniqueUsers = Array.from(new Set(usersFromAllCompanies.map(user => user.value)))
        .map(id => {
          return usersFromAllCompanies.find(user => user.value === id);
        });

      setUsers(uniqueUsers);
    } else {
      setUsers([]);
    }
  };

  function convertHourlySpentStringIntoObject(str) {

    if (typeof str === "string") {
      return {
        hours: parseInt(str.split("-")[0]) || "00",
        minutes: parseInt(str.split("-")[1]) || "00",
      };
    } else {
      return {
        hours: "0",
        minutes: "0",
      };
    }
  }
  return (
    <>
      <div className=" main-container">
        <div>


          <FormHeader
            to={"/projectManagement/Specifications"}
            formHeader={"Specifications"}
            breadcrumItem={["Specifications", `Edit Specifications`]} />

          {loader ? (
            <Loader />
          ) : (
            <div className="row m-0 p-0">



              <div className="row m-0 p-0 mt-3 ">

                <div className="col-md-6 col-12 d-flex align-items-end">
                  <div className="w-100">
                    <label className="labels mb-2"> Set Name :</label>{" "}
                    <span className="text-danger">*</span>
                    <div className="card flex justify-content-center  ">


                      <AutoCompleteSelect
                        options={name?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []} // Add fallback for options
                        value={title}
                        placeholder=" Set Name"
                        optionLabel="lebal"
                        onChange={(value) => {
                          setTitle(value)
                        }}
                        className={title === '' ? "Validation" : ""}
                        onClick={() => setValidation({ ...Validation, title: false })}
                      />

                    </div>
                  </div>
                  <div className="mt-4 d-flex align-items-end ">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setVisible(true)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex align-items-end">
                  <div className="w-100">
                    <label className="labels mb-2"> Format :</label>{" "}
                    <span className="text-danger">*</span>
                    <div className="card flex justify-content-center  ">


                      <AutoCompleteSelect
                        optionLabel="lebal"
                        options={formats?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []} // Add fallback for options
                        placeholder="Format"
                        value={format}
                        onChange={(value) => {
                          setFormat(value)
                        }} // Correctly set the selected format
                        className={format === '' ? "Validation" : ""}
                        onClick={() => setValidation({ ...Validation, format: false })}
                      />

                    </div>
                  </div>
                  <div className="mt-4 d-flex align-items-end ">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setFormatVisible(true)}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0 p-0 mt-3">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Contractor Company (s):{" "}
                  </label>

                  <MultiSelect
                    value={responsibleContractor}
                    //  onChange={(e) => setResponsibleContractor(e.value)}  
                    onChange={handleCompanyChange}
                    options={companies}
                    optionLabel="label"
                    filter placeholder="Company"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`p-0 ${Validation.contractor ? "Validation" : ""}`}
                    onClick={() =>
                      setValidation({ ...Validation, contractor: false })
                    }

                  />

                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Member (s):
                  </label>
                  <MultiSelect
                    value={membersResponsible}
                    onChange={(e) => setMembersResponsible(e.value)}
                    options={users}
                    optionLabel="label"
                    filter placeholder="Select Member"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`w-full md:w-20rem ${Validation.membersResponsible ? "Validation" : ""}`}
                    onClick={() =>
                      setValidation({ ...Validation, membersResponsible: false })
                    }
                  />
                </div>
              </div>

              <div className="row p-0 m-0 mt-3">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec Manager(s): <span className="text-danger">*</span>
                  </label>
                  <MultiSelect
                    value={specManager}
                    optionLabel="label"
                    onChange={(e) => setSpecManager(e?.value)}
                    options={allUsersOfProject?.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))}
                    filter placeholder="Select Member"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={Validation?.specManager ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, specManager: false })
                    }
                  />

                </div>

                <div className="col-md-6 col-12 d-flex align-items-end">
                  <div className="w-100">
                    <label htmlFor="" className="py-2 labelCss">
                      Spec Language:<span className="text-danger">*</span>
                    </label>

                    <div className="card flex justify-content-center  ">


                      <AutoCompleteSelect
                        // className="p-0"
                        placeholder=" Specifications Language"
                        options={languageData?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        })) || []} // Add fallback for options
                        optionLabel="lebal"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e)
                        }}
                        className={Validation.language ? "Validation" : ""}
                        onClick={() =>
                          setValidation({ ...Validation, language: false })
                        }
                      />

                    </div>
                  </div>
                  <div className="mt-4 d-flex align-items-end ">
                    <MdAdd
                      className="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() => setLanguagevisible(true)}
                    />
                  </div>
                </div>
              </div>



              <div className="row p-0 m-0 mt-3">
                <div className="col-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec ID
                  </label>
                  <TextInput
                    type="text"
                    placeholder="Spec ID"
                    onChange={(e) => setSpecificationsCount(e?.target?.value)
                    }
                    value={specificationsCount}


                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss mb-2">
                    Spec version:<span className="text-danger">*</span>
                  </label>
                  <TextInput
                    placeholder="Spec version"
                    value={revision}
                    onChange={(e) => {
                      setRevision(e.target.value);
                    }}
                    className={Validation.revision ? "Validation" : ""}
                    onClick={() =>
                      setValidation({ ...Validation, revision: false })
                    }
                  />
                </div>
              </div>
              <div className="row p-0 m-0 mt-3">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Hours Spent:
                  </label>

                  <TimeInput
                    value={convertHourlySpentStringIntoObject(hourSpent)}
                    onChange={(newValue) =>
                      setHourSpent(`${newValue.hours}-${newValue.minutes}`)
                    }
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Responsible Person (s):
                  </label>
                  <MultiSelect
                    value={responsiblePerson}
                    onChange={(e) => setResponsiblePerson(e.value)}
                    options={allUsersOfProject.map((item) => ({
                      label: item?.first_name,
                      value: item?._id,
                    }))} optionLabel="label"
                    filter placeholder="Select Person"
                    // maxSelectedLabels={3} 
                    style={{ width: "100%" }}
                    className={`p-0 ${Validation.contractor ? "Validation" : ""}`}

                  />

                </div>

              </div>

              <div className="row m-0 p-0 mt-3 ">

                {/* <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approval Status: <span className="text-danger">*</span>
                    </label>
                    <SingleSelect
                      value={approvestatus}
                      options={approvelOptions}
                      placeholder="approvel status"
                      onChange={(e) => setApproveStatus(e.target.value)}
                      className={Validation.approvestatus ? "Validation p-0" : "p-0"}
                      onClick={() =>
                        setValidation({ ...Validation, approvestatus: false })
                      }
                    // disabled={
                    //   alldata.rfi_approval_status == "approved" ||
                    //   alldata.rfi_review_status == "approved"
                    // }
                    />
                  </div> */}
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Work Flow Status:<span className="text-danger">*</span>
                  </label>
                  <SingleSelect
                    value={workflowStatus}
                    options={WORKFLOW_STATUS}
                    placeholder="Workflow Status"
                    onChange={(e) => setWorkflowStatus(e.value)}
                    className={Validation.workflowStatus ? "Validation p-0" : "p-0"}
                    onClick={() => setValidation({ ...Validation, workflowStatus: false })}

                  />
                </div>


              </div>
              <div className="row  m-0 p-0 mt-3">
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec Issue Date:<span className="text-danger">*</span>
                  </label>
                  {/* <Calender
                      value={issuedDate}
                      // className="p-0"
                      placeholder="mm/dd/yy"
                      selected={issuedDate}
                      onChange={(e) => setIssuedDate(e.target.value)}
                      className={Validation.issuedDate ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, issuedDate: false })
                      }
                    /> */}
                  <input
                    type="date"
                    style={{ height: "50px" }}
                    value={formatDate(issuedDate)}
                    // className="p-0"
                    placeholder="mm/dd/yy"
                    selected={issuedDate}
                    onChange={(e) => setIssuedDate(e.target.value)}
                    className={Validation.issuedDate ? "Validation" : "form-control"}
                    onClick={() =>
                      setValidation({ ...Validation, issuedDate: false })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="" className="py-2 labelCss">
                    Spec Received Date:<span className="text-danger">*</span>
                  </label>
                  {/* <Calender
                      value={receivedDate}
                      // className="p-0"
                      placeholder="mm/dd/yy"
                      selected={receivedDate}
                      onChange={(e) => setReceivedDate(e.target.value)}
                      className={Validation.receivedDate ? "Validation" : ""}
                      onClick={() =>
                        setValidation({ ...Validation, receivedDate: false })
                      }
                    /> */}
                  <input
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    value={formatDate(receivedDate)}
                    style={{ height: "50px" }}
                    // className="p-0"
                    placeholder="mm/dd/yy"
                    selected={receivedDate}
                    onChange={(e) => setReceivedDate(e.target.value)}
                    className={Validation.receivedDate ? "Validation" : "form-control"}
                    onClick={() =>
                      setValidation({ ...Validation, receivedDate: false })
                    }
                  />
                </div>
              </div>


              <div className="row m-0 p-0">

                {isReviewerAccess && (
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Reviewer:
                    </label>
                    <AutoCompleteSelect
                      options={allUsersOfProject.map((item) => ({
                        label: item?.first_name,
                        value: item?._id,
                      }))}
                      value={reviewer}
                      optionLabel="label"
                      onChange={(value) => {
                        setReviewer(value)
                      }}
                      className={`p-0 `}
                      // disabled={reviewer === getUserId() || allData?.status == 'approved'}
                      placeholder="Select Reviewer"
                    />
                  </div>
                )}

                {isApproverAccess && (
                  <div className="col-md-6 col-12">
                    <label htmlFor="" className="py-2 labelCss">
                      Approver:
                    </label>

                    <AutoCompleteSelect
                      options={allUsersOfProject.map((item) => ({
                        label: item?.first_name,
                        value: item?._id,
                      }))}
                      value={approver}
                      optionLabel="label"
                      onChange={(value) => {
                        setApprover(value)
                      }}
                      className={`p-0 `}

                      placeholder="Select Approver"
                    // disabled={approver === getUserId() || allData?.status == 'approved'}
                    />
                  </div>
                )}

              </div>

              <div className="row m-0 p-0 mt-3">
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Reviewer Comment:
                  </label>
                  <TextInput
                    placeholder="Reviewer Comment"
                    value={reviewerComment}
                    // onChange={(e) => setReviewerComment(e.target.value)}
                    disabled
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label htmlFor="" className="py-2 labelCss">
                    Approver Comment:
                  </label>
                  <TextInput
                    placeholder="Approver Comment"
                    value={approverComment}
                    // onChange={(e) => setApproverComment(e.target.value)}
                    disabled
                  />
                </div>

              </div>

              {/* <div className="col-md-6 col-12">
                <label htmlFor="" className="py-2 labelCss">
                  Select Sub Tasks:
                </label>


                <SingleSelect
                  showClear
                  placeholder="Select Parent"
                  options={parentsToolData}
                  optionLabel="subject"
                  value={parentsToolData?.find((e) => e._id == parentId?.[0]) || null}
                  onChange={(e) => {
                    setParentId(e?.value?._id ? [e?.value?._id] : []);
                  }}
                  filter={true}
                  filterInputAutoFocus={true}
                />

              </div> */}

              <div className="row m-0 p-0  gap-0 ">
                <label htmlFor="" className="py-2 labelCss px-0">
                  Description:
                </label>
                {/* <Editor
                  style={{ height: "200px" }}
                  value={description}
                  onTextChange={(e) => setDescription(e.htmlValue)}
                  className={`p-0 ${Validation.description ? "Validation" : ""}`}
                  onClick={() =>
                    setValidation({ ...Validation, description: false })
                  }
                /> */}

                <ReactQuill theme="snow"
                  value={description}
                  onChange={setDescription}
                />



              </div>

              <div className="row m-0 p-0  mt-3 css_com">
                <div className="col-md-12">
                  <DragAndDrop
                    setRemovePhoto={setRemovePhoto}
                    value={fileName}
                    setFiles={setFileUpload}
                    onChange={handleFileSubmit}
                    setValue={setFileName}
                    setPreviousFiles={setPreviousFiles}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4 ">
                <span
                  className="cancelBtn py-2 px-2"
                  onClick={() => navigate("/projectManagement/Specifications")}
                >
                  {" "}
                  Cancel
                </span>&nbsp;&nbsp;
                <span className="CreateRfi py-2 px-3" onClick={updatehandle}>
                  Update
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
      <AddSets
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Specification Set "}
        label={"Set"}
        reqFor={"Update"}
        value={addName}
        setValue={setAddName}
        onSubmit={UpdateName}
      />

      <AddSets
        visible={formatvisible}
        setVisible={setFormatVisible}
        CategoryName={"Spec Format "}
        label={"Format"}
        reqFor={"Update"}
        value={formatName}
        setValue={setFormatName}
        onSubmit={UpdateFormat}
      />
      <AddSets
        visible={languagevisible}
        setVisible={setLanguagevisible}
        CategoryName={"Spec Language "}
        label={"language"}
        reqFor={"Update"}
        value={languageName}
        setValue={setLanguageName}
        onSubmit={UpdateLanguage}
      />
    </>
  );
};
