import React, { useEffect, useRef, useState } from "react";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { EditButton } from "../../Components/Buttons/EditButton";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getObservationById } from "../../Api/Admin/AllAPIs";
import axios from "axios";
import fileDownload from "js-file-download";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { downloadPdf } from "../../Components/Common/ViewPageComponents/downloadPdf";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import moment from "moment/moment";
import ViewVersion from "../../Components/Common/ViewVersion";

export const ViewObservation = ({ usedAsComponent, includeVersionHistory }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },

    { qty: 1, height: "5rem" },
  ];

  async function fetchDataById() {
    try {
      setSkeletonLoader(true);
      const res = await getObservationById(id);

      const Data = res?.data?.data?.[0] || {};
      const originalDate = new Date(Data?.dueDate);
      const options = { year: "numeric", month: "long", day: "2-digit" };
      const formattedDate = originalDate.toLocaleDateString("en-US", options);
     
      Data["DueDate"] = formattedDate;
      setData(Data);
    } catch (error) {
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  }

  useEffect(() => {
    fetchDataById();
  }, []);

  const handleDownload = async (fileName, fileUrl) => {
  
    try {
      const response = await axios.get(fileUrl, { responseType: "blob" });
      
      fileDownload(response.data, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  let pdfRef = useRef();
  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents",
        files: data?.attachments.map((e) => e.url),
      },
    ],"Observation");
  }

  return (
    <>
      <div>
        <div>
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
          ) : (
            <div className="paint-touch">
              <div className="row">
              {!usedAsComponent && 
                <div className="col-12 py-3 ">
                  <span
                    className="open_Rfi_text"
                    onClick={() => navigate("/projectManagement/observation")}
                  >
                    {" "}
                    Observation
                  </span>
                  <span className="px-1 open_Rfi_text"> /</span>
                  <span className="createNew_text"> View Observation </span>
                </div>}
              </div>
              <br />

              <div className="row">
                <div className="d-flex mb-3 justify-content-between">
                  {/* <h1 className="heading" style={{textTransform:"capitalize"}}>{data?.title} </h1> */}
                  <div className="newRfi_text">
              Observation
                </div>
                {!usedAsComponent &&
                  <div
                    className="d-flex align-items-center gap-2 "
                    onClick={pdfDownload}
                  >
                    <div
                      className="d-flex gap-2 align-items-center"
                      style={{ cursor: "pointer" }}
                    >
                      <AiOutlineExport />
                      Export
                      <MdOutlineKeyboardArrowDown />
                    </div>

                    {/* <div className="d-flex" style={{ cursor: "pointer" }}>
                  <BsThreeDotsVertical />
                </div> */}
                  </div>}
                </div>

                <div className="row">
                  <div className="col-6">
                    <ViewPageTable
                      tableData={[
                        { "Observation Id": data?.observationId },
                        {
                          "Observation Status":<div style={{textTransform:"capitalize"}}>{data?.observationStatus?.[0]?.name || "--"}</div>
                            // (
                            //   <Statuscomp
                            //     className={`${data?.observationStatus?.[0]?.name}_status_obser`}
                            //     label={data?.observationStatus?.[0]?.name}
                            //   />
                            // ) || "",
                        },
                        {
                          "Observed Date":
                            moment(data?.observedDate).format("DD/MM/YYYY") ||
                            "",
                        },
                        // { "Member Responsible": data?.MembersResponsible || "--" },
                        {
                          "Created By":
                            data?.observedBy?.[0]?.first_name || "--",
                        },
                        { Title:<div style={{textTransform:"capitalize"}}>{data?.title || "--"}</div> },
                        { Category:<div style={{textTransform:"capitalize"}}>{data?.category?.[0]?.name || "--"}</div>  },
                        // { "Type": data?.type || "--" },
                      ]}
                    />
                  </div>
                  <div className="col-6">
                    <ViewPageTable
                      tableData={[
                        {
                          "Observed By":
                            data?.observedBy?.[0]?.first_name || "--",
                        },
                        // { "Location": data?.location || "--" },
                        { Trade:<div style={{textTransform:"capitalize"}}>{ data?.trade?.[0]?.name || "--" }</div>},
                        {
                          "Hours Spent": 
                          // data?.hoursSpent || "--"
                          `${data?.hoursSpent?.split("-")[0]} Hr ${data?.hoursSpent?.split("-")[1]} Min` 
                          
                          ,
                        },
                        { Location:<div style={{textTransform:"capitalize"}}>{ data?.location || "--"}</div> },
                        { Approver: <div style={{textTransform:"capitalize"}}>{data?.approver?.[0]?.first_name || "--"}</div> },

                        { Reviewer:<div style={{textTransform:"capitalize"}}>{ data?.reviewer?.[0]?.first_name || "--"}</div> },
                        { "Approver Comment": <div style={{textTransform:"capitalize"}}>{data?.approver_comment || "--"}</div> },
                { "Reviewer Comment": <div style={{textTransform:"capitalize"}}>{data?.reviewer_comment || "--"}</div> }
                      ]}
                    />
                  </div>
                </div>

                <div className="view_page_para_head mt-4 mb-2">
                  Attached Files(s):
                </div>

                <div className="d-flex flex-wrap gap-2">
                  {data?.attachments?.map((file, index) => (
                    <ViewPageDocumentName
                      docUrl={file?.url}
                      key={index}
                      name={file.fileName}
                    />
                  ))}
                </div>
              </div>

              {/* <div className="end mt-3">
            <Link to={"/projectManagement/observation"}>
              <button className="btn border">Close</button>
            </Link>
          </div> */}
            </div>
          )}
        </div>

        {(!usedAsComponent || includeVersionHistory) && (
          <>
            <div className="mt-3">
              <ViewVersion isView={true} />
            </div>
          </>
        )}
      </div>

      <div style={{ height: "0px", overflow: "hidden" }}>
        <div ref={pdfRef} style={{width:"2000px"}}>
          {/* <ViewPagesPdfTemplate
            header={`#RFI ${data?.title}`}
            tableData1={[
              {
                Status:
                  (
                    <Statuscomp
                      className={`${data?.observationStatus?.[0]?.name}_status_obser`}
                      label={data?.observationStatus?.[0]?.name}
                    />
                  ) || "",
              },
              {
                "Due Date": moment(data?.dueDate).format("YYYY/MM/DD") || "--",
              },
              // { "Member Responsible": data?.MembersResponsible || "--" },
              { "Created By": data?.observedBy?.[0]?.first_name || "--" },
              { Title: data?.title || "--" },
              // { "Type": data?.type || "--" },
            ]}
            tableData2={[
              { "Observed By": data?.observedBy?.[0]?.first_name || "--" },
              { Location: data?.location || "--" },
              { Trade: data?.trade?.[0]?.name || "--" },
              {
                "Due Date": moment(data?.dueDate).format("YYYY/MM/DD") || "--",
              },
              { Location: data?.location || "--" },
            ]}
            
          /> */}
          <div className="card p-5">
            {/* <h1 className="heading" style={{textTransform:"capitalize"}}>{data?.title} </h1> */}
            <div className="newRfi_text">
                  Observation
                </div>
            <div className="row mt-3">
              <div className="col-6">
                <ViewPageTable
                  tableData={[
                    { "Observation Id": data?.observationId },
                    {
                      "Observation Status":
                      <div style={{textTransform:"capitalize"}}>{data?.observationStatus?.[0]?.name || "--"}</div>,
                    },
                    {
                      "Observed Date":
                        moment(data?.observedDate).format("DD/MM/YYYY") || "",
                    },
                    // { "Member Responsible": data?.MembersResponsible || "--" },
                    {
                      "Created By": <div style={{textTransform:"capitalize"}}>{data?.category?.[0]?.name || "--"}</div> ,
                    },
                    { Title: <div style={{textTransform:"capitalize"}}>{data?.title || "--"}</div> },
                    { Category: <div style={{textTransform:"capitalize"}}>{data?.category?.[0]?.name || "--"}</div> },
                    // { "Type": data?.type || "--" },
                  ]}
                />
              </div>
              <div className="col-6">
                <ViewPageTable
                  tableData={[
                    {
                      "Observed By": data?.observedBy?.[0]?.first_name || "--",
                    },
                    // { "Location": data?.location || "--" },
                    { Trade: <div style={{textTransform:"capitalize"}}>{ data?.trade?.[0]?.name || "--" }</div> },
                    {
                      "Hours Spent": 
                      // data?.hoursSpent || "--"
                      `${data?.hoursSpent?.split("-")[0]} Hr ${data?.hoursSpent?.split("-")[1]} Min`  
                      
                    },
                    { Location: <div style={{textTransform:"capitalize"}}>{data?.location || "--"}</div> },
                    { Approver: <div style={{textTransform:"capitalize"}}>{data?.approver?.[0]?.first_name || "--"}</div> },

                    { Reviewer:<div style={{textTransform:"capitalize"}}> {data?.reviewer?.[0]?.first_name || "--"}</div> },
                    { "Approver Comment": <div style={{textTransform:"capitalize"}}>{data?.approver_comment || "--"}</div> },
                { "Reviewer Comment": <div style={{textTransform:"capitalize"}}>{data?.reviewer_comment || "--"}</div> }
                  ]}
                />
              </div>
            </div>

            <div className="view_page_para_head mt-4 mb-2">
                  Attached Files(s):
                </div>

            <div className="d-flex flex-wrap gap-2">
                  {data?.attachments?.map((file, index) => (
                    <ViewPageDocumentName
                      docUrl={file?.url}
                      key={index}
                      name={file.fileName}
                    />
                  ))}
                </div>
            {(!usedAsComponent || includeVersionHistory) && (
              <>
                <div className="mt-3">
                  <ViewVersion isView={true} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!usedAsComponent &&
      <div className="d-flex justify-content-end mt-2">
        <button
          className="btn border"
          onClick={() => navigate("/projectManagement/observation")}
        >
          Back
        </button>
      </div>}
      <br />
    </>
  );
};
