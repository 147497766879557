import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import SingleSelect from '../../Components/Common/SingleSelect'
import CustomDragAndDrop from '../../Components/Inputs/CustomDragAndDrop'
import { Editor } from 'primereact/editor'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { getUserName } from '../../utils'
import ReactQuill from 'react-quill'

export const AddResponse = ({ inspectionData, onSubmit }) => {


    let sectionOptions = inspectionData.map((e, ind) => ({ label: e.data.sectionHeading, value: ind }))
    // let subSectionOptions=inspectionData.map((e, ind) => ())
    let [subSectionoptions, setSubSectionOptions] = useState([])




    let [visible, setVisible] = useState(false)

    let [responseData, setResponseData] = useState({
        section: "",
        subSection: "",
        submitted_by: getUserName(),
        response: "",
        attachments: []
    })

    useEffect(() => {

        setSubSectionOptions(inspectionData?.[responseData?.section]?.children?.map((e, ind) => ({ label: e?.data?.subsectionHeadingData, value: ind })) || [])
    }, [responseData?.section])


    function formSubmit() {
        onSubmit({ ...responseData, _id: Math.random() })

        setResponseData({
            section: "",
            subSection: "",
            submitted_by: getUserName(),
            response: "",
            attachments: []
        })

        setVisible(false)
    }


    return (
        <div>

            <div
                className="clear_btn gray-lt"
                onClick={() => setVisible(true)}
            >
                <IoMdAdd /> Add Response
            </div>


            <Dialog
                onClick={(e) => e.stopPropagation()}
                header="Add Inspection Response"
                style={{ width: "60rem", minWidth: "30rem" }}
                headerClassName="addBorder_bottom"
                draggable={false}
                visible={visible}
                onHide={() => setVisible(false)}
            >

                <div className="row">

                    <div className="col-12 mt-3">
                        <label className="labelCss">Section Name</label>
                        <SingleSelect
                            placeholder="Section Name"
                            options={sectionOptions}
                            value={responseData.section}
                            onChange={(e) => {
                                setResponseData(pre => ({ ...pre, section: e.target.value, subSection: "" }))
                                setSubSectionOptions([])
                            }}
                            filter={true}
                            filterInputAutoFocus={true}
                        />
                    </div>

                    <div className="col-12 mt-3">
                        <label className="labelCss">Item Name</label>
                        <SingleSelect
                            placeholder="Item Name"
                            options={subSectionoptions}
                            value={responseData.subSection}
                            onChange={(e) => { setResponseData(pre => ({ ...pre, subSection: e.target.value })) }}
                            filter={true}
                            filterInputAutoFocus={true}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <label className="labelCss">Response</label>
                        {/* <Editor
                            style={{ height: "200px" }}
                            value={responseData.response}
                            onTextChange={(e) => { setResponseData(pre => ({ ...pre, response: e.htmlValue })) }}
                        /> */}

                        <ReactQuill theme="snow"
                            value={responseData.response}
                            onChange={(e) => {
                                setResponseData(pre => ({ ...pre, response: e.htmlValue }))
                            }}
                        />


                    </div>

                    <div className="col-12 mt-3">
                        <label htmlFor="" className="py-2 labelCss">
                            Attachments:
                        </label>
                        <CustomDragAndDrop
                            value={responseData.attachments}
                            setDeleted={() => { }}
                            onChange={(e) => { setResponseData(pre => ({ ...pre, attachments: e })) }}
                        />
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <div className="d-flex">
                            <button
                                className="btn border me-3"
                                type="button"
                                onClick={() => setVisible(false)}
                            >
                                Cancel
                            </button>
                            <PrimaryButton
                                onClick={formSubmit}
                                type="button"
                                text={"Add"}

                            />
                        </div>
                    </div>


                </div>



            </Dialog >






        </div >
    )
}
