import React from 'react'
// import { DirectCostForm } from './DirectCostForm'
import { addDirectCost, uploadDocs } from '../../../../../Api/Admin/AllAPIs';
import { useNavigate } from 'react-router-dom';
import AddSubContractor from './AddSubContractor';


export const CreateSubcontract = () => {

    let navigate = useNavigate()

    let formData = {
        direct_cost_name: "",
        direct_cost_type: "",
        direct_status: "",
        contracting_party: null,
        employees: [],
        received_date: "",
        paid_date: "",
        direct_description: "",
        attachment: [],
        cost_line_item: []
    };

 


    return (
        <div>
            <div className="row m-0 p-0">
                <div className="col-md-12 p-0 py-3">
                    <span
                        className="open_Rfi_text"
                        onClick={() => navigate("/financialManagement/commitment")}
                    >
                        Subcontract
                    </span>
                    <span className="px-1 open_Rfi_text">/</span>
                    <span className="createNew_text"> Create Subcontract</span>
                </div>
            </div>
            <AddSubContractor/>
        </div>
    )
};
