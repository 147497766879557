import { Checkbox } from 'primereact/checkbox'
import React from 'react'
import "./Email.css"
import Popover from '../../Components/Common/Popover'
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText'

export const SingleEmailCard = ({ cardData, checkedEmail, emailType, deleteEmail, emailCheckClick, moveEmailToSpamOrBin, ...props }) => {




    return (
        <div {...props} className={`single_email_card_container ${cardData.active ? "single_email_card_active" : ""} ${props.className}`}>

            <div className='single_email_checkbox'>
                <Checkbox
                    checked={checkedEmail._id == cardData?._id}
                    onChange={(e) => {
                        if (e.target.checked) {
                            emailCheckClick(cardData)
                        }
                        else {
                            emailCheckClick({})
                        }
                    }}
                />
            </div>

            <div className='single_email_card_content'>
                <div className='single_email_title'>{cardData?.subject}</div>
                <div className='single_email_card_content single_email_card_message'>
                    <HtmlIntoText html={cardData?.message} />
                </div>
                <div className='tag_card_con'>
                    {cardData?.tagData?.map(e => <div className='tag_card_item'>{e.tag_name}</div>)}

                </div>
            </div>

            <div className='single_email_card_actions'>
                <div className='text-end'>
                    <Popover
                        options={
                            emailType === "sent"
                                ? ["Mark as Spam", "Delete"]
                                : emailType === "spam"
                                    ? ["Move To Sent", "Delete"]
                                    : emailType === "bin"
                                        ? ["Move To Sent", "Move To Spam"]
                                        : []
                        }
                        onSelect={(index) => {

                            if (index === 0) {
                                // if we are on sent page then sent emal to spam 
                                if (emailType === "sent") {
                                    moveEmailToSpamOrBin(cardData._id, "spam")
                                }
                                // if we are on spam or bin page then move email to sent
                                else {
                                    moveEmailToSpamOrBin(cardData._id, "sent")
                                }
                            }

                            else if (index === 1) {
                                if (emailType === "bin") {
                                    moveEmailToSpamOrBin(cardData._id, "spam")
                                }
                                else {
                                    moveEmailToSpamOrBin(cardData._id, "bin")
                                }
                            }

                        }
                        }
                    />

                </div>
                <div>{cardData?.date}</div>

            </div>


        </div>
    )
}
