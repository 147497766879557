import React, { useEffect, useState } from 'react'
import style from './Overview.module.css'
import ReactApexChart from "react-apexcharts";
import ProjectProgress from '../../Components/ProjectOverview/ProjectProgressTImeline/ProjectProgressTimeLineDonut';
import ProjectProgressBar from '../../Components/ProjectOverview/ProjectProgressTImeline/ProjectProgressBar';
import BudgetSummary from '../../Components/ProjectOverview/BudgetSummery';
import ProjectStazeTracker from '../../Components/ProjectOverview/ProjectStazeTracker';
import SpendingSummary from '../../Components/ProjectOverview/SpendingSummary';
import CompletionTracker from '../../Components/ProjectOverview/CompletionTracker';
import UpcomingTask from '../../Components/ProjectOverview/UpcomingTask/UpcomingTask';
import AdaptiveHeight from '../../Components/ProjectOverview/ProjectPictures';
import DateNavigator from '../../Components/ProjectOverview/DailySchedule/DailySchedule';
import { getallrole, getProjectAssignData, overviewData } from '../../Api/Admin/AllAPIs';
import Loader from '../../Components/Common/Loader';
import BarChart from '../../Components/Portfolio/BarChart';
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TableSkelton } from '../../Components/Common/TableSkelton';
import { useNavigate } from 'react-router-dom';
import FormSkeleton from '../../Components/Common/FormSkeleton';
import { Carousel } from 'primereact/carousel';
import LightShadowCard from '../../Components/Cards/LightShadowCard';


const Overview = () => {

  const [overViewGetData, setOverViewGetData] = useState([])

  const projectId = localStorage.getItem('projectId')
  let [userTableSkelton, setUserTableSkelton] = useState(true)
  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "22.8rem" }
  ]
  const [roleAll, setRoleAll] = useState([])
  const getAllRoleData = async () => {
    try {
      const res = await getallrole();
      const response = res.data.data;
      let data = response.map((e) => {
        return {
          label: e?.role,
          value: e?._id
        }
      })

      setRoleAll(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllRoleData()
  }, []);



  const skeletonData1 = [
    { qty: 1, height: "29rem" },


  ]

  const allOverViewData = async () => {
    setSkeletonLoader(true);
    await overviewData(projectId).then((res) => {
      setSkeletonLoader(false);

      setOverViewGetData(res.data.data)

    }).catch((err) => {
      console.log(err)


    })
  }
  const [allUsers, setAllUsers] = useState([])

  useEffect(() => {
    allOverViewData()
    getAllUsersData()
  }, [])
  let navigation = useNavigate()

  async function getAllUsersData() {
    try {
      setUserTableSkelton(true)
      let res = await getProjectAssignData(projectId);
      let data = res.data.data
      let final = data?.flatMap((res) => res?.person)?.map(d => ({ ...d?.user, project_role: d?.project_role }))
      setAllUsers(final)

    }
    catch (err) {
      console.log(err)
    }
    finally {
      setUserTableSkelton(false)
    }
  }

  function firstChartrowclick(event, chartContext, config) {
    var categories = ["RFIs", "Submittals", "Punch Lists", "Incidents", "Forms", "Drawing", "Issue", "Observation"];
    var clickedCategory = categories[config.dataPointIndex];


    switch (clickedCategory) {
      case "RFIs":
        navigation("/projectManagement/openrfis")
        break;
      case "Submittals":
        navigation("/projectManagement/submittals")
        break;
      case "Punch Lists":
        navigation("/projectManagement/punch")
        break;
      case "Incidents":
        navigation("/projectManagement/incident")
        break
      case "Forms":
        navigation("/projectManagement/forms")
        break
      case "Drawing":
        navigation("/projectManagement/drawings")
        break
      case "Issue":
        navigation("/projectManagement/coordinationIssues")
        break
      case "Observation":
        navigation("/projectManagement/observation")
        break


      default:
        break;
    }


  }




  return (
    <>

      <>
        <div>
          {/* <TopBar/> */}
          <div className={`${style.main_container}`}>


            {/* <div className='heading'>Project Overview</div> */}
            <LightShadowCard headingClass={"chart_heading"} heading={"Project Overview"}>

              <div>
                {
                  skeletonLoader ?
                    <FormSkeleton data={skeletonData} mt={"mt-1"} />
                    :
                    <BarChart
                      rowclick={firstChartrowclick}
                      className=""
                      series={[
                        {
                          name: "Draft",
                          data: [
                            overViewGetData?.rfi?.draft || 0,
                            overViewGetData?.submittal?.draft || 0,
                            overViewGetData?.punchs?.draft || 0,
                            overViewGetData?.incident?.draft || 0,
                            overViewGetData?.form?.draft || 0,
                            overViewGetData?.drawing?.draft || 0,
                            overViewGetData?.issue?.draft || 0,
                            overViewGetData?.observation?.draft || 0,
                          ],
                        },
                        {
                          name: "Inreview",
                          data: [
                            overViewGetData?.rfi?.inreview || 0,
                            overViewGetData?.submittal?.inreview || 0,
                            overViewGetData?.punchs?.inreview || 0,
                            overViewGetData?.incident?.inreview || 0,
                            overViewGetData?.form?.inreview || 0,
                            overViewGetData?.drawing?.inreview || 0,
                            overViewGetData?.issue?.inreview || 0,
                            overViewGetData?.observation?.inreview || 0,
                          ],
                        },
                        {
                          name: "Pending Approval",
                          data: [
                            overViewGetData?.rfi?.pendingapproval || 0,
                            overViewGetData?.submittal?.pendingapproval || 0,
                            overViewGetData?.punchs?.pendingapproval || 0,
                            overViewGetData?.incident?.pendingapproval || 0,
                            overViewGetData?.form?.pendingapproval || 0,
                            overViewGetData?.drawing?.pendingapproval || 0,
                            overViewGetData?.issue?.pendingapproval || 0,
                            overViewGetData?.observation?.pendingapproval || 0,
                          ],
                        },
                        {
                          name: "Approved",
                          data: [
                            overViewGetData?.rfi?.approved || 0,
                            overViewGetData?.submittal?.approved || 0,
                            overViewGetData?.punchs?.approved || 0,
                            overViewGetData?.incident?.approved || 0,
                            overViewGetData?.form?.approved || 0,
                            overViewGetData?.drawing?.approved || 0,
                            overViewGetData?.issue?.approved || 0,
                            overViewGetData?.observation?.approved || 0,
                          ],
                        },
                      ]}
                    />}
              </div>
            </LightShadowCard>

            <div className="d-md-flex gap-3" >

              <LightShadowCard headingClass={"chart_heading"}
                className={"w-100"}
                heading={"Project Task Tracker"}>
                <div>
                  {
                    skeletonLoader ?
                      <FormSkeleton data={skeletonData1} mt={"mt-1"} />
                      :
                      <ProjectProgress overViewGetData={overViewGetData?.taskData} />}
                </div>
              </LightShadowCard>

              <LightShadowCard
                className={"w-100 mt-md-0 mt-3"}
                headingClass={"chart_heading w-100"} heading={"Budget Summary"}>
                <div>
                  {
                    skeletonLoader ?
                      <FormSkeleton data={skeletonData1} mt={"mt-1"} />
                      :
                      <BudgetSummary overViewGetData={overViewGetData?.estimated_project_value?.price || 0} />}
                </div>
              </LightShadowCard>

            </div>


            <div className="d-md-flex gap-3" >
              <LightShadowCard
                className={"w-100 mt-md-0 mt-3"}
                headingClass={"chart_heading w-100"} heading={"Project Stage"}>
                {skeletonLoader ?
                  <FormSkeleton data={skeletonData1} mt={"mt-1"} /> :
                  <ProjectStazeTracker overViewGetData={overViewGetData?.project_stages} />}
              </LightShadowCard>


              <LightShadowCard
                className={"w-100 mt-md-0 mt-3"}
                headingClass={"chart_heading w-100"} heading={"Project Drawing"}>
                {skeletonLoader ? <FormSkeleton data={skeletonData1} mt={"mt-1"} />
                  : <SpendingSummary overViewGetData={overViewGetData?.drawingsData} />}
              </LightShadowCard>
            </div>


            <div className="row m-0 gap-3">
              <div className="col-md-6 col-12 p-0">
                <LightShadowCard
                  className={"w-100 mt-md-0 mt-3"}
                  headingClass={"chart_heading w-100"} heading={"Project Picture"}>
                  {
                    skeletonLoader ? <FormSkeleton data={skeletonData1} mt={"mt-1"} />
                      :
                      <AdaptiveHeight overViewGetData={overViewGetData?.project_photos} />
                  }
                </LightShadowCard>
              </div>
              <div className="col p-0"></div>

            </div>

            <LightShadowCard
              className={"mt-3"}
              headingClass={"chart_heading w-100"} heading={"Upcoming Tasks"}>
              {
                skeletonLoader ? <FormSkeleton data={skeletonData1} mt={"mt-1"} />
                  :
                  <UpcomingTask overViewGetData={overViewGetData?.taskData} />
              }
            </LightShadowCard>



            <LightShadowCard
              className={"mt-3"}
              headingClass={"chart_heading w-100"} heading={"Project Team"}>
              {userTableSkelton ? <TableSkelton header={["Role", "Name", "Email", "Address"]} /> :
                <div className='overview_table_scroll'>

                  <BorderedTable>
                    <DataTable
                      value={allUsers}
                      tableStyle={{ minWidth: "50rem" }}
                      paginator
                      rows={5}
                      rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
                    >
                      <Column field="project_role"
                        body={(rowData, meta) => <>{roleAll?.find(res => res?.value == rowData?.project_role)?.label}</>}
                        header="Role"></Column>
                      <Column field="first_name" header="Name"></Column>
                      <Column field="email_address" header="Email"></Column>
                      <Column field="address" header="Address"></Column>
                    </DataTable>
                  </BorderedTable>
                </div>
              }
            </LightShadowCard>


          </div>



        </div >
      </>


    </>

  )
}

export default Overview
