import React, { useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../../Components/Buttons/PrimaryButton';
import { TabPanel, TabView } from 'primereact/tabview';
import { ExportPdfButton } from '../../../../Components/Buttons/ExportPdfButton';

export const DirectCostOutlet = () => {
  const [activeStep, setActiveStep] = useState(0)
  let navigate = useNavigate();

  const steps = [
    { label: 'General table', path: "/financialManagement/directCost" },
    { label: 'Summary By Cost Code', path: "/financialManagement/directCost/summaryByCode" },
  ]
  return (
    <div className='budgetModule_container'>

      {/* <div className='d-flex justify-content-between align-items-center'>
        <div className="page_heading">Direct Cost</div>


            <div className='d-flex gap-3'>
            <div className=" d-flex align-items-center">
                                <ExportPdfButton exportToPDF={""}/>
                                  
                            </div>
          <Link to={"create"}>
            <PrimaryButton text={"Create Direct Cost"} addIcon />
          </Link>

            </div>
   
      </div> */}

      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">Direct Cost</div>

        <div className="d-flex align_center justify-content-end gap-2 mt-sm-0 mt-3">
          <div className="right d-flex gap-2 align-items-center">
          <ExportPdfButton exportToPDF={""}/>
          </div>
          <Link to={"create"}>
            <PrimaryButton text={"Create Direct Cost"} addIcon />
          </Link>
        </div>
      </div>

      <TabView activeIndex={activeStep}
        onTabChange={(e) => {
          setActiveStep(e.index)
          navigate(steps[e.index].path)
        }}
        
      >
        {steps.map((res, key) => <TabPanel key={key} header={res.label} />)}
      </TabView>


      <Outlet context={{ setActiveStep }} />
    </div>
  )
}
