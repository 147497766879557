import React from 'react'
import { FaRegEdit } from 'react-icons/fa'
import style from "./button.module.css"

export const EditButton = ({...props}) => {
  return (
    <button {...props} className={style.editButton}>
      <FaRegEdit />
    </button>
  )
}
