import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import AddSets from '../../Components/Common/AddSets'
import { addDocumentType } from '../../Api/Admin/AllAPIs'

export const CreateDocType = ({ onSubmit }) => {

    const [dialog, setDialog] = useState(false)
    const [loader, setLoader] = useState(false)
    const [value, setValue] = useState("")

    async function formSubmit() {
        console.log(value)
        try {
            let body = {
                name: value
            }
            setLoader(true)
            let res = await addDocumentType(body)
            onSubmit()
            setDialog(false)
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false)
        }
    }



    return (
        <>

            <MdAdd
                className="fs-2"
                style={{ cursor: "pointer" }}
                onClick={() => setDialog(true)}
            />

            <AddSets
                loading={loader}
                visible={dialog}
                setVisible={setDialog}
                CategoryName={"Document Type"}
                label={"Document Type"}
                reqFor={"create"}
                value={value}
                setValue={setValue}
                onSubmit={formSubmit}
            />


        </>
    )
}
