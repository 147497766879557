import React, { useState } from 'react'

import "./pagesComponents.css"

export const TabCard = ({ header, active, smallCard, valueClassName, headerClassName,className, value, ...props }) => {
  return (
    <div className={` ${smallCard ? "tabCard_smallCard" : ""} tabCard_container ${active ? "tabCard_activeCard" : ""} ${className}`} {...props} >
      <div className={`tabCard_header ${headerClassName}`}>{header}</div>
      <div className={`tabCard_value ${valueClassName}`}>{value}</div>
    </div>
  );
};