import { Checkbox, Divider } from '@mui/material'
import React from 'react'
import style from "./clientRequestForm.module.css"
import AutoCompleteSelect from '../../Components/Common/AutoCompleteSelect'
import ReactQuill from 'react-quill'
import { Controller, useForm } from 'react-hook-form'
import TextInput from '../../Components/Inputs/TextInput'
import { PROJECT_TYPES } from '../../constants'
import { Dropdown } from 'primereact/dropdown'
import { useGetAllCountries, useGetCitiesOfCountryAndState, useGetStatesOfCountry } from '../../hooks/useGetAllCountryCityStateData'
import { addClientRequest } from '../../Api/Admin/AllAPIs'
import { loader } from '../../utils'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { Link } from 'react-router-dom'
import {  useNavigate} from 'react-router-dom'


export const ClientRequestForm = () => {

    let { data: allCountries } = useGetAllCountries()
    let { data: allStates, getData: fetchStatesByCountry } = useGetStatesOfCountry()
    let { data: allCities, getData: fetchCities } = useGetCitiesOfCountryAndState()
let navigate=useNavigate()
    const additionalServiceRequired = ["Site Preparation & Demolition", "Foundation & Framing", "Electrical & Plumbing", "Roofing & Insulation",
        " Interior Finishing (Drywall, Painting, Flooring, etc.)", "Landscaping & Exterior Work", "others"]

    const preferredContactMethods = ["Email", "Phone", "In-Person Meeting"];
    const PROJECT_STATUS = ["In Review", "Pending", "Rejected"]

    const { control, setValue, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            fullName: "",
            companyName: "",
            mobileNumber: "",
            email: "",
            address: "",
            country: "",
            city: "",
            state: "",
            zipcode: "",
            projectType: "",
            startDate: "",
            completionDate: "",
            estimatedBudget: "",
            isArchitechturalPlan: "",
            needAssistance: "",
            description: "",
            additionalServices: [],
            status: "",
            // otherServiceDescription: "",
            additionalNotes: "",
            contactMethod: []
        }
    })

    let country = watch("country")

    async function formSubmit(e) {
     

        try {
            let body = {
                ...e,
                isArchitechturalPlan: e.isArchitechturalPlan === "Yes",
                needAssistance: e.needAssistance === "Yes"
            }
            loader.start()
          
            let res = await addClientRequest(body)
        
            navigate("/clientRequest")
        } catch (error) {

            console.log(error)
        }
        finally {
            loader.stop()
        }

    };

    return (
        <div className="p-3 pt-0">
            <div className='page_heading'>Client Request Form</div>
            <Divider sx={{ mt: 1 }} />

            <form onSubmit={handleSubmit(formSubmit)}>

                <div>
                    <div className='my-2'>
                        <BulletPoints number="1" heading="Client Information" />
                    </div>
                    <div className="row">

                        <div className="col-12">
                            <label htmlFor="" className="py-2 labelCss">
                                Full Name: <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="fullName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={errors?.fullName && "Validation"}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Full Name"}
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="" className="py-2 labelCss">
                                Company Name (If applicable): <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="companyName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={errors?.companyName && "Validation"}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Company Name"}
                                        />
                                    );
                                }}
                            />
                        </div>


                        <div className="col-6">
                            <label htmlFor="" className="py-2 labelCss">
                                Phone:
                            </label>

                            <Controller
                                name="mobileNumber"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                          
                                            className={`${errors?.mobileNumber && "Validation"} form-control input_field`}
                                            value={value}
                                            type="number"
                                            onChange={onChange}
                                            placeholder={"Phone Number"}
                                        />
                                    );
                                }}
                            />


                        </div>
                        <div className="col-6">
                            <label htmlFor="" className="py-2 labelCss">
                                Email: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="email"
                                rules={{
                                    required: "Email is required", // Error message for required
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: "Enter a valid email address", // Error message for invalid email format
                                    },
                                }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={errors?.email && "Validation"}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Email"}
                                        />
                                    );
                                }}
                            />

                        </div>
                        <div className="col-12">
                            <label htmlFor="" className="py-2 labelCss">
                                Project Address: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="address"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={errors?.address && "Validation"}
                                            value={value}
                                            onChange={onChange}
                                            placeholder={"Address"}
                                        />
                                    );
                                }}
                            />

                        </div>

                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Country: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="country"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown

                                            // style={{border:"1px solid red"}}
                                            options={allCountries}
                                            value={value}
                                            optionLabel='label'

                                            className={`w-100 ${errors.country && "Validation"} `}
                                            onChange={(e) => {
                                                onChange(e)
                                                fetchStatesByCountry(e.target.value)
                                                setValue("state", "")
                                                setValue("city", "")
                                            }}
                                            placeholder="Select Country"
                                        />
                                    );
                                }}
                            />

                        </div>
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                State: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="state"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            options={allStates}
                                            value={value}
                                            className={`w-100 ${errors.state && "Validation"} `}
                                            optionLabel="label"
                                            onChange={(e) => {
                                                onChange(e)
                                                fetchCities(country, e.target.value)
                                                setValue("city", "")
                                            }}
                                            placeholder="Select State"
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                City: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="city"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            options={allCities}
                                            value={value}
                                            className={`w-100 ${errors.city && "Validation"} `}

                                            optionLabel="label"
                                            onChange={(e) => {
                                              
                                                onChange(e.target.value)
                                            }}
                                            placeholder="Select City"
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className="col-4">
                            <label htmlFor="" className="py-2 labelCss">
                                Zip Code: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="zipcode"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={errors?.zipcode && "Validation"}
                                            value={value}

                                            onChange={onChange}
                                            placeholder={"Zip Code"}
                                        />
                                    );
                                }}
                            />

                        </div>
                        <div className="col-4">
                            <label htmlFor="" className="py-2 labelCss">
                                Project Status: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="status"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            options={PROJECT_STATUS}
                                            value={value}
                                            className={`w-100 ${errors.status && "Validation"} `}
                                            onChange={(e) => {
                                                onChange(e.target.value)
                                            }}
                                            placeholder="Select Project Status"
                                        />
                                    );
                                }}
                            />

                        </div>

                    </div>
                    <div className='my-2'>
                        <BulletPoints number="2" heading="Project Details" />
                    </div>
                    <div className="row">
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Type of Project: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="projectType"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            className={`w-100 ${errors.projectType && "Validation"} `}
                                            options={PROJECT_TYPES}
                                            placeholder="Select Type of Project"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Preferred Start Date: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="startDate"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            className={`${errors?.startDate && "Validation"} input_field form-control`}
                                            value={value}
                                            type="date"
                                            onChange={onChange}

                                        />
                                    );
                                }}
                            />

                        </div>
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Preferred Completion Date: <span className="text-danger">*</span>
                            </label>
                            <Controller
                                name="completionDate"
                                rules={{ required: true }}
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <input
                                            className={`${errors?.completionDate && "Validation"} input_field form-control`}
                                            value={value}
                                            type="date"
                                            onChange={onChange}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Estimated Budget: <span className="text-danger">*</span>
                            </label>

                            <Controller
                                rules={{ required: true }}
                                name="estimatedBudget"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextInput
                                            className={errors?.estimatedBudget && "Validation"}
                                            value={value}
                                            type="date"
                                            placeholder="Estimated Budget"
                                            onChange={onChange}
                                        />
                                    );
                                }}
                            />


                        </div>
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Do you have architectural plan? <span className="text-danger">*</span>
                            </label>

                            <Controller
                                rules={{ required: true }}
                                name="isArchitechturalPlan"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            className={`w-100 ${errors.isArchitechturalPlan && "Validation"} `}
                                            options={["Yes", "No"]}
                                            placeholder="Do you have architectural plan"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                            />


                        </div>
                        <div className='col-4'>
                            <label htmlFor="" className="py-2 labelCss">
                                Do you need assesstance with permits ? <span className="text-danger">*</span>
                            </label>

                            <Controller
                                name="needAssistance"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Dropdown
                                            className={`w-100 ${errors.needAssistance && "Validation"} `}
                                            options={["Yes", "No"]}
                                            placeholder="Do you need assesstance with permits"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="" className="py-2 labelCss">
                                Project Description
                            </label>
                            <Controller
                                name="description"
                                control={control}

                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <ReactQuill theme="snow"
                                            value={value}
                                            onChange={onChange}
                                        />
                                    );
                                }}
                            />

                        </div>
                    </div>

                    <div className='my-2'>
                        <BulletPoints number="3" heading="Additional Service Required" />
                    </div>


                    <Controller
                        name="additionalServices"

                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                additionalServiceRequired.map((res, ind) => {
                                    return (
                                        <div className='d-flex align-items-center'>
                                            <Checkbox checked={value.includes(res)}
                                                onChange={(e) => {
                                                    let { checked } = e.target
                                                    onChange(checked ? [...value, res] : value.filter(e => e !== res))
                                                }}
                                            />
                                            <div>{res}</div>
                                        </div>

                                    )
                                })
                            );
                        }}
                    />

                    {/* <input type='text' className='form-control' /> */}

                    <div className='my-2'>
                        <BulletPoints number="4" heading="Additional Notes Required" />
                    </div>
                    <Controller
                        name="additionalNotes"

                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <ReactQuill theme="snow"
                                    value={value}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />

                    <div className='my-2'>
                        <BulletPoints number="5" heading="Preferred Contact Method" />
                    </div>
                    <Controller
                        name="contactMethod"

                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                preferredContactMethods.map((res, ind) => {
                                    return (
                                        <div className='d-flex align-items-center'>
                                            <Checkbox
                                                checked={value.includes(res)}
                                                onChange={(e) => {
                                                    let { checked } = e.target
                                                    onChange(checked ? [...value, res] : value.filter(e => e !== res))
                                                }}
                                            />
                                            <div>{res}</div>
                                        </div>
                                    )
                                })
                            );
                        }}
                    />
                </div>
                <div className="end gap-3">
                    <Link to="/clientRequest">
                        <button className='btn-Secondary'>Cancel</button>
                    </Link>
                    <PrimaryButton text={"Submit"} type="submit" />
                </div>

            </form>
        </div>
    )
}

function BulletPoints({ number, heading }) {
    return (
        <div className={style.client_request_form_bullet_points}>
            <div className={style.bullet_point}>{number}</div>
            <div className={style.bullet_point_text}>{heading}</div>
        </div>
    )
}