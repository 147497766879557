import React, { useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import style from "./schedule.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { useNavigate } from "react-router-dom";
import Calender from "../../Components/Common/Calender";
import TextInput from "../../Components/Inputs/TextInput";
import { Editor } from "primereact/editor";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import ReactQuill from "react-quill";

export const ViewEvent = () => {
  const progressValue = 20;

  const [text, setText] = useState("");
  const [approved, stApproved] = useState(false);

  const navigate = useNavigate();

  const [products, setProducts] = useState([
    {
      id: 1,
      web: "Expected Start Date:",
      "3.2.3": "28 February 2024, 02:00 PM IST (Wednesday)",
      Resource: "Expected Finish Date:",
      Electrical: "04 March 2024, 06:00 PM IST (Monday)",
    },
    {
      id: 2,
      web: "Actual Start Date:",
      "3.2.3": "28 February 2024, 02:00 PM IST (Wednesday)",
      Resource: "Expected Finish Date:",
      Electrical: "04 March 2024, 06:00 PM IST (Monday)",
    },
    {
      id: 3,
      web: "Start Date Variation:",
      "3.2.3": "02:30 Hrs",
      Resource: "Finish Date Variation:",
      Electrical: "17 Hrs",
    },
    {
      id: 4,
      web: "Expected Duration:",
      "3.2.3": "5 days, 4 hrs",
      Resource: "Actual Duration:",
      Electrical: "5 days, 18 hrs 30 mins",
    },
  ]);

  const [products2, setProducts2] = useState([
    {
      id: 1,
      RequestedOn: "20/02/2024",
      RequestedBy: "Joshua Templeton (Project Manager)",
      ChangeRequested:
        "Change Start Date From 27/02/2024, 2pm to 27/02/2024, 2pm",
      Reason: "Delay from the previous step",
      Status: "Approved",
    },
    {
      id: 2,
      RequestedOn: "02/11/2023",
      RequestedBy: " Dennis Paul (Project Manager)",
      ChangeRequested:
        "Change Finish Date From 27/02/2024, 2pm to 27/02/2024, 2pm",
      Reason: "Staffing Problem",
      Status: "Unapproved",
    },
    {
      id: 3,
      RequestedOn: "02/03/2024",
      RequestedBy: "Joshua Templeton (Project Manager)",
      ChangeRequested:
        "Change Finish Date From 27/02/2024, 2pm to 27/02/2024, 2pm",
      Reason: "Unfavorable weather conditions ",
      Status: "Approved",
    },
  ]);
  const [visible, setVisible] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [{ name: "All", code: "all" }];

  return (
    <>
      <div className="mainContainer">
        <div className="card p-md-4 p-sm-3 p-2">
          <div className="row">
            <div className="col-12 py-3  px-4 " style={{ marginLeft: "4px" }}>
              <span
                className="open_Rfi_text"
                onClick={() => navigate("/schedule")}
              >
                {" "}
                Schedule (List)
              </span>
              <span className="px-1 open_Rfi_text"> /</span>
              <span className="createNew_text">
                {" "}
                Submittals: Lighting Control: Fabrications/Shipping
              </span>
            </div>
          </div>

          <div className="row px-3 mt-4">
            <div className="col-12 ">
              <span className="newRfi_text">
                {" "}
                Submittals: Lighting Control: Fabrications/Shipping{" "}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 py-3  px-4 " style={{ marginLeft: "4px" }}>
              <div className={`card   `}>
                <div
                  className={` d-flex align-items-center gap-2 ${style.ProgressBarCss}`}
                >
                  <ProgressBar
                    className="w-100"
                    value={progressValue}
                  ></ProgressBar>
                  <span className="ms-1">{`${progressValue}%`} </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5  px-3">
            <BorderedTable>
              <DataTable value={products}>
                <Column field="web" header="WBS"></Column>
                <Column field="3.2.3" header="3.2.3"></Column>
                <Column field="Resource" header="Resource"></Column>
                <Column field="Electrical" header="Electrical"></Column>
              </DataTable>
            </BorderedTable>

            <span className={` ${style.addBorder}`}></span>
          </div>
          <div className="mt-3  px-3">
            <span className={style.request_Heading}>
              REQUEST A SCHEDULE CHANGE
            </span>
            <div className="row mt-3">
              <div className="col-4">
                <label htmlFor="" className="py-2 labelCss">
                  New Start Date:
                </label>
                <Calender />
              </div>
              <div className="col-4">
                <label htmlFor="" className="py-2 labelCss">
                  New End Date:
                </label>
                <Calender />
              </div>
              <div className="col-4">
                <label htmlFor="" className="py-2 labelCss">
                  Current Progress Percentage:
                </label>

                <TextInput />
              </div>
              <div className="col-12 mt-3">
                <label htmlFor="" className="py-2 labelCss">
                  Reason for Request:
                </label>
                <div className="card">
                  {/* <Editor
                    value={text}
                    onTextChange={(e) => setText(e.htmlValue)}
                    style={{ minHeight: "140px" }}
                  /> */}

                  <ReactQuill theme="snow"
                    value={text}
                    onChange={setText}
                  />


                </div>
                <div className="col-12 my-3 d-flex justify-content-end">
                  <PrimaryButton text={"Request"} />
                </div>
                <span className={` ${style.addBorder}`}></span>
              </div>
            </div>
          </div>

          <span className={`${style.schedlueChangeHeading} px-3 `}>
            SCHEDULE CHANGE REQUEST HISTORY
          </span>
          <div className="mt-3  px-3">
            <BorderedTable>
              <DataTable value={products2}>
                <Column field="RequestedOn" header="Requested On"></Column>
                <Column field="RequestedBy" header="Requested By"></Column>
                <Column
                  field="ChangeRequested"
                  header="Change Requested"
                ></Column>
                <Column field="Reason" header="Reason for Request"></Column>
                <Column
                  field="Status"
                  header="Status"
                  body={(rowData) => (
                    <div
                      style={{
                        color:
                          rowData.Status === "Approved" ? "green" : "#F3A218",
                      }}
                    >
                      {rowData.Status}
                    </div>
                  )}
                ></Column>
              </DataTable>
            </BorderedTable>
          </div>
        </div>
      </div>
    </>
  );
};
