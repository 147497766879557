import React, { useEffect, useState } from "react";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import moment from "moment";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TableSkelton } from "../../Components/Common/TableSkelton";
const ViewProjectStages = ({ stageData,hidePagination }) => {
  let [stageInfo, setStageInfo] = useState([]);
  const dynamicColumns = [
    {
      field: "stageName",
      header: "Stage Name",
      body: (rowData, rowIndex) => {
        return <>{rowData.stage_name}</>;
      },
    },
    {
      field: "startDate",
      header: "Start Date",
      body: (rowData, rowIndex) => {
        return <>{moment(rowData?.expected_start_date).format("MM/DD/YYYY")}</>;
      },
    },
    {
      field: "endDate",
      header: "End Date",
      body: (rowData, rowIndex) => {
        return <>{moment(rowData.expected_end_date).format("MM/DD/YYYY")}</>;
      },
    },
  ].map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      body={col.body}
    />
  ));
  useEffect(() => {
    if (stageData) {
      setStageInfo(stageData);
    }
  }, [stageData]);
  return (
    <>
      <div className="table">
      { 
      stageData?
      <div style={{width:'100%',overflow:'auto'}}>
          <div className="card">
            {hidePagination? <DataTable
              value={stageInfo}
              reorderableColumns
              // paginator
              // rows={5}
              // rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
              // reorderableRows
              // onRowReorder={(e) => setRows(e.value)}
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                body={(rowData, index) => <div> {index.rowIndex + 1} </div>}
              />
              {dynamicColumns}
            </DataTable>: <DataTable
              value={stageInfo}
              reorderableColumns
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}
              reorderableRows
              // onRowReorder={(e) => setRows(e.value)}
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                body={(rowData, index) => <div> {index.rowIndex + 1} </div>}
              />
              {dynamicColumns}
            </DataTable>}
           
          </div>
        </div>:<TableSkelton header={["","Stage Name","Start Date","End Date"]}/>}
      </div>
    </>
  );
};

export default ViewProjectStages;
