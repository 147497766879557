import { Dialog } from '@mui/material';
import React from 'react'
import TextInput from '../../Components/Inputs/TextInput';

export default function AddInternalAttribute({
    visible,
    toggle,
    dialogFor,
    value,
    setValue,
    onSubmit,
    label,
    loader
}) {
    return (
        <>
            <Dialog style={{ overflow: 'hidden' }} open={visible} onClose={() => toggle()}>
                <form onSubmit={onSubmit}>
                    <div className="row py-4 mx-3">
                        <div className="col-md-12 mt-4">
                            <label className="labels mb-2">{dialogFor} Name:</label>
                            <span className="text-danger">*</span>
                            {/* 
                        {
                            false ? <FormSkeleton data={skeletonData} mt={"mt-1"} /> : <div className="card flex justify-content-center  "> */}
                            <TextInput
                                placeholder={`Enter ${label} Name`}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                required
                                showIcon
                            // style={{ textTransform: 'capitalize' }}
                            />
                            {/* </div> */}
                            {/* } */}
                        </div>

                        <div className="col-md-12 mt-5 d-flex justify-content-end">
                            <div className="d-flex align-items-center gap-3">
                                <button
                                    type='button'
                                    className="filter_btn"
                                    onClick={toggle}
                                >
                                    Cancel
                                </button>
                                <button className="filter_btn_apply" disabled={loader} type='submit'>
                                    {loader ? <div className='mx-2'>
                                        <div class="spinner-border text-light spinner-border-sm" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div> : `Add`}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        </>
    )
}
