import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Calender from "../../Components/Common/Calender";
import { getPrioritytype, getUserByProjectId } from "../../Api/Admin/AllAPIs";

const Filter = ({ filterData, setFilterData, onFilter,className }) => {
  const [visible, setVisible] = useState(false)
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const [priorities, setPriorities] = useState([])
  const projectId = localStorage.getItem("projectId");

  const statusOptions = [
    { label: "Draft", value: "draft" },
    { label: "In Review", value: "inreview" },
    { label: "Pending Approval", value: "pendingapproval" },
    { label: "Approved", value: "approved" },
  ]


  function inpChange(e) {
    let { name, value } = e.target;
    setFilterData(pre => ({ ...pre, [name]: value }))
  }

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId)
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllUsersOfProject()
  }, [])

  const getIncidentPriority = async (projectId) => {
    try {
      const res = await getPrioritytype(projectId)
      const typee = res?.data?.data
      setPriorities(typee)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIncidentPriority(projectId)
  }, [])

  function handleFilter() {
    setVisible(false)
    onFilter()

  };

  return (
    <div className={`card flex justify-content-center ${className}`}>
      <button
        className="filter_btn w-100"
        onClick={() => setVisible(true)}
      >
        Filter 
      </button>
      <Dialog
        draggable={false}
        header="Filter Incident"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row mt-4">
          <div className="col-md-6 mt-3">
            <label className="labels mb-1">Approval Status:</label>

            <div className="card flex justify-content-center ">
              <Dropdown
                value={filterData.issue_status}
                onChange={inpChange}
                name="status"
                options={statusOptions}
                placeholder="Status"
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <label className="labels mb-1"> Incident Identified By:</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData?.incident_identifiedBy}
                onChange={inpChange}
                options={allUsersOfProject.map(d => ({ label: d.first_name, value: d._id }))}
                name="incident_identifiedBy"
                placeholder="All Users"
              />
             
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <label className="labels mb-1"> Priority:</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData?.priority}
                onChange={inpChange}
                options={priorities.map(d => ({ label: d.name, value: d._id }))}
                name="priority"
                placeholder="All Users"
              />
             
            </div>
          </div>

          {/* <div className="col-md-6 mt-3">
            <label className="labels mb-1">Hazards:</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData.issue_type}
                onChange={inpChange}
                name="hazard"
                options={hazards}
                placeholder="Status"
              />
            </div>
          </div> */}
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={handleFilter}>Apply Filter</Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Filter;
