// TimeInput.js
import React, { useState } from 'react';
import { MenuItem, Popover, TextField, Button, List } from '@mui/material';
import "./textInput.css"


const TimeInput = ({ value, onChange, hourLength = 24, minuteLength = 60, ...props }) => {
    const hours = Array.from({ length: hourLength }, (_, i) => i);
    const minutes = Array.from({ length: minuteLength }, (_, i) => i);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentField, setCurrentField] = useState(null);

    const handleClick = (field, event) => {
        setAnchorEl(event.currentTarget);
        setCurrentField(field);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentField(null);
    };

    const handleSelect = (selectedValue) => {
        const newValue = { ...value, [currentField]: selectedValue };
        onChange(newValue);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const formattedHours = String(value.hours).padStart(2, '0');
    const formattedMinutes = String(value.minutes).padStart(2, '0');


    return (
        <div className="timeInputContainer">
            <TextField
                disabled
                // value={`${formattedHours}:${formattedMinutes}`}
                // onClick={(event) => handleClick(currentField, event)}
                // placeholder="HH:MM"
                {...props}
                readOnly
                fullWidth
                InputProps={{
                    startAdornment: (
                        <div className='d-flex align-items-center gap-2'>
                            <div
                                className='timeSelect_item'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setCurrentField('hours');
                                    handleClick('hours', event);
                                }}
                            >
                                {formattedHours == "00" ? "HH" : formattedHours}
                            </div>
                            :
                            <div
                                className='timeSelect_item'
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setCurrentField('minutes');
                                    handleClick('minutes', event);
                                }}
                            >
                                {formattedMinutes == "00" ? "MM" : formattedMinutes}
                            </div>
                        </div>
                    )
                }}
            />
            <Popover
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <List sx={{ width: "150px", height: "300px", overflow: "auto" }}>
                    {(currentField === 'hours' ? hours : minutes).map((item) => (
                        <MenuItem key={item} onClick={() => handleSelect(item)}>
                            {String(item).padStart(2, '0')}
                        </MenuItem>
                    ))}
                </List>
            </Popover>
        </div>
    );
};

export default TimeInput;
