import React from 'react'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'

export const NoAccessPage = () => {
    let navigate = useNavigate();
    return (
        <div className="p-5" style={{height:"calc(100vh - 80px)"}}>
            <div className='w-100 card h-100 d-flex align-items-center justify-content-center'>
                <div>
                    <h2>
                        You Don't Have Permission To Access This Page !
                    </h2>

                </div>

            </div>
        </div>
    )
}
