import React, { useState } from "react";
import { BorderedTable } from "../../../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import Popover from "../../../../Components/Common/Popover";
import { Row } from "primereact/row";
import { RxCross2 } from "react-icons/rx";
import { Dialog } from "primereact/dialog";
import NewPayment from "./NewPayment";

function PaymentReceived() {
  const [visible, setVisible] = useState(false);

  const centeredHeaderTemplate = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="flex justify-center w-full font-bold">
        Add Vertical Markup
      </span>

      <span className=" " onClick={() => setVisible(false)}>
        <span className="add_icon_text">
          {" "}
          <RxCross2 size={15} />{" "}
        </span>
      </span>
    </div>
  );

  const demodata = [
    {
      id: "1000",
      code: "f230fh0g3",
      Owner: "Invoice Name 01",
      Title: "Details about the payment",
      Status: "01",
      Executed: "657102",
      Original_Contract_Amount: "16/02/2024",
      Approved_Change_Orders: "Document1.pdf",
      Amount: "$ 1,250.00"
    },
    {
      id: "1001",
      code: "a430fj0h2",
      Owner: "Invoice Name 02",
      Title: "Payment for services",
      Status: "02",
      Executed: "657103",
      Original_Contract_Amount: "20/03/2024",
      Approved_Change_Orders: "Document2.pdf",
      Amount: "$ 2,500.00"
    },
    {
      id: "1002",
      code: "b330gk0i4",
      Owner: "Invoice Name 03",
      Title: "Invoice for products",
      Status: "03",
      Executed: "657104",
      Original_Contract_Amount: "10/04/2024",
      Approved_Change_Orders: "Document3.pdf",
      Amount: "$ 3,750.00"
    },
    {
      id: "1003",
      code: "c230hl0j5",
      Owner: "Invoice Name 04",
      Title: "Final payment details",
      Status: "04",
      Executed: "657105",
      Original_Contract_Amount: "05/05/2024",
      Approved_Change_Orders: "Document4.pdf",
      Amount: "$ 5,000.00"
    },
];


  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Grand Total:"
          colSpan={2}
          footerStyle={{ textAlign: "center" }}
        />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={"$ 12500.00"} />
      </Row>
    </ColumnGroup>
  );
  return (
    <>
    
    <div className="mt-4">
      <BorderedTable>
        <DataTable
          scrollable
          scrollHeight="flex"
          value={demodata}
          footerColumnGroup={footerGroup}
          tableStyle={{ minWidth: "" }}
        >
          <Column
            field="#"
            header="#"
            body={(rowData, rowIndex) => (
              <div>
                {rowIndex.rowIndex < 99 ? "0" : ""}
                {rowIndex.rowIndex < 9 ? "0" : ""}
                {rowIndex.rowIndex + 1}
              </div>
            )}
          ></Column>
          <Column
            field="Owner"
            header="Invoice"
            body={(rowData, rowIndex) => (
              <span
                style={{
                  // cursor: "pointer",
                  // color: "blue",
                  // textDecoration: "underline",
                }}
                // onClick={() => navigate("ViewContract")}
              >
                {rowData.Owner}
              </span>
            )}
          />

          <Column field="Title" header="Description"></Column>
          <Column field="Status" header="Invoice #"></Column>
          <Column field="Executed" header="Check #"></Column>
          <Column
            header="Date"
            field="Original_Contract_Amount"
            // body={(rowData) => moment(rowData.initiatedDate).format("MM-DD-YYYY")}
          ></Column>
          <Column
            field=""
            header="Attachments"
            body={(rowData, rowIndex) => (
              <span
                style={{
                  cursor: "pointer",
                  // color: "blue",
                  textDecoration: "underline",
                }}
                // onClick={() => navigate("ViewContract")}
              >
                {rowData.Approved_Change_Orders}
              </span>
            )}
          ></Column>
          <Column
            field="Amount"
            header="Amount"
          ></Column>
        </DataTable>
      </BorderedTable>
    </div>

    <Dialog
        style={{ width: "40%" }}
        visible={visible}
        closable={false}
        draggable={false}
        onHide={() => setVisible(false)}
        header={centeredHeaderTemplate}
      >aaaaaaaa</Dialog>

    </>
  );
}

export default PaymentReceived;
