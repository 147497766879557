import React, { useEffect, useState } from 'react'
import { SearchInput } from '../../Components/Inputs/SearchInput'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { Link } from 'react-router-dom'
import { BorderedTable } from '../../Components/Tables/BorderedTable'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useGetAllClientRequestData } from '../../hooks/useGetAllClientRequestData'
import moment from 'moment'

export const ClientRequests = () => {
   
    const { data, getData,setSearch,search } = useGetAllClientRequestData()

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>

                <div className='page_heading'>Client Request</div>
                <Link to={"create"}>
                    <PrimaryButton text={"Create"} />
                </Link>
            </div>
            <div className="d-flex mb-20 gap-3 flex-sm-row flex-column-reverse justify-content-sm-between  align-items-sm-center align-items-end mt-3">
                <div>
                    <SearchInput
                        value={search}
                        onCancel={() => {
                            setSearch("")
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value)

                        }}
                    />
                </div>

            </div>
            <br />

            <BorderedTable>
                <DataTable
                    value={data}
                >
                    <Column field="fullName" header="Full Name"></Column>
                    <Column field="email" header="Email"></Column>
                    <Column field="companyName" header="Company Name"></Column>
                    <Column header="Start Date"
                        body={(rowData) => moment(rowData.startDate).format("DD-MM-YYYY")}
                    ></Column>
                    <Column
                        body={(rowData) => moment(rowData.completionDate).format("DD-MM-YYYY")}
                        header="End Date"></Column>

                </DataTable>
            </BorderedTable>




        </div>
    )
}
