import { TabPanel, TabView } from 'primereact/tabview';
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../../Components/Buttons/PrimaryButton';
import { ExportPdfButton } from '../../../../Components/Buttons/ExportPdfButton';

export const BudgetOutlet = () => {

    const [activeStep, setActiveStep] = useState(0)
    let navigate = useNavigate();
    const Data = [
        { name: 'PDF', code: 'pd' },
        { name: "CSV ", code: 'cs' }
      
    
    ];
    const steps = [
        { label: 'Budget', path: "/financialManagement/budget" },
        { label: 'Budget Changes', path: "/financialManagement/budget/budgetChanges" },
        { label: 'Modifications', path: "/financialManagement/budget/modifications" },
        { label: 'Snapshots', path: "/financialManagement/budget/snapShots" },
    ]

    return (
        <div className='budgetModule_container'>

            <div className="align_center flex-wrap between">
                <div className="page_heading mb-2">Budget</div>
                <div className='d-flex gap-3'>
                <div className=" d-flex align-items-center">
                                <ExportPdfButton exportToPDF={"CSV "}  PassData={Data}/>
                                  
                            </div>
                    <PrimaryButton text={"Add Budget"} onClick={()=>navigate("budgetCreate")}  addIcon />
                </div>
            </div>

            <TabView activeIndex={activeStep}
                onTabChange={(e) => {
                    setActiveStep(e.index)
                    navigate(steps[e.index].path)
                }}
            >
                {steps.map((res, key) => <TabPanel key={key} header={res.label} />)}
            </TabView>


           <Outlet />
        </div>
    )
};
