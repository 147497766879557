import { Country, State, City } from 'country-state-city'
import { useCallback, useEffect, useState } from 'react'

export const useGetAllCountries = () => {
  let [data, setData] = useState([])

  useEffect(() => {
    let r = Country.getAllCountries().map(e => ({
      label: e.name,
      value: e.isoCode
    }))
    setData(r)
  }, [])

  return { data }
}

export const useGetStatesOfCountry = () => {
  let [data, setData] = useState([])
  let getData = useCallback(countryIsoCode => {
    let r = State.getStatesOfCountry(countryIsoCode).map(e => ({
      label: e.name,
      value: e.isoCode
    }))
    setData(r)
  }, [])
  return { getData, data }
}

export const useGetCitiesOfCountryAndState=()=>{
  let [data,setData]=useState([])
let getData=useCallback((countryCode,stateCode)=>{
let cityData=City.getCitiesOfState(countryCode,stateCode).map(e => ({
  label: e.name,
  value: e.name
}))
setData(cityData)
},[])

return ({data,getData})

}