import React from 'react'
import { DirectCostForm } from './DirectCostForm'
import { addDirectCost, uploadDocs } from '../../../../Api/Admin/AllAPIs';
import { useNavigate } from 'react-router-dom';

export const CreateDirectCost = () => {

    let navigate = useNavigate()

    let formData = {
        direct_cost_name: "",
        direct_cost_type: "",
        direct_status: "",
        contracting_party: null,
        employees: [],
        received_date: "",
        paid_date: "",
        direct_description: "",
        attachment: [],
        cost_line_item: []
    };

    async function createDirectCost(data) {
        try {
            let attachment = [];

        
            if (data?.attachment?.length !== 0) {
                const formData = new FormData();
                for (const file of data?.attachment) {
                    formData.append("upload", file.file);
                    attachment.push(file.file)
                }
                let uploadResponse = await uploadDocs(formData);
           
                data.attachment = uploadResponse.data.map((item) => ({
                    fileName: item.fileName,
                    fileUrl: item.fileUrl,
                }))
            }
         
            let res = await addDirectCost(data)
        
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <div className="row m-0 p-0">
                <div className="col-md-12 p-0 py-3">
                    <span
                        className="open_Rfi_text"
                        onClick={() => navigate("/financialManagement/directCost")}
                    >
                        Direct Cost
                    </span>
                    <span className="px-1 open_Rfi_text">/</span>
                    <span className="createNew_text"> Create Direct Cost</span>
                </div>
            </div>
            <DirectCostForm formData={formData} onFormSubmit={createDirectCost} />
        </div>
    )
};
