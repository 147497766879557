import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextInput from '../../../../Components/Inputs/TextInput';
import { Dropdown } from 'primereact/dropdown';
import { DIRECT_COST_STATUS, DIRECT_COST_TYPES } from '../../../../constants';
import { Editor } from 'primereact/editor'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AddItemLine } from './AddItemLine';
import { HtmlIntoText } from "../../../../Components/Common/HtmlIntoText";
import { PrimaryButton } from '../../../../Components/Buttons/PrimaryButton';
import { getProjectCompanyData, getUserOfCompanyInsideProject } from '../../../../Api/Admin/AllAPIs';
import { getProjectId } from '../../../../utils';
import { MultiSelect } from 'primereact/multiselect';
import CustomDragAndDrop from '../../../../Components/Inputs/CustomDragAndDrop';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';

export const DirectCostForm = ({ formData, onFormSubmit }) => {

    const { control, watch, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: { ...formData }
    });
    const [deleted, setDeleted] = useState([]);

    const [memberLoader, setMemberLoader] = useState(false)

    const { fields, append } = useFieldArray({
        control,
        name: "cost_line_item"
    });
    const [contractingParty, setContractingParty] = useState([])
    const [members, setMembers] = useState([])

    const onSubmit = async (data) => {
        onFormSubmit(data)

    }

    async function fetchCompanyOfProject() {
        try {
            let project_id = getProjectId();
            let res = await getProjectCompanyData(project_id);
            setContractingParty(res?.data?.data.map(dd => ({ label: `${dd.name} (${dd.type})`, value: { id: dd._id, type: dd.type } })) || [])
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchCompanyOfProject()
    }, [])

    let contracting_party = watch("contracting_party")

    useEffect(() => {
        if (contracting_party?.id) {
            setValue("employees", [])
            setMembers([])
            if (contracting_party?.type !== "individual") {
                fetchUsersOfProject(contracting_party)
            }

        }
    }, [contracting_party]);

    async function fetchUsersOfProject(data) {
        try {
            setMemberLoader(true)
            let res = await getUserOfCompanyInsideProject(getProjectId(), { id: data.id })
           
            setMembers(res?.data?.data.map(e => ({ label: e.first_name, value: e._id })) || [])
        } catch (error) {
            console.log(error)
        }
        finally {
            setMemberLoader(false)
        }
    }

    return (
        <div>
            <div className="page_heading">CREATE DIRECT COST</div>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row">
                    <div className="col-12 mt-3">
                        <label className="py-1 labelCss">
                            Name: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="direct_cost_name"
                            rules={{ required: true }}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TextInput
                                        className={errors?.direct_cost_name && "Validation"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Name"}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="py-1 labelCss">
                            Type: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="direct_cost_type"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Dropdown
                                        placeholder='Type'
                                        className={`${errors?.direct_cost_type && "Validation"} w-100`}
                                        options={DIRECT_COST_TYPES}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="py-1 labelCss">
                            Status: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="direct_status"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Dropdown
                                        placeholder='Status'
                                        className={`${errors?.direct_status && "Validation"} w-100`}
                                        options={DIRECT_COST_STATUS}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="py-1 labelCss">
                            Contracting Party: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="contracting_party"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Dropdown
                                        placeholder='Contracting Party'
                                        className={`${errors?.contracting_party && "Validation"} w-100`}
                                        options={contractingParty}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="py-1 labelCss">
                            Employee (s): <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="employees"
                            control={control}
                            rules={{ required: contracting_party?.type !== "individual" }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MultiSelect
                                        showSelectAll={false}
                                        disabled={contracting_party?.type == "individual"}
                                        removeIcon={null}
                                        loading={memberLoader}
                                        value={value} className={`${errors?.employees && contracting_party?.type !== "individual" && "Validation"} w-100`}
                                        onChange={onChange}
                                        options={members}
                                        placeholder="Members"
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="py-1 labelCss">
                            Received Date: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="received_date"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <input
                                        type={"date"}
                                        value={value}
                                        className={`${errors?.received_date && "Validation"} common_input form-control`}

                                        onChange={onChange}
                                        placeholder={"Name"}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="py-1 labelCss">
                            Paid Date: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="paid_date"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <input
                                        className={`${errors?.paid_date && "Validation"} common_input form-control`}
                                        type={"date"}
                                        value={value}
                                        onChange={onChange}
                                        placeholder={"Name"}
                                    />
                                )
                            }}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <label className="py-2 labelCss">
                            Description: <span className="text-danger">*</span>
                        </label>
                        <Controller
                            name="direct_description"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    // <Editor
                                    //     style={{ minHeight: "100px" }}
                                    //     value={value}
                                    //     onTextChange={(e) => onChange(e.htmlValue)}
                                    // />

                                    <ReactQuill theme="snow"
                                        value={value}
                                        onChange={onChange}
                                    />

                                )
                            }}
                        />
                    </div>
                    <div className="col-12 mt-3">

                        <Controller
                            name="attachment"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <CustomDragAndDrop
                                        onChange={(e) => {
                                           
                                            onChange(e)
                                        }}
                                        value={value}
                                        setDeleted={setDeleted}
                                    />
                                )
                            }}
                        />



                    </div>
                </div>
                <br />
                <h2>Cost Line Items</h2>

                <DataTable
                    value={fields}
                >
                    <Column header="#" />
                    <Column header="Cost Code" body={(rowData) => {
                        return (
                            <div>{`${rowData?.budget_parent_code}-${rowData?.budget_child_code} ${rowData?.budget_child_name}`}</div>
                        )
                    }} />
                    <Column header="Description" body={(rowData) => {
                        return <HtmlIntoText html={rowData?.costLine_description} />
                    }} />
                    <Column header="Tax Code" body={"----"} />
                    <Column header="Quantity" field='quantity' />
                    <Column header="UOM" field='unity_measure' />
                    <Column header="Unit Cost" field='unit_cost' />
                    <Column header="Amount" field='amount' />
                </DataTable>
                <br />
                <AddItemLine onSubmit={append} />

                <br />
                <div className="d-flex justify-content-end">
                    <Link to={"/financialManagement/directCost"}>

                        <button type='button' className='btn border'>Cancel</button>
                    </Link>
                    &nbsp;&nbsp;
                    <PrimaryButton text={"Create Direct Cost"} />
                </div>

            </form>

            <br />
        </div>
    )
}
