import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';

import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import Popover from '../../../../Components/Common/Popover';
import { useNavigate } from 'react-router-dom';
import { Statuscomp } from '../../../../Components/Common/StatusComp';
import Pagination from '../../../../Components/Common/Pagination/Pagination';

const sampleData = [
  { id: 1, cost_division: 'General Requirements', cost_code: '002 - Instructions', edited_by: 'Joshua Templeton', Status: 'Approved', status: 'open', edited_on: '2023-01-01' },
  { id: 2, cost_division: 'Structural', cost_code: '005 - Foundations', edited_by: 'Megan Brown', Status: 'Pending', status: 'close', edited_on: '2023-02-15' },
  { id: 3, cost_division: 'Electrical', cost_code: '010 - Wiring', edited_by: 'Alice Green', Status: 'Approved', status: 'reopen', edited_on: '2023-03-10' },
  { id: 4, cost_division: 'Plumbing', cost_code: '015 - Pipes', edited_by: 'Bob White', Status: 'Pending', status: 'onhold', edited_on: '2023-04-22' },
  { id: 5, cost_division: 'HVAC', cost_code: '020 - Ventilation', edited_by: 'Charlie Black', Status: 'Approved', status: 'open', edited_on: '2023-05-17' },
  { id: 6, cost_division: 'Finishes', cost_code: '025 - Painting', edited_by: 'David Blue', Status: 'Pending', status: 'close', edited_on: '2023-06-30' },
  { id: 7, cost_division: 'Site Work', cost_code: '030 - Landscaping', edited_by: 'Eva Red', Status: 'Approved', status: 'reopen', edited_on: '2023-07-08' },
  
];




export const BudgetChanges = () => {
  const [budgetChanges, setBudgetChanges] = useState([]);
  const [page, setPage] = useState(1)
const [totalRecords, setTotalRecords] = useState(0)
const perPageData = 10
  const navigate = useNavigate();

  useEffect(() => {
    setBudgetChanges(sampleData);
    setTotalRecords(5)
  }, []);

  return (
    <div>
      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput
            placeholder={"search"}
          />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <button className="filter_btn w-100">Filter Changes</button>
          <button className="filter_btn w-100">Clear Filter</button>
        </div>
      </div>

      <div className="card mt-4">
        <DataTable value={budgetChanges} rows={5}>
          <Column field="id" header="#" />
          <Column
            field="status"
            header="Status"
            style={{ width: "5rem" }}
            body={(rowData) => (
              <div className="d-flex justify-content-center">
                {rowData?.status?.toLowerCase() === "open" ? (
                  <Statuscomp className={"open_status"} />
                ) : rowData?.status?.toLowerCase() === "close" ? (
                  <Statuscomp className={"close_status"} />
                ) : rowData?.status?.toLowerCase() === "reopen" ? (
                  <Statuscomp className={"reopen_status"} />
                ) : rowData?.status?.toLowerCase() === "onhold" ? (
                  <Statuscomp className={"on_hold"} />
                ) : null}
              </div>
            )}
          ></Column>
          <Column field="cost_division" header="Cost Division" />
          <Column field="cost_code" header="Cost Code" />
          <Column field="edited_by" header="Edited by" />
          <Column field="edited_on" header="Edited on" />
          <Column
            header=""
            body={(rowData, meta) => (
              <div className="flex justify-content-center">
                <Popover
                  options={["Mark as Approved","Mark as Void"]}
                  onSelect={(index, event) => {
                    if (index === 0)
                      navigate(``);
                  }}
                />
            
              </div>
            )}
          />
        </DataTable>
      </div>
        
        <div className='mt-2'>
        <Pagination
                    currentPage={page}
                    totalCount={totalRecords}
                    pageSize={perPageData}
                    onPageChange={page => {
                      
                        setPage(page)
                    }}
                />
        </div>
    </div>
  );
}
