
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';
import { CreateRole } from './CreateRole';
import { getallrole } from '../../Api/Admin/AllAPIs';
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { TableSkelton } from '../../Components/Common/TableSkelton';
import Pagination from '../../Components/Common/Pagination/Pagination';
import { SearchInput } from '../../Components/Inputs/SearchInput';
import { searchDataWithKeys } from '../../utils';

export const RoleManagement = () => {

    const [allRoles, setAllRoles] = useState([])
    let [loader, setLoader] = useState(false);

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const perPageData = 10;
    const [totalRecords, setTotalRecords] = useState(0);
    const [allData, setAllData] = useState([])


    useEffect(() => {
        getAllRoles()
    }, [page])


    async function getAllRoles() {
        try {
            setLoader(true)

            let params = {
                page: page,
                perPageData: perPageData
            }
            setSearch("")
            let res = await getallrole(params)
            let data = res?.data;
            setTotalRecords(data?.totalCount || 0)
            setAllData(data?.data || [])
            setAllRoles(data?.data || [])
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoader(false)
        }
    }

    return (
        <div>

            <div className='d-flex justify-content-between align-items-center'>

                <div className='page_heading'>Role Management</div>

            </div>
            <div className="d-flex mb-20 gap-3 flex-sm-row flex-column-reverse justify-content-sm-between  align-items-sm-center align-items-end mt-3">
                <div>
                    <SearchInput
                        value={search}
                        onCancel={() => {
                            setAllRoles(allData)
                            setSearch("")
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value)
                            setAllRoles(searchDataWithKeys(["role"], allData, e.target.value))

                        }}
                    />
                </div>
                <CreateRole refresh={getAllRoles} />
            </div>
            <br />
            <div className='roleManagement_table_scroll'>
                {loader ? <TableSkelton header={["Role Name", "Actions"]} /> :
                    <BorderedTable>
                        <DataTable
                            value={allRoles}
                        >
                            <Column field="role" header="Role Name"></Column>
                            <Column field="user_type" header="Actions" body={(rowData) => {
                                return (
                                    <Link to={`manageAccess/${rowData._id}`} className='text-primary text-underline'>
                                        Update Access
                                    </Link>
                                )
                            }}></Column>

                        </DataTable>
                    </BorderedTable>

                }
            </div>
            <br />
            <Pagination
                currentPage={page}
                totalCount={totalRecords}
                pageSize={perPageData}
                onPageChange={setPage}
            />

        </div>
    )
};





















