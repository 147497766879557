import React, { useEffect, useState } from 'react';
import { Gantt } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { getScheduleDataWithPagination } from '../../Api/Admin/AllAPIs';
import { getProjectId, getToolsCommonData, loader } from '../../utils';
import { TOOLS_COLORS } from '../../constants';
import moment from 'moment';
import style from "./schedule.module.css"
import ScheduleTaskModal from './ScheduleTaskModal';

export default function ScheduleGanttChart() {

  const [selectedTool, setSelectedTool] = useState(null)
  const [taskData, setTaskData] = useState([])
  const [modal, setModal] = useState(false)
  const [clickedTaskData, setClickedTaskData] = useState({})
  const toolsOptions = [
    { name: "Task", code: "tasks", colorKey: "task" },
    { name: "Inspection", code: "inspectiondatas", colorKey: "inspection" },
    { name: "Incident", code: "incidents", colorKey: "incident" },
    { name: "Issue", code: "issues", colorKey: "issue" },
    { name: "Daily log", code: "dailylogdatas", colorKey: "dailyLog" },
    { name: "Specification", code: "specifications", colorKey: "specification" },
    { name: "Observation", code: "observations", colorKey: "observation" },
    { name: "Punch", code: "punchdatas", colorKey: "punch" },
    { name: "Submittal", code: "submittals", colorKey: "submittal" },
    { name: "Rfi", code: "rfis", colorKey: "rfi" },
  ];

  useEffect(() => {
    getData();
  }, [selectedTool]);

  async function getData() {
    try {
      let body = {
        toolName: selectedTool
      }
      loader.start()
      let res = await getScheduleDataWithPagination(getProjectId(), body)
      let response = res?.data?.data || []
      let data = response.map((res) => {
        return {
          id: res._id,
          name: getToolsCommonData(res.toolName, res).name,
          start: moment(res.created_at).startOf('day').toDate(),
          end: moment(getToolsCommonData(res.toolName, res).dueDate).endOf("day").toDate(),
          progress: 100,
          fontSize: "40px",
          taskId: getToolsCommonData(res.toolName, res).id,
          styles: { progressColor: TOOLS_COLORS[res.toolName], progressSelectedColor: '#ff9e0d', color: "red", fontSize: "40" }
        }
      })
      setTaskData(data)
    } catch (error) {
      console.log(error)
    }
    finally {
      loader.stop()
    }
  }

  const toolsTemplate = () => {
    return (
      <div className="col-12 bg-white p-3 border rounded shadow mb-3">
        <div className="d-flex flex-wrap gap-3">
          {toolsOptions.map((tool) => (
            <div key={tool}
              onClick={() => setSelectedTool(tool.code === selectedTool ? null : tool.code)}
              className={`d-flex align-items-center pointer ${style.tool_name} ${selectedTool === tool.code && style.selected_tool}`}
              style={{ marginRight: "10px", marginBottom: "10px" }}>
              <div
                className="rounded me-2"
                style={{
                  width: "30px",
                  height: "20px",
                  backgroundColor: TOOLS_COLORS[tool.colorKey],
                }}
              ></div>
              <span>{tool.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
 
  return (
    <div className="container-fluid">
      {toolsTemplate()}
      <ScheduleTaskModal
        onCloseModal={() => setModal(false)}
        data={clickedTaskData} open={modal} />
      {/* <GanttChart/> */}
      <div className="d-flex">
        {/* Gantt Chart Section */}
        <div className="flex-grow-1 border p-3" style={{ overflowX: 'auto' }}>
          {!!taskData?.length && <Gantt onClick={(e) => {
            setClickedTaskData(e)
            setModal(true)
          }} tasks={taskData} />}
        </div>
      </div>
    </div>
  );
}
