import moment from 'moment'
import { success, error, info } from '../Store/Slice/toast/toastSlice'
import { store } from '../Store/store'
import { startLoader, stopLoader } from '../Store/Slice/LoaderSlice'
import CryptoJS from 'crypto-js'
import { CRYPTO_HASH_KEY } from '../constants'

export const toast = {
  success: e => store.dispatch(success({ msg: e, show: true })),
  error: e => store.dispatch(error({ msg: e, show: true })),
  info: e => store.dispatch(info({ msg: e, show: true }))
}
export const loader = {
  start: () => store.dispatch(startLoader()),
  stop: () => store.dispatch(stopLoader())
}

export function searchDataWithKeys (keyarr, allData, searchString) {
  return allData.filter(d =>
    keyarr.some(dd =>
      d[dd]?.trim()?.toLowerCase().includes(searchString?.trim()?.toLowerCase())
    )
  )
}

export const parseHashedData = encryptedData => {
  if (!encryptedData) {
    return null
  }
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, CRYPTO_HASH_KEY)
    if (bytes) {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

export function getUserType () {
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)
  if (userData) {
    return userData?.user_type
  }
  return null
}

export function getUserId () {
  let userId = null
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)

  if (userData) {
    userId = userData._id
  }
  return userId
}

export function getUserData () {
  let userAllData = null
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)

  if (userData) {
    userAllData = userData
  }
  return userAllData
}
export const getUserOuterRoe = () => {
  let role = ''
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)
  if (userData) {
    role = userData?.roleData?.[0]?.role || ''
  }
  return role
}

export const getUserRoleId = () => {
  let userId = null
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)
  if (userData) {
    userId = userData?.role
  }
  return userId
}
export const getUserName = () => {
  let username = ''
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)
  if (userData) {
    username =
      userData?.name || userData?.first_name + ' ' + userData?.last_name
  }
  return username
}
export const getUserEmail = () => {
  let useremail = ''
  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)
  if (userData) {
    useremail = userData?.email || userData?.email_address
  }
  return useremail
}

export const getActionsPermission = moduleName => {
  if (getUserType() === 'admin') {
    return true
  }
  let permissionData = store.getState().rolesAndPermissionData?.data
  let isAccess = permissionData.some(
    d =>
      (d.module == moduleName && d.isAcess) ||
      d.sub_module.some(dd => dd.module == moduleName && dd.isAcess)
  )
  return isAccess
  // return useSelector("rolesAndPermissionData")
}

export const getProjectName = () => {
  let projectData = localStorage.getItem('projectData')
  let projectName = ''
  if (projectData) {
    projectName = JSON.parse(projectData).project_name
  }
  return projectName
}
export const getProjectData = () => {
  let projectData = localStorage.getItem('projectData')
  if (projectData) {
    return JSON.parse(projectData)
  }
  return null
}
export const getProjectCode = () => {
  let projectData = localStorage.getItem('projectData')
  if (projectData) {
    return (
      JSON.parse(projectData)?.projectNumber ||
      JSON.parse(projectData)?.project_code
    )
  }
  return null
}

export const getProjectId = () => {
  let projectId = localStorage.getItem('projectId')
  return projectId
}

export const isReviewerOfProject = () => {
  let projectData = localStorage.getItem('projectData')
  let isReviewer = false
  if (projectData) {
    isReviewer = JSON.parse(projectData).reviewer_id == getUserId()
  }
  return isReviewer
}
export const isApproverOfProject = () => {
  let projectData = localStorage.getItem('projectData')
  let isReviewer = false
  if (projectData) {
    isReviewer = JSON.parse(projectData).approver_id == getUserId()
  }
  return isReviewer
}

export const filterArrayWithMultipleKey = (dataArray, keyArr, string) => {
  return dataArray.filter(res => {
    return keyArr.some(
      key =>
        res.hasOwnProperty(key) &&
        res[key]
          .toString()
          .trim()
          .toLowerCase()
          .includes(string.trim().toLowerCase())
    )
  })
}

export const formatDateInIST = dateString => {
  if (!dateString) return ''
  const date = new Date(dateString)
  return date.toLocaleDateString('en-IN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

export const getPaginatedData = (data, page, perPage) => {
  const start = (page - 1) * perPage
  const end = page * perPage
  return data.slice(start, end)
}

export function showDateAndTime (date) {
  return `${moment(date).format('DD-MM-YYYY')} / ${moment
    .utc(date)
    .format('HH:mm')}`
}
export function convertHourlySpentStringIntoObject (str) {
  return {
    hours: parseInt(str.split('-')[0]) || '00',
    minutes: parseInt(str.split('-')[1]) || '00'
  }
}

export function changeStatusUi2 (status) {
  switch (status) {
    case 'Not Started':
      return (
        <div className='statusOuterns'>
          <div className='statusDotns' />
          <div className='Statusns'>Not Started</div>
        </div>
      )
      break
    case 'In Progress':
      return (
        <div className='statusOuterip'>
          <div className='statusDotip' />
          <div className='Statusip'>In Progress</div>
        </div>
      )
      break
    case 'Completed':
      return (
        <div className='statusOuterc'>
          <div className='statusDotc' />
          <div className='Statusc'>Completed</div>
        </div>
      )
      break

    case 'Ready for Review':
      return (
        <div className='statusOuterr'>
          <div className='statusDotr' />
          <div className='Statusr'>Ready for Review</div>
        </div>
      )
      break
    case 'Incomplete':
      return (
        <div className='statusOuterin'>
          <div className='statusDotin' />
          <div className='Statusin'>Incomplete</div>
        </div>
      )
      break

    default:
      break
  }
}

export function shortenFilename (filename) {
  const extension = filename.split('.').pop()
  const normalizedName = filename.replace(/[^a-zA-Z0-9]/g, ' ').trim()
  const parts = normalizedName.split(' ')
  if (parts.length === 0) return `...${extension}`
  const firstPart = parts[0].substring(0, 3)
  const lastPart = parts[parts.length - 1].substring(0, 3)
  const newFilename = `${firstPart}..${lastPart}.${extension}`
  return newFilename
}

export function formatKeysValues (key) {
  switch (key) {
    case 'reviewer_id':
      return 'Reviewer'
      break
    case 'approver_id':
      return 'Approver'
      break
    case 'task_name':
      return 'Task Name'
      break
    case 'workFlowStatus':
      return 'Work flow Status'
      break
    case 'taskManager':
      return 'Task Manager'
      break
    case 'description':
      return 'Description'
      break
    case 'responsibleContractor':
      return 'Responsible Contractor'
      break
    case 'responsibleMembers':
      return 'Responsible Members'
      break
    case 'responsiblePerson':
      return 'Responsible Person'
      break
    case 'hourSpent':
      return 'Hours Spent'
      break
    case 'dueDate':
      return 'Due Date'
      break
    case 'rfi_manager':
      return 'Rfi Manager'
      break
    case 'questionReceived':
      return 'Question Received'
      break
    case 'submittal_responsibleContractor':
      return 'Responsible Contractor'
      break
    case 'submittal_receivedFrom':
      return 'Received From'
      break
    case 'submittal_submittalManager':
      return 'Submittal Manager'
      break
    case 'received_from':
      return 'Received From'
      break
    case 'workflow_status':
      return 'Workflow Status'
      break
    case 'submittal_issueDate':
      return 'Issue Date'
      break
    case 'submittal_finalDueDate':
      return 'Final Due Date'
      break
    case 'inspection_type':
      return 'Inspection Type'
      break
    case 'meeting_name':
      return 'Meeting Name'
      break
    case 'updatedBy':
      return 'Updated By'
      break
    case 'phase_id':
      return 'Phase'
      break
    case 'category_id':
      return 'Category'
      break
    case 'sub_category_id':
      return 'Sub Caegory'
      break
    case 'doctype_id':
      return 'Doc Type'
      break
    case 'category_id':
      return 'Category'
      break
    case 'plannedStartDate':
      return 'Plan Start Date'
      break
    case 'plannedEndDate':
      return 'Plan End Date'
      break
    case 'actualStartDate':
      return 'Actual Start Date'
      break
    case 'actualEndDate':
      return 'Actual End Date'
      break
    case 'visitorCompanyName':
      return 'Visitor Company Name'
      break
    case 'visitorName':
      return 'Visitor Name'
      break

    default:
      return key
  }
}

export function getToolsCommonData(tool, toolData) {
  let obj = {
    name: '',
    id: '',
    dueDate:null
  }
  switch (tool) {
    case 'task':
      obj.name = toolData.task_name
      obj.id = toolData.taskId
      obj.dueDate=toolData.dueDate

      break;
    case "inspection":
      obj.name = toolData.name
      obj.id = toolData.inspectionId
      obj.dueDate=toolData.created_at
      break;
    case "incident":
      obj.name = toolData.title
      obj.id = toolData.incidentID
      obj.dueDate=toolData.incident_date
      break;
    case "issue":
      obj.name = toolData.issue_title
      obj.id = toolData.issue_ID
      obj.dueDate=toolData.issue_dueDate
      break;
    case "dailylog":
      obj.name = toolData.visitorName
      obj.id = toolData.dailyLogId
      obj.dueDate=toolData.actualEndDate
      break;
    case "specification":
      obj.name = toolData.visitorName
      obj.id = toolData.spec_id
      obj.dueDate=toolData.created_at
      break;
    case "observation":
      obj.name = toolData.title
      obj.id = toolData.observationId
      obj.dueDate=toolData.observedDate
      break;
    case "punch":
      obj.name = toolData.PunchItemName
      obj.id = toolData.punchID  
      obj.dueDate=toolData.date    
      break;
    case "submittal":
      obj.name = toolData.submittal_title
      obj.id = toolData.submittal_number
      obj.dueDate=toolData.submittal_finalDueDate
      break;
    case "rfi":
      obj.name = toolData.subject
      obj.id = toolData.rfi
      obj.dueDate=toolData.dueDate            
      break;

    default:
      break
  }
  return obj
}


export function durationBetwenTwoDays(date1,date2){
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);

  if (!momentDate1.isValid() || !momentDate2.isValid()) {
    return null; // Return null if any date is invalid
  }

  let durationInDays = momentDate2.diff(momentDate1, 'days');
  return durationInDays === 0 ? 1 : durationInDays;
}