import React from 'react'
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton'
import { SearchInput } from '../../Components/Inputs/SearchInput'
import { BorderedTable } from '../../Components/Tables/BorderedTable'
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

export const Reports = () => {


    let products = [
        { report: "Cost Codes Report", description: "All items across all tabs grouped by Cost Codes" },
        { report: "RFI Report", description: "RFI status with questions and responses" },
        { report: " Punch List Delays Report", description: "Punch items which are causing schedule delays" },
        { report: "Budget Detail Report", description: "Shows all commitments, change orders and budget modifications along with each line on budget." },
        { report: "Schedule Change Report", description: "Summary of all changes in the schedule filter by time or task assignment" },
        { report: "Weather Delays", description: "All days which have been market Delay Days due to the weather" },
        { report: "Daily Manpower Log", description: "All the daily logs of manpower" },
        { report: "Daily Productivity Report", description: "All the daily logs of tasks etc done" },
        { report: "Accidents Reports", description: "Log of all the accident, big or small, related to the project" },
        { report: "Issues Report", description: "Daily log of all the notes that has been marked as issues" },
    ]

    return (
        <div className="mainContainer main-container">
            <div className="card p-md-4 p-sm-3 p-2 ">
                <div className='d-flex justify-content-end'>
                    <PrimaryButton text={"Create"} />
                </div>
                <div className='d-flex'>
                    <SearchInput />
                </div>
                <br />
                <BorderedTable>
                    <DataTable value={products} paginator rows={5} rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 75, 100]}  >

                        <Column field="report" header="Report"></Column>
                        <Column field="description" header="Description"></Column>

                    </DataTable>
                </BorderedTable>
            </div>
        </div>
    )
}
