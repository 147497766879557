import { Dialog } from 'primereact/dialog'
import { MultiSelect } from 'primereact/multiselect'
import React, { useState } from 'react'
import { Button } from "primereact/button";

export const FilterEmail = ({ filterData, setFilterData, allTags, onFilter }) => {

    let [visible, setVisible] = useState(false)



    return (
        <div className="card flex justify-content-center">
            <button
                className="filter_btn"
                onClick={() => setVisible(true)}
            >
                Filter Items
            </button>
            <Dialog
                draggable={false}
                header="Filter Project"
                visible={visible}
                maximizable
                style={{ width: "50rem", color: "#101928" }}
                onHide={() => setVisible(false)}
                headerClassName="addBorder_bottom"
            >
                <div className="row ">

                    <div className="col-md-6">

                        <label className="labels py-2">Visitor Status</label>
                        <div className="card flex justify-content-center">
                            <MultiSelect
                                value={filterData?.tag}
                                onChange={(e) => {
                                    setFilterData(pre => ({ ...pre, tag: e.value }))
                                }}
                                options={allTags.map(e => ({ label: e.tag_name, value: e._id }))}
                                filter placeholder="Tag"
                            />
                        </div>
                    </div>


                    <div className="col-md-12 mt-5 d-flex justify-content-end">
                        <div className="d-flex align-items-center gap-3">
                            <button className="filter_btn" onClick={() => setVisible(false)}>
                                Cancel
                            </button>
                            <Button className="Apply_filter_btn" onClick={() => {
                                onFilter()
                                setVisible(false)
                            }}>
                                Apply Filter
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
