import React from 'react'
import { useState } from 'react';
import 'primeicons/primeicons.css';

import { NavLink } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import { ADMIN_ROLES } from '../../../constants';
import { getActionsPermission, getUserType } from '../../../utils';
import { useSelector } from 'react-redux';
import { ProgressBar } from 'primereact/progressbar';
import { cleanRolesAndPermissions } from '../../../Store/Slice/RolesAndPermissionsSlice';
import { IoClipboardOutline } from "react-icons/io5";
import { useDispatch } from 'react-redux';

import { MdOutlineMailOutline } from "react-icons/md";
const AdminWhole = ({ opentoggle }) => {

  const [openToggle, setopenToggle] = useState(opentoggle)

  let isAdmin = ADMIN_ROLES.includes(getUserType())

  let res = useSelector(e => e.rolesAndPermissionData)
  let dispatch = useDispatch()

  return (
    <div>
      {/* {isAdmin && */}
      {!res.loader ?
        <>
          {(getActionsPermission("Home") || isAdmin) &&
            <NavLink style={{ textDecoration: "none" }} to={`mainOverview`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">
                  <i className={`pi pi-home ${openToggle && "ms-1"}`}></i>

                </div>
                {openToggle ?
                  <div>
                    <span className="">Home</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }
          {(getActionsPermission("All Projects") || isAdmin) &&
            <NavLink style={{ textDecoration: "none" }} to={`projects`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <i className={`pi pi-briefcase ${openToggle && "ms-1"}`}></i>
                {openToggle ?
                  <div>
                    <span className="min-w2">All Project</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }



          {/* {(getActionsPermission("Financial Management") || isAdmin) &&
            <NavLink style={{ textDecoration: "none" }} to={`financialManagement`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">
                  <i className={`pi pi-wallet ${openToggle && "ms-1"}`}></i>

                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Financial Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          } */}

          {(getActionsPermission("Vendor Management") || isAdmin) &&
            <NavLink style={{ textDecoration: "none" }} to={`projectDirectories`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">
                  <i className={`pi pi-cog ${openToggle && "ms-1"}`}></i>

                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Vendor Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }

          {(getActionsPermission("User Management") || isAdmin) &&
            <NavLink style={{ textDecoration: "none" }} to={`userManagement`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">
                  <i className={`pi pi-users ${openToggle && "ms-1"}`}></i>
                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Users Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }

          {isAdmin &&
            <NavLink style={{ textDecoration: "none" }} to={`/rbacManagement`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">

                  <i className={`pi pi-sliders-h ${openToggle && "ms-1"}`}></i>
                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Role Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }
          {isAdmin &&
            <NavLink style={{ textDecoration: "none" }} to={`/emailManagement`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">

                  <MdOutlineMailOutline  className={`pi pi-sliders-h ${openToggle && "ms-1"}`}/>
                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Email Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }
          {isAdmin &&
            <NavLink style={{ textDecoration: "none" }} to={`/picklistManagement`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">

                  <IoClipboardOutline  className={`pi pi-sliders-h ${openToggle && "ms-1"}`}/>
                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Picklist Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }
          {isAdmin &&
            <NavLink style={{ textDecoration: "none" }} to={`/staffManagement`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">

                  <IoClipboardOutline className={`pi pi-sliders-h ${openToggle && "ms-1"}`}/>
                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Staff Management</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }
          {isAdmin &&
            <NavLink style={{ textDecoration: "none" }} to={`/clientRequest`}>
              <div className={`navlink ${!openToggle && "d-flex align-items-center justify-content-center"}`}>
                <div className="">

                  <IoClipboardOutline  className={`pi pi-sliders-h ${openToggle && "ms-1"}`}/>
                </div>
                {openToggle ?
                  <div>
                    <span className="min-w2">Client Request</span>
                  </div>
                  : ""}
              </div>
            </NavLink>
          }


          {/* <NavLink
            style={{ textDecoration: "none" }}
            to={`/`}
            onClick={()=>{
              dispatch(cleanRolesAndPermissions())
            }}
          >
            <div className="navlink">
              <div className="">
                <CiLogout />
              </div>
              <div className="mt-1">{openToggle ? <span className="">Logout</span> : ""}</div>
            </div>
          </NavLink> */}

        </> :
        <ProgressBar mode="indeterminate" style={{ height: '3px' }}></ProgressBar>
      }


    </div >
  )
}
export default AdminWhole;