import React, { useEffect, useState } from "react";
import "./Email.css";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";

import { useNavigate } from "react-router-dom";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MultiSelect } from "primereact/multiselect";
import { IoMdAdd } from "react-icons/io";

import AddSets from "../../Components/Common/AddSets";
import { SentEmails } from "./SentEmails";
import { createTag, getEmail, getTag, updateEmailById } from "../../Api/Admin/AllAPIs";
import { FilterEmail } from "./FilterEmail";
import { getActionsPermission, getProjectId, getUserId } from "../../utils";
import { getRolesAndPermissionData } from "../../Store/Slice/RolesAndPermissionsSlice";
import { useSelector } from "react-redux";

export default function Email() {


  let emailType = ["sent", "spam", "bin"]
  let d = useSelector(e => e.getRolesAndPermissionData)
  let navigate = useNavigate();
  let [selectedEmail, setSelectedEmail] = useState({})
  let [selectedTag, setSelectedTag] = useState([])
  let [totalCount, setTotalCount] = useState(0)
  let [visible, setVisible] = useState(false);
  let [loader, setLoader] = useState(true)
  let [activeIndex, setActiveIndex] = useState(0);
  let [checkedEmail, setCheckedEmail] = useState({})
  let [filteredData, setFilteredData] = useState({})

  let [allTags, setAllTags] = useState([])
  let [allEmails, setAllEmails] = useState([])

  let [tagName, setTagName] = useState("");
  let perPageData = 10;
  let [paginationData, setPaginationData] = useState({
    page: 1,
    emailType: "sent",
    tag: [],
    search: ""
  })


  async function addTagHandler() {
  
    try {
      setVisible(false)
      let res = await createTag({ tag_name: tagName, project_id: getProjectId() })
      setTagName("")
      getAllTags()
    
    }
    catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    getAllTags()
  }, [])
  async function getAllTags() {
    try {
      let res = await getTag(getProjectId())
      setAllTags(res.data.data)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllEmails()
  }, [paginationData])

  async function getAllEmails() {
    try {

      setLoader(true)
      setAllEmails([])
      setSelectedEmail({})
      let body = {
        page: paginationData.page,
        perPageData: perPageData,
        type: paginationData.emailType,
        tag: paginationData.tag,
        search: paginationData.search,
        user_id: getUserId()
      }
      let res = await getEmail(body)
     

      if (res?.data?.data?.length > 0) {
        let data = res?.data
        setAllEmails(data?.data || [])
        setTotalCount(data?.totalCount || 0)

        if (checkedEmail._id) {
          setSelectedEmail(data?.data.find(e => e._id == checkedEmail._id))
        }


      }
    } catch (error) {

      console.log(error)
    }
    finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    setCheckedEmail({})
    setSelectedTag([])
  }, [activeIndex])

  function emailCardClick(data) {
    setSelectedEmail(data)
  }
  function emailCheckClick(data) {

    setSelectedTag(data?.tag || [])
    setCheckedEmail(data)
  }

  async function updateEmailTag(tagData) {
    try {
      let res = await updateEmailById(checkedEmail._id, { tag: tagData })
      getAllEmails()
     
    }
    catch (err) {
      console.log(err)
    }
  }

  function filterData() {
  
    setPaginationData(pre => ({ ...pre, page: 1, ...filteredData }))

  }

  return (
    <div className="emailPage">

      <div>
        <div>

          <div className="header d-flex flex-column">
            <div className="upper px-2 ">
              <div className="left">
                <h1 className="page_heading">Email</h1>
              </div>

              <div className="right d-flex gap-3 align-items-center">

                {/* <div
                  className="d-flex gap-2 align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlineExport />
                  Export
                  <MdOutlineKeyboardArrowDown />
                </div> */}


                {getActionsPermission("Create Email") && <div
                  className="button"
                  onClick={() => navigate("compose")}>
                  <PrimaryButton text={"Create Email"} />
                </div>}
              </div>


            </div>
          </div>

          <div className="tabView">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => {
                setPaginationData({ page: 1, emailType: emailType[e.index] })
                setActiveIndex(e.index)
              }}
            >
              <TabPanel header="Sent">
              </TabPanel>
              <TabPanel header="Spam">
              </TabPanel>
              <TabPanel header="Bin">
              </TabPanel>
            </TabView>
          </div>

          <hr className="m-0 p-0" />

          <div className="d-flex my-4 justify-content-between">

            <div>
              <SearchInput placeholder={"Search Mails"}
                value={paginationData.search}
                onCancel={() => setPaginationData(pre => ({ ...pre, page: 1, search: '' }))}
                onChange={(e) => {
                  setPaginationData(pre => ({
                    ...pre,
                    page: 1,
                    search: e.target.value
                  }))
                }} />
            </div>

            <div className="d-flex gap-2 align-items-center">
              <div>
                <FilterEmail
                  allTags={allTags}
                  filterData={filteredData}
                  setFilterData={setFilteredData}
                  onFilter={filterData}
                />
              </div>
              <div>
                <button className={` ${Object.keys(filteredData).length === 0 && 'disabled'} clear_btn`} onClick={() => {
                  setPaginationData(pre => ({ ...pre, page: 1, search: "", tag: [] }))
                  setFilteredData({})
                }}
                  disabled={Object.keys(filteredData).length === 0}
                >Clear All</button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <MultiSelect
              disabled={!checkedEmail._id}
              value={selectedTag}
              style={{ minWidth: "300px" }}
              onChange={(e) => {
                updateEmailTag(e.value)
                setSelectedTag(e.value)
              }}
              options={allTags.map(e => ({ label: e.tag_name, value: e._id }))}
              filter placeholder="Tag"
            />
            <button className="btn border rounded add_btn" onClick={() => setVisible(true)}>
              <IoMdAdd />
            </button>
          </div>
          {(allEmails.length > 0 || loader) ?
            <SentEmails
              emailType={emailType[activeIndex]}
              allEmails={allEmails}
              getAllEmails={getAllEmails}
              loader={loader}
              selectedEmail={selectedEmail}
              emailCardClick={emailCardClick}
              emailCheckClick={emailCheckClick}
              checkedEmail={checkedEmail}
              paginationProps={{
                currentPage: paginationData.page,
                totalCount: totalCount,
                pageSize: perPageData,
                onPageChange: (e) => setPaginationData(pre => ({ ...pre, page: e }))
              }}
            />
            :
            <div className="mt-4 px-5 text-center">
              <div className='no_email_heading'>No emails in {emailType[activeIndex]} yet</div>
              <div className='no_email_content'>
                It looks like you haven’t {paginationData?.emailType} any emails from this portal yet. Once you start sending, all your {paginationData?.emailType} emails will be displayed here for easy access and tracking.
              </div>
            </div>
          }
          <br />
          <br />
        </div>
      </div>
      <AddSets
        visible={visible}
        setVisible={setVisible}
        CategoryName={"Tag"}
        label={"Tag"}
        reqFor={"create"}
        value={tagName}
        setValue={setTagName}
        onSubmit={addTagHandler}
      />
    </div>
  );
};
