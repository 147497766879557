import TextInput from "../../../Inputs/TextInput";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  createStep6,
  getProject,
  uploadDocs,
} from "../../../../Api/Admin/AllAPIs";
import { getUserName, parseHashedData, toast } from "../../../../utils";
import { LoopFileUpload } from "../../../Inputs/LoopFileUpload";
import FormSkeleton from "../../../Common/FormSkeleton";
import SevedModal from "../../../Common/SavedModal";
import CustomDragAndDrop from "../../../Inputs/CustomDragAndDrop";
export const ProjectDrawing = ({ mode }) => {
  const [fileName, setFileName] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isVersionHistory, setIsVersionHistory] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(mode == "Edit" ? true : false)
  const [deleteFile, setDeleteFile] = useState([])
  const [idStore, setIdStore] = useState();
  const [backVisible, setBackVisible] = useState(false)
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false)
  const [visible3, setVisible3] = useState(false);
  const domain = window.location.origin;
  const [sets, setSets] = useState([
    {
      set_name: "",
      set_date: "",
      default_drawing_date: "",
      default_received_date: "",
      attach_drawings: []
    }
  ]);
  const skeletonData = [
    { qty: 2 },
    { qty: 2 },
    { qty: 1, height: "4.5rem" },

  ]
  const [validation, setValidation] = useState([]);

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const checkValidation = () => {
    let isValid = true;
    let newValidation = sets.map((set, index) => {
      let setValidation = {
        setName: false,
        setDate: false,
        drawingDate: false,
        receivedDate: false,
      };

      if (!set.set_name) {
        setValidation.setName = true;
        isValid = false;
      }

      if (!set.set_date) {
        setValidation.setDate = true;
        isValid = false;
      }

      if (!set.default_drawing_date) {
        setValidation.drawingDate = true;
        isValid = false;
      }

      if (!set.default_received_date) {
        setValidation.receivedDate = true;
        isValid = false;
      }

      return setValidation;
    });

    setValidation(newValidation);

    if (!isValid) {
      toast.info("Please fill all the required fields.");
    }

    return !isValid;
  };

  useEffect(() => {
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, []);


  const addNewSet = () => {
    const newSet = {
      set_name: "",
      set_date: "",
      default_drawing_date: "",
      default_received_date: "",
      attach_drawings: []
    }
    setSets([...sets, newSet]);
  };

  const handleInputChange = (value, index, field) => {
    // const value = event.target.value;
    const newSets = [...sets];
    newSets[index][field] = value;
    setSets(newSets);
  };



  const navigate = useNavigate();

  const handleDeleteSet = (index) => {
    const newSets = [...sets];
    newSets.splice(index, 1);
    setSets(newSets);
  };
  const getProjectes = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getProject(idStore);
      const getData = res?.data?.data[0];
      setFileName(
        getData.project_drawings.map((res) => ({
          name: res?.fileName,
          id: res?._id,
        }))
      );
      setPreviousFile([...getData.project_drawings]);

      let images = {};
    
      if (getData && getData?.project_drawings?.length !== 0) {
        setIsVersionHistory(true)
        const drawings = getData?.project_drawings

        // Assuming setSets and setSelectedFiles are state setters
        setSets(drawings);
        // setSelectedFiles(images);
      } else {
        setIsVersionHistory(false)
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    if (idStore) {
      getProjectes();
    }
  }, [idStore]);

  const handleStepSix = async (btnName) => {
    if (checkValidation()) {
      return;
    }
    try {
      let data = [...sets]
     
      setBtnLoader(true)
      let newData = await Promise.all(data?.map(async (singleData) => {
        let files = []
        if (singleData?.attach_drawings?.length !== 0) {
          let forUpload = singleData?.attach_drawings?.filter(res => res?.file?.name !== undefined)
          let notForUpload = singleData?.attach_drawings?.filter(res => res?.file?.name === undefined && !deleteFile?.includes(res?.fileName))
          let uploadResponse = { data: [] }
          if (forUpload?.length !== 0) {
            const formData = new FormData();
            forUpload?.forEach(res => formData.append('upload', res.file))
            uploadResponse = await uploadDocs(formData);
          }
          files = [...notForUpload, ...uploadResponse?.data]

        }
        let body = { ...singleData, attach_drawings: files }
        return body
      }))

      let payload = [...newData]


      const userName =getUserName();
      await createStep6({
        id: idStore,
        project_drawings: payload,
        isVersionHistory: isVersionHistory,
        user_name: userName,
        project_id: idStore,
        deleted_file: deleteFile,
        updated_by: getUserName(),
        url: domain
      });
      setBtnLoader(false)
      setVisible(false)
      setVisible2(false)
      if (btnName !== "save") {
        navigate(
          mode === "Edit"
            ? "/coretools/admin/projectform/step-7"
            : "/portfolio/projectform/step-7"
        );
      } else {
        // navigate("/projects")
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row pl-4 mt-5">
        <div className="col-12 px-3">
          <span className="project_name_text"> 6. PROJECT DRAWINGS</span>
        </div>

        {
          skeletonLoader ? <FormSkeleton data={skeletonData} />
            :
            sets?.map((set, index) => (
              <div key={index}>
                <div className="row m-0 p-0 mt-3">
                  <div className="col-md-6 col-12">
                    <label className="py-2 labelCss">Set Name:<span className="text-danger">*</span></label>
                    <TextInput
                      value={set?.set_name}
                      onChange={(e) => handleInputChange(e.target.value, index, "set_name")}
                      placeholder="Enter Set Name"
                      className={validation[index]?.setName ? "Validation" : ""}
                      onClick={() => {
                        if (validation.length - 1 >= index) {
                          const newValidation = [...validation];
                          newValidation[index].setName = false;
                          setValidation(newValidation);
                        }
                      }}
                    />
                  </div>



                  <div className="col-md-6 col-12">
                    <label className="py-2 labelCss">Default Drawing Date:<span className="text-danger">*</span></label>
                    <input
                      type="date"
                      style={{ height: "50px" }}
                      value={formatDate(set?.default_drawing_date)}
                      onChange={(e) => handleInputChange(e.target.value, index, "default_drawing_date")}
                      placeholder="dd/mm/yy"
                      className={`${validation[index]?.drawingDate ? "Validation" : ""} form-control`}
                      onClick={() => {
                        if (validation.length - 1 >= index) {
                          const newValidation = [...validation];
                          newValidation[index].drawingDate = false;
                          setValidation(newValidation);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="row m-0 p-0 mt-3">
                  <div className="col-md-6 col-12">
                    <label className="py-2 labelCss">Set Date:<span className="text-danger">*</span></label>
                    <input
                      type="date"
                      style={{ height: "50px" }}
                      value={formatDate(set.set_date)}
                      onChange={(e) => handleInputChange(e.target.value, index, "set_date")}
                      placeholder="dd/mm/yy"
                      className={`${validation[index]?.setDate ? "Validation" : ""} form-control`}
                      onClick={() => {
                        if (validation.length - 1 >= index) {
                          const newValidation = [...validation];
                          newValidation[index].setDate = false;
                          setValidation(newValidation);
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <label className="py-2 labelCss">Default Received Date:<span className="text-danger">*</span></label>
                    <input
                      type="date"
                      style={{ height: "50px" }}
                      value={formatDate(set.default_received_date)}
                      onChange={(e) => handleInputChange(e.target.value, index, "default_received_date")}
                      placeholder="dd/mm/yy"
                      className={`${validation[index]?.receivedDate ? "Validation" : ""} form-control`}
                      onClick={() => {
                        if (validation.length - 1 >= index) {
                          const newValidation = [...validation];
                          newValidation[index].receivedDate = false;
                          setValidation(newValidation);
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="row m-0 p-0 mt-3">
                  <div className="col-md-12 col-12">
                    <CustomDragAndDrop
                      value={set?.attach_drawings || []}
                      setDeleted={setDeleteFile}
                      onChange={(e) => {
                        handleInputChange(e, index, "attach_drawings")
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex   my-3 justify-content-end  gap-1 align-items-center px-3 ">
                  <div className="delete_main_text">
                    <span onClick={() => handleDeleteSet(index)}>
                      <RiDeleteBin5Line className="deleteIconColor" />{" "}
                      <span className="deleteText">Delete Set</span>
                    </span>
                  </div>
                </div>
              </div>
            ))}

        <span className="mt-4 ">
          <span className="add_icon_text" onClick={addNewSet}>
            Create New Set
          </span>
        </span>

        <div className="contoleButtons mt-3 d-flex justify-content-between">
          <div className="d-flex gap-3">
            <PrimaryButton
              text="Cancel"
              onClick={() => setVisible3(true)}
            />
            <PrimaryButton
              text="Back"
              onClick={() => setBackVisible(true)}
            />
            {/* <button
              className="btn border me-3"
              onClick={() => setBackVisible(true)}
            >
              Back
            </button> */}
          </div>

          <div className="right d-flex gap-3">
            <PrimaryButton
              text={"Save"}
              onClick={() => setVisible2(true)}
            />

            <PrimaryButton text={"Save & Continue"} onClick={() => setVisible(true)} />
          </div>
        </div>
      </div>

      <SevedModal
        yes={() => navigate("/portfolio/projectform/step-5")}
        visible={backVisible}
        heading='Go Back to Previous Step'
        type='warning'
        setVisible={setBackVisible}
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}
      />
      <SevedModal
        yes={() => handleStepSix("Save and Continue")}
        visible={visible}
        heading='Proceed to Next Step'
        type='info'
        loader={btnLoader}
        setVisible={setVisible}
        headingText={`Are you sure you want to save your progress and continue?`}
      />
      <SevedModal
        yes={() => handleStepSix("save")}
        visible={visible2}
        loader={btnLoader}
        heading='Create the Project'
        type='info'
        setVisible={setVisible2}
        headingText={`Are you sure want to save your Data?`}
      />
      <SevedModal
        yes={() => navigate("/projects")}
        visible={visible3}
        heading='Confirm Cancellation'
        type='warning'
        setVisible={setVisible3}
        headingText={`Are you sure you want to discard this data?`}
      />

    </>
  );
};
