
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Menu } from 'primereact/menu';

import { Button } from 'primereact/button';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Document, Page, pdfjs } from 'react-pdf';
import { delFile, getAllInfo } from '../../Api/Admin/AllAPIs';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserName, loader, parseHashedData, toast } from '../../utils';
import DeleteDialog from '../../Components/Common/DeleteDialog';
import { DownloadPdfAndSendEmail } from '../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail';
import { ViewPagesPdfTemplate } from '../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate';
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function GridCard({ role }) {
    const menuRef = useRef(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
const params=useParams()

    const [cardData, setCardData] = useState([]);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const [visibleDelete, setVisibleDelete] = useState(false);

    const userName =  getUserName();
    const projectData = JSON.parse(localStorage.getItem("projectData"));
    const navigate = useNavigate();

    const fetchData = async () => {
        try{

            let payload1 = {
            project_id: localStorage.getItem("projectId"),
            role: role?.slice(0, -1),
        }
        loader.start()
        const res = await getAllInfo(payload1);
        let data1 = res?.data?.data;
        setCardData(data1);
    }
    catch(err){
        console.log(err)
    }
    finally{
        loader.stop()
    }
    }
    useEffect(() => {
        fetchData();
    }, [params])




    const deleteDocument = async (id) => {
        setVisibleDelete(true);
        setSelectedDocId(id);
    };
    const confirmDelete = async () => {
        // setLoader(true);
        let payload = {
            created_by: userName,
            project_name: projectData.project_name,
            project_number: projectData.projectNumber,
        }

        try {
            await delFile(selectedDocId, payload);
            toast.success(`${pageFor()} Deleted Successfully!`);
            fetchData()
        } catch (err) {
            console.error(err);
        }
    };

    function pageFor() {
        switch (role) {
            case "drawings":
                return "drawing";
                break;
            case "photos":
                return "photo";
                break;
            case "models":
                return "model";
                break;
            case "documents":
                return "document";
                break;

            default:
                break;
        }
    }

    const sendEmail = (item) => {
        alert("email");
        return (
            <DownloadPdfAndSendEmail
                fileName={`${pageFor()}`}
                heading={`${pageFor()}`}
                id={item?._id}
                files={[]}
            >
                <ViewPagesPdfTemplate
                    header={item?.title}
                    tableData1={[
                        { "Project ID": item?.project_id },
                        { "Title": item?.title },
                        { "Approver Member": item?.approver?.first_name },
                        { "Reviewer Member": item?.reviewer?.first_name },
                        { "Workflow Status": item?.workflow_status || "--" },
                        { "Project Scope": item?.scope },
                        { "Status": item?.status },
                        { "Reviewer's Comments": <HtmlIntoText html={item?.reviewer_comment} /> || "--" },
                        { "Approver's Comments": <HtmlIntoText html={item?.approver_comment} /> || "--" },
                    ]}
                />
            </DownloadPdfAndSendEmail>
        );
    };

    return (
        <div className="container">
            <div className="row  row-cols-lg-4  row-cols-md-3 row-cols-2   g-4">
                {cardData.map((item, index) => (

                    <div className="col" key={index}>
                        <Card
                            style={{ width: '100%' }}
                            header={
                                <div style={{ width: '100%', height: '175px', overflow: 'hidden', position: 'relative' }}>
                                    {item?.files[0]?.fileUrl?.endsWith('.pdf') ? (
                                        <Document
                                            file={item?.files[0]?.fileUrl}
                                            onLoadSuccess={() => setLoading(false)}
                                            onLoadError={(error) => {
                                                setLoading(false);
                                                setError(error);
                                                console.error("PDF Load Error:", error);
                                            }}
                                        >
                                            <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}>
                                                <Page
                                                    pageNumber={1}
                                                    width={200}
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                />
                                            </div>

                                        </Document>
                                    ) : (
                                        <img alt="Card" src={item?.files[0]?.fileUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                    )}
                                </div>
                            }
                        >
                            <div
                                className="d-flex justify-content-between align-items-center"
                            >

                                <div>
                                    <div>{item?.title}</div>
                                    <div>{item?.phase_details?.name}</div>
                                    <div>{item?.category_details?.name}</div>
                                    <div>{item?.doctype_details?.name}</div>
                                </div>

                                {/* Right side: Three dots menu */}
                                <div>
                                    <Button
                                        icon={<BsThreeDotsVertical />}
                                        onClick={(event) => menuRef.current.toggle(event)}
                                        className="p-button-rounded p-button-text"
                                    />
                                    <Menu
                                        model={[

                                            // {
                                            //     label: "Send Email",
                                            //     command: () => {
                                            //         sendEmail(item);
                                            //     }

                                            // },
                                            {
                                                label: "Send Via Post", command: () => {
                                                    navigate(
                                                        `/projectManagement/addressListing/${pageFor()}/${pageFor()}/${item?._id}`
                                                    );
                                                }
                                            },
                                            {
                                                label: 'Edit', command: () => {
                                                    if (role === "documents") {
                                                        navigate(`/documents/edit/${item?._id}`)
                                                    }
                                                    else {
                                                        navigate(`/projectManagement/${pageFor()}/edit/${item?._id}`)
                                                    }
                                                }
                                            },
                                            {
                                                label: 'Version History', command: () => {
                                                    navigate(
                                                        `/projectManagement/version-history/${pageFor()}/${pageFor()}/${item?._id}`
                                                    );
                                                }
                                            },
                                            {
                                                label: 'View', command: () => {
                                                    if (role === "documents") {
                                                        navigate(`/${pageFor()}/view${pageFor()}/${item?._id}`)
                                                    }
                                                    else {
                                                        navigate(`/projectManagement/${pageFor()}/view${pageFor()}/${item?._id}`)
                                                    }
                                                }
                                            },
                                            { label: 'Delete', command: () => deleteDocument(item?._id) },
                                        ]}
                                        popup
                                        ref={menuRef}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>

                ))}
            </div>
            <DeleteDialog
                visible={visibleDelete}
                setVisible={setVisibleDelete}
                onCancel={() => setSelectedDocId(null)}
                onConfirm={confirmDelete}
                header={`${pageFor()}`}
            />
        </div>
    );
}



