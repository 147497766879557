import React, { useEffect, useState } from 'react'

import style from './Notification.css'
import { getNotificationData } from '../../Api/Admin/AllAPIs';
import moment from 'moment/moment';
// Sample Notification Data



export default function Notification() {
  const [notifications, setNotifications] = useState([]);
 
  const [filteredNotificationData, setFilteredNotificationData] = useState();
  
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  
  const [selectedDate, setSelectedDate] = useState('');

  const [notificationData, setNotificationData] = useState([]);

  const unreadCount = notifications.filter(notification => !notification.isRead).length;
  const formatDate = (date) => {
    const notificationDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (notificationDate.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (notificationDate.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      const day = String(notificationDate.getDate()).padStart(2, '0');
      const month = String(notificationDate.getMonth() + 1).padStart(2, '0');
      const year = notificationDate.getFullYear(); // Full year

      return `${day}/${month}/${year}`; // ddmmyyyy format
    }
  };


  const formatTime = (date) => {
    const notificationDate = new Date(date);
    const now = new Date();
    const timeDifference = Math.floor((now - notificationDate) / 60000);

    if (timeDifference < 60) {
      return `${timeDifference} minutes ago`;
    } else {
      return notificationDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }
  };

  const groupNotificationsByDate = (notifications) => {
    return notifications.reduce((acc, notification) => {
      const dateCategory = formatDate(notification.date);
      if (!acc[dateCategory]) {
        acc[dateCategory] = [];
      }
      acc[dateCategory].push(notification);
      return acc;
    }, {});
  };
  // useEffect(() => {
  //   // setFilteredNotifications(groupNotificationsByDate(notifications));
  // }, [notifications]);

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    if (date) {
      const filtered = notifications.filter((res) => res._id == date)
      setFilteredNotifications(filtered);
    }
    else {
      setFilteredNotifications(notifications)
    }


  };
  // const handleNotificationClick = (id) => {
  //   const updatedNotifications = notifications.createdAt.map(notification =>
  //     notification.id === id ? { ...notification, isRead: true } : notification
  //   );
  //   setNotifications(updatedNotifications);
  // };

  const markAllAsRead = () => {
    const updatedNotifications = notifications.map(notification => ({ ...notification, isRead: true }));
    setNotifications(updatedNotifications);
    setFilteredNotifications(updatedNotifications); // Update filtered notifications as well
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const res = await getNotificationData(localStorage.getItem('projectId'));
        // setNotificationData(res.data.data);
      
        setNotifications(res.data.data); // Set notifications initially
        setFilteredNotifications(res.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchNotifications();
  }, []);

  return (
    <div className="notification-container">
      <div className='notification-mark-read d-flex justify-content-between'>
        <h2 className="notification-title d-flex">
          Notifications {unreadCount > 0 && <span className="number-icon">{unreadCount}</span>}
        </h2>
        <a href='#' className="mark-all-read-button" onClick={markAllAsRead}>
          Mark All Read
        </a>
      </div>
      <input
        className='notification-filter'
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
      />

      {filteredNotifications && filteredNotifications.length > 0 ? (
        filteredNotifications.map((res, index) => {
          return (
            <div key={index} className="notification-group">
              <h3 className="notification-group-title">
                {formatDate(res?._id)}
                <div className="notification-item-time"></div>
              </h3>
              {res?.data?.map((item, itemIndex) => {
                return (
                  <div key={itemIndex} className='d-flex align-items-center gap-2 p-2'>
                    <img src='/images/UserProfileIcon.png' alt={res.user} className="notification-avatar" />
                    <div>
                      <p className="notification-item-title">{item.activity}</p>
                      <p className="notification-item-description">{item.message}</p>
                    </div>
                    <div className="notification-item-time">
                      {formatTime(item.createdAt)}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : (
        <div className="no-data-message">
          <p>Data is not  available.</p>
        </div>
      )}



    </div>
  );
}

