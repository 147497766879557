import React, { useEffect, useRef, useState } from "react";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { CiCircleList } from "react-icons/ci";
import { CiGrid41 } from "react-icons/ci";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import Popover from "../../Components/Common/Popover";
import { TableSkelton } from "../../Components/Common/TableSkelton";
import style from "./style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import CompareDrawingModal from "./CompareDrawingModal";
import CompareDrawingImage from "./CompareDrawingImage";
import { delFile, getAllFiles, getAllInfo, getProjectNumber, sendEmailWithAttachment, updateFile } from "../../Api/Admin/AllAPIs";
import { getActionsPermission, getPaginatedData, getProjectCode, getProjectId, getUserName, parseHashedData, toast } from "../../utils";
import Pagination from "../../Components/Common/Pagination/Pagination";
import Filter from "./Filter";
import DeleteDialog from "../../Components/Common/DeleteDialog";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import { ChangeApprovalStatus } from "../../Components/Tools/ChangeApprovalStatus";
import { DownloadPdfAndSendEmail } from "../../Components/Common/ViewPageComponents/DownloadPdfAndSendEmail";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { HtmlIntoText } from "../../Components/Common/HtmlIntoText";
import { FaFolder } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa";
import { FaRegFolderOpen } from "react-icons/fa6";
import { IoDocumentSharp } from "react-icons/io5";
import GridCard from "./GridCard";
import { useSelector } from "react-redux";
// import { AiFillPicture } from "react-icons/ai";

export default function DrawingListing({ role }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const perPageData = 8;
  const [openCompareModal, setOpenCompareModal] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [allData, setAllData] = useState([]);
  let projectID = localStorage.getItem("projectId");
  const [search, setSearch] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [tempFilterData, setTempFilterData] = useState({});

  const [selectedDocId, setSelectedDocId] = useState(null);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [drawingData, setDrawingData] = useState([])

  const projectData = JSON.parse(localStorage.getItem("projectData"));

  let hash = localStorage.getItem('hashUserData')
  const userData = parseHashedData(hash)


  const userObj = userData;
  const userId = userObj?._id;
  const userName = getUserName();

  const [filterData, setFilterData] = useState({
    phase_id: null,
    category_id: null,
    sub_category_id: null,
    doctype_id: null,
    workflow_status: null,
  });

  function pageFor() {
    switch (role) {
      case "drawings":
        return "drawing";
        break;
      case "photos":
        return "photo";
        break;
      case "models":
        return "model";
        break;
      case "documents":
        return "document";
        break;

      default:
        break;
    }
  }

  // function compare() {
  //   setOpenCompareModal(false);
  //   setOpenCompare(true);
  // }

  const getAllDatas = async (id, role) => {
    try {
      setSkeletonLoader(true);
      let payload = {
        ...tempFilterData
      }


      let payload1 = {
        project_id: id,
        role: role,
      }

      const res1 = await getAllInfo(payload1);
  
      let data1 = res1?.data?.data;
      setDrawingData(data1);

      const res = await getAllFiles(id, role, payload);
     
      let data = res?.data?.data?.data.map(e => ({ ...e, key: Math.random() })) || [];
      setTotalRecords(data?.length);
      setAllData(data);
      let arr = getPaginatedData(data, page, perPageData);
      setTableData(arr);
      setSkeletonLoader(false);
    } catch (error) {
      // console.log(error);
      setSkeletonLoader(false);
    }
  };

  // useEffect(() => {
  //   let role = pageFor();
  //   let id = projectID;
  //   getAllDatas(id, role);
  // }, [role]);
  

  useEffect(() => {
    let role = pageFor();
    let id = projectID;
    getAllDatas(id, role);
  }, [page, tempFilterData, role]);






  function changeStatusUi2(status) {
    switch (status) {
      case "Not Started":
        return (
          <div className="statusOuterns">
            <div className="statusDotns" />
            <div className="Statusns">Not Started</div>
          </div>
        );
        break;
      case "In Progress":
        return (
          <div className="statusOuterip">
            <div className="statusDotip" />
            <div className="Statusip">In Progress</div>
          </div>
        );
        break;
      case "Completed":
        return (
          <div className="statusOuterc">
            <div className="statusDotc" />
            <div className="Statusc">Completed</div>
          </div>
        );
        break;
      case "Ready for Review":
        return (
          <div className="statusOuterr">
            <div className="statusDotr" />
            <div className="Statusr">Ready for Review</div>
          </div>
        );
        break;
      case "Incomplete":
        return (
          <div className="statusOuterin">
            <div className="statusDotin" />
            <div className="Statusin">Incomplete</div>
          </div>
        );
        break;

      default:
        break;
    }
  }

  function searchData(e) {
    let value = e.target.value;
    setSearch(value);
    if (e.target?.value == " ") {
      e.target.value = "";
    } else {
      let data = [...allData];
      if (value !== "") {
        let newArr = data?.filter((res) =>
          res?.phase_name?.toLowerCase()?.includes(value?.toLowerCase())
        );
        setTableData(newArr);
      } else {
        setTableData(data);
      }
    }
  }
  function onFilter() {
    setTempFilterData(filterData);
    setPage(1);
    setFilterActive(true);
  }

  function clearFilter() {
    setFilterActive(false);
    setFilterData({
      phase_id: null,
      category_id: null,
      sub_category_id: null,
      doctype_id: null,
      workflow_status: null,
    });
    setTempFilterData({
      phase_id: null,
      category_id: null,
      sub_category_id: null,
      doctype_id: null,
      workflow_status: null,
    });
  }


  const deleteDocument = async (id) => {
    setVisibleDelete(true);
    setSelectedDocId(id);
  };


  const confirmDelete = async () => {
    // setLoader(true);
    let payload = {
      created_by: userName,
      project_name: projectData.project_name,
      project_number: projectData.projectNumber,
    }

    try {
      await delFile(selectedDocId, payload);
      toast.success(`${pageFor()} Deleted Successfully!`);
      let role = pageFor();
      let id = projectID;
      getAllDatas(id, role);
    } catch (err) {
      console.error(err);
    }
  };

  const csvHeaders = [
    { label: "Title", key: "Title" },
    { label: "Phase", key: "Phase" },
    { label: "Category", key: "Category" },
    { label: "SubCategory", key: "SubCategory" },
    { label: "DocumentType", key: "DocumentType" },
    { label: "Scope", key: "Scope" },
    { label: "WorkFlowStatus", key: "WorkFlowStatus" },
    { label: "CreatedBy", key: " CreatedBy" },
    { label: "UpdatedBy", key: "UpdatedBy" },
    { label: "Reviewer", key: "Reviewer" },
    { label: "Approver", key: "Approver" },
    { label: "HashTag Names", key: "HashTag Names" },
    { label: "Approver Comment", key: "Approver Comment" },
    { label: "Reviewer Comment", key: "Reviewer Comment" },
  ];



  const csvData = drawingData?.map((item, index) => {

    return ({
      Title: item?.title,
      Phase: item?.phase_details?.name,
      Category: item?.category_details?.name,
      SubCategory: item?.subcategory_details?.name,
      DocumentType: item?.doctype_details?.name,
      Scope: item?.scope,
      WorkFlowStatus: item?.workflow_status,
      CreatedBy: item?.createdByInfo?.first_name,
      UpdatedBy: item?.updatedByInfo?.first_name,
      Reviewer: item?.reviewer?.first_name,
      Approver: item?.approver?.first_name,
      ["HashTag Names"]: item.hashtag_names.join(", "),
      ["Approver Comment"]: item?.approver_comment || "---",
      ["Reviewer Comment"]: item?.reviewer_comment || "---",

      HashTag: item?.hashtag_names?.join(","),
    })
  })

  const tableRef = useRef();
  const emailRef = useRef();
  let d = useSelector(e => e.rolesAndPermissionData)

  const exportToPDF = async () => {
    const doc = new jsPDF();
    try {
      doc.autoTable({
        html: tableRef.current,
        didParseCell: function (data) {
          data.cell.styles.fontSize = 2;
        },
      });
      const pdfBlob = doc.output("blob");
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = async function () {
        const base64data = reader.result.split(",")[1];
        const formattedAttachment = {
          content: base64data,
          filename: `${pageFor()}.pdf`,
          type: "application/pdf",
          disposition: "attachment",
        };

        const body = {
          subject: "Email header header",
          to: "virajgulia1998@gmail.com",
          text: "This is text of email",
          cc: [],
          bcc: [],
          attachments: formattedAttachment,
          htmlData: emailRef?.current?.innerHTML || "",
        };
        let ee = await sendEmailWithAttachment(body);
      };
      doc.save(`${pageFor()}.pdf`);
    } catch (err) {
      console.log(err);
    }
  };


  const statusChange = async (data, _id, toolId, comment, toolData) => {
    try {
      if (data.status === "approved") {
        // send email to all the assigies 
      }

      let res = await updateFile(_id, { ...data, ...comment })

      let role = pageFor();
      let id = projectID;
      getAllDatas(id, role)
    } catch (error) {

    }
  }




  return (
    <>
      <CompareDrawingModal
        open={openCompareModal}
        setOpenCompareModal={setOpenCompareModal}
        // onClick={compare}
        setOpenCompare={setOpenCompare}
        pageFor={pageFor()}
        data={drawingData}
      />
      {/* <CompareDrawingImage
        open={openCompare}
        toggle={() => setOpenCompare(false)}
        pageFor={pageFor()}
      /> */}
      <div className="d-flex flex-sm-row flex-column justify-content-between">
        <div className="page_heading">
          {`${pageFor()?.charAt(0)?.toUpperCase() + pageFor()?.slice(1)} Management `}
        </div>

        <div className="d-flex align_center justify-content-end gap-2 mt-sm-0 mt-3">
          <div className="right d-flex gap-2 align-items-center">
            <ExportPdfButton
              exportToPDF={exportToPDF}
              csvBtn={
                <CSVLink
                  className="pe-1"
                  data={csvData}
                  headers={csvHeaders}
                  filename={`${pageFor()}.csv`}
                >
                  CSV
                </CSVLink>
              }
            />
          </div>

          {
            (
              (pageFor() === "drawing" && getActionsPermission("Create Drawing")) ||
              (pageFor() === "photo") && getActionsPermission("Create Photos") ||
              (pageFor() === "model") && getActionsPermission("Create Model") ||
              (pageFor() === "document") && getActionsPermission("Create Document")

            ) &&
            <Popover
              button={
                <PrimaryButton
                  // onClick={() => navigate("createRfi")}
                  addIcon
                  text={`Add ${pageFor()}`}
                />
              }
              options={["Add New", "Import from Global"]}
              onSelect={(index, e) => {
                if (index == 0) {

                  navigate(`create${pageFor()}s`);
                } else if (index == 1) {

                  navigate(`importFromGlobal${pageFor()}s`);
                }
              }}
            />

          }

        </div>
      </div>

      <div style={{ height: "0", overflow: "hidden" }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Phase</th>
              <th>Category</th>
              <th>SubCategory</th>
              <th>DocumentType</th>
              <th>Scope</th>
              <th>WorkFlowStatus</th>
              <th>CreatedBy</th>
              <th>UpdatedBy</th>
              <th>Reviewer</th>
              <th>Approver</th>
              <th>Comments</th>
              <th>HashTag</th>
            </tr>
          </thead>
          <tbody>
            {drawingData?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.title}</td>
                  <td>{item?.phase_details?.name}</td>
                  <td>{item?.category_details?.name}</td>
                  <td>{item?.subcategory_details?.name}</td>
                  <td>{item?.doctype_details?.name}</td>
                  <td>{item?.scope}</td>
                  <td>{item?.workflow_status}</td>
                  <td>{item?.createdByInfo?.first_name}</td>
                  <td>{item?.updatedByInfo?.first_name}</td>
                  <td>{item?.reviewer?.first_name}</td>
                  <td>{item?.approver?.first_name}</td>
                  <td>
                    <HtmlIntoText html={item?.comment} />
                  </td>
                  <td>{item?.hashtag_names?.join(", ")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
        <div className="searchInput">
          <SearchInput
            onCancel={() => {
              setSearch("");
              let blank = { target: { value: "" } };
              searchData(blank);
              // searchData({ target: { value: "" } });
            }}
            value={search}
            placeholder={"Search by phase"}
            onChange={searchData}
          />
        </div>
        <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
          <Filter
            onFilter={onFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            role={role}
          />
          &nbsp;
          <button
            disabled={!filterActive}
            className="filter_btn"
            onClick={clearFilter}
          >
            Clear Filter
          </button>
        </div>
      </div>

      {role !== "documents" ? (
        <div className="d-flex justify-content-between align-items-center mt-4">
          <button
            className="btn border"
            onClick={() => setOpenCompareModal(true)}
          >
            Compare {pageFor()?.charAt(0)?.toUpperCase() + pageFor()?.slice(1)}s
          </button>
          <div className="d-flex border rounded">
            <div
              className={`w-50 pointer d-flex align-items-center f-14 gap-2 border border-right py-1 px-3 fw-500 ${isGridView ? "text-gray" : ""
                }`}
              onClick={() => setIsGridView(false)}
            >
              <CiCircleList className="fs-6" /> List
            </div>
            <div
              className={`w-50 pointer d-flex align-items-center f-14 gap-2 border border-right py-1 px-3 fw-500 ${isGridView ? "" : "text-gray"
                }`}
              onClick={() => setIsGridView(true)}
            >
              <CiGrid41 className="fs-6" /> Grid
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {!isGridView ? (
        <>
          <div className={`mt-4 ${style.tableBox}`}>
          
            {skeletonLoader ? (
              <TableSkelton
                header={[
                  "Phases",
                  "Categories",
                  "Sub - Categories",
                  "Document Type",
                  `${pageFor()} Title`,
                  "Version",
                  "Created By",
                  "Updated By",
                  "Files",
                  "Status",
                  "Approval",
                  "",
                ]}
              />
            ) : (
              <div className="" >
                <div className="document_table_scroll" style={{ minWidth: "1900px", width: "100%", overflow: "auto" }}>

                  <TreeTable
                    className=""
                    value={tableData}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field="project_role"
                      header="Phases"
                      body={(rowData) => <span>{rowData?.phase_name ? rowData?.phase_name : <FaFolder />}</span>}
                      expander
                      key={rowData => rowData?._id}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Categories"
                      body={(rowData) => (
                        <span>
                          {rowData?.category_name ? rowData?.category_name : <FaFolderOpen />}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Sub-Categories"
                      body={(rowData) => (
                        <span>
                          {rowData?.subcategory_name
                            ? rowData?.subcategory_name
                            : <FaRegFolderOpen />}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Document Type"
                      body={(rowData) => (
                        <span>
                          {rowData?.doctype_name ? rowData?.doctype_name : <IoDocumentSharp />}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="project_role"
                      header={`${pageFor()?.charAt(0)?.toUpperCase() + pageFor()?.slice(1)} Title`}
                      body={(rowData) => <span>{rowData?.title}</span>}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Version"
                      body={(rowData) => <span>{rowData?.version}</span>}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Created By"
                      body={(rowData) => (
                        <span>{rowData?.createdByInfo?.first_name}</span>
                      )}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Updated By"
                      body={(rowData) => (
                        <span>{rowData?.updatedByInfo?.first_name}</span>
                      )}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Files"
                      body={(rowData) => <span>{rowData?.files?.length}</span>}
                    ></Column>

                    <Column
                      field="project_role"
                      header="Status"
                      body={(rowData) => changeStatusUi2(rowData?.workflow_status)}
                    ></Column>
                    <Column
                      field="project_role"
                      header="Approval"
                      body={(rowData) => {
                        return (
                          <ChangeApprovalStatus
                            status={rowData.status}
                            allData={rowData}
                            onSubmit={statusChange}
                            toolData={{
                              // tool_id: rowData?._id,
                              // tool_number: rowData?.taskId,
                              // tool_name: rowData?.task_name,
                              tool: `${pageFor()}`,
                              _id: rowData?._id,
                              approver_id: rowData?.approver_id,
                              reviewer_id: rowData?.reviewer_id,
                              reviewer_name: `${rowData?.reviewer?.first_name} ${rowData?.reviewer?.last_name}`,
                              approver_name: `${rowData?.approver?.first_name} ${rowData?.approver?.last_name}`,
                              reviewer_email: `${rowData?.reviewer?.email_address}`,
                              approver_email: `${rowData?.approver?.email_address}`,
                              creator_name: rowData?.creator_email?.[0]?.first_name || rowData?.creator_email?.[0]?.name,
                              creator_email: rowData?.creator_email?.[0]?.email_address || rowData?.creator_email?.[0]?.email
                            }}
                            toolName={`${pageFor()}`}
                          />
                        )
                      }}
                    ></Column>

                    <Column
                      style={{ width: "70px" }}
                      field=""
                      header={"Action"}
                      body={(rowData) => (
                        <>
                          {rowData?.title ? (
                            <Popover
                              options={[
                                <DownloadPdfAndSendEmail

                                  fileName={`${pageFor()}`}
                                  heading={`${pageFor()}`}
                                  id={rowData._id}
                                  files={[]}
                                >

                                  <ViewPagesPdfTemplate
                                    header={rowData?.title}
                                    tableData1={[
                                      { "Project ID": getProjectCode() },
                                      { "Title": rowData?.title },
                                      {
                                        "Approver Member": rowData?.approver?.first_name
                                      },
                                      {
                                        "Reviewer Member": rowData?.reviewer?.first_name
                                      },
                                      { " Workflow Status": rowData?.workflow_status || "--" },
                                      { "Project Scope": rowData?.scope },
                                      { "Status": rowData?.status },
                                      { "Reviewer's Comments": <HtmlIntoText html={rowData?.reviewer_comment} /> || "--" },
                                      { "Approver's Comments": <HtmlIntoText html={rowData?.approver_comment} /> || "--" },
                                    ]}
                                  />
                                </DownloadPdfAndSendEmail>,
                                "Send via Post",
                                "Version History",
                                "Edit",
                                "Delete",
                                "View"
                              ]}
                              onSelect={(index) => {
                                if (index === 1) {
                                  navigate(
                                    `/projectManagement/addressListing/${pageFor()}/${pageFor()}/${rowData?._id}`
                                  );
                                }
                                if (index == 2) {
                                  if (
                                    pageFor() === "drawing" && !getActionsPermission("Drawing version history") ||
                                    pageFor() === "photo" && !getActionsPermission("Photos version history") ||
                                    pageFor() === "model" && !getActionsPermission("Model version history") ||
                                    pageFor() === "document" && !getActionsPermission("Document version history")
                                  ) {
                                    toast.error(`Yout dont't have permission to access version history of ${pageFor()}`)
                                    return
                                  }
                                  navigate(
                                    `/projectManagement/version-history/${pageFor()}/${pageFor()}/${rowData?._id}`
                                  );
                                }
                                if (index === 3) {
                                  if (role === "documents") {

                                    if ((pageFor() === "document" && !getActionsPermission("Edit Document"))) {
                                      toast.error(`Yout dont't have permission to edit ${pageFor()}`)
                                      return
                                    }
                                    navigate(`/documents/edit/${rowData?._id}`)

                                  }
                                  else {

                                    if (
                                      (pageFor() === "drawing" && !getActionsPermission("Edit Drawing")) ||
                                      (pageFor() === "photo" && !getActionsPermission("Edit Photos")) ||
                                      (pageFor() === "document" && !getActionsPermission("Edit Document"))
                                    ) {
                                      toast.error(`Yout dont't have permission to edit ${pageFor()}`)
                                      return
                                    }
                                    navigate(`/projectManagement/${pageFor()}/edit/${rowData?._id}`)
                                  }

                                }
                                else if (index === 4) {

                                  if (
                                    (pageFor() === "drawing" && !getActionsPermission("Delete Drawing")) ||
                                    (pageFor() === "photo" && !getActionsPermission("Delete Photos")) ||
                                    (pageFor() === "model" && !getActionsPermission("Delete Model")) ||
                                    (pageFor() === "document" && !getActionsPermission("Delete Document"))
                                  ) {
                                    toast.error(`Yout dont't have permission to delete ${pageFor()}`)
                                    return
                                  }

                                  deleteDocument(rowData?._id)
                                }

                                // documents/viewdocuments/:id
                                else if (index === 5) {
                                  if (role === "documents") {
                                    if ((pageFor() === "document" && !getActionsPermission("View Document"))) {
                                      toast.error(`Yout dont't have permission to View ${pageFor()}`)
                                      return
                                    }
                                    navigate(`/${pageFor()}/view${pageFor()}/${rowData?._id}`)
                                  }
                                  else {
                                    if (
                                      (pageFor() === "drawing" && !getActionsPermission("View Drawing")) ||
                                      (pageFor() === "photo" && !getActionsPermission("View Photos")) ||
                                      (pageFor() === "model" && !getActionsPermission("View Model"))

                                    ) {
                                      toast.error(`Yout dont't have permission to view ${pageFor()}`)
                                      return
                                    }
                                    navigate(`/projectManagement/${pageFor()}/view${pageFor()}/${rowData?._id}`)
                                  }
                                }
                              }}
                            />
                          ) : (
                            null
                          )}
                        </>
                      )}
                    ></Column>
                  </TreeTable>
                </div>

                <div className="my-3">
                  <Pagination
                    currentPage={page}
                    totalCount={totalRecords}
                    pageSize={perPageData}
                    onPageChange={(page) => {
                      let res = getPaginatedData(allData, page, perPageData);
                      setTableData(res);
                      setPage(page);
                    }}
                  />
                </div>
              </div>
            )}
          </div >
        </>
      ) :
        // 
        <div className="mt-4 mb-4"><GridCard role={role} /></div>
      }

      <DeleteDialog
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        onCancel={() => setSelectedDocId(null)}
        onConfirm={confirmDelete}
        header={`${pageFor()}`}
      />
    </>
  );
}
