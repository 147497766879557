import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";

import { Row } from "primereact/row";
import { BorderedTable } from "../../../../../../Components/Tables/BorderedTable";

function ScheduleValue_Subcontract() {
  const demodata = [
    {
      id: "1000",
      code: "f230fh0g3",
      ///////////////////
      Owner: "01-034 Instructions",
      Title: "Guide books of rules and proce...",
      Status: "GST",
      Executed: "$ 16,000.00",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 16,000.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      ///////////////////
      Owner: "01-034 Instructions",
      Title: "Guide books of rules and proce...",
      Status: "GST",
      Executed: "$ 16,000.00",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 16,000.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      ///////////////////
      Owner: "01-034 Instructions",
      Title: "Guide books of rules and proce...",
      Status: "GST",
      Executed: "$ 16,000.00",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 16,000.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      ///////////////////
      Owner: "01-034 Instructions",
      Title: "Guide books of rules and proce...",
      Status: "GST",
      Executed: "$ 16,000.00",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 16,000.00",
    },
    {
      id: "1000",
      code: "f230fh0g3",
      ///////////////////
      Owner: "01-034 Instructions",
      Title: "Guide books of rules and proce...",
      Status: "GST",
      Executed: "$ 16,000.00",
      Original_Contract_Amount: "$ 0.00",
      Approved_Change_Orders: "$ 16,000.00",
    },
  ];

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Grand Total:"
          colSpan={2}
          footerStyle={{ textAlign: "center" }}
        />
        <Column footer={""} />
        <Column footer={""} />
        <Column footer={"$ 575,000.00"} />
        <Column footer={"$ 0.00"} />
        <Column footer={"$ 575,000.00"} />
      </Row>
    </ColumnGroup>
  );
  return (
    <>
    
    <div className="mt-3">
      <BorderedTable>
        <DataTable
          scrollable
          scrollHeight="flex"
          value={demodata}
          footerColumnGroup={footerGroup}
          tableStyle={{ minWidth: "" }}
        >
          <Column
            field="#"
            header="#"
            body={(rowData, rowIndex) => (
              <div>
                {rowIndex.rowIndex < 9 ? "0" : ""}
                {rowIndex.rowIndex + 1}
              </div>
            )}
          ></Column>
          <Column
            field="Owner"
            header="Cost Code"
            body={(rowData, rowIndex) => (
              <span
                style={{
                  cursor: "pointer",
                  // color: "blue",
                  // textDecoration: "underline",
                }}
                // onClick={() => navigate("ViewContract")}
              >
                {rowData.Owner}
              </span>
            )}
          />

          <Column field="Title" header="Description"></Column>
          <Column field="Status" header="Tax Code"></Column>
          <Column field="Executed" header="Amount"></Column>
          <Column
            header="Billed to Date"
            field="Original_Contract_Amount"
            // body={(rowData) => moment(rowData.initiatedDate).format("MM-DD-YYYY")}
          ></Column>
          <Column
            field="Approved_Change_Orders"
            header="Amount Remaining"
          ></Column>
        </DataTable>
      </BorderedTable>
    </div>
    </>
  );
}

export default ScheduleValue_Subcontract;
