import React from "react";
import { BorderedTable } from "../../../Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import emailImage from "./tools/noun-email-6694769 1.jpg";
import rfiImage from "./tools/noun-document-information-2621385 1 (1).png";
import inspections from "./tools/noun-inspection-3052698 6.png";
import submittals from "./tools/noun-documents-1026722 1.png";
import transmittals from "./tools/noun-information-transfer-5119001 1.png";
import incident from "./tools/Group 1000006772.png";
import observation from "./tools/noun-binoculars-1422014 1.png";
import punchlist from "./tools/noun-inspection-3052698 6.png";
import meetings from "./tools/noun-online-meeting-4399939 1.png";
import schedule from "./tools/noun-daily-calendar-876952 1.png";
import daily from "./tools/noun-journal-6318567 1.png";
import photos from "./tools/noun-photos-5964620 1.png";
import drawings from "./tools/noun-blueprints-906277 1.png";
import spections from "./tools/noun-blueprints-906277 1.png";
import forms from "./tools/noun-document-information-2621385 1.png";
import issue from "./tools/noun-document-information-2621385 1 (1).png";
import modals from "./tools/noun-document-information-2621385 1 (1).png";
import { PrimaryButton } from "../../../Buttons/PrimaryButton";
import { FiMenu } from "react-icons/fi";
import { Checkbox } from "primereact/checkbox";
import { addProjectTools, getProject } from "../../../../Api/Admin/AllAPIs";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TableSkelton } from "../../../Common/TableSkelton";
import { ConfirmDialog } from "../../../Common/ConfirmDialog";
import SevedModal from "../../../Common/SavedModal";
import { getUserName, parseHashedData } from "../../../../utils";

function ProjectTool({ mode }) {
  var i = 0;
  const [rowClick, setRowClick] = useState(true);
  const [isVersionHistory, setIsVersionHistory] = useState(true);

  const [skeletonLoader, setSkeletonLoader] = useState(mode == "Edit" ? true : false)
  const [ProjectToolsData, setProjectToolsData] = useState([]);
  const [isChecked, setisChecked] = useState(true);
  let [btnLoader, setBtnLoader] = useState(false)
  const [backVisible, setBackVisible] = useState(false)
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const [idStore, setIdStore] = useState();
  const [rows, setRows] = useState([
    // {
    //   id: "1",
    //   stageName: "",
    //   startDate: "",
    //   endDate: "",
    // },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const projectIdFromLocalStorage = localStorage.getItem("projectId");
    if (projectIdFromLocalStorage) {
      setIdStore(projectIdFromLocalStorage);
    }
  }, []);
  const domain = window.location.origin;

  const [products, setProducts] = useState([
    {
      id: 15,
      name: "Task",
      description:
        "The Project level Tasks tool allows you to track and manage action items throughout the lifespan of the project.",
      img: "/images/Forms.png",
      //  checked:false
    },
    {
      id: 1,
      name: "Emails",
      description: "Organize and archieve all project emails ",
      img: emailImage,
      //checked:false
    },
    {
      id: 2,
      name: "RFIs",
      description:
        "Distribute RFIs via email or fax. Send automated reminders when items are overdue.",
      img: rfiImage,
      // checked:false
    },
    {
      id: 3,
      name: "Submittals",
      description: "Tracks and manage submittals",
      img: submittals,
      // checked:false
    },
    {
      id: 4,
      name: "Transmittals",
      description:
        "Record in detail the information sent from one party to another",
      img: transmittals,
      // checked:false
    },
    {
      id: 5,
      name: "Inspections",
      description:
        "Record the perfomance of work against the  agreed requirements",
      img: "/images/inspection.png",
      //checked:false
    },
    {
      id: 6,
      name: "Observations",
      description:
        "Allows the manager to see  what is happening in the project",
      img: observation,
      // checked:false
    },
    {
      id: 7,
      name: "Punch List",
      description:
        "Lists all the small tasks that needs to be completed before the project can be completed. ",
      img: punchlist,
      // checked:false
    },
    {
      id: 8,
      name: "Meetings",
      description:
        "To facilitate collaboration, leadership and top quality decision making",
      img: meetings,
      // checked:false
    },
    {
      id: 9,
      name: "Schedule",
      description: "Helps you plan  and organize tasks in no time",
      img: schedule,
      // checked:false
    },
    {
      id: 10,
      name: "Daily log",
      description:
        "Tracks daily activities for a project, including visitors, weather, work, equipment and other details.",
      img: daily,
      //  checked:false
    },
    {
      id: 11,
      name: "Photos",
      description:
        "Helps in tracking progress and maintaining information visually.",
      img: photos,
      //  checked:false
    },
    {
      id: 12,
      name: "Drawings",
      description:
        "Used for design development, compare changes and communicate ideas.",
      img: drawings,
      // checked:false
    },
    {
      id: 13,
      name: "Specification",
      description: "Documents that define the management plan of the project.",
      img: spections,
      // checked:false
    },
    // {
    //   id: 14,
    //   name: "Forms",
    //   description:
    //     "Used for design development, compare changes and communicate ideas.",
    //   img: "/images/Forms.png",
    //   // checked:false
    // },

    {
      id: 15,
      name: "Models",
      description:
        "Allow users to easy view and refer 3D models related to project.",
      img: "/images/Forms.png",
      //  checked:false
    },


    {
      id: 16,
      name: "Issues",
      description:
        "Allow users to identify and resolve co-ordination issues prior to construction. ",
      img: "/images/Forms.png",
      //  checked:false
    },

    {
      id: 17,
      name: "Incidents",
      description: "Prevents hazards from derailing tasks.",
      img: "/images/Incidents.png",
      //  checked:false
    },
  ]);
  const [selectedProducts, setSelectedProducts] = useState(mode === "Edit" ? [] : products);
  let arr = [];
  const [users, setUsers] = useState(products);

  useEffect(() => {
    if (idStore) {
      getProjectes();
    }
  }, [idStore]);

  const getProjectes = async () => {
    try {
      setSkeletonLoader(true);
      const res = await getProject(idStore);
  
      const getData = res?.data?.data[0];
    

      if (getData && getData.project_tools.length !== 0) {
        setIsVersionHistory(true)
        const projectTools = getData.project_tools.map((projectTool, index) => ({
          id: projectTool.id,
          name: projectTool.name || "",
          description: projectTool.description || "",
          img: projectTool.img || "",
          // checked:projectTool.checked|| ""
        }));

     
        arr = selectedProducts;

        setSelectedProducts(projectTools);

        // setSelectedProducts(prevState => [...prevState, ...projectTools]);
      } else {
        setIsVersionHistory(false)
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setSkeletonLoader(false);
    }
  };

  const addProjectTools1 = async (btnName) => {

    const userName = getUserName()
    try {
      const data1 = selectedProducts.map((item) => {
        return item;
      });
      const data = {
        id: idStore,
        project_tools: data1,
        isVersionHistory: isVersionHistory,
        user_name: userName,
        project_id: idStore,
        url: domain
      };
   
      setBtnLoader(true)
      let result = await addProjectTools({ ...data, updated_by: getUserName() });
      setBtnLoader(false)
      setVisible(false)
      setVisible2(false)
      if (btnName !== "save") {
        navigate(mode === "Edit" ? "/coretools/admin/projectform/step-5" : "/portfolio/projectform/step-5");
      } else {
        // navigate("/projects")
      }



    } catch (error) {
      console.log(error);
    }
  };
  const handleRowReorder = (event) => {
  
    setUsers(event.value);
  };

  //   const columns = [
  //     { field: "img", header: "" },
  //     { field: "name", header: "Name" },
  //     { field: "description", header: "Description" },
  //   ];
  const handleCheckboxChange = (e, rowData) => {
    i++;
   
    //setSelectedProducts([]);
    const checked = e.target.checked;
  
    setisChecked(false);

    if (checked) {
      //rowData.checked= true;
      // setProjectToolsData([...ProjectToolsData,rowData])
      //setisChecked(true);
      setSelectedProducts([...selectedProducts, rowData]);
    
    } else {
      // setisChecked(false);
  
      let arr = selectedProducts.filter((product) => {
      
        return product.name !== rowData.name;
      });
   
      setSelectedProducts(
        // selectedProducts.filter((product) => product !== rowData)
        selectedProducts.filter((product) => {
        
          return product.name !== rowData.name;
        })
      );
      //setisChecked( selectedProducts.some((product) =>{ console.log("hi",product); return product.name == rowData.name}))
    }
  };

  const dynamicColumns1 = [
    {
      field: "",
      header: "",
      body: (rowData) => {
        return (
          // selectedProducts.some(e=>e.name==rowData.name)
          //   {isChecked ?(
          //     <Checkbox
          //         checked={true}
          //         onChange={(e) => handleCheckboxChange(e, rowData)}
          //     /> )
          //     :
          //     <Checkbox
          //         checked={selectedProducts.some(e=>e.name==rowData.name)}
          //         onChange={(e) => handleCheckboxChange(e, rowData)}
          //     />
          // }
          <Checkbox
            checked={selectedProducts.some(e => e.name == rowData.name)}
            onChange={(e) => handleCheckboxChange(e, rowData)}
          />
        );
      },
    },
    {
      field: "img",
      header: "",
      body: (rowData) => <img src={rowData?.img} alt="" srcSet="" />,
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => <div> {rowData?.name} </div>,
    },
    {
      field: "description",
      header: "Description",
      body: (rowData) => <div> {rowData?.description} </div>,
    },
  ].map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      body={col.body}
    />
  ));

  const dynamicColumns = [
    {
      field: "",
      header: "",
      body: (rowData) => {

        return (
          // selectedProducts.some(e=>e.name==rowData.name)
          //   {isChecked ?(
          //     <Checkbox
          //         checked={true}
          //         onChange={(e) => handleCheckboxChange(e, rowData)}
          //     /> )
          //     :
          //     <Checkbox
          //         checked={selectedProducts.some(e=>e.name==rowData.name)}
          //         onChange={(e) => handleCheckboxChange(e, rowData)}
          //     />
          // }
          <Checkbox
            checked={selectedProducts.some((e) => e.name == rowData.name)}
            onChange={(e) => handleCheckboxChange(e, rowData)}
          />
        );
      },
    },
    {
      field: "img",
      header: "",
      body: (rowData) => <img src={rowData?.img} alt="" srcSet="" />,
    },
    {
      field: "name",
      header: "Name",
      body: (rowData) => <div> {rowData?.name} </div>,
    },
    {
      field: "description",
      header: "Description",
      body: (rowData) => <div> {rowData?.description} </div>,
    },
  ].map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      body={col.body}
    />
  ));

  return (
    <>
      <div className="row pl-4 mt-5">
        <div className="col-12 px-3">
          <span className="project_name_text"> 4. PROJECT TOOLS</span>
        </div>
        <div className="mt-4">
          {
            skeletonLoader ?
              <TableSkelton header={['', "", "", "Name", "Description"]} />
              :
              <BorderedTable>
                <DataTable
                  value={products}
                  reorderableColumns
                  reorderableRows
                  onRowReorder={(e) => setProducts(e.value)}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column rowReorder style={{ width: "3rem" }} />
                  {isChecked ? dynamicColumns1 : dynamicColumns}
                </DataTable>
              </BorderedTable>}
        </div>

        <div className="btn_main d-flex justify-content-between  mt-4 ">

          <div className="d-flex gap-3">
            <PrimaryButton
              className=""
              onClick={() => setVisible3(true)}
              text="Cancel"
            />
            <PrimaryButton
              text="Back"
              onClick={() => setBackVisible(true)}
            />

            {/* <button
              className="btn border me-3"
              onClick={() => setBackVisible(true)}
            >
              Back
            </button> */}

          </div>

          <div className="d-flex gap-3">
            <PrimaryButton text={"Save"} onClick={() => setVisible2(true)} />
            <PrimaryButton text={"Save & Continue"} onClick={() => setVisible(true)} />
          </div>
        </div>
      </div>

      <SevedModal
        yes={() => navigate("/portfolio/projectform/step-3")}
        visible={backVisible}
        setVisible={setBackVisible}
        heading='Go Back to Previous Step'
        type='warning'
        headingText={`Are you sure you want to navigate back to the previous step without saving?`}
      />
      <SevedModal
        yes={() => addProjectTools1("save and continue")}
        visible={visible}
        setVisible={setVisible}
        heading='Proceed to Next Step'
        loader={btnLoader}
        type='info'
        headingText={`Are you sure you want to save your progress and continue?`}
      />
      <SevedModal
        yes={() => addProjectTools1("save")}
        visible={visible2}
        setVisible={setVisible2}
        heading='Create the Project'
        loader={btnLoader}
        type='info'
        headingText={`Are you sure want to save your Data?`}
      />
      <SevedModal
        yes={() => navigate("/projects")}
        visible={visible3}
        setVisible={setVisible3}
        heading='Confirm Cancellation'
        type='warning'
        headingText={`Are you sure you want to discard this data?`}
      />
    </>
  );
}

export default ProjectTool;
