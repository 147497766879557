import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Router } from './Routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PrimeReactProvider } from 'primereact/api'
import './App.css'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { useSelector } from 'react-redux'
import GlobalCustomSnakbar from './Components/Common/GlobalCustomSnakbar'
import { CustomLoader } from './Components/Common/CustomLoader'
import 'react-quill/dist/quill.snow.css'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MICROSOFT_CLIENT_ID } from './constants'

const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'http://localhost:3000'
  }
}

function App ({ pageProps }) {
  const snackbarData = useSelector(e => e.snackbar)
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <PrimeReactProvider>
          <BrowserRouter>
            <GlobalCustomSnakbar snackBarData={snackbarData} />
            <CustomLoader />
            <Router {...pageProps} />
          </BrowserRouter>
        </PrimeReactProvider>
      </MsalProvider>
    </>
  )
};
export default App
