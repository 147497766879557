// import { configureStore } from '@reduxjs/toolkit';
// import toastReducer from './Slice/toast/toastSlice'; 

// export const store = configureStore({
//     reducer: {
//         toast: toastReducer, 
//     },
// });



import { configureStore } from '@reduxjs/toolkit';
import toastReducer from './Slice/toast/toastSlice';
import { sliderToggle } from './Slice/CommonSlice';
import { RolesAndPermissionSlice } from './Slice/RolesAndPermissionsSlice';
import { EmailTabSlice } from './Slice/EmailTabSlice';
import { loaderSlice } from './Slice/LoaderSlice';

export const store = configureStore({
    reducer: {
        toast: toastReducer,
        ToggleButtonState: sliderToggle.reducer,
        rolesAndPermissionData: RolesAndPermissionSlice.reducer,
        emailTabAccessData: EmailTabSlice.reducer,
        loader: loaderSlice.reducer
    },
});
