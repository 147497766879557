import React from "react";
import { DIRECT_COST_STATUS, DIRECT_COST_TYPES } from "../../../../constants";
import TextInput from "../../../../Components/Inputs/TextInput";
import SingleSelect from "../../../../Components/Common/SingleSelect";
import { Editor } from "primereact/editor";
import { DragAndDrop } from "../../../../Components/Inputs/DragAndDrop";
import Calender from "../../../../Components/Common/Calender";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Popover from "../../../../Components/Common/Popover";
import AddSOV from "./AddSOV";
import { useFieldArray, useForm } from "react-hook-form";
import { PrimaryButton } from "../../../../Components/Buttons/PrimaryButton";

function CreateContract() {
  const { control } = useForm();
  const fields = [
    {
      CostCode: "01-023 Project Executive",
      Description: "Project Executive Salary",
      Tax_Code: "GST",
      Amount: "$ 180,000.00",
      Billed_to_Date: "$ 0.00",
      Amount_Remaining: "$ 180,000.00",
    },
    {
      CostCode: "01-023 Project Executive",
      Description: "Project Executive Salary",
      Tax_Code: "GST",
      Amount: "$ 180,000.00",
      Billed_to_Date: "$ 0.00",
      Amount_Remaining: "$ 180,000.00",
    },
  ];

  const { field, append } = useFieldArray({
    control,
    name: "cost_line_item",
  });
  return (
    <div className="mb-3">
      <div className="page_heading">CREATE PRIME CONTRACT</div>
      <div className="row pl-4 mt-4">
        <div className="col-md-12 ">
          <span className="h5"> General Information </span>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <label htmlFor="" className="py-2 labelCss">
              Title: <span className="text-danger">*</span>
            </label>
            <TextInput
              // inputId="integeronly"
              placeholder="Enter Title"
              name="title"
              // value={data?.title}
              className={""}
              // onChange={(e) => handleInputChange(e)}
              // onClick={() =>
              //   setValidation({ ...Validation, title: false })
              // }
            ></TextInput>
          </div>

          <div className="row  mt-3 ">
            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Owner/Client: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                placeholder="Enter Owner/Client"
                // value={data?.projectStage}
                className={""}
                // onClick={() =>
                //   setValidation({ ...Validation, assignee: false })
                // }
                // options={stagedatas.map((e) => e.stage_name)}
                // onChange={setMembersResponsible}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Status: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                placeholder="Status "
                // value={data?.assignee}
                className={""}
                // onClick={() =>
                //   setValidation({ ...Validation, assignee: false })
                // }
                // options={showassignee}
                // onChange={(e) => setData({ ...data, assignee: e.value })}
                // const setcategory = (e) => {
                //   setData({ ...data, category: e.value });
                // };
              />
            </div>
          </div>
          <div className="row  mt-3 ">
            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Contractor: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                placeholder="Enter Contractor"
                // value={data?.projectStage}
                className={""}
                // onClick={() =>
                //   setValidation({ ...Validation, assignee: false })
                // }
                // options={stagedatas.map((e) => e.stage_name)}
                // onChange={setMembersResponsible}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Architect/Engineer: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                placeholder="Enter Architect/Engineer "
                // value={data?.assignee}
                className={""}
                // onClick={() =>
                //   setValidation({ ...Validation, assignee: false })
                // }
                // options={showassignee}
                // onChange={(e) => setData({ ...data, assignee: e.value })}
                // const setcategory = (e) => {
                //   setData({ ...data, category: e.value });
                // };
              />
            </div>
          </div>
          <div className="row  mt-3 ">
            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Default Retainage: <span className="text-danger">*</span>
              </label>
              <TextInput
                // inputId="integeronly"
                placeholder="Enter Default Retainage"
                name="title"
                // value={data?.title}
                className={""}
                // onChange={(e) => handleInputChange(e)}
                // onClick={() =>
                //   setValidation({ ...Validation, title: false })
                // }
              ></TextInput>
            </div>

            <div className="col-md-6">
              <label htmlFor="" className="py-2 labelCss">
                Execution Status: <span className="text-danger">*</span>
              </label>
              <SingleSelect
                placeholder="Enter Execution Status "
                // value={data?.assignee}
                className={""}
                // onClick={() =>
                //   setValidation({ ...Validation, assignee: false })
                // }
                // options={showassignee}
                // onChange={(e) => setData({ ...data, assignee: e.value })}
                // const setcategory = (e) => {
                //   setData({ ...data, category: e.value });
                // };
              />
            </div>
          </div>

          <div className="row  mt-2 ">
            <div className="col-md-12">
              <label htmlFor="" className="py-2 labelCss">
                Description:
              </label>
              <div className="card">
                <Editor
                  //   value={data?.description}
                  // className={Validation.description ? "Validation" : ""}
                  // onClick={() => setValidation({ ...Validation, description: false })}
                  name="description"
                  //   onTextChange={handleTextChange}
                  style={{ height: "200px" }}
                />
              </div>
            </div>
          </div>

          <div className="row  mt-2  css_com">
            <div className="col-md-12">
              <DragAndDrop
              // setRemovePhoto={setRemovePhoto}
              // value={fileName}
              // setFiles={setFileUpload}
              // onChange={handleFileSubmit}
              // setValue={setFileName}
              // setPreviousFiles={setPreviousFile}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4 ">
          <span className="h5"> Contract Dates </span>
        </div>
        <div className="row  mt-3 ">
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Start Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Start Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Estimated Completion Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Estimated Completion Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
        </div>
        <div className="row  mt-3 ">
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Substantial Completion Date:{" "}
              <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Substantial Completion Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Actual Completion Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Due Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
        </div>
        <div className="row  mt-3 ">
          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Signed Contract Received Date:{" "}
              <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Due Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="" className="py-2 labelCss">
              Contract Termination Date: <span className="text-danger">*</span>
            </label>
            <Calender
              placeholder="Due Date"
              //   value={data?.due_date}
              className={""}
              name="due_date"
              //   onChange={handleInputChange}
              //   onClick={() => setValidation({ ...Validation, due_date: false })}
            />
          </div>
        </div>
        <div className="col-md-12 mt-4 mb-2">
          <span className="h5"> Schedule of Value </span>
        </div>
        <DataTable value={fields} className="mb-3">
          <Column
            header="#"
            body={(rowData, rowIndex) => (
              <div>
                {rowIndex.rowIndex < 9 ? "0" : ""}
                {rowIndex.rowIndex + 1}
              </div>
            )}
          />
          <Column header="Cost Code" field="CostCode" />
          <Column header="Description" field="Description" />
          <Column header="Tax Code" field="Tax_Code" />
          <Column header="Amount" field="Amount" />
          <Column header="Billed to Date" field="Billed_to_Date" />
          <Column header="Amount Remaining" field="Amount_Remaining" />
          <Column
            header=""
            field=""
            body={(rowData, meta) => (
              <div className="flex justify-content-center">
                <Popover
                  options={["Edit", "delete"]}
                  onSelect={(index, event) => {
                    if (index === 0){}
                      // navigate(`/financialManagement/directCost/edit/${rowData._id}`);
                     
                  }}
                />
              </div>
            )}
          />
        </DataTable>
        <br />
        <AddSOV onSubmit={append} />
      </div>
      <div className="end mt-5 gap-2">
        <button
          className="btn border"
          // onClick={() => setVisible(false)}
          type="button"
        >
          Cancel
        </button>
        <PrimaryButton addIcon type="submit" text={"Create Contract"} />
      </div>
    </div>
  );
}

export default CreateContract;
