import React, { useEffect, useState } from "react";
import { DragAndDrop } from "./DragAndDrop";

export const LoopFileUpload = ({ index, data, setData,multi }) => {
  const [file, setfile] = useState([]);

  const [removePhoto, setRemovePhoto] = useState([]);
  const [previousFile, setPreviousFile] = useState([]);

  useEffect(() => {
    setfile(data[index] ? data[index] : []);
    setPreviousFile(data[index] ? data[index] : []);
  }, [data]);


  const handleFileInput = (event) => {
 
    let datas = event;
    if (data[index]) {
      datas = data[index];
      datas = [...datas, ...event];
     
    }
    setData((prevData) => ({ ...prevData, [index]: datas }));
  };
  const handleFileRemove = (event, file) => {
    
    const newData = data[index].filter((item) => item != file);
   
    setData((prevData) => ({ ...prevData, [index]: newData }));
  };

  return (
    <div>
      <DragAndDrop
        setRemovePhoto={setRemovePhoto}
        setPreviousFiles={setPreviousFile}
        value={file}
        onChange={handleFileInput}
        onRemoveChange={handleFileRemove}
        setFiles={setfile}
        setValue={setfile}
        multiSelect={multi}
      />
    </div>
  );
};
