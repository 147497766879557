import React, { useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../../Components/Buttons/PrimaryButton';
import { TabPanel, TabView } from 'primereact/tabview';
import { ExportPdfButton } from '../../../../Components/Buttons/ExportPdfButton';
import { CSVLink } from 'react-csv';

const ChangeOrderOutlet = () => {
    const [activeStep, setActiveStep] = useState(0)
    let navigate = useNavigate();

    const steps = [
        { label: "Prime Contract",  path: "/financialManagement/changeOrder"},
        { label: "Commitments" , path: "/financialManagement/changeOrder/commitments"}
    ]


  return (
    <div>
        <div className="d-flex flex-sm-row flex-column justify-content-between">
            <div className='page_heading'>Change Order</div>
               <div className="right d-flex align-items-center justify-content-end mt-sm-0 mt-3">
                            <div className=" d-flex align-items-center">
                                <div className=" d-flex align-items-center">
                                    <ExportPdfButton exportToPDF={""}/>    
                                </div>
                            </div>
                         <Link to={steps[activeStep].label === "Prime Contract" ? "createPCCO" : "createCCO"}>
                                <PrimaryButton className={`ms-2`} text={steps[activeStep].label === "Prime Contract" ? "PCCO" : "CCO"} addIcon />
                        </Link>
                 </div>
        </div>

        <TabView activeIndex={activeStep}
        onTabChange={(e) => {
            setActiveStep(e.index)
            navigate(steps[e.index].path)
        }}>
            {steps.map((res, key) => <TabPanel key={key} header={res.label} />)}

        </TabView>
        <Outlet context={{ setActiveStep }} />
    </div>
  )
}

export default ChangeOrderOutlet