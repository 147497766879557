
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useState } from 'react'
import { PrimaryButton } from '../Buttons/PrimaryButton'
import "./common.css"

export const DeleteConfirmDialog = ({ header, bodyText, open, setOpen, stopPropagation, data, children, primaryBtnText, secondaryBtnText, onClick }) => {

    const [visible, setVisible] = useState(open || false)

    return (
        <>
            <div onClick={(e) => {
                if (stopPropagation) {
                    e.stopPropagation()
                }
                setVisible(true)
            }}>{children}</div>
            <Dialog
                style={{ borderRadius: '10px', overflow: "hidden", padding: "0px" }}
                visible={visible}
                onHide={() => setVisible(false)}
                headerClassName='d-none'
            >
                <div>
                    <div className='deleteconfirm_image_con'>
                        <img src="/images/deleteConfirmation.png" className='w-100' alt="delete Image" />
                    </div>
                    <br />
                    <div className='d-flex gap-3 justify-content-between'>
                        <button className='deleteConfirm_secondary_btn'
                            onClick={() => {
                                setVisible(false)
                                setOpen && setOpen(false)
                            }}
                        >{secondaryBtnText || "Cancel"}</button>
                        <PrimaryButton style={{ padding: "10px 16px" }}
                            onClick={() => {
                                onClick(data)
                                setVisible(false)
                                setOpen && setOpen(false)
                            }}
                            text={primaryBtnText || "Delete"} />
                    </div>
                </div>

            </Dialog>

        </>
    )
}
