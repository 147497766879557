import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { getCompanyByProjectId, getUserByProjectId } from "../../Api/Admin/AllAPIs";
import { getProjectId } from "../../utils";

const Filter = ({ filterData, setFilterData, onFilter, className }) => {
  const [visible, setVisible] = useState(false);
  const [allUsersOfProject, setAllUsersOfProject] = useState([]);
  const projectId = localStorage.getItem("projectId");
  const [companies, setCompanies] = useState([]);

  const submittals = ["Design Proposal", "Engineering Report", "Construction Drawings", "Material Submittal"];

  function inpChange(e) {
    let { name, value, type } = e.target;
  
    if (type === 'date') {
      // Save the date in ISO 8601 format to the state
      const isoDate = new Date(value).toISOString();
      setFilterData(prev => ({ ...prev, [name]: isoDate }));
    } else {
      setFilterData(prev => ({ ...prev, [name]: value }));
    }
  }
  

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(projectId);
      let allUsers = res?.data?.data?.[0]?.combined_users || [];
      setAllUsersOfProject(allUsers);
    } catch (err) {
      console.log(err);
    }
  }

  const getcompany = async () => {
    try {
      const res = await getCompanyByProjectId(getProjectId());
      if (res && res?.data) {
        const companyNames = res?.data?.data[0]?.companynames;
        const formattedCompanies = companyNames.map((company) => ({
          label: company.company_name,
          value: company._id,
        }));
        setCompanies(formattedCompanies);
      } else {
        // console.log("Response data is not in the expected format");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsersOfProject();
    getcompany();
  }, []);

  return (
    <div className={`card flex justify-content-center ${className}`}>
      <button
        className="btn border text-nowrap w-100"
        onClick={() => setVisible(true)}
      >
        Filter
      </button>
      <Dialog
        draggable={false}
        header="Filter Submittals"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row mt-4">
          <div className="col-12">
            <label className="labels mb-1">Responsible Contractor:</label>
            <div className="card flex justify-content-center">
              <Dropdown
                value={filterData?.submittal_responsibleContractor}
                onChange={inpChange}
                name="submittal_responsibleContractor"
                options={companies.map(d => ({ label: d.label, value: d.value }))}
                optionLabel="label"
                placeholder="All"
              />
            </div>
          </div>
        
          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <Button className="Apply_filter_btn" onClick={() => {
                onFilter();
                setVisible(false);
              }}>
                Apply Filter
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;
