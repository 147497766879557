

export const PROJECT_MANAGER = ["project_manager"];
export const GOOGLE_SIGNIN_KEY = "514863985904-tn7umiussr2ak3okj3fkm3smklig8g6r.apps.googleusercontent.com"
export const MICROSOFT_CLIENT_ID="191eda0c-39e7-42c9-bf02-c8ffd0076a5e"
export const G = [];
export const CRYPTO_HASH_KEY="{[~!@#$%^&*(--)*&^%$#@!~]}"

export const ADMIN_ROLES = ["admin"]

export const USER_ROLES = ["user", "individual"]
export const PROJECT_STATUS = {
    completed: "completed",
    onHold: "onHold",
    active: "active"
}
export const WORKFLOW_STATUS = [
    "Not Started",
    "In Progress",
    "Completed",
    "Ready for Review"
]

export const TOOL_STATUS = {
    draft: "draft",
    inreview: "inreview",
    pendingapproval: "pendingapproval",
    approved: "approved"
}

export const CDC_LOGO_URL = 'https://ott-fast-data.s3.ap-southeast-1.amazonaws.com/Group+(1).png';


export const PROJECT_STATUS_OPTIONS = [
    { label: "Open", value: "open" },
    { label: "Close", value: "close" },
    { label: "Re Open", value: "reopen" },
    { label: "On Hold", value: "onhold" },
];
export const DIRECT_COST_TYPES = [
    { label: "Invoices", value: "invoices" },
    { label: "Expenses", value: "expenses" },
    { label: "Payroll", value: "payroll" },
]
export const DIRECT_COST_STATUS = [
    { label: "Draft", value: "draft" },
    { label: "Pending", value: "pending" },
    { label: "Revise & Resubmit", value: "reviseandresubmit" },
    { label: "Approved", value: "approved" },
]

export const PROJECT_BACK_BUTTON_WARNING = "Are you sure you want to proceed ?"

export const PROJECT_OVERVIEW_TOOLS = [
    { title: "Rfi", value: "rfi" },
    { title: "Drawing", value: "drawing" },
    { title: "Form", value: "form" },
    { title: "Incident", value: "incident" },
    { title: "Inspection", value: "inspection" },
    { title: "Issue", value: "issue" },
    { title: "Ovservation", value: "observation" },
    { title: "Punch", value: "punchs" },
    { title: "Specifications", value: "specification" },
    { title: "Submittal", value: "submittal" }
]

export const INSPECTION_ITEM_RESULT = [
    { label: "Pass", value: "pass" },
    { label: "Fail", value: "fail" },
    { label: "N/A", value: "n/a" },
]


export const ROLES_AND_ACCESS_PERMISSIONS = [
    {
        module: "userManagement",
        path: "/userManagement",
        isAcess: true,
        sub_modules: [
            {
                module: "createUser",
                path: "/userManagement/createUser",
                isAcess: true
            },
            {
                module: "editUser",
                path: "/userManagement/editUser/",
                isAcess: true
            },
            {
                module: "deleteUser",
                path: null,
                isAcess: true
            }
        ]
    },
    {
        module: "companyManagement",
        path: "/projectDirectories",
        isAcess: true,
        sub_modules: [
            {
                module: "createCompany",
                path: "/projectDirectories/createContractor/add",
                isAcess: true
            },
            {
                module: "editCompany",
                path: "/projectDirectories/updateContractor",
                isAcess: true
            },
            {
                module: "deleteCompany",
                path: null,
                isAcess: true
            }
        ]
    },
    {
        module: "financialManagement",
        path: "/financialManagement",
        isAcess: true,
        sub_modules: []
    },
    {
        module: "allProjects",
        path: "/projects",
        isAcess: true,
        sub_modules: [
            {
                module: "assignReviewer",
                path: null,
                isAcess: true
            },
            {
                module: "assignApprover",
                path: null,
                isAcess: true
            },

        ]
    },
]
export const PROJECT_TYPES=[
    "Type 1",
    "Type 2"
]


export const TOOLS_COLORS = {
    task: "#FFA726",
    rfi: "#42A5F5",
    submittal: "#66BB6A",
    dailyLog: "#26C6DA",
    observation: "#AB47BC",
    punch: '#FF7043',
    issue: "#EC407A",
    incident: "#005FAE",
    inspection: "#8F9BE3",
    observation: "#AB47BC",
    specification: "#AB47BC"
  };

