import React, { useRef, useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";
import { ExportPdfButton } from "../../Components/Buttons/ExportPdfButton";
import { BorderedTable } from "../../Components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { IoMdSearch } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";


export default function StaffManagement() {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([
    { id: 1, name: "John Doe", phone: "123-456-7890", email: "john.doe@example.com", address: "123 Main St, City, Country", jobRole: "Developer" },
    { id: 2, name: "Jane Smith", phone: "987-654-3210", email: "jane.smith@example.com", address: "456 Elm St, City, Country", jobRole: "Manager" },
    { id: 3, name: "Alice Johnson", phone: "456-789-1234", email: "alice.johnson@example.com", address: "789 Oak St, City, Country", jobRole: "Designer" },
    { id: 4, name: "Bob Brown", phone: "321-654-9870", email: "bob.brown@example.com", address: "101 Pine St, City, Country", jobRole: "Developer" },
    { id: 5, name: "Charlie Davis", phone: "654-987-3210", email: "charlie.davis@example.com", address: "202 Maple St, City, Country", jobRole: "Project Manager" },
    { id: 6, name: "Diana Harris", phone: "987-321-6540", email: "diana.harris@example.com", address: "303 Birch St, City, Country", jobRole: "Analyst" },
    { id: 7, name: "Edward Moore", phone: "876-543-2109", email: "edward.moore@example.com", address: "404 Cedar St, City, Country", jobRole: "Architect" },
    { id: 8, name: "Fiona Wilson", phone: "234-567-8901", email: "fiona.wilson@example.com", address: "505 Willow St, City, Country", jobRole: "HR Manager" },
    { id: 9, name: "George Taylor", phone: "543-210-9876", email: "george.taylor@example.com", address: "606 Redwood St, City, Country", jobRole: "Team Lead" },
    { id: 10, name: "Helen Lee", phone: "765-432-1098", email: "helen.lee@example.com", address: "707 Ash St, City, Country", jobRole: "Marketing" },
  ]);

  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    setFilteredProducts(
      products.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, products]);

  const csvData = products.map((item) => ({
    ID: item.id,
    Name: item.name,
    Phone: item.phone,
    Email: item.email,
    Address: item.address,
    JobRole: item.jobRole,
  }));

  const tableRef = useRef();

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: tableRef.current });
    doc.save("staff_management.pdf");
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h1 className="heading ">STAFF MANAGEMENT</h1>
        <div className="d-flex align-items-center">
          <ExportPdfButton
            exportToPDF={exportToPDF}
            csvBtn={
              <CSVLink className="pe-1" data={csvData} filename={"Staff_Management.csv"}>
                CSV
              </CSVLink>
            }
          />
          <div className="ms-4">
            <PrimaryButton addIcon text="Add Staff" />
          </div>
        </div>
      </div>
      <div className="border-bottom my-4"></div>

      {/* Search Bar Above the Table */}
      <div className="d-flex justify-content-start w-50 mt-3 mb-3">
        <input
          type="text"
          className="form-control w-50"
          placeholder="Search Staff..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div style={{ height: "0", overflow: "hidden" }}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Job Role</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td>{item.address}</td>
                <td>{item.jobRole}</td>
                <td>
                  <button className="btn btn-primary">View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

     

      <BorderedTable>
        <DataTable value={filteredProducts} paginator rows={5} rowsPerPageOptions={[5, 10, 20]}>
          <Column field="id" header="ID" />
          <Column field="name" header="Name" />
          <Column field="phone" header="Phone" />
          <Column field="email" header="Email" />
          <Column field="address" header="Address" />
          <Column field="jobRole" header="Job Role" />
          <Column
            body={() => (
             
                <IoEyeOutline size={25} />
            )}
            header="View"
          />
        </DataTable>
      </BorderedTable>

    </div>
  );
}
