import React, { useState, useRef } from 'react';
import '../Punch/PaintTouchup.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MdEditSquare } from "react-icons/md";
import { EditButton } from '../../Components/Buttons/EditButton';
import { Statuscomp } from '../../Components/Common/StatusComp';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import SingleSelect from '../../Components/Common/SingleSelect';
import { MdOutlineAttachment } from "react-icons/md";
import TextInput from "../../Components/Inputs/TextInput"
import { PrimaryButton } from '../../Components/Buttons/PrimaryButton';
import { BorderedTable } from '../../Components/Tables/BorderedTable';
import { Link } from 'react-router-dom';







function IncidentList() {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState('');


    const fileInputRef = useRef(null);

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        const files = event.target.files;
        // Do something with the selected files
    };


    const Data = [
        {
            index: '01',
            name: 'Garry Scott',
            comments: 'Construction and wrecking for a week',
            attachment: 'No attachements',
            status: 'Unresolved',
        },
        {
            index: '02',
            name: 'Garry Scott',
            comments: 'Construction and wrecking for a week',
            attachment: 'No attachements',
            status: 'Unresolved',
        }
    ]

    return (
        <>

            <div className='mainContainer'>
                <div className='card p-md-4 p-sm-3 p-2'>
                    <div className='  d-flex justify-content-between  mb-5  w-75'>
                        {/* <div className='row'> */}
                            {/* <div className='d-flex justify-content-between'> */}
                                <h1 className='heading'>Information</h1>
                                <div > <Link className='click-able '   onClick={() => setVisible(true)} >Hide</Link></div>
                            {/* </div> */}
                        </div>
                    {/* </div> */}

                    {/* <div className="container"> */}
                        <table className=" table-sm w-50">

                            <tr>
                                <th scope="row">Created By:</th>
                                <td>Mark</td>

                            </tr>
                            <tr>
                                <th scope="row">Created On:</th>
                                <td>Jacob</td>

                            </tr>
                            <tr>
                                <th scope="row">Distribution:</th>
                                <td>Jacob</td>

                            </tr>
                            <tr>
                                <th scope="row">Recordable:</th>
                                <td>Jacob</td>

                            </tr>
                            <tr>
                                <th scope="row">Hazard:</th>
                                <td>Jacob</td>

                            </tr>
                            <tr>
                                <th scope="row">Contributing Condition:</th>
                                <td>Jacob</td>

                            </tr>
                            <tr>
                                <th scope="row">Contributing Behavior:</th>
                                <td>Jacob</td>

                            </tr>
                            <tr>
                                <th scope="row">Description</th>

                            </tr>
                            <tr>
                                <td scope="row">thia is some of the Description</td>

                            </tr>
                        </table>
                      
                      

                    </div>
                    <div className='card p-md-4 p-sm-3 p-2'>
                            <div className='w-75'>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Attachment(0)</span>
                                    <span></span>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Records(0)</span>
                                    <span style={{ color: ' #F97B06' }}>Add</span>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Witness Statements(0)</span>
                                    <span style={{ color: ' #F97B06' }}>Add</span>
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Pat Johnson(PM)</span>
                                    
                                </div>
                                <div className='d-flex justify-content-between mb-2'>
                                    <span>Actions(0)</span>
                                    <span style={{ color: ' #F97B06' }}>Add</span>
                                </div>
                            </div>
                        </div>

                </div>
            {/* </div> */}

        </>
    )
}

export default IncidentList
