import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { getOuterRolesAndPermissionData } from '../../Store/Slice/RolesAndPermissionsSlice';
import { getUserRoleId, getUserType } from '../../utils';
import { useSelector } from 'react-redux';
import { ADMIN_ROLES } from '../../constants';

export const OutsideProjectRoleAccessKeeper = () => {

    let dispatch = useDispatch();
    let isAdmin = ADMIN_ROLES.includes(getUserType())
    useEffect(() => {

        let roleId = getUserRoleId()
        if (!isAdmin) {
            dispatch(getOuterRolesAndPermissionData(roleId))
        }
    }, [])
    let rolesAndPermissionData = useSelector(d => d.rolesAndPermissionData.data)
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const pathname = location.pathname;

        if (rolesAndPermissionData?.length > 0) {

            let firstCondition = rolesAndPermissionData.some(outer => {
                if (outer.path && matchPath({ path: outer?.path || "", exact: false }, pathname)) {
                    return true
                } else {
                    return outer.sub_module.some(inner => {
                        if (inner.path && inner.path && matchPath({ path: inner?.path || "", exact: false }, pathname)) {
                            return true
                        }
                        return false
                    })

                }
            })

            if (firstCondition) {
                let isUserHaveAccess = rolesAndPermissionData.some((outer) => {

                    if (outer.path && matchPath({ path: outer?.path || "", exact: false }, pathname) && outer.isAcess) {
                        return true
                    }
                    else {
                        return outer.sub_module.some((inner) => {
                            if (inner.path && matchPath({ path: inner?.path || "", exact: false }, pathname) && inner.isAcess) {
                                return true
                            }
                            return false
                        })
                    }
                    return false

                })

                if (!isUserHaveAccess) {
                    navigate("/noAccess")
                }
            }

        }





    }, [rolesAndPermissionData, location])

    return (
        <Outlet />
    )
}
