import React from 'react'
import { ViewPageTable } from './ViewPageTable';
import { getProjectName } from '../../../utils';

export const ViewPagesPdfTemplate = ({ header, tableData1, tableData2, description }) => {
    return (
        <div className='card p-4 '>
            <h2 className='mb-3'>{header}</h2>
            <div className="row">

                <div className='col-lg-6 col-12'>
                    <ViewPageTable tableData={tableData1 || []} />
                </div>
                {tableData2 &&
                    <div className='col-lg-6 col-12'>
                        <ViewPageTable tableData={tableData2 || []} />
                    </div>}
            </div>

            {description && <div className='mt-3'>
                <div className='view_page_para_head'>{description.key}</div>
                <div>{description.value}</div>
            </div>
            }



        </div>
    )
};
