

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDirectCostById } from '../../../../Api/Admin/AllAPIs';
import { DirectCostForm } from './DirectCostForm';

export const EditDirectCost = () => {

    const { id } = useParams();
    const [directCostData, setDirectCostData] = useState({})

    useEffect(() => {
        fetchDirectCost()
    }, [])

    async function fetchDirectCost() {
        try {
            // let res = await getDirectCostById(id)
            // let data = res?.data?.data?.[0] || {}
            // setDirectCostData(data)
        } catch (error) {

        }
    }

    return (
        <div>

            {/* {directCostData?.direct_cost_name && <DirectCostForm formData={directCostData} />} */}
        </div>
    )
}
