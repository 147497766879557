import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./openrfis.css";
import { BorderedTable } from "../Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ResponseDialog from "./ResponseDialog";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { getRfiById } from "../../Api/Admin/AllAPIs";
import { ViewPageTable } from "../Common/ViewPageComponents/ViewPageTable";
import moment from "moment";
import { ViewPageDocumentName } from "../Common/ViewPageComponents/ViewPageDocumentName";
import "jspdf-autotable";
import FormSkeleton from "../Common/FormSkeleton";
import { TableSkelton } from "../Common/TableSkelton";
import ViewVersion from "../Common/ViewVersion";
import { downloadPdf } from "../Common/ViewPageComponents/downloadPdf";

export default function RfiView({ includeVersionHistory, usedAsComponent }) {


  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [rfi, setRfi] = useState({});
  const [contractorData, setContractorData] = useState([]);

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 1, height: "2.4rem" },

    { qty: 1, height: "5rem" },
  ];
  let [attachedData, setAttachedData] = useState({
    drawing: [],
    files: [],
  });
  let pdfRef = useRef();
  const { id } = useParams();

  const handleGetRfi = async () => {
    try {
      setSkeletonLoader(true);
      const response = await getRfiById(id);
      const rfiData = response?.data?.data;
   
      let data = rfiData?.responsibleContractor?.map((res) => {
        let pp = rfiData?.membersResponsible?.filter(
          (item) => item?.company == res?._id
        );
        return {
          company: res?.company_name,
          persons:
            pp.length == 0
              ? "No Person Added"
              : pp?.map((ress) => ress?.first_name)?.join(", "),
        };
      });
      setContractorData(data);
      setRfi(rfiData);
      setAttachedData({
        drawing: [],
        files: [
          ...response?.data?.data?.attachFile.map((e) => e.fileUrl),
          ...response?.data?.data?.attachFile.map((e) => e.fileUrl).flat(Infinity),
        ],
        // files: response.data.data.attachFile.map((e) => e.fileUrl),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    handleGetRfi();
  }, []);



  function downloadPdf2() {
    const content = pdfRef.current;
    let urlLinks = [...rfi?.attachFile?.map(e => e.fileUrl), ...rfi?.response?.map(es => es?.attachFile.map(sa => sa?.fileUrl)).flat(Infinity)]
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents", files: urlLinks
      }
    ], "rfi")
 
  }


  return (
    <>
      <div className={`rfiView card`}>
        <div className="rfiparent">
          {skeletonLoader ? (
            <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} />
          ) : (
            <>
              {!usedAsComponent && (
                <div className="w-100 breadcrum mb-5 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <span
                      className="open_Rfi_text"
                      onClick={() => navigate("/projectManagement/openrfis")}
                    >
                      RFIs
                    </span>
                    <span className="px-1 open_Rfi_text ">/</span>
                    <span className="createNew_text">{rfi?.subject}</span>
                  </div>
                  {/* <div style={{ width: "120px" }}>
                    <EmailTemplateWithPdf sendEmail={okok} isDialog={true} />
                  </div> */}
                </div>
              )}

              <div className="rfibody d-flex flex-column gap-4">
                {!usedAsComponent && (
                  <div className="d-flex justify-content-between">
                    <h1 className="headdingRFI"> {rfi?.subject}</h1>
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={downloadPdf2}
                      >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex flex-column gap-3">
                  <div className="row">
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Question Received From":
                              rfi?.questionReceived?.[0]?.first_name || "--",
                          },
                          { "RFI Number": rfi?.rfi || "--" },
                          { "Workflow Status": rfi?.workflow_status || "--" },
                          {
                            "RFI Manager":
                              rfi?.rfi_manager
                                ?.map((e) => e?.first_name)
                                ?.join(", ") || "--",
                          },
                          {
                            "Responsible Person":
                              rfi?.assignees
                                ?.map((e) => e?.first_name)
                                ?.join(", ") || "--",
                          },
                          { Reference: rfi?.reference || "--" },
                          { "Scheduled Impact": rfi?.scheduledImpact || "--" },
                          { "Drawing Number": rfi?.drawingNumber || "--" },
                          { Location: rfi?.location || "--" },
                        ]}
                      />
                      <div className="gray-lt text-nowrap pb-2">
                        Responsible Contractor Company / Name:
                      </div>
                      <div className="d-flex flex-column">
                        {contractorData?.map((res, index) => (
                          <div className="gray-d pb-2" key={index}>
                            {res?.company} - {res?.persons}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          {
                            "Approval Status":
                              rfi?.status == "pendingapproval"
                                ? "Pending Approval"
                                : rfi?.status || "--",
                          },
                          {
                            "Due Date":
                              moment(rfi?.dueDate).format("MM/DD/YYYY") || "--",
                          },
                          { Location: rfi?.location || "--" },
                          { Category: rfi?.rfiCategosry[0]?.name || "--" },
                          { "Cost Code": rfi?.costCode || "--" },
                          { "Cost Impact": rfi?.costImpact || "--" },
                          {"Hours Spent": rfi?.hourSpent
                              ? `${rfi?.hourSpent?.split("-")[0]} hour ${rfi?.hourSpent?.split("-")[1]
                              } Min`
                              : "--",
                          },
                          {
                            Reviewer:
                              rfi?.reviewer?.length !== 0 &&
                                rfi?.reviewer !== undefined
                                ? `${rfi?.reviewer[0]?.first_name} ${rfi?.reviewer[0]?.last_name}`
                                : "--",
                          },
                          {
                            Approver:
                              rfi?.approver?.length !== 0 &&
                                rfi?.approver !== undefined
                                ? `${rfi?.approver[0]?.first_name} ${rfi?.approver[0]?.last_name}`
                                : "--",
                          },
                          { "Reviewer Comment": rfi?.reviewer_comment || "--" },
                          { "Approver Comment": rfi?.approver_comment || "--" },
                          // { Reference: rfi?.reference || "--" },
                          {
                            "Created Date":
                              moment(rfi?.created_at).format(
                                "DD/MM/YYYY h:mm:ss A"
                              ) || "--",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>



              <div className="view_page_para_head mt-4 mb-2">Question</div>
              <div className="view_page_bold_text">
                <div dangerouslySetInnerHTML={{ __html: rfi?.question }} />
              </div>
              <div className="view_page_para_head mt-4 mb-2">
                Attached Document:
              </div>
              {rfi?.attachFile?.map((res, key) => {
                return (
                  <ViewPageDocumentName
                    key={key}
                    docUrl={res?.fileUrl}
                    name={res?.fileName}
                  />
                );
              })}
            </>
          )}
          <hr />

          <div className="d-flex flex-column mt-4 gap-3">
            <div className="d-flex justify-content-between">
              <h2>{`Responses (${rfi?.response?.length})`}</h2>{" "}
              <div
                className="clear_btn gray-lt"
                onClick={() => setVisible(true)}
              >
                Add Response
              </div>
            </div>

            {rfi?.response?.length === 0 ? (
              <div className="noData gray-ult w-100 justify-content-center text-center">
                There are no responses to this RFI right now. Once added, all
                the responses to this RFI will be visible here.
              </div>
            ) : skeletonLoader ? (
              <TableSkelton
                header={[
                  "",
                  "Response By",
                  "Response Date",
                  "Response",
                  "Attachments",
                ]}
              />
            ) : (
              <div>
                <BorderedTable>
                  <DataTable
                    value={rfi?.response}
               
                  >
                    <Column
                      body={(rfi, rowIndex) => (
                        <div className="text-center">
                          {rowIndex.rowIndex + 1}
                        </div>
                      )}
                      style={{ minWidth: "4rem" }}
                    />
                   
                    <Column
                      field="response_date"
                      header="Response Date"
                      style={{ width: "15%" }}
                    
                    />
                    <Column
                      field="response_descripttion"
                      header="Response"
                      style={{ width: "54.6%" }}
                      body={(rfi, rowIndex) => (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: rfi?.response_descripttion,
                          }}
                        />
                      )}
                    />
                    <Column
                      field=""
                      header="Attachments"
                      style={{ width: "15%" }}
                      body={(rowData, rowIndex) => (
                        <div>
                       
                          {rowData?.attachFile?.map((file, index) => (
                            <ViewPageDocumentName
                              docUrl={file?.fileUrl}
                              key={index}
                              name={file?.fileName}
                            />
                          ))}
                        </div>
                      )}
                    />
                  </DataTable>
                </BorderedTable>
              </div>
            )}
          </div>

          {(!usedAsComponent || includeVersionHistory) && (
            <>
              <hr />
              <ViewVersion isView={true} />
              <br />
              <br />
            </>
          )}
          <div>
         
          </div>
        </div>
        <ResponseDialog
          visible={visible}
          setVisible={setVisible}
          id={id}
          reloade={handleGetRfi}
        />
      </div>

      <div style={{ height: "0px", overflow: "hidden" }}>
        <div ref={pdfRef}>
          <div className="card p-5">

            <h1 className="headdingRFI"> {rfi?.subject}</h1>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="row">
                <div className="col-6">
                  <ViewPageTable
                    tableData={[
                      {
                        "Question Received From":
                          rfi?.questionReceived?.[0]?.first_name || "--",
                      },
                      { "RFI Number": rfi?.rfi || "--" },
                      { "Workflow Status": rfi?.workflow_status || "--" },
                      {
                        "RFI Manager":
                          rfi?.rfi_manager
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--",
                      },
                      {
                        "Responsible Person":
                          rfi?.assignees
                            ?.map((e) => e?.first_name)
                            ?.join(", ") || "--",
                      },
                      { Reference: rfi?.reference || "--" },
                      { "Scheduled Impact": rfi?.scheduledImpact || "--" },
                      { "Drawing Number": rfi?.drawingNumber || "--" },
                      { Location: rfi?.location || "--" },
                    ]}
                  />
                  <div className="gray-lt text-nowrap pb-2">
                    Responsible Contractor Company / Name:
                  </div>
                  <div className="d-flex flex-column">
                    {contractorData?.map((res, index) => (
                      <div className="gray-d pb-2" key={index}>
                        {res?.company} - {res?.persons}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-6">
                  <ViewPageTable
                    tableData={[
                      {
                        "Approval Status":
                          rfi?.status == "pendingapproval"
                            ? "Pending Approval"
                            : rfi?.status || "--",
                      },
                      {
                        "Due Data":
                          moment(rfi?.dueDate, "DD/MM/YYYY h:mm:ss a").format(
                            "MM/DD/YYYY"
                          ) || "--",
                      },
                      { Location: rfi?.location || "--" },
                      { Category: rfi?.rfiCategosry?.name || "--" },
                      { "Cost Code": rfi?.costCode || "--" },
                      { "Cost Impact": rfi?.costImpact || "--" },
                      {
                        "Hours Spent": rfi?.hourSpent
                          ? `${rfi?.hourSpent?.split("-")[0]} hour ${rfi?.hourSpent?.split("-")[1]
                          } Min`
                          : "--",
                      },
                      {
                        Reviewer:
                          rfi?.reviewer?.length !== 0 &&
                            rfi?.reviewer !== undefined
                            ? `${rfi?.reviewer[0]?.first_name} ${rfi?.reviewer[0]?.last_name}`
                            : "--",
                      },
                      {
                        Approver:
                          rfi?.approver?.length !== 0 &&
                            rfi?.approver !== undefined
                            ? `${rfi?.approver[0]?.first_name} ${rfi?.approver[0]?.last_name}`
                            : "--",
                      },
                      { "Reviewer Comment": rfi?.reviewer_comment || "--" },
                      { "Approver Comment": rfi?.approver_comment || "--" },
                      // { Reference: rfi?.reference || "--" },
                      {
                        "Created Date":
                          moment(rfi?.created_at).format(
                            "DD/MM/YYYY h:mm:ss A"
                          ) || "--",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="view_page_para_head mt-4 mb-2">Question</div>
            <div className="view_page_bold_text">
              <div dangerouslySetInnerHTML={{ __html: rfi?.question }} />
            </div>

            <div>
              {/* <BorderedTable> */}
              <DataTable
                value={rfi?.response}
            
              >
                <Column
                  body={(rfi, rowIndex) => (
                    <div>
                      {rowIndex.rowIndex + 1}
                    </div>
                  )}
                // style={{ minWidth: "4rem" }}
                />
              
                <Column
                  field="response_date"
                  header="Response Date"
                  style={{ width: "15%" }}
                //   body={(rfi, rowIndex) => (
                //     <div>{rowIndex.rowIndex + 1}</div>
                //   )}
                />
                <Column
                  field="response_descripttion"
                  header="Response"
                  // style={{ width: "54.6%" }}
                  body={(rfi, rowIndex) => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: rfi?.response_descripttion,
                      }}
                    />
                  )}
                />
             
              </DataTable>
              {/* </BorderedTable> */}
            </div>
            {(!usedAsComponent || includeVersionHistory) && (
              <>
                <hr />
                <ViewVersion isView={true} />
                <br />
                <br />
              </>
            )}
          </div>
        </div>
      </div>

      {!usedAsComponent &&
        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn border"
            onClick={() => navigate("/projectManagement/openrfis")}
          >
            Back
          </button>
        </div>
      }

      <br />
    </>
  );
}
