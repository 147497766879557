import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { PROJECT_STATUS_OPTIONS } from "../../constants";
import { getUserByProjectId } from "../../Api/Admin/AllAPIs";
import { getProjectId } from "../../utils";
import { MultiSelect } from "primereact/multiselect";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";

const Filter = ({ filterData, setFilterData, onFilter }) => {

  const [visible, setVisible] = useState(false)
  const [allUsersOfProject, setAllUsersOfProject] = useState([])

  const status_optionsP = [
    { id: 1, value: "open", label: "Open" },
    { id: 2, value: "close", label: "Close" },
    { id: 3, value: "reopen", label: "Reopen" },
    { id: 4, value: "onhold", label: "On Hold" },
  ];

  const type = [
    "In Progress",
    "Incomplete",
    "Completed",
    "Not Started",
  ];

  function inpChange(e) {
    let { name, value } = e.target;
    setFilterData((pre) => ({ ...pre, [name]: value }));
  }

  function applyFilterr() {
    setVisible(false);
    onFilter();
  }




  useEffect(() => {
    getAllUsersOfProject()
  }, [])

  async function getAllUsersOfProject() {
    try {
      let res = await getUserByProjectId(getProjectId())
      let allUsers = res?.data?.data?.[0]?.combined_users || []
      setAllUsersOfProject(allUsers)
     
    }
    catch (err) {
      console.log(err)
    }
  }
  function inpChange(e) {
    let { name, value } = e?.target;
    setFilterData(pre => ({ ...pre, [name]: value }))
   
  }
 

  return (
    <div className="card flex justify-content-center ">
      <button
        className="filter_btn"
        onClick={() => setVisible(true)}
      >
        Filter 
      </button>
      <Dialog
        draggable={false}
        header="Filter Specification"
        visible={visible}
        maximizable
        style={{ width: "50rem", color: "#101928" }}
        onHide={() => setVisible(false)}
        headerClassName="addBorder_bottom"
      >
        <div className="row ">
          <div className="col-md-6">
            <label className="labels py-2">Workflow Status:</label>

            <div className="card flex justify-content-center  ">
              <Dropdown  options={type} onChange={(e) => inpChange(e)}
                name="workflow_status"
                placeholder="Workflow Status"
                value={filterData?.workflow_status}

              />
              {/* <Dropdown
                value={filterData?.workflow_status}
                onChange={inpChange}
                options={workFlowOption}
                name="workflow_status"
                placeholder="Workflow Status"
              /> */}
            </div>
          </div>
          <div className="col-md-6">
            <label className="labels py-2">Responsible Person:</label>
            <div className="card flex justify-content-center  ">
              <MultiSelect
                name="responsible_person"
                value={filterData?.responsible_person}
                onChange={inpChange}
                options={allUsersOfProject.map((item) => ({
                  label: item?.first_name,
                  value: item?._id,
                }))} optionLabel="label"
                filter placeholder="Select Person"
                // maxSelectedLabels={3} 
                style={{ width: "100%" }}


              />
            </div>
          </div>


          <div className="col-md-12 mt-5 d-flex justify-content-end">
            <div className="d-flex align-items-center gap-3">
              <button className="filter_btn" onClick={() => setVisible(false)}>
                Cancel
              </button>
              <PrimaryButton
                onClick={applyFilterr}
                addIcon
                text={"Apply Filter"}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Filter;
