import React from 'react'

export const BellIcon = () => {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_237_22035)">
                <path d="M20.5091 23.5497C20.5091 21.4491 21.3436 19.4344 22.829 17.949C24.3144 16.4636 26.3291 15.6292 28.4297 15.6292C30.5304 15.6292 32.545 16.4636 34.0304 17.949C35.5158 19.4344 36.3503 21.4491 36.3503 23.5497V27.4005C36.3503 28.3285 36.6513 29.2315 37.2084 29.9747L39.9146 33.5825C40.1352 33.8767 40.2696 34.2265 40.3026 34.5928C40.3356 34.959 40.266 35.3272 40.1016 35.6561C39.9371 35.9851 39.6843 36.2617 39.3715 36.455C39.0587 36.6483 38.6982 36.7507 38.3305 36.7507H18.529C18.1612 36.7507 17.8008 36.6483 17.488 36.455C17.1751 36.2617 16.9223 35.9851 16.7579 35.6561C16.5934 35.3272 16.5238 34.959 16.5568 34.5928C16.5899 34.2265 16.7242 33.8767 16.9449 33.5825L19.6511 29.9747C20.208 29.232 20.5091 28.3288 20.5091 27.4005V23.5497ZM28.4297 41.3711C27.4346 41.3709 26.4661 41.0497 25.6682 40.4551C24.8703 39.8605 24.2855 39.0243 24.0008 38.0708H32.8587C32.5739 39.0243 31.9892 39.8605 31.1912 40.4551C30.3933 41.0497 29.4248 41.3709 28.4297 41.3711Z" fill="#F97B06" />
            </g>
        </svg>
    )
}
