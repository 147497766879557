import React, { useEffect, useState } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Link, useNavigate } from "react-router-dom";
import AddUser from "./AddUser";
import { ExportButton } from "../../Components/Buttons/ExportButton";
import Contractors from "./ContractorListining";
import SubContractors from "./SubContractorListining";
import Individuals from "./IndividualListining";
import { getContractorSubIndCount } from "../../Api/Admin/AllAPIs";
import Loader from "../../Components/Common/Loader";
import { PrimaryButton } from "../../Components/Buttons/PrimaryButton";

import { getActionsPermission } from "../../utils";
import { TabCard } from "../../Components/Common/PagesComponents/TabCard";
import { SearchInput } from "../../Components/Inputs/SearchInput";
import { useSelector } from "react-redux";
function ProjectDirectories() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [visible2, setVisible2] = useState(false);
  const [search, setSearch] = useState();

  const [isCreateAccess, setIsCreateAccess] = useState(false);
  let createAccess = getActionsPermission("Create Company")

  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    setIsCreateAccess(createAccess)
  }, [createAccess])

  const s = useSelector(e => e.rolesAndPermissionData);


  const [companyLength, setCompanyLength] = useState(0);
  const [personLength, setPersonLength] = useState(0);
  const navigate = useNavigate();


  const handleDataFromChild = (companyLen, personLen) => {
    setCompanyLength(companyLen);
    setPersonLength(personLen);
  };
  const option = [
    { name: 'Contractor', link: "/projectDirectories/createContractor/add" },
    { name: 'Sub Contractor', link: `/projectDirectories/createContractor/add?formType=subContractor` },
    { name: 'Individual', link: '/projectDirectories/createContractor/add?formType=individual' },
  ];

  const handleChange = (e) => {
    navigate(e.value.link);
  };

  const [usersCount, setUsersCount] = useState({
    contractor: 0,
    subcontractor: 0,
    individual: 0
  });

  async function getUsersCount() {
    try {
      let response = await getContractorSubIndCount()
     
      setUsersCount({
        contractor: response?.data?.data?.contractor,
        subcontractor: response?.data?.data?.subcontractor,
        individual: response?.data?.data?.individual,
      })

    }
    catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    getUsersCount()
  }, [])

  return (
    <>


      <div className="between23">
        <h1 className="page_heading">Vendor Management</h1>
        <div className="flex align_center gap-3">



        </div>
      </div>

      <div className="d-flex mb-20 gap-3 flex-sm-row flex-column-reverse justify-content-sm-between  align-items-sm-center align-items-end mt-3">
        <div className="searchInput">
          <SearchInput
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }
            }
            onCancel={() => {
              setSearch("")
            }}
          />
        </div>
        {getActionsPermission("Create Vendor") && <div>
          <Link to={"/projectDirectories/createContractor/add"}>
            <PrimaryButton addIcon text={"Create"} />
          </Link>
        </div>}
      </div>

      {/* Link navigation  */}

      <div>
        <div className="tabView">
          <TabView
            activeIndex={activeTabIndex}
            onTabChange={(e) => setActiveTabIndex(e.index)}
          >
            <TabPanel
              //  header={`Contractors(${usersCount.contractor || 0})`}
              headerStyle={{ marginRight: "5px" }}
              headerTemplate={
                <TabCard
                  style={{ width: "100% !important" }}
                  onClick={() => setActiveTabIndex(0)}
                  key={"ok"}
                  smallCard
                  header={`Contractor (${usersCount.contractor || 0})`}
                  active={activeTabIndex === 0}
                />
              }
            >
              <Contractors setUsersCount={setUsersCount} onSendData={handleDataFromChild} search={search} setSearch={setSearch} />
            </TabPanel>
            <TabPanel
              headerTemplate={
                <TabCard
                  smallCard
                  onClick={() => setActiveTabIndex(1)}
                  key={"ok2"}
                  header={`Sub Contractor (${usersCount.subcontractor || 0})`}

                  active={activeTabIndex === 1}
                />
              }
              header={`Sub-Contractors (${usersCount.subcontractor || 0})`}

            >
              <SubContractors setUsersCount={setUsersCount} search={search} setSearch={setSearch} />
            </TabPanel>

            {/* <TabPanel header={`Individuals (${usersCount.individual || 0})`}>
                  <Individuals />
                </TabPanel> */}


          </TabView>
        </div>
      </div>





      <AddUser setVisible2={setVisible2} visible2={visible2} />
    </>
  );
}

export default ProjectDirectories;
