import "./cards.css"

export default function LightShadowCard({ children, headingClass, className, heading, ...props }) {


    return (
        <div className={`lightShadowCard_container ${className}`} {...props}>
            {heading && <div className={`${headingClass} card_header`}>{heading}</div>}
            {children}
        </div>
    )
}