import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import AutoCompleteSelect from '../../Components/Common/AutoCompleteSelect'
import { toast } from '../../utils';
import CompareDrawingImage from './CompareDrawingImage';

export default function CompareDrawingModal({ open, pageFor, data, setOpenCompareModal }) {
    const [selectedTitle, setSelectedTitle] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [selectedTitle1, setSelectedTitle1] = useState([]);
    const [selectedFiles1, setSelectedFiles1] = useState([]);
    const [selectedFile1, setSelectedFile1] = useState(null);
    const [openCompare, setOpenCompare] = useState(false);

    const [viewData, setViewData] = useState({
        view1: "",
        view2: ""
    })



    const toggle = () => {
        setOpenCompareModal(false);
        setSelectedTitle([]);
        setSelectedTitle1([]);
        setSelectedFiles([]);
        setSelectedFiles1([]);
        setSelectedFile(null);
        setSelectedFile1(null)
    }

    const collections = data?.map(collection => ({
        title: collection?.title,
        files: collection?.files.map(file => ({
            fileName: file?.fileName,
            fileUrl: file?.fileUrl,
            _id: file?._id
        }))
    }))






    const handleTitleChange = (value) => {
        setSelectedTitle(value);
        const collection = collections.find(item => item?.title === value);
      
        setSelectedFiles(collection?.files?.map((item) => ({
            label: item?.fileName,
            value: item?.fileUrl
        })))
    }

    const handleTitleChange1 = (value) => {
        setSelectedTitle1(value);
        const collection = collections.find(item => item?.title === value);
    
        setSelectedFiles1(collection?.files?.map((item) => ({
            label: item?.fileName,
            value: item?.fileUrl
        })))
    }


    function compare() {
        if (selectedTitle == null || selectedTitle1 == null || selectedFile1 == null || selectedFile == null) {
            toast.info("Please Select all the field's");
            return
        }
        setOpenCompareModal(false);
        setOpenCompare(true);
    }


    return (
        <>
            <CompareDrawingImage
                open={openCompare}
                toggle={() => setOpenCompare(false)}
                pageFor={pageFor}
                viewData={viewData}
            />
            <Dialog header={`Compare ${pageFor?.charAt(0)?.toUpperCase() + pageFor?.slice(1)}`} visible={open} style={{ width: '40vw' }} onHide={toggle} breakpoints={{ '1200px': '60vw', '960px': '75vw', '641px': '90vw' }}>
                <div className='row'>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="labels mb-2">{pageFor?.charAt(0)?.toUpperCase() + pageFor?.slice(1)} 1 Title: </label>
                        <div className="card flex justify-content-center">
                            <AutoCompleteSelect
                                options={collections?.map((item) => ({ label: item?.title, value: item.title }))}
                                value={selectedTitle}
                                optionLabel="title_name"
                                onChange={handleTitleChange}
                                className={`p-0  text-capitalize`}
                                placeholder={`Select ${pageFor} Title`}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="labels mb-2">{pageFor?.charAt(0)?.toUpperCase() + pageFor?.slice(1)} 1 File: </label>
                        <div className="card flex justify-content-center">
                            <AutoCompleteSelect
                                options={selectedFiles}
                                value={selectedFile}
                                optionLabel="file_url"
                                onChange={(value) => {
                                    setSelectedFile(value);
                                 
                                    setViewData(prevState => ({
                                        ...prevState,
                                        view1: value
                                    }));
                                }}
                                className={`p-0 text-capitalize`}
                                placeholder={`Select ${pageFor} File`}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="labels mb-2">{pageFor?.charAt(0)?.toUpperCase() + pageFor?.slice(1)} 2 Title: </label>
                        <div className="card flex justify-content-center">
                            <AutoCompleteSelect
                                options={collections?.map((item) => ({ label: item?.title, value: item.title }))}
                                value={selectedTitle1}
                                optionLabel="title_name1"
                                onChange={handleTitleChange1}
                                className={`p-0 text-capitalize`}
                                placeholder={`Select ${pageFor} Title`}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mt-3">
                        <label className="labels mb-2">{pageFor?.charAt(0)?.toUpperCase() + pageFor?.slice(1)} 2 File: </label>
                        <div className="card flex justify-content-center">
                            <AutoCompleteSelect
                                options={selectedFiles1}
                                value={selectedFile1}
                                optionLabel="file_url"
                                onChange={(value) => {
                                    setSelectedFile1(value);
                                    setViewData(prevState => ({
                                        ...prevState,
                                        view2: value
                                    }));
                                }}
                                className={`p-0 text-capitalize`}
                                placeholder={`Select ${pageFor} File`}
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-end gap-3 mt-4">
                        <span
                            className="btn border  px-2 py-2"
                            onClick={toggle}
                        >

                            Cancel
                        </span>

                        <span className="btn btn-primary px-2 py-2" onClick={compare} >
                            Compare
                        </span>
                    </div>
                </div>
            </Dialog>

        </>
    )
}
