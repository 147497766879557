import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export async function downloadPdfUsingCanvas(components, fileLinks, pdfName, noDownload) {
    try {
    
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const padding = 10; // Adjust padding as needed

        for (let i = 0; i < components.length; i++) {
            const component = components[i];

            // Capture the content as a canvas
            const canvas = await html2canvas(component, { scrollY: -window.scrollY });
            const imgData = canvas.toDataURL('image/png');
            const imgProps = pdf.getImageProperties(imgData);
            let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            if (i > 0) {
                pdf.addPage();
            }

            let startY = padding; // Start with padding at the top
            let remainingHeight = pdfHeight;

            while (remainingHeight > 0) {
                if (startY !== padding) {
                    pdf.addPage();
                }

                const imgHeight = Math.min(remainingHeight, pageHeight - 2 * padding); // Ensure imgHeight considers padding
                pdf.addImage(imgData, 'PNG', padding, startY, pdfWidth - 2 * padding, imgHeight);
                remainingHeight -= imgHeight; // Subtract imgHeight instead of pageHeight
                startY += imgHeight; // Move startY by imgHeight
            }
           
        }

        if (noDownload) {
            const pdfBlob = pdf.output('blob');
            return pdfBlob;
        } else {
            pdf.save(pdfName || 'download.pdf');
            return null;
        }
    } catch (err) {
        console.error('Error generating PDF', err);
        return err;
    }
}
