const projectIdFromLocalStorage = localStorage.getItem("projectId");


const product = [
  {
      "checked": false,
      "_id": 1,
      "code": "01",
      "description": "General Requirements",
      "children": [
          {
              "checked": false,
              "code": "000",
              "parentId": 1,
              "description": "Purpose",
              "_id": 1
          },
         
          {
              "checked": false,
              "code": "002",
              "parentId": 1,
              "description": "Instruction",
              "_id": 2
          },
          {
              "checked": false,
              "code": "010",
              "parentId": 1,
              "description": "Project Manager",
              "_id": 3
          },
          {
              "checked": false,
              "code": "011",
              "parentId": 1,
              "description": "Project Engineer",
              "_id": 4
          },
          {
              "checked": false,
              "code": "012",
              "parentId": 1,
              "description": "Superintendent",
              "_id": 5
          },
          {
              "checked": false,
              "code": "013",
              "description": "Project Coordinator",
              "parentId": 1,
              "_id": 6
          },
          {
              "checked": false,
              "code": "014",
              "description": "Project Executive",
              "parentId": 1,
              "_id": 7
          },
          {
              "checked": false,
              "code": "500",
              "description": "Temporary Facilities and Controls",
              "parentId": 1,
              "_id": 8
          },
          {
              "checked": false,
              "code": "510",
              "description": "Temporary Utilities",
              "parentId": 1,
              "_id": 9
          },
          {
              "checked": false,
              "code": "511",
              "description": "Temporary Electricity",
              "parentId": 1,
              "_id": 10
          },
          {
              "checked": false,
              "code": "514",
              "description": "Temporary Heating, Cooling, and Ventilation",
              "parentId": 1,
              "_id": 11
          },
          {
              "checked": false,
              "code": "515",
              "description": "Temporary Lighting",
              "parentId": 1,
              "_id": 12
          },
          {
              "checked": false,
              "code": "517",
              "description": "Temporary Telephone",
              "parentId": 1,
              "_id": 13
          },
          {
              "checked": false,
              "code": "520",
              "description": "Construction Facilities",
              "parentId": 1,
              "_id": 14
          },
          {
              "checked": false,
              "code": "523",
              "description": "Sanitary Facilities",
              "parentId": 1,
              "_id": 15
          },
          {
              "checked": false,
              "code": "530",
              "description": "Temporary Construction",
              "parentId": 1,
              "_id": 16
          },
          {
              "checked": false,
              "code": "540",
              "description": "Construction Aids",
              "parentId": 1,
              "_id": 17
          },
          {
              "checked": false,
              "code": "542\t",
              "description": "Construction Scaffolding and Platforms",
              "parentId": 1,
              "_id": 18
          },
          {
              "checked": false,
              "code": "550",
              "description": "Vehicular Access and Parking",
              "parentId": 1,
              "_id": 19
          },
          {
              "checked": false,
              "code": "560",
              "parentId": 1,
              "description": "Temporary Barriers and Enclosures",
              "_id": 20
          },
          {
              "checked": false,
              "code": "570",
              "description": "Temporary Controls",
              "parentId": 1,
              "_id": 21
          },
          {
              "checked": false,
              "code": "580",
              "description": "Project Identification",
              "parentId": 1,
              "_id": 22
          },
          {
              "checked": false,
              "code": "600",
              "description": "Product Requirements (Scope of Work)",
              "parentId": 1,
              "_id": 23
          },
          {
              "checked": false,
              "code": "630",
              "description": "Product Substitution Procedures",
              "parentId": 1,
              "_id": 24
          },
          {
              "checked": false,
              "code": "640",
              "description": "Owner Furnished Products",
              "parentId": 1,
              "_id": 25
          },
          {
              "checked": false,
              "code": "700",
              "description": "Execution Requirements",
              "parentId": 1,
              "_id": 26
          },
          {
              "checked": false,
              "code": "712",
              "description": "Local Conditions",
              "parentId": 1,
              "_id": 27
          },
          {
              "checked": false,
              "code": "740",
              "description": "Cleaning",
              "parentId": 1,
              "_id": 28
          },
          {
              "checked": false,
              "code": "760",
              "description": "Protecting Installed Construction",
              "parentId": 1,
              "_id": 29
          },
          {
              "checked": false,
              "code": "903",
              "description": "Hazardous Materials Abatement",
              "parentId": 1,
              "_id": 30
          },
          {
              "checked": false,
              "code": "904",
              "description": "Hazardous Materials Removal and Disposal",
              "parentId": 1,
              "_id": 31
          }
      ],
      "project_id":projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 2,
      "code": "02",
      "description": "Site Construction",
      "children": [
          {
              "checked": false,
              "parentId": 2,
              "code": "000",
              "description": "General ",
              "_id": 32
          },
          {
              "checked": false,
              "code": "200",
              "description": "Site Preparation",
              "parentId": 2,
              "_id": 33
          },
          {
              "checked": false,
              "code": "220",
              "description": "Site Demolition",
              "parentId": 2,
              "_id": 34
          },
          {
              "checked": false,
              "code": "230",
              "description": "Site Clearing",
              "parentId": 2,
              "_id": 35
          },
          {
              "checked": false,
              "code": "240",
              "description": "Dewatering",
              "parentId": 2,
              "_id": 36
          },
          {
              "checked": false,
              "code": "250",
              "description": "Shoring and Underpinning",
              "parentId": 2,
              "_id": 37
          },
          {
              "checked": false,
              "code": "260",
              "description": "Excavation Support and Protection",
              "parentId": 2,
              "_id": 38
          },
          {
              "checked": false,
              "code": "300",
              "description": "Earthwork",
              "parentId": 2,
              "_id": 39
          },
          {
              "checked": false,
              "code": "310",
              "description": "Grading",
              "parentId": 2,
              "_id": 40
          },
          {
              "checked": false,
              "code": "311",
              "description": "Final Grading",
              "parentId": 2,
              "_id": 41
          },
          {
              "checked": false,
              "code": "312",
              "description": "Rough Grading",
              "parentId": 2,
              "_id": 42
          },
          {
              "checked": false,
              "code": "315",
              "description": "Excavation",
              "parentId": 2,
              "_id": 43
          },
          {
              "checked": false,
              "code": "316",
              "description": "Backfilling",
              "parentId": 2,
              "_id": 44
          },
          {
              "checked": false,
              "code": "317",
              "description": "Select Borrow",
              "parentId": 2,
              "_id": 45
          },
          {
              "checked": false,
              "code": "320",
              "description": "Excavation and Fill",
              "parentId": 2,
              "_id": 46
          },
          {
              "checked": false,
              "code": "362",
              "description": "Termite Control",
              "parentId": 2,
              "_id": 47
          },
          {
              "checked": false,
              "code": "370",
              "description": "Erosion and Sedimentation Control",
              "parentId": 2,
              "_id": 48
          },
          {
              "checked": false,
              "code": "500",
              "description": "Utility Services",
              "parentId": 2,
              "_id": 49
          },
          {
              "checked": false,
              "code": "540",
              "description": "Septic Tank",
              "parentId": 2,
              "_id": 50
          },
          {
              "checked": false,
              "code": "621",
              "description": "Foundation Drainage Piping",
              "parentId": 2,
              "_id": 51
          },
          {
              "checked": false,
              "code": "625",
              "description": "Retaining Wall Drainage Piping",
              "parentId": 2,
              "_id": 52
          },
          {
              "checked": false,
              "code": "740",
              "description": "Flexible Pavement Asphalt Pavement",
              "parentId": 2,
              "_id": 53
          },
          {
              "checked": false,
              "code": "750",
              "description": "Concrete Pads and Walks",
              "parentId": 2,
              "_id": 54
          },
          {
              "checked": false,
              "code": "770",
              "description": "Curb and Gutters",
              "parentId": 2,
              "_id": 55
          },
          {
              "checked": false,
              "code": "780",
              "description": "Clay Unit Pavers",
              "parentId": 2,
              "_id": 56
          },
          {
              "checked": false,
              "code": "781",
              "description": "Asphalt Pavers",
              "parentId": 2,
              "_id": 57
          },
          {
              "checked": false,
              "code": "782",
              "description": "Brick Pavers",
              "parentId": 2,
              "_id": 58
          },
          {
              "checked": false,
              "code": "784",
              "description": "Interlocking Concrete Unit Paving",
              "parentId": 2,
              "_id": 59
          },
          {
              "checked": false,
              "code": "784",
              "description": "Stone Unit Pavers",
              "parentId": 2,
              "_id": 60
          },
          {
              "checked": false,
              "code": "790",
              "description": "Athletic Surfacing",
              "parentId": 2,
              "_id": 61
          },
          {
              "checked": false,
              "code": "795",
              "description": "Porous Paving",
              "parentId": 2,
              "_id": 62
          },
          {
              "checked": false,
              "code": "800 ",
              "description": "Site Amenities",
              "parentId": 2,
              "_id": 63
          },
          {
              "checked": false,
              "code": "812",
              "description": "Drip Irrigation",
              "parentId": 2,
              "_id": 64
          },
          {
              "checked": false,
              "code": "813",
              "description": "Lawn Sprinkling and Irrigation",
              "parentId": 2,
              "_id": 65
          },
          {
              "checked": false,
              "code": "815",
              "description": "Fountains",
              "parentId": 2,
              "_id": 66
          },
          {
              "checked": false,
              "code": "820",
              "description": "Fences and Gates",
              "parentId": 2,
              "_id": 67
          },
          {
              "checked": false,
              "code": "821",
              "description": "Chain Link Fences",
              "parentId": 2,
              "_id": 68
          },
          {
              "checked": false,
              "code": "822",
              "description": "Ornamental Metal Fences and Gates",
              "parentId": 2,
              "_id": 69
          },
          {
              "checked": false,
              "code": "823",
              "description": "PVC Fences and Gates",
              "parentId": 2,
              "_id": 70
          },
          {
              "checked": false,
              "code": "824",
              "description": "Wire Fences and Gates",
              "parentId": 2,
              "_id": 71
          },
          {
              "checked": false,
              "code": "825",
              "description": "Wood Fences and Gates",
              "parentId": 2,
              "_id": 72
          },
          {
              "checked": false,
              "code": "830",
              "description": "Retaining Walls",
              "parentId": 2,
              "_id": 73
          },
          {
              "checked": false,
              "code": "850",
              "description": "Bridges/Footbridges",
              "parentId": 2,
              "_id": 74
          },
          {
              "checked": false,
              "code": "870",
              "description": "Sculpture/Ornamental",
              "parentId": 2,
              "_id": 75
          },
          {
              "checked": false,
              "code": "900",
              "description": "Landscaping",
              "parentId": 2,
              "_id": 76
          },
          {
              "checked": false,
              "code": "915",
              "description": "Mulch",
              "parentId": 2,
              "_id": 77
          },
          {
              "checked": false,
              "code": "917 ",
              "description": "Soil Preparation",
              "parentId": 2,
              "_id": 78
          },
          {
              "checked": false,
              "code": "919",
              "description": "Topsoil",
              "parentId": 2,
              "_id": 79
          },
          {
              "checked": false,
              "code": "923",
              "description": "Seeding and Soil Supplements",
              "parentId": 2,
              "_id": 80
          },
          {
              "checked": false,
              "code": "924",
              "description": "Sodding",
              "parentId": 2,
              "_id": 81
          },
          {
              "checked": false,
              "code": "930",
              "description": "Exterior Plants",
              "parentId": 2,
              "_id": 82
          },
          {
              "checked": false,
              "code": "932",
              "description": "Plants and Bulbs",
              "parentId": 2,
              "_id": 83
          },
          {
              "checked": false,
              "code": "935",
              "description": "Plant Maintenance",
              "parentId": 2,
              "_id": 84
          },
          {
              "checked": false,
              "code": "936",
              "description": "Fertilizer    ",
              "parentId": 2,
              "_id": 85
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 3,
      "code": "03",
      "description": "Concrete",
      "children": [
          {
              "checked": false,
              "code": "000",
              "description": "General",
              "parentId": 3,
              "_id": 86
          },
          {
              "checked": false,
              "code": "050",
              "description": "Concrete Subcontractor",
              "parentId": 3,
              "_id": 87
          },
          {
              "checked": false,
              "code": "100",
              "description": "Concrete Reinforcement",
              "parentId": 3,
              "_id": 88
          },
          {
              "checked": false,
              "code": "210",
              "description": "Cast-In-Place Concrete",
              "parentId": 3,
              "_id": 89
          },
          {
              "checked": false,
              "code": "230",
              "description": "Anchor Bolts",
              "parentId": 3,
              "_id": 90
          },
          {
              "checked": false,
              "code": "300",
              "description": "Footings",
              "parentId": 3,
              "_id": 91
          },
          {
              "checked": false,
              "code": "310",
              "description": "Expansion Joints",
              "parentId": 3,
              "_id": 92
          },
          {
              "checked": false,
              "code": "320",
              "description": "Slab Foundations",
              "parentId": 3,
              "_id": 93
          },
          {
              "checked": false,
              "code": "330",
              "description": "Poured Concrete Basement Walls",
              "parentId": 3,
              "_id": 94
          },
          {
              "checked": false,
              "code": "350",
              "description": "Concrete Finishing",
              "parentId": 3,
              "_id": 95
          },
          {
              "checked": false,
              "code": "400",
              "description": "Precast Concrete",
              "parentId": 3,
              "_id": 96
          },
          {
              "checked": false,
              "code": "500",
              "description": "Cementitious Decks and Underlayments",
              "parentId": 3,
              "_id": 97
          },
          {
              "checked": false,
              "code": "600",
              "description": "Grouts",
              "parentId": 3,
              "_id": 98
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 4,
      "code": "04",
      "description": "Masonry",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Masonry Materials and Methods",
              "parentId": 4,
              "_id": 99
          },
          {
              "checked": false,
              "code": "200",
              "description": "Masonry Units",
              "parentId": 4,
              "_id": 100
          },
          {
              "checked": false,
              "code": "400",
              "description": " Stone",
              "parentId": 4,
              "_id": 101
          },
          {
              "checked": false,
              "code": "500",
              "description": "Refractories",
              "parentId": 4,
              "_id": 102
          },
          {
              "checked": false,
              "code": "600",
              "description": "Corrosion-Resistant Masonry",
              "parentId": 4,
              "_id": 103
          },
          {
              "checked": false,
              "code": "700",
              "description": "Simulated Masonry",
              "parentId": 4,
              "_id": 104
          },
          {
              "checked": false,
              "code": "800",
              "description": "Masonry Assemblies",
              "parentId": 4,
              "_id": 105
          },
          {
              "checked": false,
              "code": "900",
              "description": "Masonry Restoration and Cleaning",
              "parentId": 4,
              "_id": 106
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 5,
      "code": "05",
      "description": "Metals",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Metal Materials and Method",
              "parentId": 5,
              "_id": 107
          },
          {
              "checked": false,
              "code": "100",
              "description": "Structural Metals",
              "parentId": 5,
              "_id": 108
          },
          {
              "checked": false,
              "code": "200",
              "description": " Metal Joists",
              "parentId": 5,
              "_id": 109
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Metal Deck",
              "parentId": 5,
              "_id": 110
          },
          {
              "checked": false,
              "code": "400",
              "description": "Cold-Formed Metal Framing",
              "parentId": 5,
              "_id": 111
          },
          {
              "checked": false,
              "code": "500",
              "description": "Metal Fabrications",
              "parentId": 5,
              "_id": 112
          },
          {
              "checked": false,
              "code": "600",
              "description": "Hydraulic Fabrications",
              "parentId": 5,
              "_id": 113
          },
          {
              "checked": false,
              "code": "700",
              "description": "Ornamental Metal",
              "parentId": 5,
              "_id": 114
          },
          {
              "checked": false,
              "code": "800",
              "description": "Erosion Control",
              "parentId": 5,
              "_id": 115
          },
          {
              "checked": false,
              "code": "900",
              "description": "Metal Restoration and Cleaning",
              "parentId": 5,
              "_id": 116
          }
      ],
      "project_id":projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 6,
      "code": "06",
      "description": "Wood and Plastics",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Wood and Plastic Materials and Methods",
              "parentId": 6,
              "_id": 117
          },
          {
              "checked": false,
              "code": "100",
              "description": "Rough Carpentry",
              "parentId": 6,
              "_id": 118
          },
          {
              "checked": false,
              "code": "200",
              "description": "Finish Carpentry",
              "parentId": 6,
              "_id": 119
          },
          {
              "checked": false,
              "code": "400",
              "description": "Architectural Woodwork",
              "parentId": 6,
              "_id": 120
          },
          {
              "checked": false,
              "code": "500",
              "description": "Structural Plastics",
              "parentId": 6,
              "_id": 121
          },
          {
              "checked": false,
              "code": "600",
              "description": " Plastic Fabrications ",
              "parentId": 6,
              "_id": 122
          },
          {
              "checked": false,
              "code": "900",
              "description": "  Wood and Plastic Restoration and Cleaning ",
              "parentId": 6,
              "_id": 123
          }
      ],
      "project_id":projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 7,
      "code": "07",
      "description": "Thermal and Moisture Protection",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Thermal and Moisture Protection Materials and Methods",
              "parentId": 7,
              "_id": 124
          },
          {
              "checked": false,
              "code": "100",
              "description": "Damproofing and Waterproofing",
              "parentId": 7,
              "_id": 125
          },
          {
              "checked": false,
              "code": "200",
              "description": "Thermal Protection - Insulation",
              "parentId": 7,
              "_id": 126
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Shingles, Roof Tiles, and Roof Coverings",
              "parentId": 7,
              "_id": 127
          },
          {
              "checked": false,
              "code": "400",
              "description": "Roofing and Siding Panels",
              "parentId": 7,
              "_id": 128
          },
          {
              "checked": false,
              "code": "500",
              "description": " Membrane Roofing",
              "parentId": 7,
              "_id": 129
          },
          {
              "checked": false,
              "code": "600",
              "description": "Flashing and Sheet Metal",
              "parentId": 7,
              "_id": 130
          },
          {
              "checked": false,
              "code": "700",
              "description": "Roof Specialties and Accessories",
              "parentId": 7,
              "_id": 131
          },
          {
              "checked": false,
              "code": "800",
              "description": "Fire and Smoke Protection",
              "parentId": 7,
              "_id": 132
          },
          {
              "checked": false,
              "code": "900",
              "description": "Joint Sealers",
              "parentId": 7,
              "_id": 133
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 8,
      "code": "08",
      "description": "Doors and Windows",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Door and Window Materials and Methods",
              "parentId": 8,
              "_id": 134
          },
          {
              "checked": false,
              "code": "100",
              "description": "Doors",
              "parentId": 8,
              "_id": 135
          },
          {
              "checked": false,
              "code": "200",
              "description": "Wood and Plastic Doors",
              "parentId": 8,
              "_id": 136
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Specialty Doors",
              "parentId": 8,
              "_id": 137
          },
          {
              "checked": false,
              "code": "400",
              "description": "Entrances and Storefronts",
              "parentId": 8,
              "_id": 138
          },
          {
              "checked": false,
              "code": "500",
              "description": "Windows",
              "parentId": 8,
              "_id": 139
          },
          {
              "checked": false,
              "code": "600",
              "description": "Skylights",
              "parentId": 8,
              "_id": 140
          },
          {
              "checked": false,
              "code": "700",
              "description": "Hardware",
              "parentId": 8,
              "_id": 141
          },
          {
              "checked": false,
              "code": "800",
              "description": "Glazing",
              "parentId": 8,
              "_id": 142
          },
          {
              "checked": false,
              "code": "900",
              "description": "Glazed Curtain Wall",
              "parentId": 8,
              "_id": 143
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 9,
      "code": "09",
      "description": "Finishes",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Finish Materials and Methods",
              "parentId": 9,
              "_id": 144
          },
          {
              "checked": false,
              "code": "100",
              "description": "Metal Support Assemblies",
              "parentId": 9,
              "_id": 145
          },
          {
              "checked": false,
              "code": "250",
              "description": "Gypsum Wallboard",
              "parentId": 9,
              "_id": 146
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Tile",
              "parentId": 9,
              "_id": 147
          },
          {
              "checked": false,
              "code": "400",
              "description": "Terrazzo",
              "parentId": 9,
              "_id": 148
          },
          {
              "checked": false,
              "code": "500",
              "description": "Ceilings",
              "parentId": 9,
              "_id": 149
          },
          {
              "checked": false,
              "code": "600",
              "description": "Flooring",
              "parentId": 9,
              "_id": 150
          },
          {
              "checked": false,
              "code": "680",
              "description": "Carpet",
              "parentId": 9,
              "_id": 151
          },
          {
              "checked": false,
              "code": "700",
              "description": "Wall Finishes",
              "parentId": 9,
              "_id": 152
          },
          {
              "checked": false,
              "code": "800",
              "description": "Acoustical Treatment",
              "parentId": 9,
              "_id": 153
          },
          {
              "checked": false,
              "code": "900",
              "description": "Paints and Coatings",
              "parentId": 9,
              "_id": 154
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 10,
      "code": "10",
      "description": "Specialties",
      "children": [
          {
              "checked": false,
              "code": "100",
              "description": "Visual Display Boards",
              "parentId": 10,
              "_id": 155
          },
          {
              "checked": false,
              "code": "150",
              "description": "Compartments and Cubicles",
              "parentId": 10,
              "_id": 156
          },
          {
              "checked": false,
              "code": "200",
              "description": "Louvers and Vents",
              "parentId": 10,
              "_id": 157
          },
          {
              "checked": false,
              "code": "240",
              "description": "Grilles and Screens",
              "parentId": 10,
              "_id": 158
          },
          {
              "checked": false,
              "code": "250",
              "description": "Service Walls",
              "parentId": 10,
              "_id": 159
          },
          {
              "checked": false,
              "code": "260",
              "description": "Wall and Corner Guards",
              "parentId": 10,
              "_id": 160
          },
          {
              "checked": false,
              "code": "270",
              "description": "Access Flooring",
              "parentId": 10,
              "_id": 161
          },
          {
              "checked": false,
              "code": "290",
              "description": "Pest Control",
              "parentId": 10,
              "_id": 162
          },
          {
              "checked": false,
              "code": "300",
              "description": "Fireplaces and Stoves",
              "parentId": 10,
              "_id": 163
          },
          {
              "checked": false,
              "code": "340",
              "description": "Manufactured Exterior Specialties",
              "parentId": 10,
              "_id": 164
          },
          {
              "checked": false,
              "code": "350",
              "description": "Flagpoles",
              "parentId": 10,
              "_id": 165
          },
          {
              "checked": false,
              "code": "400",
              "description": "Identification Devices",
              "parentId": 10,
              "_id": 166
          },
          {
              "checked": false,
              "code": "450",
              "description": "Pedestrian Control Devices",
              "parentId": 10,
              "_id": 167
          },
          {
              "checked": false,
              "code": "500",
              "description": "Lockers",
              "parentId": 10,
              "_id": 168
          },
          {
              "checked": false,
              "code": "520",
              "description": "Fire Protection Specialties",
              "parentId": 10,
              "_id": 169
          },
          {
              "checked": false,
              "code": "530",
              "description": "Protective Covers",
              "parentId": 10,
              "_id": 170
          },
          {
              "checked": false,
              "code": "550",
              "description": "Postal Specialties",
              "parentId": 10,
              "_id": 171
          },
          {
              "checked": false,
              "code": "600",
              "description": "Partitions",
              "parentId": 10,
              "_id": 172
          },
          {
              "checked": false,
              "code": "670",
              "description": "Storage Shelving",
              "parentId": 10,
              "_id": 173
          },
          {
              "checked": false,
              "code": "700",
              "description": "Exterior Protection",
              "parentId": 10,
              "_id": 174
          },
          {
              "checked": false,
              "code": "750",
              "description": "Telephone Specialties",
              "parentId": 10,
              "_id": 175
          },
          {
              "checked": false,
              "code": "800",
              "description": "Toilet, Bath, and Laundry Specialties",
              "parentId": 10,
              "_id": 176
          },
          {
              "checked": false,
              "code": "820",
              "description": "Bathroom Accessories",
              "parentId": 10,
              "_id": 177
          },
          {
              "checked": false,
              "code": "880",
              "description": "Scales",
              "parentId": 10,
              "_id": 178
          },
          {
              "checked": false,
              "code": "900",
              "description": "Wardrobe and Closet Specialties",
              "parentId": 10,
              "_id": 179
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 11,
      "code": "11",
      "description": "Equipment",
      "children": [
          {
              "checked": false,
              "code": "010",
              "description": "Maintenance Equipment",
              "parentId": 11,
              "_id": 180
          },
          {
              "checked": false,
              "code": "020",
              "description": "Security and Vault Equipment",
              "parentId": 11,
              "_id": 181
          },
          {
              "checked": false,
              "code": "030",
              "description": "Teller and Service Equipment",
              "parentId": 11,
              "_id": 182
          },
          {
              "checked": false,
              "code": "040",
              "description": "Ecclesiastical Equipment",
              "parentId": 11,
              "_id": 183
          },
          {
              "checked": false,
              "code": "050",
              "description": "Library Equipment",
              "parentId": 11,
              "_id": 184
          },
          {
              "checked": false,
              "code": "060",
              "description": "Theater and Stage Equipment",
              "parentId": 11,
              "_id": 185
          },
          {
              "checked": false,
              "code": "070",
              "description": "Instrumental Equipment",
              "parentId": 11,
              "_id": 186
          },
          {
              "checked": false,
              "code": "080",
              "description": "Registration Equipment",
              "parentId": 11,
              "_id": 187
          },
          {
              "checked": false,
              "code": "090",
              "description": "Checkroom Equipment",
              "parentId": 11,
              "_id": 188
          },
          {
              "checked": false,
              "code": "100",
              "description": "Mercantile Equipment",
              "parentId": 11,
              "_id": 189
          },
          {
              "checked": false,
              "code": "110",
              "description": "Commercial Laundry and Dry Cleaning Equipment",
              "parentId": 11,
              "_id": 190
          },
          {
              "checked": false,
              "code": "120",
              "description": "Vending Equipment",
              "parentId": 11,
              "_id": 191
          },
          {
              "checked": false,
              "code": "130",
              "description": "Audio-Visual Equipment",
              "parentId": 11,
              "_id": 192
          },
          {
              "checked": false,
              "code": "140",
              "description": "Vehicle Service Equipment",
              "parentId": 11,
              "_id": 193
          },
          {
              "checked": false,
              "code": "150",
              "description": "Parking Control Equipment",
              "parentId": 11,
              "_id": 194
          },
          {
              "checked": false,
              "code": "160",
              "description": "Loading Dock Equipment",
              "parentId": 11,
              "_id": 195
          },
          {
              "checked": false,
              "code": "170",
              "description": "Solid Waste Handling Equipment",
              "parentId": 11,
              "_id": 196
          },
          {
              "checked": false,
              "code": "190",
              "description": "Detention Equipment",
              "parentId": 11,
              "_id": 197
          },
          {
              "checked": false,
              "code": "200",
              "description": "Water Supply and Treatment Equipment",
              "parentId": 11,
              "_id": 198
          },
          {
              "checked": false,
              "code": "280",
              "description": "Hydraulic Gates and Valves",
              "parentId": 11,
              "_id": 199
          },
          {
              "checked": false,
              "code": "300",
              "description": "Fluid Waste Treatment and Disposal Equipment",
              "parentId": 11,
              "_id": 200
          },
          {
              "checked": false,
              "code": "400",
              "description": "Food Service Equipment",
              "parentId": 11,
              "_id": 201
          },
          {
              "checked": false,
              "code": "450",
              "description": "Residential Equipment",
              "parentId": 11,
              "_id": 202
          },
          {
              "checked": false,
              "code": "460",
              "description": "Unit Kitchens",
              "parentId": 11,
              "_id": 203
          },
          {
              "checked": false,
              "code": "470",
              "description": "Darkroom Equipment",
              "parentId": 11,
              "_id": 204
          },
          {
              "checked": false,
              "code": "480",
              "description": "Athletic, Recreational, and Therapeutic Equipment",
              "parentId": 11,
              "_id": 205
          },
          {
              "checked": false,
              "code": "500",
              "description": "Industrial and Process Equipment",
              "parentId": 11,
              "_id": 206
          },
          {
              "checked": false,
              "code": "600",
              "description": "Laboratory Equipment",
              "parentId": 11,
              "_id": 207
          },
          {
              "checked": false,
              "code": "650",
              "description": "Planetarium Equipment",
              "parentId": 11,
              "_id": 208
          },
          {
              "checked": false,
              "code": "660",
              "description": "Observatory Equipment",
              "parentId": 11,
              "_id": 209
          },
          {
              "checked": false,
              "code": "680",
              "description": "Office Equipment",
              "parentId": 11,
              "_id": 210
          },
          {
              "checked": false,
              "code": "700",
              "description": "Medical Equipment",
              "parentId": 11,
              "_id": 211
          },
          {
              "checked": false,
              "code": "850",
              "description": "Navigation Equipment",
              "parentId": 11,
              "_id": 212
          },
          {
              "checked": false,
              "code": "870",
              "description": "Agricultural Equipment",
              "parentId": 11,
              "_id": 213
          },
          {
              "checked": false,
              "code": "900",
              "description": "Exhibit Equipment",
              "parentId": 11,
              "_id": 214
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 12,
      "code": "12",
      "description": "  Furnishing",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Fabrics",
              "parentId": 12,
              "_id": 215
          },
          {
              "checked": false,
              "code": "100",
              "description": "Art",
              "parentId": 12,
              "_id": 216
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Manufactured Casework",
              "parentId": 12,
              "_id": 217
          },
          {
              "checked": false,
              "code": "400",
              "description": "Furnishing and Accessories",
              "parentId": 12,
              "_id": 218
          },
          {
              "checked": false,
              "code": "500",
              "description": "Furniture",
              "parentId": 12,
              "_id": 219
          },
          {
              "checked": false,
              "code": "600",
              "description": "Multiple Seating",
              "parentId": 12,
              "_id": 220
          },
          {
              "checked": false,
              "code": "700",
              "description": "Systems Furniture",
              "parentId": 12,
              "_id": 221
          },
          {
              "checked": false,
              "code": "800",
              "description": "Interior Plants and Planters",
              "parentId": 12,
              "_id": 222
          },
          {
              "checked": false,
              "code": "900",
              "description": "Furnishing Restoration and Repair",
              "parentId": 12,
              "_id": 223
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 13,
      "code": "13",
      "description": "Special Construction",
      "children": [
          {
              "checked": false,
              "code": "010",
              "description": "Air-Supported Structures",
              "parentId": 13,
              "_id": 224
          },
          {
              "checked": false,
              "code": "020",
              "description": "Building Modules",
              "parentId": 13,
              "_id": 225
          },
          {
              "checked": false,
              "code": "030",
              "description": "Special Purpose Rooms",
              "parentId": 13,
              "_id": 226
          },
          {
              "checked": false,
              "code": "090",
              "description": "Radiation Protection",
              "parentId": 13,
              "_id": 227
          },
          {
              "checked": false,
              "code": "100",
              "description": "Lightning Protection",
              "parentId": 13,
              "_id": 228
          },
          {
              "checked": false,
              "code": "110",
              "description": "Cathodic Protection",
              "parentId": 13,
              "_id": 229
          },
          {
              "checked": false,
              "code": "120",
              "description": "Pre-Engineered Structures",
              "parentId": 13,
              "_id": 230
          },
          {
              "checked": false,
              "code": "150",
              "description": "Swimming Pools",
              "parentId": 13,
              "_id": 231
          },
          {
              "checked": false,
              "code": "160",
              "description": "Aquariums",
              "parentId": 13,
              "_id": 232
          },
          {
              "checked": false,
              "code": "165",
              "description": "Aquatic Park Facilities",
              "parentId": 13,
              "_id": 233
          },
          {
              "checked": false,
              "code": "170",
              "description": "Tubs and Pools",
              "parentId": 13,
              "_id": 234
          },
          {
              "checked": false,
              "code": "175",
              "description": "Ice Rinks",
              "parentId": 13,
              "_id": 235
          },
          {
              "checked": false,
              "code": "185",
              "description": "Kennels and Animal Shelters",
              "parentId": 13,
              "_id": 236
          },
          {
              "checked": false,
              "code": "190",
              "description": "Site-Constructed Incinerators",
              "parentId": 13,
              "_id": 237
          },
          {
              "checked": false,
              "code": "200",
              "description": "Storage Tanks",
              "parentId": 13,
              "_id": 238
          },
          {
              "checked": false,
              "code": "220",
              "description": "Filter Underdrains and Media",
              "parentId": 13,
              "_id": 239
          },
          {
              "checked": false,
              "code": "230",
              "description": "Digester Covers and Appurtenances",
              "parentId": 13,
              "_id": 240
          },
          {
              "checked": false,
              "code": "240",
              "description": "Oxygenation Systems",
              "parentId": 13,
              "_id": 241
          },
          {
              "checked": false,
              "code": "260",
              "description": "Sludge Conditioning Systems",
              "parentId": 13,
              "_id": 242
          },
          {
              "checked": false,
              "code": "280",
              "description": "Hazardous Material Remediation",
              "parentId": 13,
              "_id": 243
          },
          {
              "checked": false,
              "code": "400",
              "description": "Measurement and Control Instrumentation",
              "parentId": 13,
              "_id": 244
          },
          {
              "checked": false,
              "code": "500",
              "description": "Recording Instrumentation",
              "parentId": 13,
              "_id": 245
          },
          {
              "checked": false,
              "code": "550",
              "description": "Transportatin Control Instrumentation",
              "parentId": 13,
              "_id": 246
          },
          {
              "checked": false,
              "code": "600",
              "description": "Solar and Wind Energy Equipment",
              "parentId": 13,
              "_id": 247
          },
          {
              "checked": false,
              "code": "700",
              "description": "Security Access and Surveillance",
              "parentId": 13,
              "_id": 248
          },
          {
              "checked": false,
              "code": "800",
              "description": "Building Automation and Control",
              "parentId": 13,
              "_id": 249
          },
          {
              "checked": false,
              "code": "850",
              "description": "Detection and Alarm",
              "parentId": 13,
              "_id": 250
          },
          {
              "checked": false,
              "code": "900",
              "description": "Fire Suppression",
              "parentId": 13,
              "_id": 251
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 14,
      "code": "14",
      "description": " Conveying Systems",
      "children": [
          {
              "checked": false,
              "code": "100",
              "description": "Dumbwaiters",
              "parentId": 14,
              "_id": 252
          },
          {
              "checked": false,
              "code": "200",
              "description": "Elevators",
              "parentId": 14,
              "_id": 253
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Escalators and Moving Walks",
              "parentId": 14,
              "_id": 254
          },
          {
              "checked": false,
              "code": "400",
              "description": "Lifts",
              "parentId": 14,
              "_id": 255
          },
          {
              "checked": false,
              "code": "500",
              "description": "Material Handling",
              "parentId": 14,
              "_id": 256
          },
          {
              "checked": false,
              "code": "600",
              "description": "Hoists and Cables",
              "parentId": 14,
              "_id": 257
          },
          {
              "checked": false,
              "code": "700",
              "description": "Turntables",
              "parentId": 14,
              "_id": 258
          },
          {
              "checked": false,
              "code": "800",
              "description": "Scaffolding",
              "parentId": 14,
              "_id": 259
          },
          {
              "checked": false,
              "code": "900",
              "description": "Transportation",
              "parentId": 14,
              "_id": 260
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 15,
      "code": "15",
      "description": "Mechanical",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Mechanical Materials and Methods",
              "parentId": 15,
              "_id": 261
          },
          {
              "checked": false,
              "code": "100",
              "description": "Plumbing",
              "parentId": 15,
              "_id": 262
          },
          {
              "checked": false,
              "code": "200",
              "description": "Process Piping",
              "parentId": 15,
              "_id": 263
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Fire Protection Piping",
              "parentId": 15,
              "_id": 264
          },
          {
              "checked": false,
              "code": "400",
              "description": "Plumbing Fixtures and Equipment",
              "parentId": 15,
              "_id": 265
          },
          {
              "checked": false,
              "code": "500",
              "description": "Heat-Generation Equipment",
              "parentId": 15,
              "_id": 266
          },
          {
              "checked": false,
              "code": "600",
              "description": "Refrigeration Equipment",
              "parentId": 15,
              "_id": 267
          },
          {
              "checked": false,
              "code": "700",
              "description": "Heating, Venting, and Air Conditioning",
              "parentId": 15,
              "_id": 268
          },
          {
              "checked": false,
              "code": "800",
              "description": "Air Distribution",
              "parentId": 15,
              "_id": 269
          },
          {
              "checked": false,
              "code": "900",
              "description": "HVAC Instruments and Controls",
              "parentId": 15,
              "_id": 270
          },
          {
              "checked": false,
              "code": "950",
              "description": "Testing, Adjusting, and Balancing",
              "parentId": 15,
              "_id": 271
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 16,
      "code": "16",
      "description": "Electrical",
      "children": [
          {
              "checked": false,
              "code": "050",
              "description": "Basic Electrical Materials and Methods",
              "parentId": 16,
              "_id": 272
          },
          {
              "checked": false,
              "code": "100",
              "description": "Electrical",
              "parentId": 16,
              "_id": 273
          },
          {
              "checked": false,
              "code": "200",
              "description": "Electrical Power",
              "parentId": 16,
              "_id": 274
          },
          {
              "checked": false,
              "code": "300  ",
              "description": "Transmission and Distribution",
              "parentId": 16,
              "_id": 275
          },
          {
              "checked": false,
              "code": "400",
              "description": "Low-Voltage Distribution",
              "parentId": 16,
              "_id": 276
          },
          {
              "checked": false,
              "code": "500",
              "description": "Lighting",
              "parentId": 16,
              "_id": 277
          },
          {
              "checked": false,
              "code": "700",
              "description": "Communications",
              "parentId": 16,
              "_id": 278
          },
          {
              "checked": false,
              "code": "800",
              "description": "Sound and Video",
              "parentId": 16,
              "_id": 279
          }
      ],
      "project_id": projectIdFromLocalStorage
  },
  {
      "checked": false,
      "_id": 17,
      "code": "17",
      "description": " Markup and Contingency",
      "children": [
          {
              "checked": false,
              "code": "010",
              "description": "Contingency",
              "parentId": 17,
              "_id": 280
          },
          {
              "checked": false,
              "code": "020",
              "description": "Insurance",
              "parentId": 17,
              "_id": 281
          },
          {
              "checked": false,
              "code": "030",
              "description": " Bond",
              "parentId": 17,
              "_id": 282
          },
          {
              "checked": false,
              "code": "040  ",
              "description": "Profit",
              "parentId": 17,
              "_id": 283
          }
      ],
      "project_id": projectIdFromLocalStorage
  }
]

export default product;

// const handleSelectionChange = (e) => { checked : false,
//     setSelectedProducts(e.value);


// };
// const handleCopySelectedCodes = () => { checked : false,
//     // Copy selected codes to the second table
//     setCopiedCodes([...selectedProducts]);
// };
