import React from 'react'
import { LuReply } from "react-icons/lu"
import { MdDeleteOutline } from "react-icons/md";
import { HtmlIntoText } from '../../Components/Common/HtmlIntoText';
import moment from 'moment';
import { shortenFilename } from '../../utils';
import Popover from '../../Components/Common/Popover';

export const SingleEmailView = ({ emailData, emailType, moveEmailToSpamOrBin, emailCheckClick }) => {


    return (
        <div className='single_email_view'>

            <div className="d-flex justify-content-between align-items-center">
                <div className='singleEmailHeading'>{emailData?.subject
                }</div>
                <div className='single_email_view_date'>
                    {moment(emailData.createdAt).format('MM/DD [at] hh:mm a')}
                </div>
            </div>


            <div className='d-flex align-items-center  gap-2 flex-wrap mt-2'>


                <div>
                    {emailType !== "bin" && <button className='single_email_secondary_btns' onClick={() => emailCheckClick(emailData)}>Add Tag</button>}
                </div>

                {/* {emailType === "sent" &&
                    <button onClick={() => {
                        moveEmailToSpamOrBin(emailData?._id, "spam")
                    }} className='single_email_secondary_btns'>
                        Mark as Spam
                    </button>
                }

                {emailType !== "bin" && <button className='single_email_secondary_btns'
                    onClick={() => {
                        moveEmailToSpamOrBin(emailData?._id, "bin")
                    }}
                >
                    <MdDeleteOutline />
                    Delete
                </button>} */}


            </div>
            <hr />

            <div className='tag_card_con'>
                {emailData?.tagData?.map(e => <div className='tag_card_item'>{e.tag_name}</div>)}
            </div>
            <br />
            <div className='single_email_view_content_basic'>
                <span>From:</span>
                <span>{emailData?.fromData?.[0]?.email}</span>
            </div>

            <div className='single_email_view_content_basic'>
                <span>To:</span>
                <span>{emailData?.to?.join(",")}</span>
            </div>
            <div className='single_email_view_content_basic'>
                <span>CC:</span>
                <span>{emailData?.cc?.join(",")}</span>
            </div>
            <div className='single_email_view_content_basic'>
                <span>Sub:</span>
                <span>{emailData?.subject}</span>
            </div>
            <div>
                <span>Attachments:</span>
                <span>{emailData?.attachments?.map(e => shortenFilename(e?.fileName || ""))?.join(",")}</span>
            </div>

            <div className='sigle_email_view_html'>
                <HtmlIntoText html={emailData?.message} />
            </div>



        </div>
    )
}
