
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';

import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import Popover from '../../../../Components/Common/Popover';
import { useNavigate } from 'react-router-dom';

import Pagination from '../../../../Components/Common/Pagination/Pagination';

const sampleData = [
  { id: 1, Name: 'Original Budget', Description: 'Original Budget Version', Taken_by: 'Joshua Templeton', Taken_on: '25/04/2024 at 02:30 pm',  },
  { id: 2, Name: 'Version 2.0', Description: 'Changes due to delay', Taken_by: 'Ericc Pager', Taken_on: '11/05/2024 at 05:16 pm',  },

  
  
];

export const SnapShots = () => {
  const [budgetChanges, setBudgetChanges] = useState([]);
  const [page, setPage] = useState(1)
const [totalRecords, setTotalRecords] = useState(0)
const perPageData = 10
  const navigate = useNavigate();

  useEffect(() => {
    setBudgetChanges(sampleData);
    setTotalRecords(5)
  }, []);
  return (
    <div>
    <div className="mt-4 d-sm-flex gap-3 justify-content-between align-items-center">
      <div className="searchInput">
        <SearchInput
          placeholder={"search"}
        />
      </div>
      <div className="d-flex text-nowrap gap-2 mt-sm-0 mt-2">
        <button className="filter_btn w-100"> Filter Modifications</button>
        <button className="filter_btn w-100">Clear Filter</button>
      </div>
    </div>

    <div className="card mt-4">
      <DataTable value={budgetChanges} rows={5}>
        <Column field="id" header="#" />

        <Column field="Name" header="Name" />
        <Column field="Description" header="Description" />
        <Column field="Taken_by" header="Taken by" />
        <Column field="Taken_on" header="Taken on" />
     
        <Column
          header=""
          body={(rowData, meta) => (
            <div className="flex justify-content-center">
              <Popover
                options={["Mark as Approved","Mark as Void"]}
                onSelect={(index, event) => {
                  if (index === 0)
                    navigate(``);
                }}
              />
          
            </div>
          )}
        />
      </DataTable>
    </div>
      
      <div className='mt-2'>
      <Pagination
                  currentPage={page}
                  totalCount={totalRecords}
                  pageSize={perPageData}
                  onPageChange={page => {
                     
                      setPage(page)
                  }}
              />
      </div>
  </div>
  )
}
