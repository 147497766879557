import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ViewPageTable } from "../../Components/Common/ViewPageComponents/ViewPageTable";
import { Statuscomp } from "../../Components/Common/StatusComp";
import { ViewPageDocumentName } from "../../Components/Common/ViewPageComponents/ViewPageDocumentName";
import { getIssueById } from "../../Api/Admin/AllAPIs";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import FormSkeleton from "../../Components/Common/FormSkeleton";
import ViewVersion from "../../Components/Common/ViewVersion";
import { ViewPagesPdfTemplate } from "../../Components/Common/ViewPageComponents/ViewPagesPdfTemplate";
import { downloadPdf } from "../../Components/Common/ViewPageComponents/downloadPdf";
import moment from "moment";

export const ViewIssue = ({ usedAsComponent, includeVersionHistory }) => {
  let pdfRef = useRef();
  let [data, setData] = useState({});
  let navigate = useNavigate();
  let { id } = useParams();

  const [skeletonLoader, setSkeletonLoader] = useState(true);
  const skeletonData = [
    { qty: 1, height: "2.5rem" },
    { qty: 2, height: "2.5rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },
    { qty: 2, height: "1.4rem" },

    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "1.4rem" },
    { qty: 1, height: "5rem" },


  ]

  async function getData() {
    try {
      setSkeletonLoader(true);
      let res = await getIssueById(id);
    
      setData(res?.data?.data?.[0] || {});
    } catch (err) {
      console.log(err);
    }
    finally {
      setSkeletonLoader(false);
    }
  }

  function pdfDownload() {
    downloadPdf(pdfRef.current, [
      {
        heading: "Attached Documents",
        files: data?.attachFile?.map((e) => e?.fileUrl),
      },
    ],"Issue");
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div>

        <div className="paint-touch">
          {
            skeletonLoader ? <FormSkeleton data={skeletonData} mt={"mt-3"} gap={"gap-3"} /> :
              <>
                <div className="row">
                  <div className="col-12 py-3 ">
                    <span
                      className="open_Rfi_text"
                      onClick={() =>
                        navigate("/projectManagement/coordinationIssues")
                      }
                    >
                      {" "}
                      Issues
                    </span>
                    <span className="px-1 open_Rfi_text"> /</span>
                    <span className="createNew_text"> View Issue</span>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="d-flex justify-content-between mb-3">
                    <h1 className="heading">{data?.issue_title} </h1>
                    <div className="d-flex align-items-center gap-2">
                    {!usedAsComponent &&
 <div
                        className="d-flex gap-2 align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={pdfDownload}
                      >
                        <AiOutlineExport />
                        Export
                        <MdOutlineKeyboardArrowDown />
                      </div>}

                      {/* <div className="d-flex" style={{ cursor: "pointer" }}>
                                    <BsThreeDotsVertical />
                                </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          { "Issue Title": data?.issue_title || "" },
                          { "Issue ID": data?.issue_ID || "" },

                          { "Responsible Person": data?.responsiblePerson?.map(e => e?.first_name).join(", ") || "--" },
                          { Location: data?.issue_location || "" },
                     
                          {
                            "Responsible Contractor Company": data?.responsibleContractor?.map(e => e?.
                              company_name).join(", ") || "--"
                          },
                          { "Reviewer": data?.reviewer?.first_name || "--" },
                          { "Reviewer Comment":data?.reviewer_comment || "--" }
                        ]}
                      />
                    </div>
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          { "Due Date": moment(data?.issue_dueDate).format("DD/MM/YYYY") },
                          { "Issue Type": data?.issue_type?.name || "--" },
                          {"Responsible Member":data?.responsibleMember?.map(e => e?.first_name).join(", ") || "--" },
                          {
                            "Issue Identified by": data?.
                              Issue_IdentifiedBy?.map(e => e?.first_name).join(", ") || "--"
                          },
                        ,
                          { Trade: data?.issue_trade?.name || "--" },
                          { Priority: data?.issue_priority?.name || "--" },
                          { "Approver": data?.approver?.first_name || "--" },
                          { "Approver Comment":data?.approver_comment || "--" }
                        ]}
                      />

                    </div>
                  </div>

                  <div className="view_page_para_head mt-4 mb-2">
                    Specification Description:
                  </div>
                  <div
                    className="view_page_bold_text"
                    dangerouslySetInnerHTML={{ __html: data?.issue_description }}
                  ></div>

                  <div className="view_page_para_head mt-4 mb-2">
                    Attached Document:
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    {data?.issue_file?.map((file, index) => (
                      <ViewPageDocumentName docUrl={file?.url} name={file.fileName} key={index} />
                    ))}
                  </div>
                </div>

              </>}

          {(!usedAsComponent || includeVersionHistory) && (
            <>
              <hr />
              <ViewVersion isView={true} />
              <br />
              <br />
            </>
          )}

          <div style={{ height: "0px", overflow: "hidden" }}>
            <div ref={pdfRef}>
            <div className="row">
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                          { "Issue Title": data?.issue_title || "" },
                          { "Issue ID": data?.issue_ID || "" },

                          { "Responsible Person": data?.responsiblePerson?.map(e => e?.first_name).join(", ") || "--" },
                          { Location: data?.issue_location || "" },
                     
                          {
                            "Responsible Contractor Company": data?.responsibleContractor?.map(e => e?.
                              company_name).join(", ") || "--"
                          },
                          { "Reviewer": data?.reviewer?.[0]?.first_name || "--" },
                          { "Reviewer Comment":data?.reviewer_comment || "--" }
                        ]}
                      />
                    </div>
                    <div className="col-6">
                      <ViewPageTable
                        tableData={[
                         
                          { "Due Date": moment(data?.issue_dueDate).format("DD/MM/YYYY") },
                          { "Issue Type": data?.issue_type?.name || "--" },
                          {"Responsible Member":data?.responsibleMember?.map(e => e?.first_name).join(", ") || "--" },
                          {
                            "Issue Identified by": data?.
                              Issue_IdentifiedBy?.map(e => e?.first_name).join(", ") || "--"
                          },
                        ,
                          { Trade: data?.issue_trade?.name || "--" },
                          { Priority: data?.issue_priority?.name || "--" },
                          { "Approver": data?.approver?.first_name || "--" },
                          { "Approver Comment":data?.approver_comment || "--" }
                        ]}
                        />
                       

                    </div>
                  </div>

              <div className="ml-2"><ViewVersion isView={true} /></div>
              <div>
                <br />

              </div>
            </div>
          </div>
        </div>
      </div>
      {!usedAsComponent &&

      <div className="d-flex justify-content-end mt-2">
        <button className='btn border' onClick={() => navigate("/projectManagement/coordinationIssues")}>Back</button>
      </div>}
      <br />
    </div>
  );
};
